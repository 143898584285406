import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  CSSProperties,
} from "react";

import { Column, RowInfo } from "react-table-6";
import { useHistory } from "react-router-dom";

// UX
import CardIntraTelepharQueueUX from "./CardIntraTelepharQueueUX";
import CardFilterOnlineQueue from "./CardFilterOnlineQueue";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";
import ModInfo from "react-lib/appcon/common/ModInfo";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

// Interface
import {
  State,
  RunSequence,
  MasterOptionsType,
  INPROGRESS_RX_STATUS,
  INPROGRESS_RX_TYPE,
  APPROVE_OPTIONS,
  TYPE_LABEL,
  BILLED_RX_STATUS,
  BILLED_RX_TYPE,
  INTRA_TELEPHAR_TYPE,
  INTRA_TELEPHAR_STATUS,
  CALL_TYPE,
  CALL_STATUS,
  ONLINE_QUEUE_FILTER,
  DrugOrderQueueSerializer,
  CURRENT_DATE,
} from "./sequence/IntraTelepharQueue";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { Icon, Popup } from "semantic-ui-react";
import { useIntl } from "react-intl";

// Types
type CardIntraTelepharQueueProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // seq
  runSequence: RunSequence;
  IntraTelepharQueueSequence?: State["IntraTelepharQueueSequence"];
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  selectedDivision?: Record<string, any>;

  // options
  masterOptions?: MasterOptionsType;

  // callback
  forward: () => any;
};

// Images
const IMAGES = {
  patient_miss_meds: "/static/images/intratelephar/patient-miss-meds.png",
  phone_calling_01: "/static/images/intratelephar/phone-calling-01.png",
  phone_calling_02: "/static/images/intratelephar/phone-calling-02.png",
};

// Styles
const COLORS = {
  white: "rgba(245, 245, 245, 1)",
  grey: "rgba(85, 85, 85, 1)",
  red: "rgba(218, 0, 0, 1)",
  half_red: "rgba(255, 0, 0, 0.6)",
  green: "rgba(27, 157, 44, 1)",
};

const getArrowStyle = (
  direction: string,
  position: number,
  right: number,
  rotation: number
) => ({
  position: "absolute",
  [direction]: `${position}px`,
  zIndex: "1",
  transform: `rotate(${rotation}deg)`,
  right: `${right}px`,
});

const styles = {
  collapse: {
    top: getArrowStyle("top", 2, -27, 133),
    bottom: getArrowStyle("bottom", 2, -11, -45),
  },
  expend: {
    top: getArrowStyle("top", -1, -20, -45),
    bottom: getArrowStyle("bottom", -2, -19, 133),
  },
  not_found: {
    display: "grid",
    textAlign: "center",
    whiteSpace: "pre-line",
    lineHeight: "1.35",
    margin: "0 -0.75rem",
  } as CSSProperties,
};

const COLLAPSE_EXPEND_INIT = {
  inprogress: {
    collapse: { height: "calc(50dvh - 6.25rem)", minRows: 7 },
    expend: { height: "calc(50dvh - -10rem)", minRows: 13 },
    type: "collapse",
  },
  billed: {
    collapse: { height: "calc(50dvh - 12.5rem)", minRows: 5 },
    expend: { height: "calc(50dvh - -7.5rem)", minRows: 12 },
    type: "collapse",
  },
} as const;

const BUTTON_ACTIONS = {
  search: "_SEARCH",
};

const CARD_INTRA_TELEPHAR_QUEUE = "CardIntraTelepharQueue";

const ACTION_SEARCH = `${CARD_INTRA_TELEPHAR_QUEUE}${BUTTON_ACTIONS.search}`;

const CardIntraTelepharQueue = (props: CardIntraTelepharQueueProps) => {
  const intl = useIntl();
  const history = useHistory();

  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [collapseExpend, setCollapseExpend] = useState(COLLAPSE_EXPEND_INIT);

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "IntraTelepharQueue",
      restart: true,
    });
  }, []);

  // Callback
  const handleSelectedItem = useCallback(
    (data: any) => async (value: any) => {
      props.setProp(
        `IntraTelepharQueueSequence.filterRx.${data.name}`,
        value || null
      );
    },
    [props.searchedItemListWithKey]
  );

  const handleSelectRow = useCallback(async (data: any) => {
    props.runSequence({
      sequence: "IntraTelepharQueue",
      action: "SELECT_ORDER",
      data,
      history,
      // callback
      forward: props.forward,
    });
  }, []);

  const handleGetTheadThProps = useCallback(() => {
    return {
      style: {
        backgroundColor: COLORS.white,
        color: COLORS.grey,
        borderRight: "none",
        borderLeft: "none",
        fontSize: ".85rem",
      },
    };
  }, []);

  const handleGetTdProps = useCallback(
    (state: any, rowInfo: RowInfo, column: Column) => {
      const columnIds = ["patient.full_name", "order_by", "patient_name"];

      return {
        style: {
          borderRight: "none",
          color:
            rowInfo?.original?.type === INPROGRESS_RX_TYPE.STAT
              ? COLORS.red
              : "",
          textAlign: columnIds.includes(column.id || "") ? "" : "center",
        },
      };
    },
    []
  );

  const handleGetTrProps = useCallback((state: any, rowInfo: RowInfo) => {
    return {
      style: {
        backgroundColor: rowInfo?.original?.exceed_time ? COLORS.half_red : "",
      },
      onClick: () => {
        handleSelectRow(rowInfo.original);
      },
    };
  }, []);

  const formatOrderTime = useCallback((orderTime: string) => {
    return formatDatetime(orderTime, true);
  }, []);

  const mapOptions = useCallback(
    ([key, text]: any) => ({ key, value: key, text }),
    []
  );

  // Memo
  const inprogressRxStatusOptions = useMemo(() => {
    return Object.entries(INPROGRESS_RX_STATUS).map(mapOptions);
  }, []);

  const billedRxStatusOptions = useMemo(() => {
    return Object.entries(BILLED_RX_STATUS).map(mapOptions);
  }, []);

  const billedRxTypeOptions = useMemo(() => {
    return Object.entries(BILLED_RX_TYPE).map(mapOptions);
  }, []);

  const isEqualFilter = useMemo(() => {
    const formatData = (data: any) => {
      return {
        type: (data.type || []).slice().sort(),
        status: (data.status || []).slice().sort(),
      };
    };

    const filter = props.IntraTelepharQueueSequence?.filterOnlineQueue;

    return (
      JSON.stringify(formatData(ONLINE_QUEUE_FILTER)) ===
        JSON.stringify(formatData(filter || {})) || !filter
    );
  }, [props.IntraTelepharQueueSequence?.filterOnlineQueue]);

  const tableOnlineQueue = useMemo(() => {
    const minRows =
      collapseExpend.inprogress[collapseExpend.inprogress.type].minRows +
      collapseExpend.billed[collapseExpend.billed.type].minRows;

    return { minRows };
  }, [collapseExpend]);

  const inprogressRxItems = useMemo(() => {
    const lists =
      props.IntraTelepharQueueSequence?.inprogressRxList?.result || [];

    return lists.map((item) => ({
      ...item,
      order_time: formatOrderTime(item.order_time),
      type_label: <DrugTypeContent data={item} />,
    }));
  }, [props.IntraTelepharQueueSequence?.inprogressRxList]);

  const billedRxItems = useMemo(() => {
    const lists = props.IntraTelepharQueueSequence?.billedRxList?.result || [];

    return lists.map((item) => ({
      ...item,
      order_time: formatOrderTime(item.order_time),
      type_label: <DrugTypeContent data={item} />,
      bill_time: formatDatetime(item.receipts.slice(-1)[0].created, true),
    }));
  }, [props.IntraTelepharQueueSequence?.billedRxList]);

  const onlineQueueItems = useMemo(() => {
    const lists =
      props.IntraTelepharQueueSequence?.onlineQueueList?.result || [];

    return lists.map((item) => {
      let color = "";

      if (item.call_type === CALL_TYPE.CONSULT) {
        color = COLORS.red;
      } else if (item.call_status === INTRA_TELEPHAR_STATUS.COUNSELLING) {
        color = COLORS.green;
      }

      return {
        ...item,
        call_type_status: (
          <div style={{ color: color }}>
            <div>{CALL_TYPE[item.call_type]}</div>
            <div>{CALL_STATUS[item.call_status]}</div>
          </div>
        ),
        call: item.consult_status === "ON_CALL" && (
          <div className="intra-telephar-calling">
            <img src={IMAGES.phone_calling_01} alt="phone-calling-01" />
            <img src={IMAGES.phone_calling_02} alt="phone-calling-02" />
          </div>
        ),
      };
    });
  }, [props.IntraTelepharQueueSequence?.onlineQueueList]);

  // Handler
  const handleChangeValue = async (e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;

    props.setProp(`IntraTelepharQueueSequence.filterRx.${data.name}`, value);
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChangeValue(null, { name, value });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "IntraTelepharQueue",
      action: "SEARCH",
      card: ACTION_SEARCH,
    });
  };

  const handleClearFilter = () => {
    props.setProp("IntraTelepharQueueSequence.filterRx", {
      startDate: CURRENT_DATE,
      endDate: CURRENT_DATE,
      toDivisionId: props.selectedDivision?.id,
      approveId: "NONE",
    });
  };

  const handleChangeFilterInprogress = (e: any, data: any) => {
    props.runSequence({
      sequence: "IntraTelepharQueue",
      action: "FILTER_INPROGRESS_RX",
      data,
    });
  };

  const handleChangeFilterBilled = (e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;

    props.runSequence({
      sequence: "IntraTelepharQueue",
      action: "FILTER_BILLED_RX",
      data: { ...data, value },
    });
  };

  const handleClickFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleConfirmFilter = (data: any) => {
    props.runSequence({
      sequence: "IntraTelepharQueue",
      action: "FILTER_ONLINE_QUEUE",
      data,
    });

    handleCloseFilter();
  };

  const handleToggleChevron = (key: any) => (type: any) => {
    const data: any = { ...collapseExpend };

    data[key].type = type;

    setCollapseExpend(data);
  };

  const handleCloseModNotFound = () => {
    props.setProp(`errorMessage.${ACTION_SEARCH}`, null);
  };

  console.log("CardIntraTelepharQueue props", props);

  return (
    <div>
      <CardIntraTelepharQueueUX
        // data
        filterRx={props.IntraTelepharQueueSequence?.filterRx}
        filterBilled={props.IntraTelepharQueueSequence?.filterBilled}
        inprogressRxList={inprogressRxItems}
        billedRxList={billedRxItems}
        onlineQueueList={onlineQueueItems}
        billedRxCount={
          props.IntraTelepharQueueSequence?.billedRxList?.all.length
        }
        isEqualFilter={isEqualFilter}
        // options
        divisionPharmaOptions={props.masterOptions?.divisionPharma}
        divisionOptions={props.masterOptions?.division}
        approveOptions={APPROVE_OPTIONS}
        billedRxStatusOptions={billedRxStatusOptions}
        // styles
        tableInprogress={
          collapseExpend.inprogress[collapseExpend.inprogress.type]
        }
        tableBilled={collapseExpend.billed[collapseExpend.billed.type]}
        tableOnlineQueue={tableOnlineQueue}
        // callback
        onChangeValue={handleChangeValue}
        onChangeDate={handleChangeDate}
        onChangeFilterBilled={handleChangeFilterBilled}
        onClickClear={handleClearFilter}
        onClickFilter={handleClickFilter}
        // table
        onGetTheadThProps={handleGetTheadThProps}
        onGetTdProps={handleGetTdProps}
        onGetTrProps={handleGetTrProps}
        // Element
        PatientSearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="HNFirstLast"
            id="ITQ"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            inline={true}
            placeholder={"HN, First name, Last name"}
            noResultsMessage={
              <div style={styles.not_found}>
                <span>{intl.formatMessage({ id: "ไม่พบข้อมูล" })}</span>
                <span>{intl.formatMessage({ id: "กรุณาทำการค้นหาใหม่อีกครั้ง" })}</span>
              </div>
            }
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.IntraTelepharQueueSequence?.filterRx?.patientId || null
            }
            iconStyle={{ marginTop: "-0.85rem" }}
            setSelectedItem={handleSelectedItem({
              name: "patientId",
              type: "Patient",
            })}
          />
        }
        OrderBySearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="Doctor"
            id="ITQ"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            inline={true}
            noResultsMessage={
              <div style={styles.not_found}>
                <span>{intl.formatMessage({ id: "ไม่พบข้อมูล" })}</span>
                <span>{intl.formatMessage({ id: "กรุณาทำการค้นหาใหม่อีกครั้ง" })}</span>
              </div>
            }
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.IntraTelepharQueueSequence?.filterRx?.doctorId || null
            }
            iconStyle={{ marginTop: "-0.85rem" }}
            setSelectedItem={handleSelectedItem({
              name: "doctorId",
              type: "Doctor",
            })}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={ACTION_SEARCH}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SEARCH]}
            // config
            color={"blue"}
            name="SEARCH"
            size="mini"
            title={intl.formatMessage({ id: "ค้นหา" })}
          />
        }
        InprogressTypeDropdown={
          <DropdownOptions
            value={
              props.IntraTelepharQueueSequence?.filterInprogress?.type || []
            }
            name="type"
            multiple={true}
            search={true}
            searchBox={true}
            placeholder={intl.formatMessage({ id: "เลือก Type" })}
            checked={true}
            options={
              props.IntraTelepharQueueSequence?.inprogressRxTypeOptions || []
            }
            onChange={handleChangeFilterInprogress}
            fluid={true}
            style={{ width: "100%" }}
          />
        }
        InprogressStatusDropdown={
          <DropdownOptions
            value={
              props.IntraTelepharQueueSequence?.filterInprogress?.status || ""
            }
            name="status"
            multiple={true}
            search={true}
            searchBox={true}
            placeholder={intl.formatMessage({ id: "เลือก Status" })}
            checked={true}
            options={inprogressRxStatusOptions}
            onChange={handleChangeFilterInprogress}
            fluid={true}
            style={{ width: "100%" }}
          />
        }
        BilledTypeDropdown={
          <DropdownOptions
            value={props.IntraTelepharQueueSequence?.filterBilled?.type || ""}
            name="type"
            multiple={true}
            search={true}
            searchBox={true}
            placeholder={intl.formatMessage({ id: "เลือก Type" })}
            checked={true}
            options={billedRxTypeOptions}
            onChange={handleChangeFilterBilled}
            fluid={true}
            style={{ width: "100%" }}
          />
        }
        CardFilterOnlineQueue={
          openFilter && (
            <CardFilterOnlineQueue
              // data
              filterOnlineQueue={
                props.IntraTelepharQueueSequence?.filterOnlineQueue || {}
              }
              // callback
              onClose={handleCloseFilter}
              onConfirm={handleConfirmFilter}
              languageUX={props.languageUX}
            />
          )
        }
        CXIElement={
          <ChevronIcons
            type={collapseExpend.inprogress.type}
            onChange={handleToggleChevron("inprogress")}
          />
        }
        CXBElement={
          <ChevronIcons
            type={collapseExpend.billed.type}
            onChange={handleToggleChevron("billed")}
          />
        }
        languageUX={props.languageUX}
      />

      <ModInfo
        open={props.errorMessage?.[ACTION_SEARCH]}
        titleColor={"red"}
        onApprove={handleCloseModNotFound}
        onClose={handleCloseModNotFound}
      >
        <div style={{ padding: "0.5rem 0", fontWeight: "bold" }}>
          {props.errorMessage?.[ACTION_SEARCH] === "NOT_FOUND" ? (
            <div>{intl.formatMessage({ id: "ไม่พบรายการใบสั่งยาของผู้ป่วย" })}</div>
          ) : (
            <ErrorMessage error={props.errorMessage?.[ACTION_SEARCH]} />
          )}
        </div>
      </ModInfo>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      ChevronIcons                      */

/* ------------------------------------------------------ */
type ChevronIconsProps = {
  type: "collapse" | "expend";
  // callback
  onChange: (type: any) => any;
};

const ChevronIcons = (props: ChevronIconsProps) => {
  const handleChange = () => {
    const swap = {
      collapse: "expend",
      expend: "collapse",
    }[props.type];

    props.onChange(swap);
  };

  return (
    <div
      aria-hidden="true"
      style={{ cursor: "pointer" }}
      onClick={handleChange}
    >
      <Icon name="chevron left" style={styles[props.type].top} />
      <Icon name="chevron left" style={styles[props.type].bottom} />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                     DrugTypeContent                    */

/* ------------------------------------------------------ */
const DrugTypeContent = (props: { data: DrugOrderQueueSerializer }) => {
  const typeLabel = useMemo(() => {
    const result = props.data.missing
      ? {
          icon: IMAGES.patient_miss_meds,
          style: { padding: "0 2px 2px" },
        }
      : TYPE_LABEL[props.data.type];

    return result as { icon: string; style?: any; active?: string };
  }, [props.data]);

  const isShipping = useMemo(() => {
    return (
      props.data.shipping_order &&
      props.data.type === INTRA_TELEPHAR_TYPE.HOME_DELIVERY &&
      props.data.status === INTRA_TELEPHAR_STATUS.DELIVERED
    );
  }, [props.data]);

  const imgElement = useMemo(() => {
    return (
      <img
        src={isShipping ? typeLabel.active : typeLabel.icon}
        alt="drug-type"
        style={{
          width: "20px",
          marginLeft: "2px",
          ...typeLabel.style,
        }}
      />
    );
  }, [isShipping, typeLabel]);

  return (
    <div style={{ display: "flex", paddingLeft: "1rem", alignItems: "center" }}>
      {TYPE_LABEL[props.data.type].text}{" "}
      {isShipping ? (
        <Popup
          content={
            <div style={{ lineHeight: 1.75, margin: "-0.2rem 0" }}>
              <div>
                Shipper : {props.data.shipping_order.company_name || "-"}
              </div>
              <div>
                Tracking No. {props.data.shipping_order.tracking_number || "-"}
              </div>
            </div>
          }
          trigger={imgElement}
        />
      ) : (
        typeLabel.icon && imgElement
      )}
    </div>
  );
};

export default React.memo(CardIntraTelepharQueue);
