import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Input,
  Checkbox
} from 'semantic-ui-react'

const ModSelectOperatingDatetTimeUX = (props: any) => {
    return(
      <div>
        <div
          style={{height:"100%", width: "100%", position: "relative"}}>
          
          <div
            style={{padding: "10px", fontSize: "18px", fontWeight: "bold", backgroundColor: "#066cbb", color: "white"}}>
            จองวันและเวลาผ่าตัด
          </div>
          <div
            style={{position: "absolute", right: 0}}>
            
            <div>
              {props.statusName}
            </div>
          </div>
          <div
            className={`ui form ${props.disabledOn ? "--override-disabled" : ""}`}
            style={{padding: props.isAdmit ? "15px 0px 5px 0px" : "15px 0px", marginLeft: "-1.5rem"}}>
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={16}>
                <div
                  style={{width: "100%", textAlign: "center"}}>
                  {props.timeSpent}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width="1">
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label>
                  HN:
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label>
                  {props.patientHN}
                </label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{minWidth: "max-content"}}>
                  ชื่อ-สกุล
                </label>
              </FormField>
              <FormField
                inline={true}
                width={8}>
                <label>
                  {props.patientName}
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label>
                  วันนัดหมาย:
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <label>
                  {props.selectedDate}
                </label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label>
                  จาก
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{width: "100%"}}>
                  {props.startTime}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{textAlign: "center", width: "100%"}}>
                  ถึง
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{width: "100%"}}>
                  {props.endTime}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  className="required">
                  แพทย์ผ่าตัด
                </label>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <Dropdown
                  disabled={props.disabledDoctor || true}
                  fluid={true}
                  onChange={props.onChangeDoctor}
                  options={props.doctorOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.doctor}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  className="required">
                  วิสัญญีแพทย์
                </label>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <Dropdown
                  disabled={props.disabledAnesthesia}
                  fluid={true}
                  onChange={props.onChangeAnesthesia}
                  options={props.anesthesiaOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.anesthesia}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{minWidth: "max-content"}}>
                  วิธีระงับความรู้สึก
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.anesthesiaMethod || "-"}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  className="required">
                  ห้องผ่าตัด
                </label>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <Dropdown
                  disabled={props.disabledOperatingRoom}
                  fluid={true}
                  loading={props.loadingRoomOption || false}
                  onChange={props.onChangeOperatingRoom}
                  options={props.operatingRoomOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.operatingRoom}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label>
                  Procedure Type
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.procedureType || "-"}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={15}>
                <label>
                  รายการผ่าตัด
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={14}>
                <div
                  style={{width: "100%"}}>
                  {props.treatmentList}
                </div>
              </FormField>
            </FormGroup>
          </div>
          <div
            className={`ui form ${props.disabledOn ? "--override-disabled" : ""}`}
            style={{padding: "0px 0px 15px 0px", display: "none"}}>
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{width: "92px"}}>
                  นัดหมาย Admit
                </label>
                <label>
                  {props.admitCase}
                </label>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{width: "96px"}}>
                  นอน รพจำนวน
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{paddingRight: 0}}
                width={2}>
                <Input
                  disabled={props.disabledOn}
                  onChange={props.onChangeStayDays}
                  style={{width: "100%"}}
                  value={props.stayDays}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{textAlign: "center", width: "100%"}}>
                  วัน
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{width: "92px"}}>
                  วันที่ Admit
                </label>
                <div>
                  {props.admitDate}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{width: "96px"}}>
                  เวลา
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{paddingRight: 0}}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  {props.admitTime}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <Checkbox
                  checked={props.checkedRequestICU}
                  disabled={props.disabledOn}
                  label="Request ICU"
                  onChange={props.onChangeCheckedRequestICU}
                  style={{fontWeight: "bold", padding: "5px 0px"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{width: "96px"}}>
                  นอน ICU จำนวน
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{paddingRight: 0}}
                width={2}>
                <Input
                  disabled={props.disabledOn}
                  onChange={props.onChangeStayDaysICU}
                  style={{width: "100%"}}
                  value={props.stayDaysICU}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{textAlign: "center", width: "100%"}}>
                  วัน
                </label>
              </FormField>
            </FormGroup>
          </div>
          <div
            className={`ui form ${props.disabledOn ? "--override-disabled" : ""}`}
            style={{paddingLeft: "30px", display: props.isAdmit? "": "none"}}>
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{width: "92px"}}>
                  Case : 
                </label>
                <label
                  style={{ display: "flex", justifyContent: "center" }}>
                  {props.case || ""}
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <label
                  style={{width: "92px"}}>
                  วันที่ นัด Admit
                </label>
                <div>
                  {props.admitDate}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{width: "92px"}}>
                  เวลา Admit
                </label>
                <div
                  style={{ width: "100%" }}>
                  {props.admitTime}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label>
                  นอน ICU จำนวน
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{paddingRight: 0}}
                width={2}>
                <Input
                  disabled={props.disabledOn}
                  name="admitStayDate"
                  onChange={props.onChangeStayDays}
                  style={{width: "100%"}}
                  value={props.stayDays}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <label
                  style={{textAlign: "center", width: "100%"}}>
                  วัน
                </label>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <Checkbox
                  checked={props.checkedRequestICU || false}
                  label="Request ICU"
                  onChange={props.onChangeValue}
                  style={{minWidth: "max-content", fontWeight: "bold" }}>
                </Checkbox>
                <Input
                  fluid={true}
                  name="admitICUStay"
                  onChange={props.onChangeValue}
                  style={{width: "40%"}}
                  type="number"
                  value={props.stayDaysICU || ""}>
                </Input>
                <label
                  style={{fontWeight: "bold", marginLeft: "0.5rem"}}>
                  วัน
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label>
                  แผนกที่ติดต่อ
                </label>
              </FormField>
              <FormField
                inline={true}
                width={10}>
                <Dropdown
                  fluid={true}
                  name="division"
                  onChange={props.onChangeValue}
                  options={props.divisionOption}
                  selection={true}
                  style={{ width: "100%" }}
                  value={props.division}>
                </Dropdown>
              </FormField>
            </FormGroup>
          </div>
          <div
            className="ui form"
            style={{padding: "0 15px 15px"}}>
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={8}>
              </FormField>
              <FormField
                inline={true}
                width={8}>
                <div
                  style={{width: "100%", display: "flex", justifyContent: props.centeredButton ? "":"flex-end", marginLeft: props.centeredButton? "-37.5px":""}}>
                  {props.buttonList}
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default ModSelectOperatingDatetTimeUX

export const screenPropsDefault = {}

/* Date Time : Thu Nov 14 2024 12:46:04 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\", width: \"100%\", position: \"relative\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "จองวันและเวลาผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontSize: \"18px\", fontWeight: \"bold\", backgroundColor: \"#066cbb\", color: \"white\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "`ui form ${props.disabledOn ? \"--override-disabled\" : \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{padding: props.isAdmit ? \"15px 0px 5px 0px\" : \"15px 0px\", marginLeft: \"-1.5rem\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 6,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "HN:"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "วันนัดหมาย:"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedDate"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "จาก"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientHN"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientName"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผ่าตัด"
        },
        "className": {
          "type": "value",
          "value": "required"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "compact": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledDoctor || true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDoctor"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.doctor"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 47,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "วิสัญญีแพทย์"
        },
        "className": {
          "type": "value",
          "value": "required"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAnesthesia"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeAnesthesia"
        },
        "options": {
          "type": "code",
          "value": "props.anesthesiaOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.anesthesia"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 53,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "ห้องผ่าตัด"
        },
        "className": {
          "type": "value",
          "value": "required"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Dropdown",
      "parent": 57,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledOperatingRoom"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingRoomOption || false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeOperatingRoom"
        },
        "options": {
          "type": "code",
          "value": "props.operatingRoomOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.operatingRoom"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 10,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 10,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการผ่าตัด"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.treatmentList"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 70,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "code",
          "value": "props.timeSpent"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", textAlign: \"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "`ui form ${props.disabledOn ? \"--override-disabled\" : \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 0px 15px 0px\", display: \"none\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 15px 15px\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormGroup",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonList"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"flex\", justifyContent: props.centeredButton ? \"\":\"flex-end\", marginLeft: props.centeredButton? \"-37.5px\":\"\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormGroup",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 79,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 79,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.admitCase"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 79,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "นอน รพจำนวน"
        },
        "style": {
          "type": "code",
          "value": "{width: \"96px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 79,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: 0}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 79,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Input",
      "parent": 87,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledOn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStayDays"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.stayDays"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormGroup",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormField",
      "parent": 94,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 94,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 94,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{width: \"96px\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 94,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: 0}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "code",
          "value": "props.admitTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormGroup",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Checkbox",
      "parent": 109,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedRequestICU"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledOn"
        },
        "label": {
          "type": "value",
          "value": "Request ICU"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckedRequestICU"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", padding: \"5px 0px\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "props.admitDate"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "label",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "นอน ICU จำนวน"
        },
        "style": {
          "type": "code",
          "value": "{width: \"96px\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: 0}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Input",
      "parent": 114,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledOn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStayDaysICU"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.stayDaysICU"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 107,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "label",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "label",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "วิธีระงับความรู้สึก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesthesiaMethod || \"-\""
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure Type"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 125,
      "props": {
        "children": {
          "type": "code",
          "value": "props.procedureType || \"-\""
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", right: 0}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 128,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusName"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "นัดหมาย Admit"
        },
        "style": {
          "type": "code",
          "value": "{width: \"92px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ Admit"
        },
        "style": {
          "type": "code",
          "value": "{width: \"92px\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": "`ui form ${props.disabledOn ? \"--override-disabled\" : \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"30px\", display: props.isAdmit? \"\": \"none\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormGroup",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormGroup",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormGroup",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "Case : "
        },
        "style": {
          "type": "code",
          "value": "{width: \"92px\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.case || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "code",
          "value": "props.admitDate"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "label",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ นัด Admit"
        },
        "style": {
          "type": "code",
          "value": "{width: \"92px\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "label",
      "parent": 140,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา Admit"
        },
        "style": {
          "type": "code",
          "value": "{width: \"92px\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormField",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: 0}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Checkbox",
      "parent": 151,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedRequestICU || false"
        },
        "label": {
          "type": "value",
          "value": "Request ICU"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"bold\" }"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "Input",
      "parent": 151,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "admitICUStay"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.stayDaysICU || \"\""
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "นอน ICU จำนวน"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "Input",
      "parent": 150,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledOn"
        },
        "name": {
          "type": "value",
          "value": "admitStayDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStayDays"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.stayDays"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "label",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", width: \"100%\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 137,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormField",
      "parent": 137,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "label",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่ติดต่อ"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Dropdown",
      "parent": 161,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOption"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.division"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "code",
          "value": "props.admitTime"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 164,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 45,
  "isMounted": false,
  "memo": false,
  "name": "ModSelectOperatingDatetTimeUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 60
}

*********************************************************************************** */
