import React, { useEffect, useState, useRef, MutableRefObject, useMemo, useCallback } from "react";
import { Button, Modal, Image, Icon, Pagination, Label } from "semantic-ui-react";

// Framework
import { Table } from "react-lib/frameworks/Table";

// UX
import CardRegSearchUX from "./CardRegSearchUX";
import ModOpenEmergencyUX from "./ModOpenEmergencyUX";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import CardPatientOldName from "react-lib/apps/common/CardPatientOldName";

// CONFIG
import CONFIG from "config/config";
import { REG_SEARCH_LIMIT } from "./sequence/SetPatient";
import { useIntl } from "react-intl";

// Const
const MOD_OPEN_EMERGENCY = "ModOpenEmergencyUX";

const IMAGES = {
  //icon
  addPatient: "/static/images/regSearch/addPatient.png",
  addPatientGroup: "/static/images/regSearch/addPatientGroup.png",
  smartCard: "/static/images/regSearch/smartCard.png",
  addPatientEmergency: "/static/images/regSearch/addPatientEmergency.png",
  plus: "/static/images/regSearch/plus.png",
  mobilePhoneLock: "/static/images/regSearch/mobilePhoneLock.png",
  mobilePhonCheck: "/static/images/regSearch/mobilePhonCheck.png",
};

const BUTTON_ACTIONS = {
  search: "search",
  smart_card: "smartCard",
};

const CARD_REG_SEARCH = "CardRegSearch";

const ACTION_SEARCH = `${CARD_REG_SEARCH}_${BUTTON_ACTIONS.search}`;
const ACTION_SMART_CARD = `${CARD_REG_SEARCH}_${BUTTON_ACTIONS.smart_card}`;

const searchOptions = [
  { key: "auto", text: "Auto", value: "auto" },
  { key: "patient", text: "Patient", value: "patient" },
  { key: "encounterID", text: "Encounter ID", value: "encounterID" },
  { key: "encounterOPD", text: "Encounter OPD", value: "encounterOPD" },
  { key: "receipt", text: "Receipt", value: "receipt" },
  { key: "invoice", text: "Invoice", value: "invoice" },
];

const CardRegSearch = (props: any) => {
  const intl = useIntl();
  const [tab, setTab] = useState("register");

  // data
  const [dataFile, setDataFile] = useState<any>();
  // const [page, setPage] = useState(1);
  // mod
  const [openCAgentMessage, setOpenCAgentMessage] = useState<boolean>(false);
  const [openModEmergency, setOpenModEmergency] = useState<boolean>(false);
  const [openBulkRegist, setOpenBulkRegist] = useState<boolean>(false);

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [openCardPatientOldName, setOpenCardPatientOldName] = useState<boolean>(false);

  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  // useEffect(() => {
  //   props.runSequence({
  //     sequence: "SetPatient",
  //     action: "search",
  //     searchText: props.SetPatientSequence?.searchText,
  //     page: page,
  //   });
  // }, [page]);

  // Effect
  useEffect(() => {
    // get เลขที่ใบเสร็จ
    if (props.divisionType === "การเงิน") {
      props.onEvent({
        message: "HandleGetReceiptCode",
        params: {},
      });
    }
  }, [props.selectedDevice, props.divisionType]);

  useEffect(() => {
    const error = props.errorMessage?.[ACTION_SMART_CARD]?.error;

    if (error && error !== "NOT_FOUND") {
      setOpenCAgentMessage(true);
      setButtonLoading(false);
    }
  }, [props.errorMessage]);

  useEffect(() => {
    if (props.successMessage?.[MOD_OPEN_EMERGENCY]) {
      setOpenModEmergency(false);
    }
  }, [props.successMessage?.[MOD_OPEN_EMERGENCY]]);

  useEffect(() => {
    if (
      props.SetPatientSequence?.patientList &&
      props.SetPatientSequence?.patientList?.length >= 0 &&
      buttonLoading
    ) {
      setButtonLoading(false);
    }
  }, [props.SetPatientSequence?.patientList]);

  useEffect(() => {
    const list = (props.SetPatientSequence?.patientEmergencyList || [])?.map(
      (item: any, index: number) => ({
        ...item,
        no: index + 1,
      })
    );

    setDataFile(list);
  }, [props.SetPatientSequence?.patientEmergencyList]);

  useEffect(() => {
    if (!!props.SetPatientSequence?.searchScanData) {
      props.runSequence({
        sequence: "SetPatient",
        action: "select",
        patient: props.SetPatientSequence?.searchScanData,
      });

      props.forward();
    }
  }, [props.SetPatientSequence?.searchScanData]);

  // Callback
  const getMsgLabel = useCallback((error: any) => {
    return Object.entries(error).reduce((result: any[], [key]: any) => {
      const msgLabel = {
        gender: "เพศ",
      } as any;

      return [...result, msgLabel[key] || key];
    }, []);
  }, []);

  // Memo
  const errorMsgLabel = useMemo(() => {
    const error = props.errorMessage?.[MOD_OPEN_EMERGENCY];

    return !error
      ? null
      : {
          กรอกข้อมูลที่จำเป็น: getMsgLabel(error?.error),
        };
  }, [props.errorMessage]);

  const handleCloseCardPatientOldName = () => {
    setOpenCardPatientOldName(false);
  };

  const onOpenModPatientOldName = (e: any, patient: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.runSequence({
      sequence: "SetPatient",
      action: "oldname_list",
      patient: patient,
    });
    setOpenCardPatientOldName(true);
  };

  const patientList = useMemo(() => {
    return (props.SetPatientSequence?.patientList || []).map((item: any) => ({
      ...item,
      hn: (
        <div style={{ display: "flex", margin: "20px 0px" }}>
          <div>{item.hn}</div>
          <div style={{ display: "flex", margin: "0px 5px" }}>
            {item.profile_status === "VERIFIED" ? (
              <Image height={25} src={IMAGES.mobilePhonCheck} style={{ marginLeft: "10px" }} />
            ) : (
              item.profile_status === "NOT_VERIFIED" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Image height={25} src={IMAGES.mobilePhoneLock} style={{ marginLeft: "10px" }} />
                  <div style={{ color: "red", fontSize: "10px" }}>{intl.formatMessage({ id: "รอยืนยันประวัติ" })}</div>
                </div>
              )
            )}
          </div>
        </div>
      ),
      old_name: item.old_name !== "" && (
        <div>
          {item.old_name}
          &nbsp;
          <Button
            size="mini"
            color="blue"
            icon="history"
            onClick={(e: any) => onOpenModPatientOldName(e, item)}
          />
        </div>
      ),
    }));
  }, [props.SetPatientSequence?.patientList]);

  // Handler
  const handleCloseCAgent = () => {
    setOpenCAgentMessage(false);

    props.setProp(`errorMessage.${ACTION_SMART_CARD}`, null);
  };

  const handleChangeFile = (event: any) => {
    const file = event?.target?.files?.[0];

    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);

      fileReader.onload = async (event: any) => {
        const XLSX = await import("xlsx");

        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

        const list: any[] = rowObject.map((item: any, index: number) => ({
          ...item,
          no: index + 1,
          hn: "",
          pre_name: item["คำนำหน้า"],
          first_name_th: item["ชื่อ"],
          last_name_th: item["นามสกุล"],
          gender: item["เพศ"],
        }));

        setDataFile(list);
      };
    } else {
      fileRef.current.value = "";
    }
  };

  const handlePaginationChange = async (e: any, { activePage }: any) => {
    await setButtonLoading(true);

    props.runSequence({
      sequence: "SetPatient",
      action: "search",
      searchText: props.SetPatientSequence?.searchText,
      citizenText: props.SetPatientSequence?.citizenText,
      page: activePage,
      limit: REG_SEARCH_LIMIT,
      card: CARD_REG_SEARCH,
    });
  };

  const changeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`SetPatientSequence.patientEmergency.${key}`, v.value);
  };

  const handleCloseModGroupPatientHistory = () => {
    setOpenBulkRegist(false);

    props.setProp("SetPatientSequence.patientEmergencyList", []);
  };

  // Import File
  const handleClickUpload = () => {
    fileRef.current?.click?.();
  };

  const handleSearchText = (e: any, v: any) => {
    props.setProp("SetPatientSequence.searchText", v.value);
  };

  const handleCitizenText = (e: any, v: any) => {
    props.setProp("SetPatientSequence.citizenText", v.value);
  };

  const handleClickSmartCard = async (e: any) => {
    await setButtonLoading(true);

    props.runSequence({
      sequence: "SetPatient",
      action: "smartCard",
      card: CARD_REG_SEARCH,
    });
  };

  const handleSearch = async (e: any) => {
    await setButtonLoading(true);

    // setPage(1);
    props.runSequence({
      sequence: "SetPatient",
      action: "search",
      searchText: props.SetPatientSequence?.searchText,
      citizenText: props.SetPatientSequence?.citizenText,
      page: 1,
      limit: REG_SEARCH_LIMIT,
      card: CARD_REG_SEARCH,
    });
  };

  const handleNewPatient = (patient: any = {}) => {
    handleCloseModSmartCard();

    props.runSequence({
      sequence: "SetPatient",
      action: "new",
      patient: patient,
    });

    props.forward();
  };

  const handlePatientRowProps = (state: any, rowInfo: any, column: any, instance: any) => {
    return {
      onClick: async (e: any) => {
        props.runSequence({
          sequence: "SetPatient",
          action: "select",
          patient: rowInfo?.original,
        });

        props.forward();
      },
    };
  };

  const handleOpenEmergency = () => {
    setOpenModEmergency(true);
  };

  const handleCloseModSmartCard = () => {
    props.setProp(`errorMessage.${ACTION_SMART_CARD}`, null);
  };

  const handleSearchType = (e: any, v: any) => {
    props.setProp(`SetPatientSequence.${v.name}`, v.value);
  };

  const handleSearchScan = () => {
    props.runSequence({
      sequence: "SetPatient",
      action: "searchScan",
      buttonLoadKey: `${CARD_REG_SEARCH}_searchScan`,
    });
  };

  const handleCloseSearchScan = () => {
    props.setProp("errorMessage", {
      ...props.errorMessage,
      [`${CARD_REG_SEARCH}_searchScan`]: null,
    });
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {props.divisionType === "การเงิน" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: "1",
            }}
          >
            <Label
              size="large"
              style={{
                backgroundColor: "#FDC38E",
                color: "black",
                fontWeight: "normal",
              }}
            >
              เลขที่ใบเสร็จปัจจุบัน :{" "}
              {typeof props.bilReceiptCodeDetail?.code === "undefined"
                ? "-"
                : props.bilReceiptCodeDetail?.code || "ยังไม่ได้ตั้งเลขที่ใบเสร็จ"}
            </Label>
          </div>
        </div>
      )}

      {/* <div
        style={{
          display: "flex",
          padding: "5px 0 10px 0",
          flex: "1 0 auto"
        }}
      >
        <MenuItem
          tab={tab}
          name="register"
          title={intl.formatMessage({ id: "เปิดประวัติ (บุคคล)" })}
          setTab={setTab}
        />
        {!CONFIG.V3_HIDDEN_TAB_LIST.includes("emergency") && (
          <MenuItem
            tab={tab}
            name="emergency"
            title={intl.formatMessage({ id: "เปิดประวัติหมู่" })}
            setTab={setTab}
          />
        )}
      </div> */}
      <Modal open={openCardPatientOldName}>
        <CardPatientOldName
          hideCallBack={handleCloseCardPatientOldName}
          hn={props.SetPatientSequence?.patient?.hn || "-"}
          fullName={props.SetPatientSequence?.patient?.full_name || "-"}
          patientOldNameLoading={false}
          patientOldNameDataList={props.SetPatientSequence?.patientOldNameList || []}
          languageUX={props.languageUX}
        />
      </Modal>
      {tab === "register" ? (
        <CardRegSearchUX
          // data
          searchText={props.SetPatientSequence?.searchText}
          citizenText={props.SetPatientSequence?.citizenText}
          patientList={patientList || []}
          // callback
          changeSearchText={handleSearchText}
          changeCitizenText={handleCitizenText}
          onSearch={handleSearch}
          onNewPatient={() => handleNewPatient()}
          patientRowProps={handlePatientRowProps}
          OnEmergency={handleOpenEmergency}
          // condig
          // isFinance={["การเงิน"].includes(props.divisionType || "")}
          isHidden={
            ["การเงิน", "backoffice"].includes(props.divisionType || "") ||
            CONFIG.V3_HIDDEN_TAB_LIST.some((i: any) => i === "submenu_createpatient")
          }
          // scan patient
          isHiddenScan={["การเงิน", "backoffice"].includes(props.divisionType || "")}
          searchOptions={searchOptions}
          changeSearchType={handleSearchType}
          searchData={props.SetPatientSequence}
          scanPlaceholder={
            searchOptions.find((item: any) => item?.value === props.SetPatientSequence?.searchType)
              ?.text
          }
          buttonSearchScan={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSearchScan}
              // data
              paramKey={`${CARD_REG_SEARCH}_searchScan`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_REG_SEARCH}_searchScan`]}
              // config
              icon={"search"}
              color={"green"}
              size="medium"
              style={{ height: "100%" }}
            />
          }
          // Element
          addPatientName={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image height={15} src={IMAGES.addPatient} style={{ marginRight: "10px" }} />
              {intl.formatMessage({ id: "เปิดประวัติ" })}
            </div>
          }
          addPatientGroupName={
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setOpenBulkRegist(true)}
            >
              <Image height={10} src={IMAGES.plus} style={{ marginRight: "5px" }} />
              <Image height={15} src={IMAGES.addPatientGroup} style={{ marginRight: "10px" }} />
              {intl.formatMessage({ id: "เปิดประวัติหมู่" })}
            </div>
          }
          addPatientEmergencyName={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image height={15} src={IMAGES.addPatientEmergency} style={{ marginRight: "10px" }} />
              {intl.formatMessage({ id: "เปิดประวัติฉุกเฉิน" })}
            </div>
          }
          paginationComponent={
            <Pagination
              activePage={props.SetPatientSequence?.page || 1}
              onPageChange={handlePaginationChange}
              totalPages={
                props.SetPatientSequence?.patientListTotal !== undefined
                  ? Math.ceil(props.SetPatientSequence?.patientListTotal / REG_SEARCH_LIMIT)
                  : 0
              }
            />
          }
          NoDataComponent={
            <NoDataComponent
              // seq
              SetPatientSequence={props.SetPatientSequence}
              // CommonInterface
              buttonLoadCheck={props.buttonLoadCheck}
              buttonLoading={buttonLoading}
            />
          }
          buttonSearch={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSearch}
              // data
              paramKey={ACTION_SEARCH}
              buttonLoadCheck={
                props.buttonLoadCheck?.[ACTION_SMART_CARD]
                  ? null
                  : props.buttonLoadCheck?.[ACTION_SEARCH]
              }
              // config
              color={"blue"}
              name={"search"}
              size="medium"
              title={intl.formatMessage({ id: "ค้นหา" })}
              style={{ height: "100%" }}
            />
          }
          buttonSmartCard={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleClickSmartCard}
              // data
              paramKey={ACTION_SMART_CARD}
              buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SMART_CARD]}
              // config
              color={"green"}
              basic={true}
              name={BUTTON_ACTIONS.smart_card}
              size="medium"
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Image height={15} src={IMAGES.smartCard} style={{ marginRight: "10px" }} />
                  Smart Card
                </div>
              }
              style={{ height: "100%" }}
            />
          }
          languageUX={props.languageUX}
        />
      ) : (
        ""
      )}

      <Modal
        open={openBulkRegist}
        closeOnDimmerClick={true}
        onClose={handleCloseModGroupPatientHistory}
      >
        <>
          <div
            style={{
              backgroundColor: "var(--primary-theme-color)",
              padding: "15px 20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", color: "white" }}>
              <Image height={10} src={IMAGES.plus} style={{ marginRight: "5px" }} />
              <Image height={15} src={IMAGES.addPatientGroup} style={{ marginRight: "10px" }} />
              เปิดประวัติหมู่
            </div>
            <Icon
              name="close"
              link={true}
              style={{ color: "white" }}
              onClick={handleCloseModGroupPatientHistory}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <div style={{ display: "flex" }}>
              <input ref={fileRef} type="file" accept=".xlsx" onChange={handleChangeFile} hidden />

              <div style={{ flex: "1" }}></div>
              <Button color="blue" size="small" onClick={handleClickUpload}>
                อัพโหลดไฟล์
              </Button>

              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "SetPatient",
                    action: "register_emergency",
                    patient: dataFile,
                    buttonLoadKey: `${CARD_REG_SEARCH}_register_emergency`,
                  });
                }}
                // data
                paramKey={`${CARD_REG_SEARCH}_register_emergency`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_REG_SEARCH}_register_emergency`]}
                // config
                color={"green"}
                size="small"
                title={intl.formatMessage({ id: "ยืนยันเปิดประวัติ" })}
              />
            </div>

            <div style={{ marginTop: "5px" }}>
              <Table
                data={dataFile}
                headers={intl.formatMessage({ id: "No.,HN,คำนำหน้า,ชื่อ,สกุล,เพศ" })}
                keys="no,hn,pre_name,first_name_th,last_name_th,gender"
                minRows="15"
                showPagination={false}
                style={{ height: "500px" }}
              ></Table>
            </div>
          </div>
        </>
      </Modal>

      <Modal
        open={openModEmergency}
        size="small"
        onClose={() => {
          setOpenModEmergency(false);
          props.setProp(`errorMessage.${MOD_OPEN_EMERGENCY}`, null);
        }}
      >
        <ModOpenEmergencyUX
          hn={props.SetPatientSequence?.patientEmergency?.hn}
          first_name={props.SetPatientSequence?.patientEmergency?.first_name}
          last_name={props.SetPatientSequence?.patientEmergency?.last_name}
          gender={props.SetPatientSequence?.patientEmergency?.gender}
          ErrorMessage={<ErrorMessage error={errorMsgLabel} />}
          changeValue={changeValue}
          OnSave={() => {
            props.runSequence({
              sequence: "SetPatient",
              action: "patient_emergency",
              card: MOD_OPEN_EMERGENCY,
              forward: props.forward,
            });
          }}
          OnClear={() => {
            props.runSequence({
              sequence: "SetPatient",
              action: "clear_emergency",
              card: MOD_OPEN_EMERGENCY,
            });
          }}
          onClose={() => {
            setOpenModEmergency(false);
            props.setProp(`errorMessage.${MOD_OPEN_EMERGENCY}`, null);
          }}
        />
      </Modal>

      <ModInfo
        open={openCAgentMessage}
        titleColor={"red"}
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}
        onApprove={handleCloseCAgent}
        onClose={handleCloseCAgent}
      >
        <div style={{ padding: "0.5rem 0", fontWeight: "bold", lineHeight: 1.5 }}>
          <div>{props.errorMessage?.[ACTION_SMART_CARD]?.error}</div>
        </div>
      </ModInfo>

      <ModConfirm
        openModal={props.errorMessage?.[ACTION_SMART_CARD]?.error === "NOT_FOUND"}
        titleName={intl.formatMessage({ id: "ไม่พบรายการผู้ป่วย" })}
        titleColor="red"
        size="mini"
        denyButtonText={intl.formatMessage({ id: "ปิดหน้าต่าง" })}
        approveButtonText={intl.formatMessage({ id: "เปิดประวัติใหม่" })}
        // style
        approveButtonStyle={{ minWidth: "9rem", margin: "0 -1.25rem" }}
        denyButtonStyle={{ minWidth: "9rem", margin: "0 -1.75rem" }}
        // callback
        onDeny={handleCloseModSmartCard}
        onCloseWithDimmerClick={handleCloseModSmartCard}
        onApprove={() => handleNewPatient(props.errorMessage?.[ACTION_SMART_CARD]?.data)}
        // ELement
        content={
          <div style={{ margin: "0rem 1.5rem -1rem 0rem", textAlign: "center" }}>
            ต้องการเปิดประวัติใหม่หรือไม่
          </div>
        }
      />

      <ModConfirm
        openModal={props.buttonLoadCheck?.[`${CARD_REG_SEARCH}_searchScan`] === "LOADING"}
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}
        titleColor="blue"
        denyButton={true}
        approveButton={true}
        // ELement
        content={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              lineHeight: 1.5,
            }}
          >
            <div>{intl.formatMessage({ id: "ระบบกำลังค้นหาข้อมูล กรุณารอสักครู่" })}</div>
          </div>
        }
      />

      <ModInfo
        open={props.errorMessage?.[`${CARD_REG_SEARCH}_searchScan`]}
        titleColor={"red"}
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}
        alertText={
          <div
            style={{
              fontSize: "20px",
              color: "red",
              padding: "10px 0px",
            }}
          >
            ไม่พบข้อมูล
          </div>
        }
        btnText={intl.formatMessage({ id: "ปิด" })}
        onApprove={handleCloseSearchScan}
        onClose={handleCloseSearchScan}
      ></ModInfo>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                    NoDataComponent;                    */

/* ------------------------------------------------------ */
const NoDataComponent = (props: any) => {
  const intl = useIntl();
  
  return (
    <div
      className="rt-noData"
      style={{
        fontSize: "1.35rem",
        fontWeight: "bold",
        color: "rgba(0,0,0,0.25)",
        textAlign: "center",
        lineHeight: 1.5,
      }}
    >
      {!!props.buttonLoading ? (
        "กำลังค้นหาผู้ป่วย..."
      ) : !props.SetPatientSequence?.patientList ? (
        "กรุณาทำการค้นหาผู้ป่วย"
      ) : !props.SetPatientSequence?.patientList.length ? (
        <>
          <div>{intl.formatMessage({ id: "ไม่พบข้อมูล" })}</div>
          <div>{intl.formatMessage({ id: "กรุณาทำการค้นหาใหม่อีกครั้ง" })}</div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

// #const MenuItem = (props: any) => (
//   <Button
//     size="tiny"
//     color={props.name === props.tab ? "blue" : undefined}
//     onClick={(e: any) => props.setTab(props.name)}
//   >
//     {props.title}
//   </Button>
// );

export default React.memo(CardRegSearch);
