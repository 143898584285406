import { ContentTable, TDocumentDefinitions } from "pdfmake/interfaces";

import { splitStringNewLine } from "../../common/CommonInterface";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

// Types
type FormDoctorIncomeSummaryDailyProps = {
  start_date: string;
  end_date: string;
  print_date_time: string;
  print_user: string;
  period: string;
  division_name: string;
  doctor_name: string;
  report_type: string;
  total_revenue_price: number;
  total_revenue_outpatient: number;
  total_revenue_credit: number;
  final_revenue_credit: number;
  total_compensation_price: number;
  total_revenue_point: number;
  final_revenue_point: number;
  items: ReportDoctorIncomeSummary[];
};

type ReportDoctorIncomeSummary = {
  examination_type: string;
  doctor_position: string;
  encounter_number: string;
  performed_date: string;
  hn: string;
  patient_name: string;
  coverage_name: string;
  paid_date: string;
  receipt_code: string;
  payment_type: string;
  item_code_list: string[];
  item_name_list: string[];
  quantity_list: number[];
  revenue_list: number[];
  management_fee_list: number[];
  credit_card_fee_list: number[];
  compensation_percent_list: number[];
  leftover_list: number[];
  final_revenue_price_list: number[];
  waiting_claim_price_list: number[];
  paid_price_list: number[];
  payment_method_list: string[];
};

type KeyType = typeof KEYS;

interface ContentTableWithId extends Omit<ContentTable, "table"> {
  id?: string;
  table: ContentTable["table"];
}

interface ITDocumentDefinitions extends TDocumentDefinitions {
  content: (TDocumentDefinitions["content"] | ContentTableWithId)[];
}

const formatPrice = (value: number | string) => {
  if (typeof(value) === "string") return value;

  if (typeof(value) === "number") {
    return value.toLocaleString("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
  }

  return "";
};

const TB_FONT_SIZE = 12;

const FONT_SIZE = 12;

const TB_MARGIN = 4;

const BOTTOM = 546;

const SUMMARY_HEIGHT = 190;

const KEYS = [
  "item_name",
  "item_code",
  "quantity",
  "revenue",
  "management_fee",
  "credit_card_fee",
  "compensation_percent",
  "leftover",
  "final_revenue_price",
  "waiting_claim_price",
  "paid_price",
  "payment_method",
] as const;

const WIDTHS = [
  "5%",
  "6%",
  "7.75%",
  "7%",
  "6%",
  "9%",
  "3.75%",
  "10.5%",
  "3.75%",
  "5.75%",
  "5.25%",
  "5.5%",
  "4.6%",
  "5.25%",
  "5.25%",
  "5.65%",
  "4%",
];

const FormDoctorIncomeSummaryDaily = async (
  props: FormDoctorIncomeSummaryDailyProps
): Promise<ITDocumentDefinitions> => {
  const headerForm = await HeaderSummaryReportForm({
    title: "รายงานสรุปรายได้ (ประจำวัน)",
    isLightLines: true,
    data: {
      start_date: props.start_date,
      end_date: props.end_date,
      print_date_time: props.print_date_time,
      print_user: props.print_user,
      period: props.period || "ทุกคาบ",
      division_name: props.division_name,
      doctor_name: props.doctor_name,
    },
    pageMargins: [10, 107.5, 10, 15],
    extra: {
      margin: [10, 10, 10, 0],
      table: {
        widths: ["100%"],
        heights: 465,
        body: [[{ text: "", border: [false, true, false, false] }]],
      },
      layout: {
        hLineWidth: () => 0.25,
        vLineWidth: () => 0.25,
      },
    },
  });

  const items = props.items;

  const tables = items.map((item) => {
    const stacks = item.item_name_list.map((name, index) => {
      const nameLines = splitStringNewLine(name, {
        width: 110,
        fontSize: FONT_SIZE,
      }).flatMap((text) => (text.includes("\n") ? [text.replace("\n", ""), ""] : [text]));

      const addBlankLine = (value: string | number) => {
        const blank = Array.from({ length: nameLines.length - 1 }).fill("");

        return [value, ...blank];
      };

      const formattedLine = Object.fromEntries(
        KEYS.map((key) => [key, addBlankLine(item[`${key}_list`][index])])
      ) as Record<KeyType[number], (string | number)[]>;

      return {
        ...formattedLine,
        item_name: nameLines,
      };
    });

    const formatValue =
      (key: KeyType[number], index: number) => (value: number | string, idx: number) => {
        const content = !["item_name", "item_code", "quantity", "payment_method"].includes(key)
          ? {
              text: `${formatPrice(value)}`,
              alignment: "right",
              noWrap: true,
            }
          : { text: `${value}`, noWrap: true };

        return {
          ...content,
          ...(index !== 0 && idx === 0 ? { marginTop: TB_FONT_SIZE / 2 } : {}),
        };
      };

    const columns = Object.fromEntries(
      KEYS.map((key) => [
        key,
        {
          stack: stacks.flatMap((stack, index) => stack[key].map(formatValue(key, index))),
        },
      ])
    ) as Record<KeyType[number], any>;

    const typeLines = splitStringNewLine(`${item.examination_type} (${item.doctor_position})`, {
      width: 50,
      fontSize: FONT_SIZE,
    });
    const patientNameLines = splitStringNewLine(item.patient_name, {
      width: 80,
      fontSize: FONT_SIZE,
    });
    const coverageNameLines = splitStringNewLine(item.coverage_name, {
      width: 72,
      fontSize: FONT_SIZE,
    });
    const paymentTypeLines = splitStringNewLine(item.payment_type ? `(${item.payment_type})` : "", {
      width: 100,
      fontSize: FONT_SIZE,
    });

    const [date, time] = item.performed_date.split(" ");

    return [
      { stack: typeLines.map((text) => ({ text, noWrap: true })), alignment: "center" },
      {
        stack: [{ text: item.encounter_number }, { text: date, noWrap: true }, { text: time }],
        alignment: "center",
      },
      {
        stack: [{ text: item.hn }, ...patientNameLines.map((text) => ({ text, noWrap: true }))],
      },
      { stack: coverageNameLines.map((text) => ({ text, noWrap: true })) },
      { text: item.paid_date, alignment: "center" },
      {
        stack: [
          { text: item.receipt_code },
          ...paymentTypeLines.map((text) => ({ text, noWrap: true })),
        ],
        alignment: "center",
      },
      { ...columns.item_code },
      { ...columns.item_name },
      { ...columns.quantity, alignment: "center" },
      { ...columns.revenue },
      { ...columns.management_fee },
      { ...columns.credit_card_fee },
      { ...columns.compensation_percent },
      { ...columns.waiting_claim_price},
      { ...columns.paid_price},
      { ...columns.final_revenue_price},
      { ...columns.payment_method, alignment: "center" },
    ];
  });

  console.log("FormDoctorIncomeSummaryDaily", props);

  return {
    ...headerForm,
    pageOrientation: `landscape`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
    content: [
      {
        table: {
          headerRows: 2,
          // dontBreakRows: true,
          widths: WIDTHS,
          body: [
            [
              {
                text: "",
                border: [true, false, true, false],
              },
              {
                text: "",
                border: [true, true, true, false],
              },
              {
                text: "",
                border: [true, true, true, false],
              },
              { text: "", style: "", border: [true, true, true, false] },
              {
                text: "",
                border: [true, true, true, false],
              },
              {
                text: "",
                border: [true, true, true, false],
              },
              { text: "", border: [true, true, true, false] },
              { text: "", border: [true, true, true, false] },
              { text: "", border: [true, true, true, false] },
              { text: "", border: [true, true, true, false] },
              { text: "", border: [true, true, true, false] },
              {
                text: "",
                border: [true, true, true, false],
              },
              { text: "", border: [true, true, true, false] },
              { text: "รายรับที่แบ่ง", style: "tableHeader", colSpan: 3 },
              "",
              "",
              { text: "", border: [true, true, true, false] },
            ],
            [
              {
                text: "ประเภท ลงตรวจ/ (ตำแหน่ง แพทย์)",
                style: "tableHeader",
                border: [true, false, true, true],
                marginTop: -TB_FONT_SIZE - 4,
              },
              {
                text: "VN/วันเวลา ที่รักษา",
                style: "tableHeader",
                border: [true, false, true, true],
                marginTop: -(TB_FONT_SIZE / 2) - 2,
              },
              { text: "HN ชื่อ-นามสกุล", style: "tableHeader", border: [true, false, true, true] },
              { text: "สิทธิ", style: "tableHeader", border: [true, false, true, true] },
              {
                text: "วันเวลาที่ ชำระเงิน",
                style: "tableHeader",
                border: [true, false, true, true],
              },
              {
                text: "เลขที่ใบเสร็จ (วิธีรับชำระ)",
                style: "tableHeader",
                border: [true, false, true, true],
              },
              { text: "รหัส", style: "tableHeader", border: [true, false, true, true] },
              { text: "รายการ", style: "tableHeader", border: [true, false, true, true] },
              { text: "จำนวน", style: "tableHeader", border: [true, false, true, true] },
              { text: "รายรับรวม", style: "tableHeader", border: [true, false, true, true] },
              { text: "หักค่า บริหาร", style: "tableHeader", border: [true, false, true, true] },
              {
                text: "หักค่า ธรรมเนียม บัตรเครดิต",
                style: "tableHeader",
                border: [true, false, true, true],
                marginTop: -(TB_FONT_SIZE / 2) - 2,
              },
              { text: "ส่วนแบ่ง (%)", style: "tableHeader", border: [true, false, true, true] },
              { text: "ส่งเบิก", style: "tableHeader" },
              { text: "ผู้ป่วย จ่าย", style: "tableHeader" },
              { text: "รายรับหลังหักส่วนแบ่ง", style: "tableHeader" },
              { text: "ประเภท รายรับ", style: "tableHeader", border: [true, false, true, true] },
            ],
            ...tables,
          ],
        },
        layout: {
          vLineWidth: (i) => {
            // รหัส
            return [7].includes(i) ? 0 : 0.25;
          },
          hLineWidth: () => {
            return 0.25;
          },
        },
      },
      // {
      //   table: {
      //     widths: ["5%", ...WIDTHS.slice(1)],
      //     body: [
      //       [
      //         { text: "", colSpan: 6 },
      //         "",
      //         "",
      //         "",
      //         "",
      //         "",
      //         {
      //           text: "ค่าตอบแทนแพทย์ OPD",
      //           colSpan: 2,
      //           marginLeft: 15,
      //         },
      //         "",
      //         { text: "1", alignment: "center" },
      //         ...[1, 2, 3, 4, 5, 6, 7].map(() => ({
      //           text: "4,000.00",
      //           alignment: "right",
      //         })),
      //         { text: "C", alignment: "center" },
      //       ],
      //     ],
      //   },
      //   layout: {
      //     hLineWidth: (i: number) => (i === 0 ? 0.25 : 0),
      //     vLineWidth: (i: number, node: any) => {
      //       const nps: any[] = node.positions || [];
      //       const top = nps.slice(-1)[0]?.top;

      //       return top + SUMMARY_HEIGHT > BOTTOM || ![0, 17].includes(i) ? 0 : 0.25;
      //     },
      //     paddingTop: () => 6,
      //   },
      // },
      {
        id: "summary",
        table: {
          widths: ["100%"],
          body: [
            [{ text: " ", marginTop: -FONT_SIZE - TB_MARGIN, border: [true, false, true, false] }],
            [
              {
                border: [true, false, true, false],
                fontSize: 14,
                margin: [5, 7, 0, 0],
                columns: [
                  {
                    width: "17.5%",
                    stack: [
                      {
                        text: "หมายเหตุ ส่วนแบ่ง (%) ยังไม่ได้ถูกหักค่า Lab (ถ้ามี)",
                        noWrap: true,
                      },
                      {
                        text: "C- ได้รับเป็นเงิน  P- ได้รับเป็นแต้มสะสม",
                        noWrap: true,
                        marginTop: 5,
                      },
                    ],
                  },
                  {
                    width: "*",
                    text: "",
                  },
                  {
                    width: "80%",
                    margin: [0, 100, 0, 5],
                    stack: [
                      {
                        columns: [
                          { text: "", width: "59%" },
                          {
                            columns: [
                              { text: "รายรับรวมทั้งหมด", width: "auto" },
                              {
                                text: formatPrice(props.total_revenue_price),
                                width: "auto",
                                marginLeft: 45,
                              },
                            ],
                            noWrap: true,
                          },
                          { text: "", width: "*" },
                        ],
                      },
                      ...[
                        {
                          width: "75%",
                          columns: [
                            [
                              {
                                width: "35%",
                                text: "ค่าบริการผู้ป่วยนอก",
                                value: formatPrice(props.total_revenue_outpatient),
                              },
                              {
                                width: "47.5%",
                                text: "รายรับจากการรักษา (รับเป็นเงิน)",
                                value: formatPrice(props.total_revenue_credit),
                              },
                              {
                                width: "17.5%",
                                text: "",
                                value: formatPrice(props.final_revenue_credit),
                              },
                            ],
                            [
                              {
                                width: "35%",
                                text: "รายรับที่ไม่แบ่ง",
                                value: formatPrice(props.total_compensation_price),
                              },
                              {
                                width: "47.5%",
                                text: "รายรับจากโครงการ (แต้มสะสม)",
                                value: formatPrice(props.total_revenue_point),
                              },
                              {
                                width: "17.5%",
                                text: "",
                                value: formatPrice(props.final_revenue_point),
                              },
                            ],
                          ],
                        },
                      ].map((item) => ({
                        marginBottom: 2.5,
                        columns: [
                          { width: "*", text: "" },
                          {
                            width: item.width,
                            stack: item.columns.map((columns) => ({
                              marginTop: 10,
                              columns: columns.map(({ text, value, width }) => ({
                                width,
                                columns: [
                                  { width: "*", text },
                                  { width: "auto", text: value },
                                ],
                                marginRight: 30,
                              })),
                            })),
                          },
                        ],
                      })),
                    ],
                  },
                ],
              },
            ],
            ...Array.from({ length: 2 }).map((_, index) => [
              {
                text: "",
                marginTop: -FONT_SIZE - TB_MARGIN,
                border: [true, false, true, index !== 0],
              },
            ]),
          ],
        },
        layout: {
          hLineWidth: () => 0.25,
          vLineWidth: () => 0.25,
          paddingTop: (i, node: any) => {
            const nps: any[] = node.positions || [];
            const top = nps.slice(-1)[0]?.top || 0;

            return i === 1 && top > 125 && top < 365 ? 365 - top : 0;
          },
          paddingBottom: (i, node: any) => {
            const nps: any[] = node.positions;
            const top = nps.slice(-1)[0]?.top;

            let result = 4;

            if (i === 3 && top > SUMMARY_HEIGHT && top < BOTTOM - SUMMARY_HEIGHT) {
              result = BOTTOM - top;
            } else if (i >= 2) {
              result = 0;
            }

            return result;
          },
        },
      },
    ],
    pageBreakBefore: (currentNode) => {
      const pageNumbers = currentNode.pageNumbers;

      if (currentNode.id === "summary") {
        return pageNumbers.length !== 1 || pageNumbers[0] !== currentNode.pages;
      }

      return false;
    },
    styles: {
      ...headerForm.styles,
      tableHeader: {
        bold: true,
        alignment: "center",
        fontSize: TB_FONT_SIZE,
      },
    },
    images: {
      ...headerForm.images,
    },
  };
};

export default FormDoctorIncomeSummaryDaily;
