import React, {
  CSSProperties,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";

import { Button, Popup } from "semantic-ui-react";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SnackMessage from "react-lib/apps/common/SnackMessage";

import { formatDatetime } from "react-lib/utils/dateUtils";

import CONFIG from "config/config";

// Types
type ButtonLockExpenseProps<T extends boolean> = ValueProps<T> & {
  readOnly?: T;
  status: StatusType;
};

type FullValueProps = {
  onEvent: (e: any) => void;
  setProp: (key: string, value: any, callback?: () => void) => void;

  encounterId: number;
  hn: string;
  number: string;
  patientName: string;
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // callback
  onSuccess?: () => void;
};

type LockedProps = {
  lockedAt: string;
  lockedByName: string;
};

type ValueProps<T extends boolean> = T extends true
  ? LockedProps & Partial<FullValueProps>
  : FullValueProps & Partial<LockedProps>;
type StatusType = keyof typeof STATUS;

type Styles = Record<"button" | "save", CSSProperties>;

// Constants
const IMAGES = {
  lock: "static/images/bil/lock-icon.png",
  lockRed: "static/images/bil/lock-red-icon.png",
  unlock: "static/images/bil/unlock-icon.png",
  unlockGreen: "static/images/bil/unlock-green-icon.png",
};

const styles: Styles = {
  button: {
    alignItems: "center",
    borderRadius: "6px",
    display: "flex",
    padding: "0.35rem 0.85rem 0.45rem",
  },
  save: { minWidth: "6rem" },
};

const STATUS = {
  LOCKED: "LOCKED",
  UNLOCKED: "UNLOCKED",
} as const;

const ACTIONS = {
  LOCK: "LOCK",
  UNLOCK: "UNLOCK",
} as const;

const MESSAGES = {
  [ACTIONS.LOCK]: "ล็อคค่าใช้จ่าย",
  [ACTIONS.UNLOCK]: "ปลดล็อคค่าใช้จ่าย",
};

const ACTION_MAPPING = {
  [STATUS.LOCKED]: ACTIONS.UNLOCK,
  [STATUS.UNLOCKED]: ACTIONS.LOCK,
};

const BUTTON_ACTIONS = {
  save: "SAVE",
};

const BUTTON_LOCK_EXPENSE = "ButtonLockExpense";

const ACTION_SAVE = `${BUTTON_LOCK_EXPENSE}_${BUTTON_ACTIONS.save}`;

const ButtonLockExpense = (props: ButtonLockExpenseProps<boolean>) => {
  const [modAction, setModAction] = useState<"" | keyof typeof ACTIONS>("");

  // Callback
  const handleAction = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      if (!props.readOnly) {
        setModAction(ACTION_MAPPING[props.status]);
      }
    },
    [props.readOnly, props.status]
  );

  const handleCloseModAction = useCallback(() => {
    setModAction("");

    props.setProp?.(`buttonLoadCheck.${ACTION_SAVE}`, null);
  }, []);

  const handleSave = useCallback(() => {
    props.onEvent?.({
      message: "UpdateEncounterLocker",
      params: {
        action: modAction,
        id: props.encounterId,
        btnAction: ACTION_SAVE,
        onSuccess: () => {
          props.onSuccess?.();

          handleCloseModAction();
        },
      },
    });
  }, [modAction, props.encounterId]);

  const handleCloseModError = useCallback(() => {
    props.setProp?.(`errorMessage.${ACTION_SAVE}`, null);
  }, []);

  // Memo
  const buttonUnLockStyle = useMemo(
    () => ({
      backgroundColor: "rgba(93, 188, 210, 1)",
      ...styles.button,
    }),
    []
  );

  const buttonLockStyle = useMemo(
    () => ({
      backgroundColor: "rgba(251, 189, 8, 1)",
      ...styles.button,
      padding: "0.36rem 0.85rem 0.46rem",
    }),
    []
  );

  const lockIconSet = useMemo(
    () =>
      props.readOnly
        ? {
            lock: (
              <img
                alt="lock"
                src={IMAGES.lockRed}
                style={{ cursor: "pointer", width: "15px" }}
                onClick={handleAction}
              />
            ),
            unlock: (
              <img
                alt="unlock"
                src={IMAGES.unlockGreen}
                style={{ width: "14px" }}
                onClick={handleAction}
              />
            ),
          }
        : {
            lock: (
              <Button style={buttonUnLockStyle} onClick={handleAction}>
                <img alt="unlock" src={IMAGES.lock} style={{ width: "12px" }} />
              </Button>
            ),
            unlock: (
              <Button style={buttonLockStyle} onClick={handleAction}>
                <img alt="lock" src={IMAGES.unlock} style={{ width: "13px" }} />
              </Button>
            ),
          },
    [buttonLockStyle, buttonUnLockStyle, handleAction, props.readOnly, props.status]
  );

  const approveButton = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color="green"
        paramKey={ACTION_SAVE}
        size="medium"
        style={styles.save}
        title={"ยืนยัน"}
        basic
        buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SAVE]}
        onClick={handleSave}
      />
    ),
    [handleSave, props.buttonLoadCheck]
  );

  const modContent = useMemo(
    () => (
      <div
        style={{
          fontWeight: "bold",
          lineHeight: 2.25,
          margin: "-0.75rem 1.5rem -1.75rem 1rem",
        }}
      >
        {!!modAction && (
          <>
            ยืนยันการ <span style={{ color: "red" }}>{MESSAGES[modAction]}</span>
          </>
        )}
        <div>
          HN: {props.hn} <span style={{ marginLeft: "5px" }}>{props.patientName}</span>
        </div>
        <div>Encounter: {props.number}</div>
      </div>
    ),
    [modAction, props.hn, props.number, props.patientName]
  );

  const titleName = useMemo(
    () => (modAction ? <>ยืนยันการ{MESSAGES[modAction]}</> : null),
    [modAction]
  );

  return CONFIG.ENABLE_ENCOUNTER_LOCKER ? (
    <div>
      {props.onEvent && (
        <SnackMessage
          onEvent={props.onEvent}
          error={props.errorMessage?.[ACTION_SAVE]}
          success={null}
          onClose={handleCloseModError}
        />
      )}

      <Popup
        open={props.readOnly && props.status === "LOCKED" ? undefined : false}
        position="bottom left"
        trigger={props.status === "UNLOCKED" ? lockIconSet.unlock : lockIconSet.lock}
        content={
          <>
            <div>ล็อครายการค่าใช้จ่าย</div>
            <div>{props.lockedByName}</div>
            <div>{formatDatetime(props.lockedAt, true)}</div>
          </>
        }
      />

      <ModConfirm
        approveButton={approveButton}
        content={modContent}
        denyButtonText="ยกเลิก"
        openModal={!!modAction}
        size="mini"
        titleColor="blue"
        titleName={titleName}
        // callback
        onCloseWithDimmerClick={handleCloseModAction}
        onDeny={handleCloseModAction}
      />
    </div>
  ) : null;
};

ButtonLockExpense.displayName = "ButtonLockExpense";

export default React.memo(ButtonLockExpense) as <T extends boolean>(
  props: ButtonLockExpenseProps<T>
) => ReactElement;
