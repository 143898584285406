import React, { useMemo, useCallback } from "react";

// UX
import CardSupplyOrderSearchUX from "./CardSupplyOrderSearchUX";
import { Checkbox, Icon, Input, Popup, Image } from "semantic-ui-react";
import RenderNameStatus from "./RenderNameStatus";
import { formatPrice } from "react-lib/apps/common/PureReactTable";

import CONFIG from "config/config";
import { useIntl } from "react-intl";

// Types
type CardSupplyOrderSearchProps = {
  setProp: any;
  // seq
  runSequence: any;
  OrderSupplySequence: any;
  // options
  supplyOrderModeOptions?: any[];
  // callback
  onSelected: () => any;
};

const CardSupplyOrderSearch = (props: CardSupplyOrderSearchProps) => {
  const intl = useIntl();
  // callback
  const handleChangeSelected = useCallback(
    (idx: number) => (e: any, data: any) => {
      let supplyList = [...(props.OrderSupplySequence?.supplyList || [])];

      supplyList[idx].selected = data.checked;

      if (supplyList[idx].count === 0) {
        supplyList[idx].count = 1;
      }

      props.setProp("OrderSupplySequence.supplyList", supplyList);
    },
    [props.OrderSupplySequence?.supplyList]
  );

  const handleChangeCount = useCallback(
    (idx: number) => (e: any) => {
      let supplyList = [...(props.OrderSupplySequence?.supplyList || [])];

      supplyList[idx].count = e.target.value;

      props.setProp("OrderSupplySequence.supplyList", supplyList);
    },
    [props.OrderSupplySequence?.supplyList]
  );

  const handleUpdateFavorite = useCallback(
    (idx: number) => () => {
      let supplyList = [...(props.OrderSupplySequence?.supplyList || [])];

      const supply = supplyList[idx];

      if (supply.favorite) {
        props.runSequence({
          sequence: "OrderSupply",
          action: "unfavorite",
          id: supply?.id,
        });
      } else {
        props.runSequence({
          sequence: "OrderSupply",
          action: "favorite",
          id: supply?.id,
        });
      }
    },
    [props.OrderSupplySequence?.supplyList]
  );

  // Memo
  const supplyOrderSearchItems = useMemo(() => {
    const items = props.OrderSupplySequence?.supplyList || [];

    return items.map((item: any, idx: any) => {
      return {
        ...item,
        name: <RenderNameStatus data={item} />,
        selected: (
          <div>
            <Checkbox
              key={item.id}
              checked={item.selected}
              disabled={["INACTIVE", "TERMINATED", "UNAVAILABLE"].includes(item.active_flag)}
              onChange={handleChangeSelected(idx)}
            />
          </div>
        ),
        count: (
          <div style={{ width: "100%", height: "100%" }}>
            <Input
              ref={(ref: any) =>
                ref &&
                ((ref.inputRef.current.style.textAlign = "right"),
                (ref.inputRef.current.style.border = "none"))
              }
              min="0"
              type="number"
              value={item.count}
              disabled={!item.selected}
              fluid={true}
              onClick={(e: any) => {
                e.target.select();
              }}
              onChange={handleChangeCount(idx)}
            />
          </div>
        ),
        package_size: <div style={{ textAlign: "right" }}>{item.package_size}</div>,
        price_unit: <div style={{ textAlign: "right" }}>{item.price_unit}</div>,
        favorite: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {item?.favorLoading ? (
              <Icon loading name="spinner" color={item.favorite ? "yellow" : "black"} />
            ) : (
              <Icon
                name="star"
                color={item.favorite ? "yellow" : "black"}
                onClick={handleUpdateFavorite(idx)}
              />
            )}
          </div>
        ),
        price_normal: <div style={{ textAlign: "right" }}>{formatPrice(item.price_normal)}</div>,
        price_special: <div style={{ textAlign: "right" }}>{formatPrice(item.price_special)}</div>,
        price_premium: <div style={{ textAlign: "right" }}>{formatPrice(item.price_premium)}</div>,
        price_foreign: <div style={{ textAlign: "right" }}>{formatPrice(item.price_foreign)}</div>,
        // img: (
        //   <>
        //   {item.images.length > 0 ? (
        //     <Popup
        //       trigger={<Icon name="picture" color={"black"} />}
        //       content={<Image src={item.images.length > 0 ? item.images[0] : ""} size="small" />}
        //       inverted
        //     />
        //   ) : (
        //     <Icon name="picture" disabled />
        //   )}
        //   </>
        // ),
      };
    });
  }, [props.OrderSupplySequence?.supplyList, props.runSequence, props.setProp]);

  // Handler
  const handleSelectFavorite = async (e: any, data: any) => {
    await props.setProp("OrderSupplySequence.isFavorite", !props.OrderSupplySequence?.isFavorite);

    props.runSequence({
      sequence: "OrderSupply",
      action: "advanceSearch",
    });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "advanceSearch",
    });
  };

  return (
    <CardSupplyOrderSearchUX
      isShowPriceUnit={CONFIG.ENABLE_PRICE_LEVEL}
      data={supplyOrderSearchItems || []}
      getTrprops={(state: any, rowInfo: any, column: any, instance: any) => {
        if (rowInfo && rowInfo.row) {
        }

        return {
          style: {
            backgroundColor: ["INACTIVE", "TERMINATED", "UNAVAILABLE"].includes(
              rowInfo?.original?.active_flag
            )
              ? "#e6e6e6"
              : "white",
          },
        };
      }}
      modeOptions={props.supplyOrderModeOptions}
      searchMode={props.OrderSupplySequence?.searchMode || ""}
      // value
      searchManufacturer={props.OrderSupplySequence?.searchManufacturer || ""}
      // Manuf
      manufacturerOptions={props.OrderSupplySequence?.manufacturerOptions}
      openManufacturer={props.OrderSupplySequence?.manufactureHaveResult}
      manufactureLoading={props.OrderSupplySequence?.manufactureLoading}
      searchGroup={props.OrderSupplySequence?.searchGroup || ""}
      searchName={props.OrderSupplySequence?.searchSupply || ""}
      nameLoading={props.OrderSupplySequence?.nameLoading}
      // favor
      isFavorite={props.OrderSupplySequence?.isFavorite}
      // นยะรนืห
      groupOptions={[{ key: 0, value: "ANY", text: "ANY" }]}
      // callback
      // Mode
      onChangeSearchMode={(e: any, data: any) =>
        props.setProp("OrderSupplySequence.searchMode", data?.value)
      }
      onCloseSearchManufacturer={() =>
        props.setProp("OrderSupplySequence.manufactureHaveResult", false)
      }
      onChangeSearchManufacturer={(e: any, data: any) => {
        props.setProp("OrderSupplySequence.searchManufacturer", e?.target?.value);
      }}
      // searchQuery
      searchQueryManufacturer={props.OrderSupplySequence?.searchManufacturer || ""}
      onSearchChangeQueryManufacturer={(e: any) => {
        props.setProp("OrderSupplySequence.searchManufacturer", e?.target?.value);
      }}
      //กองทุน
      onChangeSearchGroup={(e: any, data: any) =>
        props.setProp("OrderSupplySequence.searchGroup", data?.value)
      }
      // ชื่อ
      onChangeSearchName={(e: any) =>
        props.setProp("OrderSupplySequence.searchSupply", e.target.value)
      }
      // Button
      // ค้นหา
      onSearch={handleSearch}
      // แสดงภาพ
      onClickListView={null}
      onSelectFavorite={handleSelectFavorite}
      // ยกเลิก
      onCancel={() => {
        props.setProp("OrderSupplySequence.showSupplyList", false);
      }}
      // เลือก
      onSelect={props.onSelected}
      languageUX={props.languageUX}
    />
  );
};

CardSupplyOrderSearch.displayName = "CardSupplyOrderSearch";

export default React.memo(CardSupplyOrderSearch);
