import React, { CSSProperties, SyntheticEvent, useCallback, useMemo, useState } from "react";

import {
  DOCTOR_ORDER_ACTION_MAP,
  DOCTOR_ORDER_MAPPING_ICON,
  DRUG_ICONS,
  DRUG_LIST_ISOLATED_ICON,
  TELEPHAR_ACTION_MAP,
} from "../REG/REGInterface";

import ModDoctorOrderDetail from "./ModDoctorOrderDetail";

import {
  RESULT_SUMMARY,
  RESULT_SUMMARY_LABEL,
  getStatusImagingOrder,
} from "../IME/sequence/ImagingHandler";

// Config
import CONFIG from "config/config";
import { useIntl } from "react-intl";

// Types
type SubDoctorOrderDetailProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // data
  data: {
    id: number;
    doctor_orders: DoctorOrderType[];
    extra: Record<string, any>;
    type: "IPD" | "OPD";
  };
  doctorOrders?: SortingOrderType[];
  performedOnly?: boolean;
  // options
  masterOptions?: Record<string, any>;
  // callback
  onClose?: (orderType?: string) => any;
};

type DoctorOrderType = {
  id: number | string;
  consult_status: string;
  consult_status_label?: string;
  order_status: string;
  order_summary_detail_cache: SummaryType | string;
  specific_type: string;
  summary: SummaryType;
  telephar_type: string;
};

type SummaryType = {
  order_summary_detail: string;
  specific_items_status?: Record<
    string,
    {
      code: string;
      is_authorized?: boolean;
      name: string;
      result_summary?: number;
      status: string;
    }
  >;
  specific_status: {
    is_authorized?: boolean;
    label: string;
    name: string;
    value: number;
  };
  specific_type: { label: string; name: string; value: number };
};

type SortingOrderType = {
  id?: number;
  encounter_type: string;
  image: string;
  is_abnormal_imaging?: boolean;
  is_abnormal_lab: boolean;
  items: any[];
  order_status?: string[];
  order_type: string;
};

const formatDoctorOrder = (orders: DoctorOrderType[] = []) => {
  let formattedOrders = orders.map((order) => ({
    ...order,
    summary: parseOrderSummaryDetail(order),
  }));

  const filterCentralLabOrder = formattedOrders.filter(
    (order) => order.specific_type === "centrallaborder" && order.order_status !== "APPOINTMENT"
  );

  if (filterCentralLabOrder.length > 0) {
    const groupItems: Record<string, DoctorOrderType | undefined>[] = [];

    for (const [index, order] of filterCentralLabOrder.entries()) {
      const specificItems = order.summary.specific_items_status || {};

      for (const [key, value] of Object.entries(specificItems)) {
        order.summary.specific_status.name = value.status;
        order.summary.specific_status.is_authorized = value.is_authorized;

        const orderStatus = getOrderStatus(order.specific_type, order);

        if (groupItems[index]?.[orderStatus]) {
          const { summary } = groupItems[index][orderStatus];

          groupItems[index][orderStatus].summary = {
            ...summary,
            order_summary_detail: `${summary.order_summary_detail}<br/><b>[${value.code}] ${value.name}</b>`,
            specific_items_status: {
              ...summary.specific_items_status,
              [key]: value,
            },
          };
        } else {
          const cloneOrder = structuredClone(order);

          cloneOrder.id = `${cloneOrder.id}|${key}`;
          cloneOrder.summary.order_summary_detail = `<b>[${value.code}] ${value.name}</b>`;
          cloneOrder.summary.specific_items_status = { [key]: value };

          groupItems[index] = { ...groupItems[index], [orderStatus]: cloneOrder };
        }
      }
    }

    const ids = new Set(filterCentralLabOrder.map((order) => order.id));

    formattedOrders = formattedOrders.filter((order) => !ids.has(order.id));

    const items = groupItems
      .flatMap((item) => Object.values(item))
      .filter((item): item is DoctorOrderType => item !== undefined);

    formattedOrders.push(...items);
  }

  return formattedOrders;
};

const getConsultStatus = (order: DoctorOrderType, orderStatus: string) => {
  const isTelephar = orderStatus === "RECEIVED" && order.telephar_type === "INTRA";

  const isDeliveryAndDeliveredIdle =
    order.telephar_type === "DELIVERY" &&
    orderStatus === "DELIVERED" &&
    order.consult_status === "IDLE";

  const isNoneAndDelivered = order.telephar_type === "NONE" && orderStatus === "DELIVERED";

  if (isDeliveryAndDeliveredIdle || isNoneAndDelivered) {
    return "FINISH";
  }

  return isTelephar ? `TELE_${order.consult_status}` : order.consult_status;
};

const parseOrderSummaryDetail = (order: DoctorOrderType) =>
  typeof order.order_summary_detail_cache === "string"
    ? (JSON.parse(order.order_summary_detail_cache) as SummaryType)
    : order.order_summary_detail_cache;

const getOrderStatus = (orderType: string, order: DoctorOrderType) => {
  const specificStatus = order.summary.specific_status;

  if (orderType === "imagingorder") {
    const specificItems: { [key: string]: any; status: string }[] = Object.values(
      order.summary.specific_items_status || {}
    );

    return getStatusImagingOrder(specificItems).specific;
  } else if (orderType === "centrallaborder") {
    return specificStatus.name === "REPORTED" && specificStatus.is_authorized
      ? "PERFORMED"
      : "PENDING";
  }

  return order.order_status as string;
};

const filterDoctorOrder = ({
  actionMap,
  doctorOrderStatus,
  doctorOrderType,
  encounterType,
  orders,
}: {
  actionMap: Record<string, string[]>;
  doctorOrderStatus: string;
  doctorOrderType: string;
  encounterType: string;
  orders: DoctorOrderType[];
}) =>
  formatDoctorOrder(orders).filter((order) => {
    const specificTypeName = order.summary.specific_type.name;

    let orderStatus = getOrderStatus(doctorOrderType, order);

    if (order.specific_type.includes("drug")) {
      const isHomeOpd = specificTypeName === "HOME_OPD" && encounterType === "OPD";

      if (isHomeOpd) {
        order.specific_type = "drugopdhomeorder";
      } else if (specificTypeName === "STAT") {
        order.specific_type = "drugstatorder";
      } else if (specificTypeName === "ONE_DOSE") {
        order.specific_type = "drugonedoseorder";
      }
    }

    // * Set icon แยก drugopdhomeorder
    const regex = new RegExp(`^${doctorOrderType}`);

    const isOrderTypeMapped =
      DRUG_LIST_ISOLATED_ICON.includes(order.specific_type) && encounterType === "OPD"
        ? order.specific_type === doctorOrderType
        : order.specific_type.search(regex) >= 0;

    // * Set order status โดยใช้ consult status เนื่องจากต้อง map icon
    if (
      order.specific_type === "drugopdhomeorder" &&
      DRUG_LIST_ISOLATED_ICON.includes(order.specific_type)
    ) {
      orderStatus = getConsultStatus(order, order.summary.specific_status.name);
    }

    const doctorOrderAction: string[] = actionMap[doctorOrderStatus];

    return isOrderTypeMapped && doctorOrderAction.includes(orderStatus);
  });

const flatDrugOrder = (
  target: DoctorOrderType[],
  doctorOrderType: string,
  status: string,
  icon: any
) =>
  Object.fromEntries(
    target.map((item) => {
      let telepharType: string = item.telephar_type || "";

      telepharType = telepharType.toLowerCase();
      const statusName = item.summary.specific_status.name;

      // * Use the telelift icon when the status indicates 'transported' and when delivery occurs via telelift.
      const statusKey =
        telepharType === "intra" && statusName === "TRANSPORTED" ? "telelift" : status;

      const image = doctorOrderType.includes("home")
        ? icon[telepharType][statusKey]
        : icon[statusKey];

      return [
        item.id,
        {
          image,
          items: [item],
        },
      ];
    })
  );

const formatSortingOrder = ({
  actionMap,
  doctorOrderType,
  result,
  status,
  target,
  type,
  extra,
}: {
  actionMap: Record<string, string[]>;
  doctorOrderType: string;
  extra: any;
  result: Record<
    string,
    {
      image: any;
      items: DoctorOrderType[];
    }
  >;
  status: string;
  target: any[];
  type: string;
}) => {
  //* Check lab ผิดปกติ
  const abnormalLabOrder: number[] = extra.abnormal_lab_order || [];

  const isAbnormalLab =
    doctorOrderType === "centrallaborder" && extra?.abnormal_lab_order
      ? target.some((item) => {
          const itemId: number | string = item.id;
          const itemIdNumber = Number(itemId.toString().split("|")[0]);
          const specificItemsStatus: Record<string, Record<string, any>> = item.summary
            .specific_items_status || {};

          const hasReportedStatus = Object.values(specificItemsStatus).some(
            (itemStatus) => itemStatus.status === "REPORTED"
          );

          return abnormalLabOrder.includes(itemIdNumber) && hasReportedStatus;
        })
      : false;

  const isAbnormalImaging =
    doctorOrderType === "imagingorder"
      ? target.some((item) => {
          const specificItemsStatus: Record<string, Record<string, any>> = item.summary
            .specific_items_status || {};

          return Object.values(specificItemsStatus).some(
            (itemStatus) =>
              RESULT_SUMMARY_LABEL[itemStatus.result_summary] === RESULT_SUMMARY.ABNORMAL
          );
        })
      : false;

  //* Set items
  return Object.entries(result).map(([, value]) => ({
    encounter_type: type,
    image: value.image,
    is_abnormal_imaging: isAbnormalImaging,
    is_abnormal_lab: isAbnormalLab,
    items: value.items.map((index) => {
      const showDrugDetail = index.specific_type.includes("drug");
      const showSupplyDetail = index.specific_type.includes("supply");

      const showDetail =
        ["doctorconsultorder", "imagingorder"].includes(index.specific_type) ||
        showDrugDetail ||
        showSupplyDetail;

      return showDetail ? index : index.summary.order_summary_detail;
    }),
    order_status: actionMap[status],
    order_type: doctorOrderType,
  }));
};

export const getDoctorOrders = (
  orders: DoctorOrderType[],
  type: "IPD" | "OPD",
  extra: any = null
) => {
  const sortingOrderType: SortingOrderType[] = [];

  let mappingIcon = { ...DOCTOR_ORDER_MAPPING_ICON };
  let actionMap = { ...DOCTOR_ORDER_ACTION_MAP };

  if (CONFIG.ENABLE_INTRA_TELEPHAR) {
    mappingIcon = {
      ...mappingIcon,
      ...Object.fromEntries(DRUG_LIST_ISOLATED_ICON.map((key) => [key, DRUG_ICONS])),
    };
    actionMap = { ...actionMap, ...TELEPHAR_ACTION_MAP };
  }

  for (const doctorOrderType of Object.keys(mappingIcon)) {
    for (const doctorOrderStatus of Object.keys(actionMap)) {
      const target = filterDoctorOrder({
        actionMap,
        doctorOrderStatus,
        doctorOrderType,
        encounterType: type,
        orders,
      });

      if (target.length > 0) {
        let status = doctorOrderStatus;

        const icon = mappingIcon[doctorOrderType];
        const image = icon[status];

        let result: Record<string, { image: any; items: DoctorOrderType[] }> = {
          default: { image, items: target },
        };

        //* Mapping status เพื่อแสดง icon
        if (doctorOrderType === "doctorconsultorder") {
          result = Object.fromEntries(
            target.map((item) => {
              let consultImage = "";

              switch (item.consult_status_label) {
                case "กำลัง Consult": {
                  status = "pending";
                  consultImage = status;

                  break;
                }
                case "Consult เสร็จสิ้น": {
                  status = "performed";
                  consultImage = status;

                  break;
                }
                case "ยกเลิก": {
                  status = "cancel";
                  consultImage = status;

                  break;
                }
                case undefined: {
                  status = "appointment";
                  consultImage = "loading";

                  break;
                }

                default: {
                  status = "appointment";
                  consultImage = status;
                }
              }

              return [
                item.id,
                {
                  image: icon[consultImage],
                  items: [item],
                },
              ];
            })
          ) as any;
        } else if (DRUG_LIST_ISOLATED_ICON.includes(doctorOrderType) && type === "OPD") {
          // * Image
          result = flatDrugOrder(target, doctorOrderType, status, icon);
        }

        const lists = formatSortingOrder({
          actionMap,
          doctorOrderType,
          result,
          status,
          target,
          type,
          extra,
        });

        sortingOrderType.push(...lists);
      }
    }
  }

  return sortingOrderType;
};

const SubDoctorOrderDetail = (props: SubDoctorOrderDetailProps) => {
  const intl = useIntl();
  const [modOrder, setModOrder] = useState<{
    encounterType: string;
    items: any[];
    orderType: string;
  } | null>(null);

  const filterDoctorOrders = useCallback(
    (drOrders: SortingOrderType[]) =>
      drOrders.filter((item) => {
        const orderStatus: string[] = item.order_status || [];

        return props.performedOnly ? orderStatus.includes("PERFORMED") : true;
      }),
    [props.performedOnly]
  );

  const handleCloseModDoctorOrder = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      props.onClose?.(modOrder?.orderType);

      setModOrder(null);
    },
    [modOrder?.orderType]
  );

  const handleClickMod = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const doctorOrders = useMemo(() => {
    if (props.doctorOrders) {
      return filterDoctorOrders(props.doctorOrders);
    }

    const drOrders = getDoctorOrders(props.data.doctor_orders, props.data.type, props.data.extra);

    const filteredDrOrders = filterDoctorOrders(drOrders);

    return filteredDrOrders.map((item, index) => ({ ...item, id: index + 1 }));
  }, [props.data, props.performedOnly]);

  // Handler
  const handleClick = (target: any) => (e: SyntheticEvent) => {
    e.stopPropagation();

    props.onEvent({
      message: "HandleGetDoctorOrder",
      params: {
        encounterId: props.data.id,
        ...target,
        onCallBack: (items: any) => {
          setModOrder({
            encounterType: target.encounter_type,
            items,
            orderType: target.order_type,
          });
        },
      },
    });
  };

  return (
    <>
      {doctorOrders.map((item) => {
        const image: string = item.image || "";
        const imageStyle: CSSProperties =
          image.includes("telelift") || image.includes("intra-telephar")
            ? { height: "35px", marginTop: "-5px" }
            : { height: "30px" };
        const isAbnormalLab = item.order_type === "centrallaborder" && item.is_abnormal_lab;
        const isAbnormalImaging = item.order_type === "imagingorder" && item.is_abnormal_imaging;

        return (
          <div
            key={`order-${item.id}`}
            aria-hidden="true"
            onClick={handleClick(item)}
            style={{
              display: "flex",
              padding: "5px",
            }}
          >
            <img alt="Doctor order icon" src={item.image} style={imageStyle} />
            {(isAbnormalLab || isAbnormalImaging) && (
              <span
                style={{
                  color: "red",
                  fontSize: "20px",
                  fontWeight: "bold",
                  margin: "-3px 0 0 -7px",
                }}
              >
                !
              </span>
            )}
          </div>
        );
      })}

      <div aria-hidden="true" onClick={handleClickMod}>
        <ModDoctorOrderDetail
          onEvent={props.onEvent}
          setProp={props.setProp}
          encounterType={modOrder?.encounterType}
          items={modOrder?.items}
          // data
          open={!!modOrder}
          orderType={modOrder?.orderType}
          // options
          masterOptions={props.masterOptions}
          // callback
          onClose={handleCloseModDoctorOrder}
          languageUX={props.languageUX}
        />
      </div>
    </>
  );
};

SubDoctorOrderDetail.displayName = "SubDoctorOrderDetail";

export default React.memo(SubDoctorOrderDetail);
