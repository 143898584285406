import React from "react";
import { Modal, Segment } from "semantic-ui-react";
// UI
import CardConsultCancelDetailUX from "./CardConsultCancelDetailUX";
import { useIntl } from "react-intl";

type CardConsultCancelDetailProps = {
  onCloseMod?: any;
  isOpen?: boolean;
  cancel_note?: string;
  cancel_user?: string;
};

const CardConsultCancelDetail = (props: CardConsultCancelDetailProps) => {
  const intl = useIntl();
  return (
    <Modal closeIcon size="tiny" open={props.isOpen} onClose={props.onCloseMod}>
      <Segment inverted className="red" style={{ margin: "0px" }}>{intl.formatMessage({ id: "นัดหมายถูกยกเลิก" })}</Segment>
      <Modal.Content>
        <CardConsultCancelDetailUX
          cancel_note={props.cancel_note}
          cancel_user={props.cancel_user}
          languageUX={props.languageUX}
        />
      </Modal.Content>
    </Modal>
  );
};

export default React.memo(CardConsultCancelDetail);
