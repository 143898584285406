import React, { useEffect } from 'react';
import { useIntl } from "react-intl";
import { Header, Grid, Divider, Button } from 'semantic-ui-react'
import { formatVisitDate, getDoctorNumber } from 'react-lib/apps/HISV3/DPI/CardProgressNoteList';
import { GENDER_LIST } from "react-lib/apps/common/CardPrintList";

// common
import CardLayout from 'react-lib/appcon/common/CardLayout';
import getPdfMake from 'react-lib/appcon/common/pdfMake';

// pdf
import FormProgressNote from 'react-lib/apps/HISV3/DPI/pdfForm/FormProgressNote';

const styles = {
  gridHeader: {
    fontWeight: 'bold'
  }
}

type CardProgressNoteViewProps = {
  django: any;
  selectedPatient: any;
  closeable: boolean;
  toggleable: boolean;
  hideHeaderIcon: boolean;
  defaultBackground: boolean;
  enableMargin: boolean;
  progressionNote: any[];
  encounterId: number | null;
  encounterType: string;
  getProgressionNote: any;
  progressNoteLoading: boolean;
  getEncounterDetail: any;
  languageUX: any;
};

const CardProgressNoteView = (props: CardProgressNoteViewProps) => {
  const intl = useIntl();

  useEffect(() => {
    fetchProgressionNote()
  }, [])

  const fetchProgressionNote = () => {
    props.getProgressionNote({})
  }

  const generateData = () => {
    if(props.progressionNote.length === 0){
      return <div>No Data.</div>
    }
    if(props.encounterType !== "IPD") {
      return (
        props.progressionNote.map((items, index) => {
          return (
            <div key={index}>
              <Header as='h3'>{items.last_edit} &ensp; {items.edit_user}</Header>
              <Grid celled style={{ backgroundColor: '#F2F2F2' }}>
                <Grid.Row >
                  <Grid.Column width={2} style={styles.gridHeader}>
                    Progression Note
                  </Grid.Column>
                  <Grid.Column width={14}>
                    {items.progression_note}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
              <Divider />
            </div>
          )
      }))
    }
    return (
      props.progressionNote.map((items, index) => {
        return (
          <div key={index}>
            <Header as='h3'>{items.last_edit} &ensp; {items.reporter}</Header>
            <Grid celled style={{ backgroundColor: '#F2F2F2' }}>
              <Grid.Row >
                <Grid.Column width={2} style={styles.gridHeader}>
                  S: Subjective
                </Grid.Column>
                <Grid.Column width={14}>
                  {items.s_txt}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row >
                <Grid.Column width={2} style={styles.gridHeader}>
                  O: Objective
                </Grid.Column>
                <Grid.Column width={14}>
                  {items.o_txt}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row >
                <Grid.Column width={2} style={styles.gridHeader}>
                  A: Assessment
                </Grid.Column>
                <Grid.Column width={14}>
                  {items.a_txt}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row >
                <Grid.Column width={2} style={styles.gridHeader}>
                  P: Plan
                </Grid.Column>
                <Grid.Column width={14}>
                  {items.p_txt}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <Divider />
          </div>
        )
      })
    )
  }

  const handlePrint = async () => {
    const getItems = props.progressionNote?.map(({ menu, ...resp }: any) => resp).sort((a: any, b: any) => a.id - b.id);
    const getEncounterData = await props.getEncounterDetail(props.encounterId);
    const getDoctorCertNumber = await getDoctorNumber(props, getEncounterData?.doctor);

    const docDef = await FormProgressNote({
      printer: props.django?.user?.full_name || "",
      items: getItems || [],
      detail: {
        hn: props.selectedPatient?.hn,
        number: getEncounterData?.number || props.selectedPatient?.encounter_no || "-",
        patientName: props.selectedPatient?.full_name /*props.selectedPatient?.full_name_en ||*/,
        patient_gender_name:
          GENDER_LIST[props.selectedPatient?.gender].name_th || props.selectedPatient?.gender_name,
        patient_birthdate: props.selectedPatient?.birthdate,
        patient_age: props.selectedPatient?.full_age,
        patient_citizen_no:
          props.selectedPatient?.citizen_passport || props.selectedPatient?.citizen_no || "-",
        telMobile: props.selectedPatient?.present_address?.tel_mobile || "",
        visit_date: formatVisitDate(getEncounterData?.created) || "-",
        doctorName: getEncounterData?.doctor_name || "-",
        doctor_number: getDoctorCertNumber,
      },
    });

    const pdfMake = await getPdfMake(true);
    const pdf = pdfMake.createPdf(docDef);

    pdf.open();
  };

  return (
    <CardLayout
      titleText="Progress Note"
      headerColor="orange"
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.progressNoteLoading}
      enableMargin={props.enableMargin}
    >
      <div
        style={{
          display: props.progressionNote.length !== 0 ? "flex" : "none",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button color="blue" onClick={handlePrint}>
          พิมพ์ใบ Progress Note
        </Button>
      </div>
      <div style={{ maxHeight: 480, overflowY: "auto" }}>{generateData()}</div>
    </CardLayout>
  );
}

CardProgressNoteView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  progressionNote: [],
  progressNoteLoading: false,
  enableMargin: true,
  getProgressionNote: () => {},
};

export default CardProgressNoteView;