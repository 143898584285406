import { useState, useEffect, useMemo, SyntheticEvent } from "react";
import { Button, Message, Modal } from "semantic-ui-react";

// UX
import ModEditDiscount from "./ModEditDiscount";
import CardMedicalFeeHistoryUX from "./CardMedicalFeeHistoryUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Utils
import { dateToStringWithoutTimeBE, formatDate, formatDatetime } from "react-lib/utils/dateUtils";
import moment from "moment";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import { useIntl } from "react-intl";
import { ENCOUNTER_OPTIONS } from "react-lib/apps/HISV3/LAB/CardLabOrderQueue";

// Const
const CARD_MEDICAL_FEE_HISTORY = "CardMedicalFeeHistory";
const MOD_EDIT_DISCOUNT = "ModEditDiscountUX";
const MOD_ADD_ITEMS = "ModAddItems";

const DEFAULT_DATE = dateToStringWithoutTimeBE(moment());

const CardMedicalFeeHistory = (props: any) => {
  const intl = useIntl();
  const [openModEdit, setOpenModEdit] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [tracking, setTracking] = useState(false);
  const [modPrintInvoice, setModPrintInvoice] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(formatDate(DEFAULT_DATE));
  const [endDate, setEndDate] = useState(formatDate(DEFAULT_DATE));
  const [openMessage, setOpenMessage] = useState(false);

  const trackingStatus = props.MedicalFeeHistorySequence?.printStatus;

  useEffect(() => {
    props.runSequence({ sequence: "MedicalFeeHistory", restart: true });

    return () => {
      props.runSequence({ sequence: "MedicalFeeHistory", clear: true });
    };
  }, []);

  useEffect(() => {
    if (trackingStatus !== undefined && trackingStatus?.status !== "CANCELED") {
      setTracking(true);
    }
    if (trackingStatus?.status === "WAITING" || trackingStatus?.status === "IN_PROGRESS") {
      setTimeout(() => {
        props.runSequence({
          sequence: "MedicalFeeHistory",
          action: "trackingPrint",
          task_id: trackingStatus?.task_id,
          pdf: trackingStatus?.pdf,
        });
      }, 5000);
    }
  }, [trackingStatus]);

  const handleCloseModalTracking = () => {
    if (trackingStatus?.success || trackingStatus?.status === "CANCELED") {
      setTracking(false);
    } else {
      props.runSequence({
        sequence: "MedicalFeeHistory",
        action: "trackingPrint",
        task_id: trackingStatus?.task_id,
        pdf: trackingStatus?.pdf,
        status: "CANCEL",
      });
    }
  };

  const medicalFeeHistoryList = useMemo(() => {
    return (props.MedicalFeeHistorySequence?.MedicalFeeHistoryList || []).map((item: any) => {
      const formatPrice = "total,paid,unpaid,covered,discount".split(",").reduce(
        (r: any, k: any) => ({
          ...r,
          [k]: (
            <div style={{ textAlign: "right" }}>
              {Number(item[k]).toLocaleString("en-US", {
                style: "decimal",
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </div>
          ),
        }),
        {}
      );

      return {
        ...item,
        created: formatDatetime(item.created),
        ...formatPrice,
      };
    }) as any[];
  }, [props.MedicalFeeHistorySequence?.MedicalFeeHistoryList]);

  const handleRowProps = (state: any, rowInfo: any, column: any, instance: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id ? "#cccccc" : "white",
      },
      onClick: () => {
        setStartDate(moment(rowInfo.original.created, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY"));
        setSelectedRow(rowInfo.original);
      },
    };
  };

  const handleCloseModEdit = () => {
    setOpenModEdit(false);

    props.setProp("errorMessage", {
      ...props.errorMessage,
      [`${MOD_EDIT_DISCOUNT}_ADD`]: null,
      [`${MOD_EDIT_DISCOUNT}_SAVE`]: null,
    });

    props.setProp("MedicalFeeHistorySequence", {
      ...props.MedicalFeeHistorySequence,
      DiscountList: [],
      discountByCoverageCode: "",
      discountByCoverageName: "",
      orderList: [],
    });
  };

  const handleChangeDate = (date: any, typeDate: any) => {
    if (typeDate === "start") {
      setStartDate(date);
    } else if (typeDate === "end") {
      setEndDate(date);
    }
  };

  const handleMedicalFeeHistoryPrint = () => {
    if (startDate === "") {
      setOpenMessage(true);
    } else {
      setOpenMessage(false);
      setModPrintInvoice(false);

      props.runSequence({
        sequence: "MedicalFeeHistory",
        action: "print",
        encounter: selectedRow?.id,
        card: CARD_MEDICAL_FEE_HISTORY,
        buttonLoadKey: `${CARD_MEDICAL_FEE_HISTORY}_PRINT`,
        startDate: startDate,
        endDate: endDate,
      });
    }
  };

  const handleCloseModal = () => {
    setStartDate(DEFAULT_DATE);
    setEndDate(DEFAULT_DATE);
    setOpenMessage(false);
    setModPrintInvoice(false);
  };

  const handleChangeFilter = (
    e: SyntheticEvent | null,
    data: { checked?: boolean; name: string; value: boolean | string }
  ) => {
    const value = data.checked === undefined ? data.value : data.checked;

    props.setProp(`MedicalFeeHistorySequence.medicalFeeFilter.${data.name}`, value);
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "MedicalFeeHistory",
      action: "Search",
      card: CARD_MEDICAL_FEE_HISTORY,
      buttonLoadKey: `${CARD_MEDICAL_FEE_HISTORY}_PRINT`,
    });
  };

  return (
    <div>
      <CardMedicalFeeHistoryUX
        MedicalFeeHistoryList={medicalFeeHistoryList}
        medicalFeeFilter={props.MedicalFeeHistorySequence?.medicalFeeFilter}
        selectedRow={selectedRow}
        hideButtonEdit={["หน่วยตรวจ", "หอผู้ป่วย", "ห้องผ่าตัด"].includes(props.divisionType)}
        getTrProps={handleRowProps}
        buttonPrint={
          <Button
            disabled={selectedRow ? false : true}
            color="blue"
            onClick={() => setModPrintInvoice(true)}
          >
            {"พิมพ์รายการค่าใช้จ่าย"}
          </Button>
        }
        onEdit={() => {
          setOpenModEdit(true);
          props.runSequence({
            sequence: "MedicalFeeHistory",
            action: "openEdit",
            encounter: selectedRow?.id,
          });
        }}
        onRefresh={() => {
          props.runSequence({
            sequence: "MedicalFeeHistory",
            action: "refresh",
          });
          setSelectedRow(null);
        }}
        encounterOptions={ENCOUNTER_OPTIONS}
        onChangeFilter={handleChangeFilter}
        buttonSearch={
          <ButtonLoadCheck
            setProp={props.setProp}
            color="blue"
            paramKey={`${CARD_MEDICAL_FEE_HISTORY}_Search`}
            size="small"
            title={intl.formatMessage({ id: "ค้นหา" })}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_MEDICAL_FEE_HISTORY}_Search`]}
            // callback
            onClick={handleSearch}
          />
        }
        languageUX={props.languageUX}
      />

      <Modal open={openModEdit} size="large" onClose={handleCloseModEdit}>
        <ModEditDiscount
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          MedicalFeeHistorySequence={props.MedicalFeeHistorySequence}
          // data
          // * ไม่ต้องส่ง encounter หากไม่ต้องการ filter by encounter
          encounterId={selectedRow?.id}
          // CommonInterface
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          loadingStatus={props.loadingStatus}
          // options
          masterOptions={props.masterOptions}
        />
      </Modal>

      {/* tracking */}
      <Modal open={tracking} size="mini">
        <div>
          <div
            style={{
              backgroundColor: trackingStatus?.success
                ? "green"
                : trackingStatus?.status === "CANCELED"
                ? "red"
                : "#00B5AD",
              padding: "15px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {trackingStatus?.success
              ? "เตรียมเอกสารเสร็จสิ้น"
              : trackingStatus?.status === "CANCELED"
              ? "ยกเลิก"
              : "กำลังเตรียมเอกสาร"}
          </div>
          <div style={{ padding: "15px" }}>
            {trackingStatus?.success ? (
              <div>
                <div>{intl.formatMessage({ id: "เตรียมเอกสารเสร็จสิ้น" })}</div>
              </div>
            ) : trackingStatus?.status === "CANCELED" ? (
              <div>
                <div>{trackingStatus?.current_progress_description}</div>
              </div>
            ) : (
              <div>
                <div>{intl.formatMessage({ id: "กรุณารอสักครู่" })}</div>
                <div style={{ padding: "10px 0px" }}>
                  For IT: task_id = {trackingStatus?.task_id}
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button
                basic
                color={trackingStatus?.success ? "green" : "red"}
                size="tiny"
                onClick={() => handleCloseModalTracking()}
              >
                {trackingStatus?.success || trackingStatus?.status === "CANCELED"
                  ? "ตกลง"
                  : "ยกเลิก"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open={modPrintInvoice} onClose={handleCloseModal}>
        <div style={{ margin: "20px" }}>
          <div style={{ fontWeight: "bold" }}>{"ระบุข้อมูลช่วงค่าใช้จ่ายสำหรับพิมพ์ค่ารักษา"}</div>
          <hr />

          {/* error message  */}
          {props.errorMessage?.[`${CARD_MEDICAL_FEE_HISTORY}_PRINT`] && (
            <Message color="red">
              {props.errorMessage?.[`${CARD_MEDICAL_FEE_HISTORY}_PRINT`]}
            </Message>
          )}

          {openMessage && (
            <div style={{ marginBottom: "15px" }}>
              {startDate === "" && <Message color="red">กรุณาระบุค่าใน "วันที่"</Message>}

              {endDate === "" && <Message color="red">กรุณาระบุค่าใน "สิ้นสุด"</Message>}
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <div style={{ width: "33%", display: "flex", alignItems: "center" }}>
              <label style={{ fontWeight: "bold" }}>{"วันที่"}</label>
              <div style={{ color: "red" }}>*</div>

              <DateTextBox
                style={{ margin: "0px 10px" }}
                value={startDate}
                onChange={(date: any) => handleChangeDate(date, "start")}
              ></DateTextBox>
            </div>

            <div style={{ width: "33%", display: "flex", alignItems: "center" }}>
              <div
                style={{
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <label style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "สิ้นสุด" })}</label>
                <DateTextBox
                  style={{ margin: "0px 10px" }}
                  value={endDate}
                  onChange={(date: any) => handleChangeDate(date, "end")}
                ></DateTextBox>
              </div>
            </div>

            <div style={{ width: "20%" }}>
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleMedicalFeeHistoryPrint}
                // data
                paramKey={`${CARD_MEDICAL_FEE_HISTORY}_PRINT`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_MEDICAL_FEE_HISTORY}_PRINT`]}
                // config
                name="PRINT"
                disabled={!selectedRow}
                color="blue"
                size="small"
                title={intl.formatMessage({ id: "พิมพ์" })}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CardMedicalFeeHistory;
