import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Label, Icon } from "semantic-ui-react";
import { MainHIS } from "./MainHIS";

// HIS new architecture
import CardDrugOrder from "react-lib/apps/HISV3/TPD/CardDrugOrder";
import CardDrugOrderWorking from "react-lib/apps/HISV3/TPD/CardDrugOrderWorking";
import CardMedicalFee from "react-lib/apps/HISV3/BIL/CardMedicalFee";
import CardTriage from "react-lib/apps/HISV3/PTM/CardTriage";
import CardAssessmentBundle from "react-lib/apps/HISV3/PTM/CardAssessmentBundle";
import CardDischargeReassessment from "react-lib/apps/HISV3/PTM/CardDischargeReassessment";
import CardDoctorFeeOrderAndCompensation from "react-lib/apps/HISV3/DFC/CardDoctorFeeOrderAndCompensation";
import CardPatientList from "react-lib/apps/HISV3/common/CardPatientList";
import CardZoneLog from "react-lib/apps/HISV3/common/CardZoneLog";
import CardPreAssessment from "react-lib/apps/HISV3/ADM/CardPreAssessment";
import CardDoctorNoteOrder from "react-lib/apps/HISV3/DPO/CardDoctorNoteOrder";
import CardProgressCycle from "react-lib/apps/HISV3/DPO/CardProgressCycle";
import CardTreatmentOrder from "react-lib/apps/HISV3/DPO/CardTreatmentOrder";
import CardVerbalOrder from "react-lib/apps/HISV3/PTM/CardVerbalOrder";
import CardLabRequest from "react-lib/apps/HISV3/LAB/CardLabRequest";
import CardOPDLabSummary from "react-lib/apps/HISV3/LAB/CardOPDLabSummary";
import CardSupplyOrder from "react-lib/apps/HISV3/MSD/CardSupplyOrder";
import CardBed from "react-lib/apps/HISV3/ADM/CardBed";
import CardLabPanel from "react-lib/apps/HISV3/LAB/CardLabPanel";
import CardPatientPanel from "react-lib/apps/HISV3/common/CardPatientPanel";
import CardAppointmentManage from "react-lib/apps/HISV3/APP/CardAppointmentManage";
import CardOrderSummary from "react-lib/apps/HISV3/DPI/CardOrderSummary";
import CardDrugContinuePlan from "react-lib/apps/HISV3/TPD/CardDrugContinuePlan";
import CardMedReconcile from "react-lib/apps/HISV3/TPD/CardMedReconcile";
import CardStationSummary from "react-lib/apps/HISV3/BIL/CardStationSummary";
import CardAllergy from "react-lib/apps/HISV3/TPD/CardAllergy";
import CardDischargeSummary from "react-lib/apps/HISV3/ADM/CardDischargeSummary";
import CardDischargeSummaryNurse from "react-lib/apps/HISV3/PTM/CardDischargeSummaryNurse";
import CardNursingDiagnosis from "react-lib/apps/HISV3/PTM/CardNursingDiagnosis";
import CardTreatmentResult from "react-lib/apps/HISV3/DPO/CardTreatmentResult";
import CardFood from "react-lib/apps/HISV3/FOD/CardFood";
import CardSupplyOrderWorkflow from "react-lib/apps/HISV3/MSD/dispensing/CardSupplyOrderWorkflow";
import CardMedRequestReturn from "react-lib/apps/HISV3/MSD/dispensing/CardMedRequestReturn";
import CardMedicalFeeHistory from "react-lib/apps/HISV3/BIL/CardMedicalFeeHistory";
import CardReAssessment from "react-lib/apps/HISV3/ADM/CardReAssessment";
import CardDrugCounselling from "react-lib/apps/HISV3/TPD/CardDrugCounselling";
import CardOpenEncounter from "react-lib/apps/HISV3/REG/CardOpenEncounter";
// ANE
import CardAnestheticDocument from "react-lib/apps/HISV3/ANE/CardAnestheticDocument";
// ORM
import CardPreOperation from "react-lib/apps/HISV3/ORM/CardPreOperation";
import CardSurgicalSafetyChecklist from "react-lib/apps/HISV3/ORM/CardSurgicalSafetyChecklist";
import CardCountsAndPacking from "react-lib/apps/HISV3/ORM/CardCountsAndPacking";
import CardPerioperativeNursingRecord from "react-lib/apps/HISV3/ORM/CardPerioperativeNursingRecord";
import CardServiceRefund from "react-lib/apps/HISV3/ORM/CardServiceRefund";
import CardStaffORRequest from "react-lib/apps/HISV3/ORM/CardStaffORRequest";
// From IsHealth
import ChatBox from "react-lib/apps/MSG/ChatBox";
// BIL
import CardInvoicesSummary from "react-lib/apps/HISV3/BIL/CardInvoicesSummary";
import CardBillingDeposit from "react-lib/apps/HISV3/BIL/CardBillingDeposit";
import CardBillingDepositHistory from "react-lib/apps/HISV3/BIL/CardBillingDepositHistory";

// From MainDPO react
import { renderDrugOrderItems } from "react-lib/apps/HISV3/TPD/TPDInterface";
import CardPrintList from "react-lib/apps/common/CardPrintList";
import CardScannedDocument from "react-lib/apps/HISV3/common/CardScannedDocument";
import CardScanner from "react-lib/apps/HISV3/common/CardScanner";
import CardVitalSign from "react-lib/apps/HISV3/PTM/CardVitalSign";
import CardPatientInfo from "react-lib/apps/HISV3/DPO/CardPatientInfo";
import CardICD10React from "react-lib/apps/DPO/CardICD10";
import CardFormViewer from "react-lib/apps/PTM/CardFormViewer";
import PatientPanel from "react-lib/apps/HISV3/common/PatientPanelHorizontal";
import CardLabResult from "react-lib/apps/LAB/CardLabResult";
import CardAppointmentRequestNew from "react-lib/apps/APP/CardAppointmentRequestNew";
import CardAppointmentRequestConsultReact from "react-lib/apps/APP/CardAppointmentRequestConsult";
import CardConsultationList from "react-lib/apps/HISV3/DPO/CardConsultationList";
import CardOtherBill from "react-lib/apps/BIL/CardOtherBill";
import CardAdmissionFormReact from "react-lib/apps/DPI/CardAdmissionForm";
import CardAdmitOrder from "react-lib/apps/ADM/CardAdmitOrder";
import CardDischargeReact from "react-lib/apps/DPO/CardDischarge";
import CardImageGallery from "react-lib/apps/DPO/CardImageGallery";
import CardMedCert from "react-lib/apps/DPO/CardMedCert";
import CardMultiDisciplinaryView from "react-lib/apps/PTM/CardMultiDisciplinaryView";
import CardEpisodeOfCare from "react-lib/apps/common/CardEpisodeOfCare";
import CardBillingHistory from "react-lib/apps/HISV3/BIL/CardBillingHistory";
import CardFinancialAmountSet from "react-lib/apps/HISV3/BIL/CardFinancialAmountSet";
import CardPatientCoverage from "react-lib/apps/HISV3/BIL/CardPatientCoverage";
import CardBillingEncounters from "react-lib/apps/HISV3/BIL/CardBillingEncounters";
import CardPostOperation from "react-lib/apps/HISV3/ORM/CardPostOperation";
import CardProgressNoteList from "react-lib/apps/HISV3/DPI/CardProgressNoteList";
import CardChartSummaryContainer from "react-lib/apps/HISV3/common/CardChartSummaryContainer";
import CardImagingExamResult from "react-lib/apps/PHV/CardImagingExamResult";
import CardLabReport from "react-lib/apps/HISV3/LAB/CardLabReport";
import CardDrugOrderHistory from "react-lib/apps/HISV3/TPD/CardDrugOrderHistory";
import CardDrugResuscitationOPD from "react-lib/apps/HISV3/TPD/CardDrugResuscitationOPD";
import CardPlanTransfer from "react-lib/apps/HISV3/DPI/CardPlanTransfer";
import CardDrugOrderList from "react-lib/apps/HISV3/TPD/CardDrugOrderList";
import CardDrugTransportation from "react-lib/apps/HISV3/TPD/CardDrugTransportation";
import CardImagingOrder from "react-lib/apps/HISV3/IME/CardImagingOrder";
import CardImagingResult from "react-lib/apps/HISV3/IME/CardImagingResult";
import CardFormScreen from "react-lib/apps/HISV3/DPO/CardFormScreen";
import CardMedCertificateReport from "react-lib/apps/HISV3/DPO/CardMedCertificateReport";
import CardPurchaseHistory from "react-lib/apps/HISV3/HCU/CardPurchaseHistory";
import CardApplyPolicyPackage from "react-lib/apps/HISV3/HCU/CardApplyPolicyPackage";
import CardDrugPickingOrder from "react-lib/apps/HISV3/TPD/CardDrugPickingOrder";

import * as MainHISI from "./MainHISInterface";

import Tab from "react-lib/frameworks/Tab";
import PatientLayout from "HIS/PatientLayout";

import { beToAd } from "react-lib/utils";
import CardNurseNote from "react-lib/apps/HISV3/PTM/CardNurseNote";
import CardIntraHospitalTransfer from "react-lib/apps/HISV3/PTM/CardIntraHospitalTransfer";
import CardOPDOrderSummary from "react-lib/apps/HISV3/PTM/CardOPDOrderSummary";
import CardPHVList from "react-lib/apps/HISV3/PHV/CardPHVList";
import CardSearchPackage from "react-lib/apps/HISV3/HCU/CardSearchPackage";
import CardConfirmCoverage from "react-lib/apps/HISV3/REG/CardConfirmCoverage";

// import CardImagingRequest from "react-lib/apps/Scheduling/CardImagingRequest";

import CONFIG from "config/config";
import { useIntl } from "react-intl";

const getOrderTypes = (type: string, isOrOrder: boolean) => {
  // move order-type ไปด้านหน้าสุด (ถ้ามี)
  const prioritizeItem = (types: string[], value: string) =>
    types.includes(value) ? [value, ...types.filter((i) => i !== value)] : types;

  // แยกตาม IPD หรือ OPD
  const getBaseOrderTypes = () => {
    if (isOrOrder) {
      return type === "IPD"
        ? ["STAT_IPD", "OPERATING", "HOME_IPD"]
        : ["STAT_OPD", "OPERATING", "HOME_OPD"];
    }

    return type === "IPD"
      ? ["STAT_IPD", "ONE_DAY_IPD", "ONE_DOSE_IPD", "HOME_IPD"]
      : ["STAT_OPD", "ONE_DOSE_OPD", "HOME_OPD"];
  };

  // กรองประเภทที่ซ่อนออก
  const types = getBaseOrderTypes().filter((type) => !CONFIG.V3_HIDDEN_TAB_LIST.includes(type));

  // ปรับลำดับ CU ให้ HOME_OPD อยู่หน้าสุด
  return CONFIG.COMPANY === "CU" ? prioritizeItem(types, "HOME_OPD") : types;
};

export const Main = (props: {
  onEvent: any;
  setProp: any;
  listView: any;
  panelView: any;
  recordView: any;
  resetLayout: any;
  initialLayout: any;
  apiToken: any;
  languageUX: "th" | "en";
}) => {
  // console.log("Init Card ViewEncounter.Main props: " , props)
  const history = useHistory();
  // useEffect(() => {
  //   props?.onEvent({
  //     message: "GetPatientQueue",
  //     params: {},
  //   });
  // }, []);

  useEffect(() => {
    props.onEvent({ message: "GetTriageLevel" });
  }, []);
  useEffect(() => {
    props.onEvent({ message: "GetChoicesBarcode" });
  }, []);

  const handleClearEncounter = () => {
    props.setProp("initialLayout", null);
    history.replace("?app=HIS");
  };

  const handleBackward = (layout: number) => {
    if (layout === 1) {
      props.onEvent({ message: "HandleGoBackAppointment", params: {} });
    }
  };
  // console.log(CONFIG.SCREEN_ITEM);

  return (
    <div id="ViewEncounter.Main" style={{ width: "100%", height: "100%" }}>
      <PatientLayout
        LAYOUT_SETTINGS={[
          {
            patientList: { display: true, flex: 1, size: "max" },
            patientPanel: { display: false, flex: 0, size: "none" },
            patientRecord: { display: false, flex: 0, size: "none" },
          },
          // {
          //   patientList: {display: true, flex: 1, size: "min"},
          //   patientPanel: {display: true, flex: 4, size: "max"},
          //   patientRecord: {display: false, flex: 0, size: "none"},
          //   forward: true,
          //   backward: true,
          // },
          {
            patientList: { display: false, flex: 0, size: "none" },
            patientPanel: { display: true, flex: 1, size: "min" },
            patientRecord: { display: true, flex: 4, size: "min" },
            backward: true,
          },
          {
            patientList: { display: false, flex: 0, size: "none" },
            patientPanel: { display: false, flex: 0, size: "none" },
            patientRecord: { display: true, flex: 1, size: "max" },
            backward: true,
          },
        ]}
        patientList={(layoutProps: any) => (
          <div
            style={{ backgroundColor: "white", height: "100%", width: "100%" }}
          >
            {props.listView(layoutProps)}
          </div>
        )}
        patientPanel={(layoutProps: any) => (
          <div
            style={{
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              borderRight: "solid #cccccc 1px",
            }}
          >
            {props.panelView(layoutProps)}
          </div>
        )}
        patientRecord={(layoutProps: any) => (
          <div style={{ height: "100%", width: "100%" }}>
            {props.recordView(layoutProps, props.setProp)}
          </div>
        )}
        resetLayout={props.resetLayout}
        initialLayout={props.initialLayout}
        onClearEncounter={handleClearEncounter}
        onBackward={handleBackward}
      />
    </div>
  );
};

Main.displayName = 'ViewEncounter.Main'

export const listView = (context: MainHIS) => (layoutProps) => {
  const isPharmacist = !!context.state.django?.user?.role_types?.find(
    (role: string) => ["PHARMACIST"].includes(role)
  );
  console.log('Init Card ViewEncounter.listView: context', context);
  console.log('Init Card ViewEncounter.listView: layoutProps', layoutProps);
  console.log('Init Card ViewEncounter.listView: context.props', context.props);

  return (
    <CardPatientList
      id="ViewEncounterListView"
      ref={(ref) => (context.serviceQueueRef = ref)}
      buttonLoadCheck={context.state.buttonLoadCheck}
      searchedItemList={context.state.searchedItemList}
      division={context.controller.data.division}
      divisionType={context.state.divisionType}
      onEvent={context.controller.handleEvent}
      layout={layoutProps.layout}
      resetLayout={context.state.resetLayout}
      size={layoutProps.size}
      masterOptions={context.state.masterOptions}
      masterData={context.controller.data.masterData}
      choiceTriage={context.state.ChoiceTriage}
      runSequence={context.runSequence}
      providerEmployeeInfo={context.state.providerEmployeeInfo}
      currentDoctor={context.state.currentDoctor}
      divisionOrganization={context.state.divisionOrganization}
      // หน่วยตรวจ
      django={context.state.django}
      isPharmacist={isPharmacist}
      encounterList={context.state.encounterList}
      encounterDetailDict={context.state.encounterDetailDict}
      setProp={context.controller.setProp}
      selectedEncounter={context.state.selectedEncounter}
      forward={layoutProps.forward}
      // ห้องยา
      // set mode queue
      drugOrderTabMode={context.state.drugOrderTabMode}
      drugOrderQueue={context.state.drugOrderQueue}
      verbalDrugOrderQueue={context.state.verbalDrugOrderQueue}
      drugHistoryOrderList={context.state.drugHistoryOrderList}
      drugHistoryList={context.state.drugHistoryList}
      selectedDrugOrderWorking={context.state.selectedDrugOrderWorking}
      selectedVerbalDrugOrderWorking={
        context.state.selectedVerbalDrugOrderWorking
      }
      SetDrugOrderQueueSequence={context.state.SetDrugOrderQueueSequence}
      AssessmentSequence={context.state.AssessmentSequence}
      DrugOrderHistorySequence={context.state.DrugOrderHistorySequence}
      DrugOrderActionSequence={context.state.DrugOrderActionSequence}
      HistoryCentralLabSequence={context.state.HistoryCentralLabSequence}
      InpatientDiagnosisSequence={context.state.InpatientDiagnosisSequence}
      PerioperativeNursingSequence={context.state.PerioperativeNursingSequence}
      ORHistorySequence={context.state.ORHistorySequence}
      ORCancelListSequence={context.state.ORCancelListSequence}
      ORCaseListSequence={context.state.ORCaseListSequence}
      ORPostOperationSequence={context.state.ORPostOperationSequence}
      ReportDrugUseRecordSequence={context.state.ReportDrugUseRecordSequence}
      ConsultationListSequence={context.state.ConsultationListSequence}
      consultDetail={context.state.consultDetail}
      AnesthesiaStatReportSequence={context.state.AnesthesiaStatReportSequence}
      // Controller
      drugOrderHistoryController={context.drugOrderHistoryController}
      drugOrderQueueController={context.drugOrderQueueController}
      crdAdmitOrderController={context.crdAdmitOrderController}
      subICD10DetailController={context.subICD10DetailController}
      printListController={context.printListController}
      scannedDocumentController={context.scannedDocumentController}
      patientPanelController={context.patientPanelController}
      proxyController={context.proxyController}
      drugRequestList={context.state.drugRequestList}
      DrugTransferRequestSequence={context.state.DrugTransferRequestSequence}
      selectedDevice={context.state.selectedDevice}
      selectedDivision={context.state.selectedDivision}
      drugTransferRequestlist={context.state.drugTransferRequestlist}
      drugTransferLog={context.state.drugTransferLog}
      // Allergy
      AllergySequence={context.state.AllergySequence}
      // แลป
      labOrderQueue={context.state.labOrderQueue}
      selectedLabOrderWorking={context.state.selectedLabOrderWorking}
      labNotify={context.state.labNotify}
      permissionLabResultConfidential={
        context.state.permissionLabResultConfidential
      }
      LabReportSequence={context.state.LabReportSequence}
      // Medication Error List
      MedErrorListSequence={context.state.MedErrorListSequence}
      errorMessage={context.state.errorMessage}
      successMessage={context.state.successMessage}
      drugTransferRequestDetail={context.state.drugTransferRequestDetail}
      // Ward
      WardQueueSequence={context.state.WardQueueSequence}
      AdmitChangeSequence={context.state.AdmitChangeSequence}
      modAdmit={context.state.modAdmit}
      intraTransferForm={context.state.intraTransferForm}
      intraHospitalTransferOptions={context.state.intraHospitalTransferOptions}
      selectVitalSignLoading={context.state.selectVitalSignLoading}
      getEncounterVitalSignListErr={context.state.getEncounterVitalSignListErr}
      encounterVitalSignList={context.state.encounterVitalSignList}
      // ADM
      SetBedSequence={context.state.SetBedSequence}
      ReportPatientIPDListSequence={context.state.ReportPatientIPDListSequence}
      ReportPatientListSequence={context.state.ReportPatientListSequence}
      eocIsLoading={context.state.eocIsLoading}
      // BIL
      billingFilterQueue={context.state.billingFilterQueue}
      billingQueueList={context.state.billingQueueList}
      billingHistorySequence={context.state.BillingHistorySequence}
      billingQueuePagination={context.state.billingQueuePagination}
      receiptGroupSetting={context.state.receiptGroupSetting}
      //Receive
      ReceiveOrderSequence={context.state.ReceiveOrderSequence}
      EncounterReceive={context.state.EncounterReceive}
      ChoiceBarcode={context.state.ChoiceBarcode}
      isOpenModReceive={context.state.isOpenModReceive}
      // DispensingOrderSupplySequence={context.state.DispensingOrderSupplySequence}
      // ReturnSupplySequence={context.state.ReturnSupplySequence}
      //ReturnOrder
      ReturnOrderSequence={context.state.ReturnOrderSequence}
      OrderReturnList={context.state.OrderReturnList}
      DrugReturnList={context.state.DrugReturnList}
      OrderHistory={context.state.OrderHistory}
      OrderReturnLog={context.state.OrderReturnLog}
      selectedEmr={context.state.selectedEmr}
      drugOrder={context.state.drugOrder}
      drugOrderLog={context.state.drugOrderLog}
      modNoteReject={context.state.modNoteReject}
      DrugSelectSequence={context.state.DrugSelectSequence}
      selectedPatient={context.state.selectedPatient}
      selectedDrug={context.state.selectedDrug}
      drugDose={context.state.drugDose}
      drugDelivery={context.state.drugDelivery}
      modConfirmAction={context.state.modConfirmAction}
      medErrorWorking={context.state.MedErrorListSequence?.medErrorWorking}
      loadingStatus={context.state.loadingStatus}
      // DrugTransferRequest
      drugTransferRequestSequence={context.state.DrugTransferRequestSequence}
      // PHV Port
      apiToken={context.controller.apiToken}
      // MedAdmin
      medRecordSummary={context.state.medRecordSummary}
      medicationRecord={context.state.medicationRecord}
      patientInfo={context.state.patientInfo}
      wardTypeList={context.state.wardTypeList}
      tabCurrentIndex={context.state.tabCurrentIndex}
      divisionList={context.state.divisionList}
      // DoctorPendingTask
      DoctorPendingTaskSequence={context.state.DoctorPendingTaskSequence}
      // DispensingOrderSupply
      DispensingOrderSupplySequence={
        context.state.DispensingOrderSupplySequence
      }
      SupplyDeliverySequence={context.state.SupplyDeliverySequence}
      RefillDivisionSequence={context.state.RefillDivisionSequence}
      supplyOrderStatus={context.state.masterOptions?.supplyOrderStatus}
      //DrugtransferRequestHistory
      DrugTransferRequestHistorySequence={
        context.state.DrugTransferRequestHistorySequence
      }
      DrugTransferRequestHistoryList={
        context.state.DrugTransferRequestHistoryList
      }
      //DrugReturnRequestHistory
      DrugReturnRequestHistorySequence={
        context.state.DrugReturnRequestHistorySequence
      }
      DrugReturnRequestHistoryList={context.state.DrugReturnRequestHistoryList}
      //AppointmentTelephar
      AppointmentTelepharSequence={context.state.AppointmentTelepharSequence}
      AppointmentTelepharList={context.state.AppointmentTelepharList}
      chatMessageTemplateOptions={context.state.chatMessageTemplateOptions}
      // ORM
      selectedOrOrder={context.state.selectedOrOrder}
      AnesDoctorFeeListSequence={context.state.AnesDoctorFeeListSequence}
      ORQueueSequence={context.state.ORQueueSequence}
      ZoneLogSequence={context.state.ZoneLogSequence}
      ORRequestSequence={context.state.ORRequestSequence}
      PreOperationSequence={context.state.PreOperationSequence}
      searchedItemListWithKey={context.state.searchedItemListWithKey}
      userTokenize={context.state.userTokenize}
      divisionId={context.controller.data.division}
      SupplyOrderHistorySequence={context.state.SupplyOrderHistorySequence}
      SupplyDeliveryHistorySequence={
        context.state.SupplyDeliveryHistorySequence
      }
      supplyOrderEligibilityOptions={
        context.state.supplyOrderEligibilityOptions
      }
      MedRequestReturnSequence={context.state.MedRequestReturnSequence}
      ReturnSupplySequence={context.state.ReturnSupplySequence}
      // FOD
      FoodListSequence={context.state.FoodListSequence}
      FoodRequestSequence={context.state.FoodRequestSequence}
      //Reject Order
      RejectOrderSequence={context.state.RejectOrderSequence}
      patientADR={context.state.patientADR}
      disabledVerbalMedOrder={context.state.divisionType !== "ห้องยา"}
      orderTypes={getOrderTypes(
        context.state.selectedEncounter?.type,
        !!context.state.selectedOrOrder
      )}
      // selectedDrugItem to edit
      selectedDrugItemDetail={context.state.selectedDrugItemDetail}
      selectedDrugItemDetailOptions={
        context.state.selectedDrugItemDetailOptions
      }
      drugSearchText={context.state.drugSearchText}
      drugSearchResult={context.state.drugSearchResult}
      // DrugSelectSequence={context.state.DrugSelectSequence}

      drugOrderList={context.state.drugOrderList}
      drugOrderListLoading={context.state.drugOrderListLoading}
      // drugOrder={context.state.drugOrder}
      renderDrugOrderItems={renderDrugOrderItems}
      // drugDose={context.state.drugDose}
      drugQty={context.state.drugQty}
      drugDuration={context.state.drugDuration}
      drugPrn={context.state.drugPrn}
      // modal doctor certificate check
      modDoctorCertificate={context.state.modDoctorCertificate}
      modDrugRecommendation={context.state.modDrugRecommendation}
      DrugOrderTemplateSequence={context.state.DrugOrderTemplateSequence}
      modConfirmDeleteDrug={context.state.modConfirmDeleteDrug}
      modReMedication={context.state.modReMedication}
      modDrugLabInteraction={context.state.modDrugLabInteraction}
      modDrugDisease={context.state.modDrugDisease}
      modDrugInteraction={context.state.modDrugInteraction}
      modRecontinueMed={context.state.modRecontinueMed}
      // currentDoctor={context.state.currentDoctor}
      drugOrderItemEstimate={context.state.drugOrderItemEstimate}
      drugPermission={context.state.drugPermission}
      // searchedItemList={context.state.searchedItemList}
      drugOrderItemMasterData={{
        masterUnits: context.state.masterOptions.unit,
        masterRoutes: context.state.masterOptions.route,
        masterSites: context.state.masterOptions.site,
        masterFrequencies: context.state.masterOptions.frequency,
        masterMethods: context.state.masterOptions.method,
      }}
      duplicateReasonsOptions={context.state.masterOptions.duplicateReasons}
      // SolventSelectionConfig
      modSolventSelectionConfig={context.state.modSolventSelectionConfig}
      // selectedVerbalDrugOrderWorking={
      //   context.state.selectedVerbalDrugOrderWorking
      // }

      // ClassifyOptions in PatientQueue
      classifyList={context.state.classifyList}
      DrugResuscitationSequence={context.state.DrugResuscitationSequence}
      // StockManagement
      StockManagementSequence={context.state.StockManagementSequence}
      StockTransferOrderSequence={context.state.StockTransferOrderSequence}
      // ReceiptCode
      bilReceiptCodeDetail={context.state.bilReceiptCodeDetail}
      // StockManagement
      lotNoExpList={context.state.lotNoExpList}
      // TelePharmacy
      TelePharmacySequence={context.state.TelePharmacySequence}
      DashboardTelepharSequence={context.state.DashboardTelepharSequence}
      // Med Reconcile
      // TODO: Check is need MedReconcile Alert modal
      medReconcileIndex={context.state.medReconcileIndex}
      transferOrderCount={context.state.transferOrderCount}
      postponeNotificationList={context.state.postponeNotificationList}
      // Imaging Worklist
      ImagingWorkListSequence={context.state.ImagingWorkListSequence}
      // Imaging Result
      ImagingResultSequence={context.state.ImagingResultSequence}
      // CommonInterface
      modXrayDetail={context.state.modXrayDetail}
      // ----- Dental Diagram
      clinicalFindingId={context.state.clinicalFindingId}
      clinicalFindingIndex={context.state.clinicalFindingIndex}
      clinicalFindingList={context.state.clinicalFindingList}
      organ={context.controller.data.organ}
      clinicaltags={context.controller.data.clinicaltags}
      filterClinicalFindingIds={context.state.filterClinicalFindingIds}
      // CardOpenEncounter
      setPatientSequence={context.state.SetPatientSequence}
      CreateUpdateEncounterSequence={
        context.state.CreateUpdateEncounterSequence
      }
      appointmentEncounterList={context.state.appointmentEncounterList}
      selectedAppointmentItems={context.state.selectedAppointmentItems}
      // CardConsultDetail
      consultData={context.state.consultData}
      notificationMessageList={context.state.notificationMessageList}
      selectedTabPatientList={context.state.selectedTabPatientList}
      // -----#
      IntraTelepharQueueSequence={context.state.IntraTelepharQueueSequence}
      // Feature 66725
      patientQueueEncounter={context.state.patientQueueEncounter}
      languageUX={context.props.userSelectedLanguage}
    />
  );
};

export const panelView = (context: React.ComponentType<typeof MainHIS>) => (layoutProps: any) => {
  // console.log("Init Card ViewEncounter.panelView")
  // const admit_code = context.state.encounterDetailDict?.admitorderfrom
  //                           ?.[context.state.selectedEncounter?.id]?.[0]?.code;
  return (
    <div style={{ height: "100%", width: "100%", overflow: "scroll" }}>
      <CardPatientPanel
        //Feature #54627
        onEvent={context.controller.handleEvent}
        cardDischargeController={context.cardDischargeController}
        patientData={context.state.patientData}
        django={context.state.django}
        patientADR={context.state.patientADR}
        userId={context.controller.data.user}
        selectedPatient={context.state.selectedPatient}
        chatDetail={context.state.chatDetail}
        selectedEncounter={context.state.selectedEncounter}
        CreateUpdateEncounterSequence={context.state.CreateUpdateEncounterSequence}
        selectedAppointment={context.state.selectedAppointment}
        selectedAdmitOrderRoomItem={context.state.selectedAdmitOrderRoomItem}
        selectedMainOrOrder={context.state.selectedMainOrOrder}
        orDischargeResult={context.state.orDischargeResult}
        runSequence={context.runSequence}
        masterOptions={context.state.masterOptions}
        errorMessage={context.state.errorMessage}
        loadingStatus={context.state.loadingStatus}
        vitalSignsInfo={context.state.vitalSignsInfo}
        patientAssessment={context.state.patientAssessment}
        problemListDPO={context.state.problemListDPO}
        messageProblemDPO={context.state.messageProblemDPO}
        weightInfo={context.state.weightInfo}
        heightInfo={context.state.heightInfo}
        LabResult={context.state.LabResult}
        selectedShippingOrder={context.state.selectedShippingOrder}
        selectedEmr={context.state.selectedEmr}
        selectedProgressCycle={context.state.selectedProgressCycle}
        buttonLoadCheck={context.state.buttonLoadCheck}
        divisionType={context.state.divisionType}
        form={"ViewEncounter"}
        //CardVitalSign
        closeable={false}
        toggleable={false}
        callOnDidMount={false}
        hideTabMenu={layoutProps.hideTabMenu}
        setHideTabMenu={layoutProps.setHideTabMenu}
        onGetLastHeight={() =>
          context.controller.handleEvent({
            message: "GetLastHeight",
            params: {},
          })
        }
        onGetVitalSignType={() =>
          context.controller.handleEvent({
            message: "GetVitalSignType",
            params: {},
          })
        }
        onGetVitalSign={(params: any) =>
          context.controller.handleEvent({
            message: "GetVitalSign",
            params: params,
          })
        }
        onNewMeasurement={(params: any) =>
          context.controller.handleEvent({
            message: "SaveNewMeasurement",
            params: params,
          })
        }
        onUpdateVitalsignValue={(params: any) =>
          context.controller.handleEvent({
            message: "AddVitalSignValue",
            params: params,
          })
        }
        onDeleteMeasurement={(delMeasureId: number, params: any) =>
          context.controller.handleEvent({
            message: "CancelMeasurement",
            params: { measureId: delMeasureId, data: params },
          })
        }
        //CardAllegy
        AllergySequence={context.state.AllergySequence}
        setProp={context.controller.setProp}
        successMessage={context.state.successMessage}
        searchedItemList={context.state.searchedItemList}
        tabPatientRef={context.tabPatientRef}
        dischargeDoctor={context.state.dischargeDoctor}
        loginVia={context.state.loginVia}
        approve_by={context.state.approve_by}
        // Suspending
        encounterSuspension={context.state.encounterSuspension}
        MedicalFeeHistorySequence={context.state.MedicalFeeHistorySequence}
        // Med reconcile
        medReconcileIndex={context.state.medReconcileIndex}
        patientEmr={context.state.patientEmr}
        isStudentUser={context.state.isStudentUser}
        checkApprove={context.state.checkApprove}
        // Assessment
        AssessmentSequence={context.state.AssessmentSequence}
        // CardPatientOldName
        SetPatientSequence={context.state.SetPatientSequence}
        languageUX={context.props.userSelectedLanguage}
      />
    </div>
  );
};

export const recordView =
  (context: MainHISType) => (layoutProps: any, setProp: any) => {
    const intl = useIntl();
    // console.log("Init Card ViewEncounter.recordView"

    // const getMedReconcileIndex = () => {
    //   console.log("getMedReconcileIndex", tabRef?.current)
    //   let idx = tabRef?.current?.getMedReconcileIndex()
    //   return idx
    // }

    // console.log(" context?.controller?.data?.userProfile", context?.controller);
    // console.log('CONFIG: ', CONFIG);
    // CardPharmacyTask - กด menu OR Schedule

    // console.debug('recordView CONFIG.SCREEN_ITEM: ',   CONFIG.SCREEN_ITEM);

    const isORSchedule = context.state.drugOrderTabMode === "orschedule";
    // console.debug('recordView isORSchedule: ', isORSchedule);

    const selectedEncounter = context.state.selectedEncounter;
    // console.debug('recordView selectedEncounter: ', selectedEncounter);
    const divisionType = context.state.divisionType || "";
    // console.debug('recordView divisionType: ', divisionType);

    const isNurse = !!context.state.django?.user?.role_types?.find(
      (role: string) => ["REGISTERED_NURSE"].includes(role)
    );
    const isDoctor = !!context.state.django?.user?.role_types?.find(
      (role: string) => ["DOCTOR"].includes(role)
    );
    const isPharmacist = !!context.state.django?.user?.role_types?.find(
      (role: string) => ["PHARMACIST"].includes(role)
    );

    const isCheckout = [
      "CHECKED_OUT",
      "WAIT_PAY",
      "WAIT_DISPENSE",
      "DISCHARGED",
    ].includes(selectedEncounter?.status);

    const isEncounterTypeIPD =
      !!selectedEncounter && selectedEncounter.type === "IPD";
    // console.debug('recordView isEncounterTypeIPD: ', isEncounterTypeIPD);
    const isOrOrder = !!context.state.selectedMainOrOrder;
    // console.debug('recordView isOrOrder: ', isOrOrder);
    const isImagingOrder = !!context.state.selectedEncounter?.is_imaging_order;
    // console.debug('recordView isImagingOrder: ', isImagingOrder);

    const isDrugDivision = !!selectedEncounter && divisionType === "ห้องยา";
    const isBilDivision = !!selectedEncounter && divisionType === "การเงิน";
    const isOrDivision = !!selectedEncounter && divisionType === "ห้องผ่าตัด";
    const isSupplyDivision = !!selectedEncounter && divisionType === "ห้องเวชภัณฑ์";
    const isSpecimenDivision = !!selectedEncounter && divisionType  === "แผนกเก็บ Specimen"
    const isGeneralDivision = !!selectedEncounter && divisionType === "ทั่วไป";

    const isClinicWardDivision =
      !!selectedEncounter &&
      [
        "หน่วยตรวจ",
        "หอผู้ป่วย",
        "พื้นที่ดูแล",
        "คลินิกพรีเมี่ยม",
        "หอผู้ป่วยพรีเมี่ยม",
      ].includes(divisionType);
    const isOrClinicWardDivision =
      divisionType === "ห้องผ่าตัด" || isClinicWardDivision;
    const isDrugClinicWardDivision = isDrugDivision || isClinicWardDivision;
    // OR_STATUS REQUESTED = 1, 'รอยืนยัน' CONFIRMED = 2, 'ยืนยันแล้ว' SCHEDULED = 3, 'ยืนยันตาราง' REALIZED = 4, 'นัดหมายเสร็จสิ้น' OPERATION = 5, 'Registration area' COMPLETED = 6, 'Complete' POSTPONED = 7, 'เลื่อนนัด' CANCELED = 10, 'ยกเลิก' HOLDING_ROOM = 11, 'Holding Room' OPERATING_ROOM = 12, 'Operating Room' PACU = 13, 'PACU'
    // เมื่อยืนยันตารางแล้ว
    const isScheduleConfirmed =
      context.state.selectedMainOrOrder?.status &&
      context.state.selectedMainOrOrder?.status > 4;

    // const isAnesthesiaorder =
    //   isOrOrder &&
    //   context.state.selectedMainOrOrder?.other_orders?.filter(
    //     (item: any) => item.model === "anesthesiaorder"
    //   )?.length > 0;

    const consultOrder =
      context.state.selectedEncounter?.doctor_orders?.filter(
        (item: any) => item?.specific_type === "doctorconsultorder"
      )?.[0] || null;

    const admitOrder =
      context.state.selectedEncounter?.doctor_orders?.filter(
        (item: any) => item?.specific_type === "admitorder"
      )?.[0] || null;

    // check approve_info
    let labOrderInfo;
    let diagnosisInfo;
    let drugOrderInfo;
    let patientInfoApproval;
    let treatmentOrderInfo;
    let treatmentOrderResultInfo;

    if (selectedEncounter?.approve_info) {
      Object.keys(selectedEncounter?.approve_info).map((name: any) => {
        Object.keys(selectedEncounter?.approve_info?.[name]).map(
          (data: any) => {
            if (
              selectedEncounter?.approve_info?.[name]?.[data]?.need_approve ===
              true
            ) {
              switch (name) {
                case "centrallaborder":
                  return (labOrderInfo = true);
                case "diagnosismedicalrecordapproval":
                  return (diagnosisInfo = true);
                case "drugorder":
                  return (drugOrderInfo = true);
                case "patientinfoapproval":
                  return (patientInfoApproval = true);
                case "treatmentorder":
                  return (treatmentOrderInfo = true);
                case "treatmentorderresultapproval":
                  return (treatmentOrderResultInfo = true);
                default:
                  return null;
              }
            }
          }
        );
      });
    }

    return (
      <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
        <Tab
          setProp={setProp}
          tabWidth="160px"
          marginBottom="16px"
          contentWidth="0"
          showSearchInput={true}
          selectedTabIndex={context.state.selectedRecordViewIndex}
          medReconcileIndex={context.state.medReconcileIndex}
          hideTabMenu={layoutProps.hideTabMenu}
          disabled={context.state.selectedRecordViewIndex === -1}
          onTabChange={(index: number) => {
            context.controller.setProp("selectedRecordViewIndex", index);
          }}
          data={[
            {
              key: "Drug Workflow",
              name: "Drug Workflow",
              hide: !isDrugDivision || isORSchedule || CONFIG.ENABLE_TELEPHAR,
              render: () => (
                <CardDrugOrderWorking
                  runSequence={context.runSequence}
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedEmr={context.state.selectedEmr}
                  selectedDivision={context.state.selectedDivision}
                  drugOrderQueue={context.state.drugOrderQueue}
                  verbalDrugOrderQueue={context.state.verbalDrugOrderQueue}
                  drugOrder={context.state.selectedDrugOrderWorking}
                  drugOrderLog={context.state.drugOrderLog}
                  modNoteReject={context.state.modNoteReject}
                  layout={layoutProps.layout}
                  forward={layoutProps.forward}
                  DrugSelectSequence={context.state.DrugSelectSequence}
                  selectedPatient={context.state.selectedPatient}
                  selectedDrug={context.state.selectedDrug}
                  drugDose={context.state.drugDose}
                  drugDelivery={context.state.drugDelivery}
                  modConfirmAction={context.state.modConfirmAction}
                  masterOptions={context.state.masterOptions}
                  masterData={context.controller.data.masterData}
                  DrugOrderActionSequence={
                    context.state.DrugOrderActionSequence
                  }
                  MedErrorListSequence={context.state.MedErrorListSequence}
                  errorMessage={context.state.errorMessage}
                  loadingStatus={context.state.loadingStatus}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // DrugTransferRequest
                  drugTransferRequestSequence={
                    context.state.DrugTransferRequestSequence
                  }
                  drugOrderHistoryController={
                    context.drugOrderHistoryController
                  }
                  drugRequestList={context.state.drugRequestList}
                  selectedDevice={context.state.selectedDevice}
                  drugTransferRequestlist={
                    context.state.drugTransferRequestlist
                  }
                  drugTransferLog={context.state.drugTransferLog}
                  drugTransferRequestDetail={
                    context.state.drugTransferRequestDetail
                  }
                  // CardDrugContinuePlan
                  drugPermission={context.state.drugPermission}
                  drugContinueFilter={context.state.drugContinueFilter}
                  drugContinuePlanItems={context.state.drugContinuePlanItems}
                  drugSearchLoading={context.state.drugSearchLoading}
                  drugSearchText={context.state.drugSearchText}
                  drugSearchResult={context.state.drugSearchResult}
                  modPlanningConfig={context.state.modPlanningConfig}
                  planningData={context.state.planningData}
                  modSpecialDirectionConfig={
                    context.state.modSpecialDirectionConfig
                  }
                  modSolventSelectionConfig={
                    context.state.modSolventSelectionConfig
                  }
                  modNoteData={context.state.modNoteData}
                  modDoctorCertificate={context.state.modDoctorCertificate}
                  modDrugLabInteraction={context.state.modDrugLabInteraction}
                  modDrugDisease={context.state.modDrugDisease}
                  modDrugInteraction={context.state.modDrugInteraction}
                  modDrugRecommendation={context.state.modDrugRecommendation}
                  currentDoctor={context.state.currentDoctor}
                  isDoctor={isDoctor}
                  isPharmacist={isPharmacist}
                  //ReturnOrder
                  ReturnOrderSequence={context.state.ReturnOrderSequence}
                  OrderReturnList={context.state.OrderReturnList}
                  DrugReturnList={context.state.DrugReturnList}
                  OrderHistory={context.state.OrderHistory}
                  OrderReturnLog={context.state.OrderReturnLog}
                  // config
                  closeIcon={true}
                  //MedReconcile
                  medReconcileCheck={context.state.medReconcileCheck}
                  divisionType={context.state.divisionType}
                  medReconcileIndex={context.state.medReconcileIndex}
                  django={context.state.django}
                  // refill
                  DrugRefillSequence={context.state.DrugRefillSequence}
                  DrugOrderPendingList={context.state.DrugOrderPendingList}
                   // Allergy
                  AllergySequence={context.state.AllergySequence}
                  // StockManagement
                  lotNoExpList={context.state.lotNoExpList}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  openVideoCallModal={context.state.openVideoCallModal}
                  onOpenVideoCall={(url: string) => {
                    context.controller.setProp("openVideoCallModal", true);
                    context.controller.setProp("closeOverriding", false);
                    context.controller.setProp("videoCallRoom", url);
                    context.controller.setProp(
                      "selectedEncounter",
                      context.state.selectedEncounter
                    );
                    context.controller.setProp(
                      "currChatChannelId",
                      context.state.chatDetail?.chat_channel
                    );
                  }}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "OR Request",
              name: "OR Request",
              hide: !isOrOrder,
              render: () => (
                <CardStaffORRequest
                  //common
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  loadingStatus={context.state.loadingStatus}
                  userTokenize={context.state.userTokenize}
                  divisionId={context.controller.data.division}
                  preOrderList={context.state.preOrderList}
                  masterOptions={context.state.masterOptions}
                  masterData={context.controller.data.masterData}
                  // for case OR drug queue
                  drugOrderTabMode={context.state.drugOrderTabMode}
                  divisionType={context.state.divisionType}
                  // Run sequence
                  ORRequestSequence={context.state.ORRequestSequence}
                  OperatingDateTimeSequence={
                    context.state.OperatingDateTimeSequence
                  }
                  OrderTreatmentSequence={context.state.OrderTreatmentSequence}
                  OrderCentralLabSequence={
                    context.state.OrderCentralLabSequence
                  }
                  DrugSelectSequence={context.state.DrugSelectSequence}
                  // OR request
                  selectedOrOrder={context.state.selectedOrOrder}
                  proxyController={context.proxyController}
                  // Operating datetime
                  selectedOperatingDetail={
                    context.state.selectedOperatingDetail
                  }
                  selectedDoctor={context.state.selectedDoctor}
                  selectedAnesthesiologist={
                    context.state.selectedAnesthesiologist
                  }
                  selectedOperatingRoom={context.state.selectedOperatingRoom}
                  operatingBlock={context.state.operatingBlock}
                  selectOperatingDSBChange={
                    context.state.selectOperatingDSBChange
                  }
                  estimateTimeText={context.state.estimateTimeText}
                  operatingLocationRoom={context.state.operatingLocationRoom}
                  // Other order
                  treatmentOrderList={context.state.treatmentOrderList}
                  searchedItemList={context.state.searchedItemList}
                  procedureItems={context.state.procedureItems}
                  secondaryItems={context.state.secondaryItems}
                  treatmentTemplateList={context.state.treatmentTemplateList}
                  selectedAppointment={context.state.selectedAppointment}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedEmr={context.state.selectedEmr}
                  selectedProgressCycle={context.state.selectedProgressCycle}
                  doctorLabOrderList={context.state.doctorLabOrderList}
                  imagingList={context.state.imagingList}
                  orderImagingList={context.state.orderImagingList}
                  selectedOperatingOption={
                    context.state.selectedOperatingOption
                  }
                  isSelectTabCalendar={context.state.isSelectTabCalendar}
                  selectedDrugItemDetail={context.state.selectedDrugItemDetail}
                  selectedDrugItemDetailOptions={
                    context.state.selectedDrugItemDetailOptions
                  }
                  drugSearchText={context.state.drugSearchText}
                  drugSearchResult={context.state.drugSearchResult}
                  selectedDrug={context.state.selectedDrug}
                  drugOrderList={context.state.drugOrderList}
                  drugOrderListLoading={context.state.drugOrderListLoading}
                  drugOrder={context.state.drugOrder}
                  renderDrugOrderItems={renderDrugOrderItems}
                  drugDose={context.state.drugDose}
                  drugQty={context.state.drugQty}
                  drugDuration={context.state.drugDuration}
                  drugDescription={context.state.drugDescription}
                  drugPrn={context.state.drugPrn}
                  drugSearchLoading={context.state.drugSearchLoading}
                  modDoctorCertificate={context.state.modDoctorCertificate}
                  DrugOrderTemplateSequence={
                    context.state.DrugOrderTemplateSequence
                  }
                  modDrugLabInteraction={context.state.modDrugLabInteraction}
                  modDrugDisease={context.state.modDrugDisease}
                  modDrugInteraction={context.state.modDrugInteraction}
                  currentDoctor={context.state.currentDoctor}
                  modReMedication={context.state.modReMedication}
                  modConfirmDeleteDrug={context.state.modConfirmDeleteDrug}
                  drugOrderItemEstimate={context.state.drugOrderItemEstimate}
                  drugPermission={context.state.drugPermission}
                  modSolventSelectionConfig={
                    context.state.modSolventSelectionConfig
                  }
                  selectedVerbalDrugOrderWorking={
                    context.state.selectedVerbalDrugOrderWorking
                  }
                  // config={{
                  //   // disabledPostpone: true,
                  //   // disabledWaitingList: true,
                  //   isEditable:
                  //     (context.state.currentDoctor?.id || false) &&
                  //     context.state.selectedOrOrder?.status_name ===
                  //       "REQUESTED",
                  // }}
                  showOrderAppointment={true}
                  // Imaging
                  ImagingOrderSequence={context.state.ImagingOrderSequence}
                  modXrayDetail={context.state.modXrayDetail}
                  // ----- Dental Diagram
                  clinicalFindingId={context.state.clinicalFindingId}
                  clinicalFindingIndex={context.state.clinicalFindingIndex}
                  clinicalFindings={context.state.clinicalFindingList || []}
                  organ={context.controller.data.organ}
                  clinicaltags={context.controller.data.clinicaltags}
                  filterClinicalFindingIds={
                    context.state.filterClinicalFindingIds || []
                  }
                  // CardSurgeryTeam
                  django={context.state.django}
                  controller={context.controller}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Pre-Operation",
              name: "Pre-Operation",
              hide: !isScheduleConfirmed,
              render: () => (
                <CardPreOperation
                  // function
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // select OR
                  selectedOrOrder={context.state.selectedOrOrder}
                  // options
                  masterOptions={context.state.masterOptions}
                  masterData={context.controller.data.masterData}
                  // seq
                  runSequence={context.runSequence}
                  PreOperationSequence={context.state.PreOperationSequence}
                  HistoryCentralLabSequence={
                    context.state.HistoryCentralLabSequence
                  }
                  // or request
                  ORRequestSequence={context.state.ORRequestSequence}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  loadingStatus={context.state.loadingStatus}
                  proxyController={context.proxyController}
                  userTokenize={context.state.userTokenize}
                  divisionId={context.controller.data.division}
                  //assessment
                  DJANGO={context.state.django}
                  selectedEncounter={context.state.selectedEncounter}
                  AssessmentSequence={context.state.AssessmentSequence}
                  choiceTriage={context.state.ChoiceTriage}
                  patientInfo={context.state.selectedPatient}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Anesthetic document",
              name: "Anesthetic document",
              hide: !isOrOrder || isImagingOrder,  // !isAnesthesiaorder
              render: () => (
                <CardAnestheticDocument
                  // function
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  DJANGO={context.state.django}
                  // controller
                  cardANSBillController={context.cardANSBillController}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  billDrugOrder={context.state.billDrugOrder}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedEmr={context.state.selectedEmr}
                  selectedProgressCycle={context.state.selectedProgressCycle}
                  // select OR
                  selectedOrOrder={context.state.selectedOrOrder}
                  // options
                  masterOptions={context.state.masterOptions}
                  masterData={context.controller.data.masterData}
                  // seq
                  runSequence={context.runSequence}
                  PreAnestheticSequence={context.state.PreAnestheticSequence}
                  PACURecordSequence={context.state.PACURecordSequence}
                  PeriDataSheetSequence={context.state.PeriDataSheetSequence}
                  AnesComplicationSequence={
                    context.state.AnesComplicationSequence
                  }
                  AnesRecordSequence={context.state.AnesRecordSequence}
                  AnesBillSequence={context.state.AnesBillSequence}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  HistoryCentralLabSequence={context.state.HistoryCentralLabSequence}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Sugical Safety Checklist",
              name: "Sugical Safety Checklist",
              hide: !isScheduleConfirmed,
              render: () => (
                <CardSurgicalSafetyChecklist
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // seq
                  runSequence={context.runSequence}
                  PerioperativeNursingSequence={
                    context.state.PerioperativeNursingSequence
                  }
                  // CommonInterface
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  selectedOrOrder={context.state.selectedOrOrder}
                  errorMessage={context.state.errorMessage}
                  // data
                  masterData={context.controller.data.masterData}
                  DJANGO={context.state.django}
                  // options
                  masterOptions={context.state.masterOptions}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Perioperative Nursing Record",
              name: "Perioperative Nursing Record",
              hide: !isScheduleConfirmed,
              render: () => (
                <CardPerioperativeNursingRecord
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  selectedOrOrder={context.state.selectedOrOrder}
                  masterOptions={context.state.masterOptions}
                  masterData={context.controller.data.masterData}
                  runSequence={context.runSequence}
                  PerioperativeNursingSequence={
                    context.state.PerioperativeNursingSequence
                  }
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  DJANGO={context.state.django}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Counts & Packing",
              name: "Counts & Packing",
              hide: !isScheduleConfirmed,
              render: () => (
                <CardCountsAndPacking
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  searchedItemList={context.state.searchedItemList}
                  ORCountsSequence={context.state.ORCountsSequence}
                  successMessage={context.state.successMessage}
                  errorMessage={context.state.errorMessage}
                  selectedOrOrder={context.state.selectedOrOrder}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  masterOptions={context.state.masterOptions}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  searchOrItems={context.state.searchOrItems}
                  DJANGO={context.state.django}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Post Operation",
              name: "Post Operation",
              hide: !isScheduleConfirmed,
              render: () => (
                <CardPostOperation
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  selectedOrOrder={context.state.selectedOrOrder}
                  ORPostOperationSequence={
                    context.state.ORPostOperationSequence
                  }
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  subICDController={context.subICD10DetailController}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  DJANGO={context.state.django}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Zone Log",
              name: "Zone Log",
              hide: !isScheduleConfirmed,
              render: () => (
                <CardZoneLog
                  // seq
                  runSequence={context.runSequence}
                  ZoneLogSequence={context.state.ZoneLogSequence}
                  // data
                  encounter={context.state.selectedOrOrder?.encounter}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Patient History Viewer",
              name: "Patient History Viewer",
              hide: !isOrClinicWardDivision,
              render: () => (
                <CardPHVList
                  apiToken={context.controller.apiToken}
                  division={context.controller.data.division}
                  // function
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // controller
                  proxyController={context.proxyController}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // options
                  masterOptions={context.state.masterOptions}
                  masterData={context.controller.data.masterData}
                  ChoiceTriage={context.state.ChoiceTriage}
                  // seq
                  runSequence={context.runSequence}
                  AssessmentSequence={context.state.AssessmentSequence}
                  HistoryCentralLabSequence={
                    context.state.HistoryCentralLabSequence
                  }
                  ORPostOperationSequence={
                    context.state.ORPostOperationSequence
                  }
                  ORHistorySequence={context.state.ORHistorySequence}
                  ORRequestSequence={context.state.ORRequestSequence}
                  PerioperativeNursingSequence={
                    context.state.PerioperativeNursingSequence
                  }
                  PreOperationSequence={context.state.PreOperationSequence}
                  // SearchBox
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  // data
                  selectedPatient={context.state.selectedPatient}
                  selectedEncounter={context.state.selectedEncounter}
                  loadingStatus={context.state.loadingStatus}
                  userTokenize={context.state.userTokenize}
                  selectedMainOrOrder={context.state.selectedMainOrOrder}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Order Summary",
              name: "Order Summary",
              hide: !((isEncounterTypeIPD && isDrugClinicWardDivision) || isScheduleConfirmed),
              render: () => (
                <CardOrderSummary
                  // Medreconcile Check
                  divisionType={context.state.divisionType}
                  medReconcileCheck={context.state.medReconcileCheck}
                  medReconcileIndex={context.state.medReconcileIndex}
                  django={context.state.django}
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  selectedEncounter={context.state.selectedEncounter}
                  orderSummary={context.state.orderSummary}
                  orderSummaryOneDay={context.state.orderSummaryOneDay}
                  filterOrderSummary={context.state.filterOrderSummary}
                  nurseOrderPerformed={context.state.nurseOrderPerformed}
                  continueHistoryList={context.state.continueHistoryList}
                  orderSummaryPermission={context.state.orderSummaryPermission}
                  errorMessage={context.state.errorMessage}
                  openModSuccessOrderSummary={
                    context.state.openModSuccessOrderSummary
                  }
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Drug Order History",
              name: "Drug Order History",
              hide: !isDrugDivision || isORSchedule,
              render: () => (
                <div style={{ padding: "1rem 10px 1.5rem" }}>
                  <CardDrugOrderHistory
                    isDrugOrderHistory={true}
                    isPharmacist={isPharmacist}
                    frezzeRecordViewIndex={true}
                    DrugOrderHistorySequence={
                      context.state.DrugOrderHistorySequence
                    }
                    drugHistoryList={context.state.drugHistoryList}
                    drugHistoryOrderList={context.state.drugHistoryOrderList}
                    controller={context.drugOrderHistoryController}
                    runSequence={context.runSequence}
                    onEvent={context.controller.handleEvent}
                    setProp={context.controller.setProp}
                    selectedEncounter={context.state.selectedEncounter}
                    selectedEmr={context.state.selectedEmr}
                    selectedDivision={context.state.selectedDivision}
                    drugOrderQueue={context.state.drugOrderQueue}
                    drugOrder={context.state.selectedDrugOrderWorking}
                    drugOrderLog={context.state.drugOrderLog}
                    modNoteReject={context.state.modNoteReject}
                    layout={layoutProps.layout}
                    forward={layoutProps.forward}
                    DrugSelectSequence={context.state.DrugSelectSequence}
                    selectedPatient={context.state.selectedPatient}
                    selectedDrug={context.state.selectedDrug}
                    drugDose={context.state.drugDose}
                    drugDelivery={context.state.drugDelivery}
                    modConfirmAction={context.state.modConfirmAction}
                    modDrugRecommendation={context.state.modDrugRecommendation}
                    masterOptions={context.state.masterOptions}
                    masterData={context.controller.data.masterData}
                    DrugOrderActionSequence={
                      context.state.DrugOrderActionSequence
                    }
                    medErrorWorking={
                      context.state.MedErrorListSequence?.medErrorWorking
                    }
                    errorMessage={context.state.errorMessage}
                    loadingStatus={context.state.loadingStatus}
                    successMessage={context.state.successMessage}
                    // DrugTransferRequest
                    drugTransferRequestSequence={
                      context.state.DrugTransferRequestSequence
                    }
                    drugOrderHistoryController={
                      context.drugOrderHistoryController
                    }
                    drugRequestList={context.state.drugRequestList}
                    selectedDevice={context.state.selectedDevice}
                    drugTransferRequestlist={
                      context.state.drugTransferRequestlist
                    }
                    drugTransferLog={context.state.drugTransferLog}
                    drugTransferRequestDetail={
                      context.state.drugTransferRequestDetail
                    }
                    //ReturnOrder
                    ReturnOrderSequence={context.state.ReturnOrderSequence}
                    OrderReturnList={context.state.OrderReturnList}
                    DrugReturnList={context.state.DrugReturnList}
                    OrderHistory={context.state.OrderHistory}
                    OrderReturnLog={context.state.OrderReturnLog}
                     // Allergy
                    AllergySequence={context.state.AllergySequence}
                    // StockManagement
                    lotNoExpList={context.state.lotNoExpList}
                    searchedItemListWithKey={
                      context.state.searchedItemListWithKey
                    }
                    type={"PATIENT_HISTORY"} // for flag selectEncounter in function SelectDrugOrderWorking
                    // MedReconcile
                    divisionType={context.state.divisionType}
                    medReconcileIndex={context.state.medReconcileIndex}
                    django={context.state.django}
                    languageUX={context.props.userSelectedLanguage}
                  />
                </div>
              ),
            },
            {
              key: "Drug Selling",
              name: "Drug Selling",
              hide: !isDrugDivision || isORSchedule,
              render: () => (
                <CardDrugOrder
                  hideCardDrugVerbalOrder={true}
                  orderTypes={
                    context.state.selectedEncounter?.type === "IPD"
                      ? ["HOME_IPD"]
                      : ["HOME_OPD"]
                  }
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedEmr={context.state.selectedEmr}
                  // selectedDrugItem to edit
                  selectedDrugItemDetail={context.state.selectedDrugItemDetail}
                  selectedDrugItemDetailOptions={
                    context.state.selectedDrugItemDetailOptions
                  }
                  drugSearchText={context.state.drugSearchText}
                  drugSearchResult={context.state.drugSearchResult}
                  DrugSelectSequence={context.state.DrugSelectSequence}
                  selectedDrug={context.state.selectedDrug}
                  drugOrderList={context.state.drugOrderList}
                  drugOrderListLoading={context.state.drugOrderListLoading}
                  drugOrder={context.state.drugOrder}
                  renderDrugOrderItems={renderDrugOrderItems}
                  drugDose={context.state.drugDose}
                  drugQty={context.state.drugQty}
                  drugDuration={context.state.drugDuration}
                  drugDescription={context.state.drugDescription}
                  drugPrn={context.state.drugPrn}
                  // modal doctor certificate check
                  modDoctorCertificate={context.state.modDoctorCertificate}
                  // modal drug order template
                  DrugOrderTemplateSequence={
                    context.state.DrugOrderTemplateSequence
                  }
                  modReMedication={context.state.modReMedication}
                  modConfirmDeleteDrug={context.state.modConfirmDeleteDrug}
                  modDrugLabInteraction={context.state.modDrugLabInteraction}
                  modDrugDisease={context.state.modDrugDisease}
                  modDrugInteraction={context.state.modDrugInteraction}
                  modRecontinueMed={context.state.modRecontinueMed}
                  modAllVerifyDrug={context.state.modAllVerifyDrug}
                  modDuplicateDrug={context.state.modDuplicateDrug}
                  pediatricData={context.state.pediatricData}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  currentDoctor={context.state.currentDoctor}
                  searchedItemList={context.state.searchedItemList}
                  drugOrderItemMasterData={{
                    masterUnits: context.state.masterOptions.unit,
                    masterRoutes: context.state.masterOptions.route,
                    masterSites: context.state.masterOptions.site,
                    masterFrequencies: context.state.masterOptions.frequency,
                    masterMethods: context.state.masterOptions.method,
                  }}
                  duplicateReasonsOptions={
                    context.state.masterOptions.duplicateReasons
                  }
                  divisionForOrderDivOptions={
                    context.state.masterOptions.divisionForOrderDiv
                  }
                  masterData={context.controller.data.masterData}
                  languageUX={context.state.userSelectedLanguage}
                  drugVerbalFreeTxt={context.state.drugVerbalFreeTxt}
                  openModPharmacistNote={context.state.openModPharmacistNote}
                />
              ),
            },
            {
              key: "Triage",
              name: "Triage",
              hide: !isClinicWardDivision,
              render: () => (
                <CardTriage
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  selectedEncounter={context.state.selectedEncounter}
                  TriageSequence={context.state.TriageSequence}
                  masterOptions={context.state.masterOptions}
                  choiceTriage={context.state.ChoiceTriage}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Vital Sign",
              name: "Vital Sign",
              hide: !isOrClinicWardDivision,
              render: () => (
                <CardVitalSign
                  useUTCTime={true}
                  key="vitalSign"
                  closeable={false}
                  toggleable={false}
                  callOnDidMount={false}
                  useCheckboxDateManual={true}
                  vitalSignTypeByAge={context.state.selectedPatient?.age}
                  vitalSignTypeByEncounter={context.state.selectedEncounter?.id}
                  defaultDateFilter={
                    context.state.selectedEncounter?.type === "IPD"
                  }
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  setProp={context.controller.setProp}
                  onGetLastHeight={() =>
                    context.controller.handleEvent({
                      message: "GetLastHeight",
                      params: {},
                    })
                  }
                  onGetVitalSignType={() =>
                    context.controller.handleEvent({
                      message: "GetVitalSignType",
                      params: {},
                    })
                  }
                  onGetVitalSign={(params: any) =>
                    context.controller.handleEvent({
                      message: "GetVitalSign",
                      params: params,
                    })
                  }
                  onNewMeasurement={(params: any) =>
                    context.controller.handleEvent({
                      message: "SaveNewMeasurement",
                      params: params,
                    })
                  }
                  onUpdateVitalsignValue={(params: any) =>
                    context.controller.handleEvent({
                      message: "AddVitalSignValue",
                      params: params,
                    })
                  }
                  onDeleteMeasurement={(delMeasureId: number, params: any) =>
                    context.controller.handleEvent({
                      message: "CancelMeasurement",
                      params: { measureId: delMeasureId, data: params },
                    })
                  }
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Assessment",
              name: "Assessment",
              hide: !isClinicWardDivision,
              render: () => (
                <CardAssessmentBundle
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  DJANGO={context.state.django}
                  selectedEncounter={context.state.selectedEncounter}
                  masterOptions={context.state.masterOptions}
                  masterData={context.controller.data.masterData}
                  AssessmentSequence={context.state.AssessmentSequence}
                  choiceTriage={context.state.ChoiceTriage}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedPatient={context.state.selectedPatient}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Chart Summary",
              name: "Chart Summary",
              hide: !isClinicWardDivision,
              render: () => (
                <CardChartSummaryContainer
                  onEvent={context.controller.handleEvent}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              // Todo: get channel id for given division, hn with PRX/resolve-chat-channel/
              key: "Telehealth_Encounter",
              name: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label>Telehealth</label>
                  {!!context.state.chatDetail?.unread_message_count && (
                    <Label
                      data-testid="msg-noti"
                      circular
                      color={"red"}
                      size="mini"
                      languageUX={context.props.userSelectedLanguage}
                    >
                      {context.state.chatDetail.unread_message_count}
                    </Label>
                  )}
                </div>
              ),
              hide:
                // !context.state.selectedEncounter ||
                !(isClinicWardDivision || isGeneralDivision) ||
                context.state.chatDetail === null ||
                isImagingOrder,
              render: () => (
                <div>
                  <ChatBox
                    ref={(ref: any) => (context.chatBoxRef = ref)}
                    // controller
                    controller={context.chatController}
                    classifyController={context.classifyController}
                    diagFormController={context.diagFormController}
                    // data
                    messageViewStyle={{ height: "81vh" }}
                    userId={context.controller.data.user?.toString() || ""}
                    apiToken={context.controller.apiToken}
                    division_id={context.state.selectedDivision?.id}
                    isPatient={false}
                    nullId={false}
                    readOnly={false}
                    fullname={"fullname"}
                    username={"username"}
                    encounterId={context.state.selectedEncounter?.id}
                    match={{
                      params: {
                        chatChannelId: context.state.chatDetail?.chat_channel,
                      },
                    }}
                    patientId={context.state.selectedPatient?.id}
                    patientData={{}}
                    // config
                    hideButtonMenu={true}
                    // callback
                    onDidMount={() =>
                      context.controller.setProp(
                        "chatDetail.unread_message_count",
                        0
                      )
                    }
                    enHasNoDiv={() => {}}
                    enHasDiv={() => {}}
                    onNavigationMessage={({ content }: any) => content}
                    onCallGetLastMessage={() => {}}
                    onOpenVideoCall={(url: string) => {
                      context.controller.setProp("openVideoCallModal", true);
                      context.controller.setProp("videoCallRoom", url);
                      context.controller.setProp(
                        "currChatChannelId",
                        context.state.chatDetail?.chat_channel
                      );
                    }}
                    onCloseVideoCall={() => {}}
                    onSetChannelName={() => {}}
                    languageUX={context.props.userSelectedLanguage}
                  />
                </div>
              ),
            },
            {
              key: "Patient Info",
              // name: "Patient Info",
              name: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Patient Info</div>

                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "1em",
                      height: "1em",
                      margin: "0px 5px",
                      ...(!patientInfoApproval && { display: "none" }),
                    }}
                  />
                </div>
              ),
              hide: !(context.state.selectedEmr && isDrugClinicWardDivision ) || isEncounterTypeIPD,
              render: () => (
                <CardPatientInfo
                  controller={context.proxyController}
                  selectedEncounter={context.state.selectedEncounter}
                  patientData={context.state.patientData}
                  emrId={context.state.selectedEmr.id}
                  divisionId={context.controller.data.division}
                  django={context.state.django}
                  SetPatientSequence={context.state.SetPatientSequence}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // TODO
                  patientSymptomInfo={null}
                  onUploadImageClicked={() => {}}
                  ref={null}
                  onCreated={() => {
                    context.controller.handleEvent({
                      message: "HandleRefreshEncounter",
                      params: {},
                    });
                  }}
                  onFailed={() => {}}
                  // onGetEmrSuccess={() =>
                  //   context.controller.handleEvent({
                  //     message: "HandleRefreshEncounter",
                  //     params: {},
                  //   })
                  // }
                  isStudentUser={context.state.isStudentUser}
                  // CardPhysicalExam
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  runSequence={context.runSequence}
                  PhysicalExamSequence={context.state.PhysicalExamSequence}
                  isCheckout={isCheckout}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Drug Resuscitation",
              name: "Medication Box",
              hide: !(isDrugClinicWardDivision || isOrClinicWardDivision),
              render: () => (
                <CardDrugResuscitationOPD
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  masterOptions={context.state.masterOptions}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedDivision={context.state.selectedDivision}
                  setProp={context.controller.setProp}
                  controller={context.state.drugOrderHistoryController}
                  DrugResuscitationSequenceOPD={
                    context.state.DrugResuscitationSequenceOPD
                  }
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  providerEmployeeInfo={context.state.providerEmployeeInfo}
                  DJANGO={context.state.django}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Allergy",
              name: "Allergy",
              hide: !(isDrugClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <CardAllergy
                  masterOptions={context.state.masterOptions}
                  AllergySequence={context.state.AllergySequence}
                  runSequence={context.runSequence}
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  searchedItemList={context.state.searchedItemList}
                  selectedDivision={context.state.selectedDivision}
                  DJANGO={context.state.django}
                  restart={true}
                  selectedEmr={context.state.selectedEmr}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Diagnosis",
              // name: "Diagnosis",
              name: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Diagnosis</div>

                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "1em",
                      height: "1em",
                      margin: "0px 5px",
                      ...(!diagnosisInfo && { display: "none" }),
                    }}
                  />
                </div>
              ),
              hide: !(context.state.selectedEmr && isDrugClinicWardDivision),
              render: () => (
                <CardICD10React
                  emrId={context.state.selectedEmr.id}
                  patientId={context.state.selectedEncounter.patient_id}
                  subICDController={context.subICD10DetailController}
                  manageTemplateUrl={
                    window.location.origin.replace("3000", "8000") +
                    "/manage/core/diagnosistemplate/"
                  }
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  setProp={context.controller.setProp}
                  isStudentUser={context.state.isStudentUser}
                  noneID={true}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Nursing diagnosis",
              name: "Nursing diagnosis",
              hide: !(isDrugClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <CardNursingDiagnosis
                  // Medreconcile Check
                  divisionType={context.state.divisionType}
                  django={context.state.django}
                  medReconcileCheck={context.state.medReconcileCheck}
                  medReconcileIndex={context.state.medReconcileIndex}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  onEvent={context.controller.handleEvent}
                  selectedEncounter={context.state.selectedEncounter}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  setProp={context.controller.setProp}
                  NursingDiagnosisSequence={
                    context.state.NursingDiagnosisSequence
                  }
                  masterOptions={context.state.masterOptions}
                  runSequence={context.runSequence}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Nurse Form",
              name: "Nurse Form",
              hide: !(isClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <CardFormViewer
                  controller={context.crdFormViewerController}
                  patientAssessmentController={context.proxyController}
                  fallRiskAssessmentController={context.proxyController}
                  painAssessmentController={context.proxyController}
                  nutritionAssessmentController={context.proxyController}
                  reassessmentController={context.proxyController}
                  nurseNoteController={context.nurseNoteController}
                  patientData={{
                    patient_id: context.state.selectedEncounter?.patient_id,
                    ENCOUNTER: {
                      encounter_id: context.state.selectedEncounter?.id,
                    },
                  }}
                  // ----- CardReAssessmentHistory
                  // seq
                  runSequence={context.runSequence}
                  // function
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  // data
                  masterOptions={context.state.masterOptions}
                  ReAssessmentSequence={context.state.ReAssessmentSequence}
                  // ----- end CardReAssessmentHistory
                  selectedEncounter={context.state.selectedEncounter}
                  django={context.state.django}
                  selectNoteOnClick={true}
                  // callback
                  onClose={() => {}}
                  onOpenCard={(card: string | null, encounter: number | null) =>
                    context.setState({ openCardNurseForm: { card, encounter } })
                  }
                  // Element
                  cardPreAssessment={
                    context.state.openCardNurseForm?.card ===
                      "CardPreAssessmentIPD" && (
                      <CardPreAssessment
                        // function
                        onEvent={context.controller.handleEvent}
                        setProp={context.controller.setProp}
                        // CommonInterface
                        errorMessage={context.state.errorMessage}
                        successMessage={context.state.successMessage}
                        buttonLoadCheck={context.state.buttonLoadCheck}
                        selectedPatient={context.state.selectedPatient}
                        // seq
                        runSequence={context.runSequence}
                        PreAssessmentSequence={
                          context.state.PreAssessmentSequence
                        }
                        // options
                        masterOptions={context.state.masterOptions}
                        // data
                        preAssessmentOptions={
                          context.state.preAssessmentOptions
                        }
                        preAssessmentActionLog={
                          context.state.preAssessmentActionLog
                        }
                        encounterId={context.state.openCardNurseForm.encounter}
                        // config
                        readOnly={true}
                        headerColor="#767676"
                        closeIcon={true}
                        // callback
                        onClose={() =>
                          context.setState({ openCardNurseForm: null })
                        }
                      />
                    )
                  }
                  // nurse note
                  divisionType={context.state.divisionType}
                  medReconcileCheck={context.state.medReconcileCheck}
                  medReconcileIndex={context.state.medReconcileIndex}
                  selectedNurseNote={context.state.selectedNurseNote}
                  nurseNoteFocusOptions={context.state.nurseNoteFocusOptions}
                  nurseNoteFilter={context.state.nurseNoteFilter}
                  openModNurseNoteForm={context.state.openModNurseNoteForm}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  nurseNoteLoading={context.state.nurseNoteLoading}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Nurse Note",
              name: "Nurse Note",
              hide: !(isClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <CardNurseNote
                  // Medreconcile Check
                  divisionType={context.state.divisionType}
                  medReconcileCheck={context.state.medReconcileCheck}
                  medReconcileIndex={context.state.medReconcileIndex}
                  onEvent={context.controller.handleEvent}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  setProp={context.controller.setProp}
                  selectedNurseNote={context.state.selectedNurseNote}
                  nurseNoteFocusOptions={context.state.nurseNoteFocusOptions}
                  nurseNoteFilter={context.state.nurseNoteFilter}
                  django={context.state.django}
                  selectedEncounter={context.state.selectedEncounter}
                  openModNurseNoteForm={context.state.openModNurseNoteForm}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  nurseNoteLoading={context.state.nurseNoteLoading}
                  nurseNoteController={context.nurseNoteController}
                  patientData={{
                    patient_id: context.state.selectedEncounter?.patient_id,
                    ENCOUNTER: {
                      encounter_id: context.state.selectedEncounter?.id,
                    },
                  }}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Image Gallery",
              name: "Image Gallery",
              hide: !(isDrugClinicWardDivision || isOrDivision),
              render: () => (
                <CardImageGallery
                  controller={context.cardImageGalleryController}
                  patientData={context.state.patientData}
                  // finishLoading={() => {
                  //   context.setState({
                  //     openCardImageGallery: CONSTANT.LOADING_ANIMATION.OPENED,
                  //   });
                  // }}
                  // onClose={() => {
                  //   context.setState({
                  //     openCardImageGallery: CONSTANT.LOADING_ANIMATION.CLOSED,
                  //   });
                  // }}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Multidisciplinary",
              name: "Multidisciplinary",
              hide: !(isDrugClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <CardMultiDisciplinaryView
                  controller={context.crdMultiDisciplinaryController}
                  onGetMultidisciplinary={async ({
                    nurseRecordStartDate,
                    nurseRecordEndDate,
                    encounterId,
                    currentPage,
                    practitionerRole,
                  }) => {
                    let params: any = {};
                    if (context.state.selectedEncounter.id)
                      params.encounter = context.state.selectedEncounter.id;
                    if (nurseRecordStartDate)
                      params.start_date = nurseRecordStartDate;
                    if (nurseRecordEndDate)
                      params.end_date = nurseRecordEndDate;
                    if (practitionerRole)
                      params.practitioner_role = practitionerRole;
                    context.setState({ multidisciplinaryLoading: true });
                    const [
                      multidisciplinaryData,
                      multidisciplinaryError,
                      numberOfPage,
                    ] = await context.proxyController.getMultidisciplinary(
                      params
                    );
                    if (multidisciplinaryError)
                      toast.error(multidisciplinaryError);
                    if (numberOfPage)
                      context.setState({
                        multidisciplinaryNumberOfPage: numberOfPage,
                      });
                    context.setState({
                      multidisciplinaryLoading: false,
                      multidisciplinaryData: multidisciplinaryData,
                    });
                  }}
                  onSaveMultidisciplinary={async (data: any) => {
                    if (data.id === undefined || data.id === null) {
                      const [response, error] =
                        await context.proxyController.saveMultidisciplinary(
                          data
                        );
                      return error;
                    } else {
                      const [response, error] =
                        await context.proxyController.updateMultidisciplinary(
                          data
                        );
                      return error;
                    }
                  }}
                  onDeleteMultidisciplinary={async (
                    item_id: number,
                    params: any
                  ) => {
                    const [response, error] =
                      await context.proxyController.deleteMultidisciplinary({
                        item_id: item_id,
                        params: params,
                      });
                    return error;
                  }}
                  onPrintMultidisciplinary={async (data: any) => {
                    const [response, error] =
                      await context.proxyController.printMultidisciplinary(
                        data
                      );
                    return error;
                  }}
                  // multidisciplinaryLoading={props.multidisciplinaryLoading}
                  multidisciplinaryData={context.state.multidisciplinaryData}
                  closeable={false}
                  toggleable={true}
                  hideHeaderIcon={false}
                  defaultBackground={false}
                  enableMargin={false}
                  readOnly={false}
                  encounterId={context.state.selectedEncounter?.id} // For save
                  userId={context.controller.data.user}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Intra Transfer Form",
              name: "Intra Transfer Form",
              hide:
                !(
                  (["IPD", "OPD"].includes(
                    context.state.selectedEncounter?.type || ""
                  ) &&
                    isClinicWardDivision) ||
                  isScheduleConfirmed
                ),
              render: () => (
                <CardIntraHospitalTransfer
                  // callback
                  runSequence={context.runSequence}
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  // data
                  intraTransferForm={context.state.intraTransferForm}
                  intraHospitalTransferOptions={
                    context.state.intraHospitalTransferOptions
                  }
                  searchedItemList={context.state.searchedItemList}
                  errorMessage={context.state.errorMessage}
                  loadingStatus={context.state.loadingStatus}
                  masterOptions={context.state.masterOptions}
                  selectVitalSignLoading={context.state.selectVitalSignLoading}
                  getEncounterVitalSignListErr={
                    context.state.getEncounterVitalSignListErr
                  }
                  encounterVitalSignList={context.state.encounterVitalSignList}
                  hideAdmit={true}
                  isRestartClear
                  selectedEncounter={selectedEncounter}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Specimen Collection",
              name: "Specimen Collection",
              // hide: context.state.divisionType !== "แผนกเก็บ Specimen",
              hide: !(["แผนกเก็บ Specimen", "ห้องผ่าตัด"].includes(divisionType) || isOrClinicWardDivision),
              render: () => (
                <CardLabPanel
                  // function
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  // common data
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  django={context.state.django}
                  // data
                  selectedEncounter={context.state.selectedEncounter}
                  selectedLabOrderWorking={
                    context.state.selectedLabOrderWorking
                  }
                  selectedContainer={context.state.selectedContainer}
                  selectedLabOrderItem={context.state.selectedLabOrderItem}
                  selectedLabOrderItemChild={
                    context.state.selectedLabOrderItemChild
                  }
                  labOrderItems={context.state.labOrderItems}
                  specimenLogs={context.state.specimenLogs}
                  specimenCollectorMasterData={
                    context.state.specimenCollectorMasterData
                  }
                  labOrderQueue={context.state.labOrderQueue}
                  selectedDivision={context.state.selectedDivision}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Lab Report",
              name: "Lab Report",
              hide: !isSpecimenDivision,
              render: () => (
                <CardLabReport
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  // seq
                  runSequence={context.runSequence}
                  LabReportSequence={context.state.LabReportSequence}
                  // data
                  patientInfo={context.state.selectedPatient}
                  selectedLabOrderWorking={
                    context.state.selectedLabOrderWorking
                  }
                  permissionLabResultConfidential={
                    context.state.permissionLabResultConfidential
                  }
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  // options
                  masterOptions={context.state.masterOptions}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Lab Order",
              // name: "Lab Order",\
              name: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Lab Order</div>

                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "1em",
                      height: "1em",
                      margin: "0px 5px",
                      ...(!labOrderInfo && { display: "none" }),
                    }}
                  />
                </div>
              ),
              hide:
                !selectedEncounter ||
                ![
                  "หน่วยตรวจ",
                  "หอผู้ป่วย",
                  "แผนกเก็บ Specimen",
                  "ห้องผ่าตัด",
                  "พื้นที่ดูแล",
                  "คลินิกพรีเมี่ยม",
                  "หอผู้ป่วยพรีเมี่ยม",
                ].includes(divisionType),
              render: () => (
                <CardLabRequest
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  //MedReconcile
                  medReconcileCheck={context.state.medReconcileCheck}
                  medReconcileIndex={context.state.medReconcileIndex}
                  divisionType={context.state.divisionType}
                  // seq
                  runSequence={context.runSequence}
                  OrderCentralLabSequence={
                    context.state.OrderCentralLabSequence
                  }
                  // CommonInterface
                  django={context.state.django}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedEmr={context.state.selectedEmr}
                  selectedProgressCycle={context.state.selectedProgressCycle}
                  // data
                  doctorLabOrderList={context.state.doctorLabOrderList}
                  masterOptions={context.state.masterOptions}
                  // style
                  doctorLabOrderTableStyle={{ height: "300px" }}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // config
                  isRestart={true}
                  isStudentUser={context.state.isStudentUser}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Lab Result",
              name: "Lab Result",
              hide: !(isClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <CardLabResult
                  style={{ marginBottom: "10px" }}
                  key="lab"
                  closeable={false}
                  toggleable={false}
                  hideHeaderIcon={true}
                  labCode={context.state.labCode}
                  labDetail={context.state.labDetail}
                  reportDetail={context.state.reportDetail}
                  labError={context.state.labError}
                  getLabCode={(params: any) => {
                    context.controller.handleEvent({
                      message: "GetLabCode",
                      params: params,
                    });
                  }}
                  getLabDetail={(params: any) => {
                    context.controller.handleEvent({
                      message: "GetLabDetail",
                      params: params,
                    });
                  }}
                  getInterfaceSummaryReportDetail={(params: any) => {
                    context.controller.handleEvent({
                      message: "GetLabInterfaceSummary",
                      params: params,
                    });
                  }}
                  getLabResultList={(params: any) => {
                    context.controller.handleEvent({
                      message: "CentralLabResultList",
                      params: params,
                    });
                  }}
                  patientId={context.state.selectedEncounter?.patient_id}
                  patientInfo={context.state.selectedPatient}
                  centralLabResultList={context.state.centralLabResultList}
                  onClearLabResult={() =>
                    context.controller.setProp("centralLabResultList", null)
                  }
                  //MedReconcile
                  medReconcileCheck={context.state.medReconcileCheck}
                  divisionType={context.state.divisionType}
                  medReconcileIndex={context.state.medReconcileIndex}
                  django={context.state.django}
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "OPD Lab Summary",
              name: "OPD Lab Summary",
              hide:
                !selectedEncounter ||
                !(
                  [
                    "หน่วยตรวจ",
                    "หอผู้ป่วย",
                    "แผนกเก็บ Specimen",
                    "ห้องยา",
                    "พื้นที่ดูแล",
                    "คลินิกพรีเมี่ยม",
                    "หอผู้ป่วยพรีเมี่ยม",
                  ].includes(divisionType) || isScheduleConfirmed
                ),
              render: () => (
                <CardOPDLabSummary
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  // seq
                  runSequence={context.runSequence}
                  OPDLabSummarySequence={context.state.OPDLabSummarySequence}
                  LabReportSequence={context.state.LabReportSequence}
                  // data
                  django={context.state.django}
                  // CommonInterface
                  permissionLabResultConfidential={
                    context.state.permissionLabResultConfidential
                  }
                  selectedPatient={context.state.selectedPatient}
                  centralLabResultList={context.state.centralLabResultList}
                  errorMessage={context.state.errorMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  successMessage={context.state.successMessage}
                  patientInfo={context.state.selectedPatient}
                  selectedLabOrderWorking={
                    context.state.selectedLabOrderWorking
                  }
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  // options
                  masterOptions={context.state.masterOptions}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Cumulative Lab Result",
              name: "Cumulative Lab Result",
              hide:
                !selectedEncounter ||
                !(
                  [
                    "หน่วยตรวจ",
                    "หอผู้ป่วย",
                    "แผนกเก็บ Specimen",
                    "ห้องยา",
                    "พื้นที่ดูแล",
                    "คลินิกพรีเมี่ยม",
                    "หอผู้ป่วยพรีเมี่ยม",
                  ].includes(divisionType) || isScheduleConfirmed
                ),
              render: () => (
                <CardOPDLabSummary
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  // seq
                  runSequence={context.runSequence}
                  OPDLabSummarySequence={context.state.OPDLabSummarySequence}
                  LabReportSequence={context.state.LabReportSequence}
                  // data
                  django={context.state.django}
                  // CommonInterface
                  selectedPatient={context.state.selectedPatient}
                  centralLabResultList={context.state.centralLabResultList}
                  errorMessage={context.state.errorMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  successMessage={context.state.successMessage}
                  permissionLabResultConfidential={
                    context.state.permissionLabResultConfidential
                  }
                  patientInfo={context.state.selectedPatient}
                  selectedLabOrderWorking={
                    context.state.selectedLabOrderWorking
                  }
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  // options
                  masterOptions={context.state.masterOptions}
                  // config
                  type={"CUMULATIVE_LAB_RESULT"}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "รคส. แพทย์",
              name: intl.formatMessage({id: "รคส. แพทย์"}),
              hide: !(divisionType === "แผนกเก็บ Specimen" || isOrClinicWardDivision),
              render: () => (
                <CardVerbalOrder
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  loadingStatus={context.state.loadingStatus}
                  OrderCentralLabSequence={
                    context.state.OrderCentralLabSequence
                  }
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedEmr={context.state.selectedEmr}
                  selectedProgressCycle={context.state.selectedProgressCycle}
                  doctorLabOrderList={context.state.doctorLabOrderList}
                  searchedItemList={context.state.searchedItemList}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  masterOptions={context.state.masterOptions}
                  // history
                  verbalOrderHistoryFilter={
                    context.state.verbalOrderHistoryFilter
                  }
                  verbalOrderHistoryList={context.state.verbalOrderHistoryList}
                  selectedDivision={context.state.selectedDivision}
                  // lab verbal
                  promptMessage={context.state.promptMessage}
                  duplicateLabDetected={context.state.duplicateLabDetected}
                  // card admit order
                  django={context.state.django}
                  crdAdmitOrderController={context.crdAdmitOrderController}
                  PATIENT_DATA={context.state.patientData}
                  subICDController={context.subICD10DetailController}
                  // treatment order
                  OrderTreatmentSequence={context.state.OrderTreatmentSequence}
                  treatmentOrderList={context.state.treatmentOrderList}
                  procedureItems={context.state.procedureItems}
                  secondaryItems={context.state.secondaryItems}
                  treatmentTemplateList={context.state.treatmentTemplateList}
                  editId={context.state?.treatmentOrderList?.item?.id || null}
                  // Medreconcile Check
                  divisionType={context.state.divisionType}
                  medReconcileCheck={context.state.medReconcileCheck}
                  medReconcileIndex={context.state.medReconcileIndex}
                  // Verbal Order
                  // PasswordRecheck={context.state.PasswordRecheck}
                  // CardDoctorNoteOrder
                  drNoteOrderPermission={context.state.drNoteOrderPermission}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  DoctorNoteOrderSequence={context.state.DoctorNoteOrderSequence}
                  drNoteDivisionOptions={context.state.drNoteDivisionOptions}
                  DJANGO={context.state.django}
                  hideButtonSave={true}
                  modPlanningConfig={context.state.modPlanningConfig}
                  planningData={context.state.planningData}
                  // CardImagingOrder
                  ImagingOrderSequence={context.state.ImagingOrderSequence}
                  userTokenize={context.state.userTokenize}
                  modXrayDetail={context.state.modXrayDetail}
                  clinicalFindingId={context.state.clinicalFindingId}
                  clinicalFindingIndex={context.state.clinicalFindingIndex}
                  clinicalFindings={context.state.clinicalFindingList || []}
                  organ={context.state.organ}
                  clinicaltags={context.state.clinicaltags}
                  filterClinicalFindingIds={context.state.filterClinicalFindingIds || []}
                  languageUX={context.state.userSelectedLanguage}
                  // CardDrugOrder
                  onlyDrug={false}
                  selectedDrugItemDetail={context.state.selectedDrugItemDetail}
                  selectedDrugItemDetailOptions={
                    context.state.selectedDrugItemDetailOptions
                  }
                  drugSearchText={context.state.drugSearchText}
                  drugSearchResult={context.state.drugSearchResult}
                  drugSearchLoading={context.state.drugSearchLoading}
                  DrugSelectSequence={context.state.DrugSelectSequence}
                  verbalDrugOrderList={context.state.verbalDrugOrderList}
                  selectedDrug={context.state.selectedDrug}
                  drugOrderNotification={context.state.drugOrderNotification}
                  drugOrderList={context.state.drugOrderList}
                  drugOrderListLoading={context.state.drugOrderListLoading}
                  drugOrder={context.state.drugOrder}
                  orderSpecificType={context.state.orderSpecificType}
                  renderDrugOrderItems={renderDrugOrderItems}
                  drugDose={context.state.drugDose}
                  drugQty={context.state.drugQty}
                  drugDuration={context.state.drugDuration}
                  drugDescription={context.state.drugDescription}
                  drugPrn={context.state.drugPrn}
                  modDoctorCertificate={context.state.modDoctorCertificate}
                  DrugOrderTemplateSequence={
                    context.state.DrugOrderTemplateSequence
                  }
                  modConfirmDeleteDrug={context.state.modConfirmDeleteDrug}
                  modReMedication={context.state.modReMedication}
                  modDrugLabInteraction={context.state.modDrugLabInteraction}
                  modDrugDisease={context.state.modDrugDisease}
                  modDrugInteraction={context.state.modDrugInteraction}
                  modRecontinueMed={context.state.modRecontinueMed}
                  modAllVerifyDrug={context.state.modAllVerifyDrug}
                  modDuplicateDrug={context.state.modDuplicateDrug}
                  pediatricData={context.state.pediatricData}
                  currentDoctor={context.state.currentDoctor}
                  drugOrderItemEstimate={context.state.drugOrderItemEstimate}
                  drugPermission={context.state.drugPermission}
                  drugOrderItemMasterData={{
                    masterUnits: context.state.masterOptions.unit,
                    masterRoutes: context.state.masterOptions.route,
                    masterSites: context.state.masterOptions.site,
                    masterFrequencies: context.state.masterOptions.frequency,
                    masterMethods: context.state.masterOptions.method,
                  }}
                  duplicateReasonsOptions={
                    context.state.masterOptions.duplicateReasons
                  }
                  divisionForOrderDivOptions={
                    context.state.masterOptions.divisionForOrderDiv
                  }
                  masterData={context.controller.data.masterData}
                  modSolventSelectionConfig={
                    context.state.modSolventSelectionConfig
                  }
                  selectedVerbalDrugOrderWorking={
                    context.state.selectedVerbalDrugOrderWorking
                  }
                  DrugOrderHistorySequence={
                    context.state.DrugOrderHistorySequence
                  }
                  drugHistoryList={context.state.drugHistoryList}
                  drugHistoryOrderList={context.state.drugHistoryOrderList}
                  drugOrderHistoryController={
                    context.drugOrderHistoryController
                  }
                  drugPackageAlertList={context.state.drugPackageAlertList}
                  isStudentUser={context.state.isStudentUser}
                  drugOrderEdit={context.state.drugOrderEdit}
                  drugMedReconcileEditId={context.state.drugMedReconcileEditId}
                  openModPharmacistNote={context.state.openModPharmacistNote}
                  // CardDrugVerbalOrder
                  drugVerbalFreeTxt={context.state.drugVerbalFreeTxt}
                />
              ),
            },
            {
              key: "ADR",
              name: "ADR",
              hide: !isDrugDivision,
              render: () => <div>Adverse Drug Reaction</div>,
            },
            {
              key: "Plan Transfer",
              name: "Plan Transfer",
              hide: !(selectedEncounter && ["หอผู้ป่วย", "หอผู้ป่วยพรีเมี่ยม"].includes(divisionType)),
              render: () => (
                <CardPlanTransfer
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // seq
                  runSequence={context.runSequence}
                  PlanTransferSequence={context.state.PlanTransferSequence}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // options
                  masterOptions={context.state.masterOptions}
                  selectedEmr={context.state.selectedEmr}
                  // MedReconcile
                  medReconcileCheck={context.state.medReconcileCheck}
                  divisionType={context.state.divisionType}
                  medReconcileIndex={context.state.medReconcileIndex}
                  django={context.state.django}
                  selectedEncounter={context.state.selectedEncounter}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Med Reconcile",
              name: "Med Reconcile",
              hide: !(isDrugClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <CardMedReconcile
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  masterOptions={context.state.masterOptions}
                  // data
                  selectedEncounter={context.state.selectedEncounter}
                  selectedExternalDrug={context.state.selectedExternalDrug}
                  drugOrderHistoryItems={context.state.drugOrderHistoryItems}
                  medReconcile={context.state.medReconcile}
                  searchedItemList={context.state.searchedItemList}
                  medReconcileInternalFilter={
                    context.state.medReconcileInternalFilter
                  }
                  medReconcileInternalCheckedItem={
                    context.state.medReconcileInternalCheckedItem
                  }
                  medReconcileList={context.state.medReconcileList}
                  medReconcileOrder={context.state.medReconcileOrder}
                  openMedReconcileOrder={context.state.openMedReconcileOrder}
                  medReconcileLogs={context.state.medReconcileLogs}
                  openMedReconcileLogs={context.state.openMedReconcileLogs}
                  // CardMedReconcile
                  django={context.state.django}
                  userProfile={context?.controller?.data?.userProfile}
                  // medReconcileWardAt={context?.state?.medReconcileStartWard}
                  MedReconcileSequence={context?.state?.MedReconcileSequence}
                  // MR DISCHARGE
                  medReconcileIPDAdmitItem={
                    context?.state?.medReconcileIPDAdmitItem
                  }
                  medReconcileIPDAdmitSelectOrder={
                    context?.state.medReconcileIPDAdmitSelectOrder
                  }
                  medReconcileIPDAdmitSelectItem={
                    context?.state?.medReconcileIPDAdmitSelectItem
                  }
                  checkIPDHome={context.state.checkIPDHome}
                  checkMRAdmit={context.state.checkMRAdmit}
                  checkSuggestion={context.state.checkSuggestion}
                  renderDrugOrderItems={renderDrugOrderItems}
                  controller={context.controller}
                  medReconcileLoading={context.state.medReconcileLoading}
                  loadingStatus={context.state.loadingStatus}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Med Order",
              // name: "Med Order",
              name: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Med Order</div>

                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "1em",
                      height: "1em",
                      margin: "0px 5px",
                      ...(!drugOrderInfo && { display: "none" }),
                    }}
                  />
                </div>
              ),
              hide: !(isDrugClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <>{isPharmacist ?
                  <CardVerbalOrder
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  loadingStatus={context.state.loadingStatus}
                  OrderCentralLabSequence={
                    context.state.OrderCentralLabSequence
                  }
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedEmr={context.state.selectedEmr}
                  selectedProgressCycle={context.state.selectedProgressCycle}
                  doctorLabOrderList={context.state.doctorLabOrderList}
                  searchedItemList={context.state.searchedItemList}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  masterOptions={context.state.masterOptions}
                  // history
                  verbalOrderHistoryFilter={
                    context.state.verbalOrderHistoryFilter
                  }
                  verbalOrderHistoryList={context.state.verbalOrderHistoryList}
                  selectedDivision={context.state.selectedDivision}
                  // lab verbal
                  promptMessage={context.state.promptMessage}
                  duplicateLabDetected={context.state.duplicateLabDetected}
                  // card admit order
                  django={context.state.django}
                  crdAdmitOrderController={context.crdAdmitOrderController}
                  PATIENT_DATA={context.state.patientData}
                  subICDController={context.subICD10DetailController}
                  // treatment order
                  OrderTreatmentSequence={context.state.OrderTreatmentSequence}
                  treatmentOrderList={context.state.treatmentOrderList}
                  procedureItems={context.state.procedureItems}
                  secondaryItems={context.state.secondaryItems}
                  treatmentTemplateList={context.state.treatmentTemplateList}
                  editId={context.state?.treatmentOrderList?.item?.id || null}
                  // Medreconcile Check
                  divisionType={context.state.divisionType}
                  medReconcileCheck={context.state.medReconcileCheck}
                  medReconcileIndex={context.state.medReconcileIndex}
                  // Verbal Order
                  // PasswordRecheck={context.state.PasswordRecheck}
                  // CardDoctorNoteOrder
                  drNoteOrderPermission={context.state.drNoteOrderPermission}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  DoctorNoteOrderSequence={context.state.DoctorNoteOrderSequence}
                  drNoteDivisionOptions={context.state.drNoteDivisionOptions}
                  DJANGO={context.state.django}
                  hideButtonSave={true}
                  modPlanningConfig={context.state.modPlanningConfig}
                  planningData={context.state.planningData}
                  // CardImagingOrder
                  ImagingOrderSequence={context.state.ImagingOrderSequence}
                  userTokenize={context.state.userTokenize}
                  modXrayDetail={context.state.modXrayDetail}
                  clinicalFindingId={context.state.clinicalFindingId}
                  clinicalFindingIndex={context.state.clinicalFindingIndex}
                  clinicalFindings={context.state.clinicalFindingList || []}
                  organ={context.state.organ}
                  clinicaltags={context.state.clinicaltags}
                  filterClinicalFindingIds={context.state.filterClinicalFindingIds || []}
                  languageUX={context.state.userSelectedLanguage}
                  // CardDrugOrder
                  onlyDrug={true}
                  selectedDrugItemDetail={context.state.selectedDrugItemDetail}
                  selectedDrugItemDetailOptions={
                    context.state.selectedDrugItemDetailOptions
                  }
                  drugSearchText={context.state.drugSearchText}
                  drugSearchResult={context.state.drugSearchResult}
                  drugSearchLoading={context.state.drugSearchLoading}
                  DrugSelectSequence={context.state.DrugSelectSequence}
                  verbalDrugOrderList={context.state.verbalDrugOrderList}
                  selectedDrug={context.state.selectedDrug}
                  drugOrderNotification={context.state.drugOrderNotification}
                  drugOrderList={context.state.drugOrderList}
                  drugOrderListLoading={context.state.drugOrderListLoading}
                  drugOrder={context.state.drugOrder}
                  orderSpecificType={context.state.orderSpecificType}
                  renderDrugOrderItems={renderDrugOrderItems}
                  drugDose={context.state.drugDose}
                  drugQty={context.state.drugQty}
                  drugDuration={context.state.drugDuration}
                  drugDescription={context.state.drugDescription}
                  drugPrn={context.state.drugPrn}
                  modDoctorCertificate={context.state.modDoctorCertificate}
                  DrugOrderTemplateSequence={
                    context.state.DrugOrderTemplateSequence
                  }
                  modConfirmDeleteDrug={context.state.modConfirmDeleteDrug}
                  modReMedication={context.state.modReMedication}
                  modDrugLabInteraction={context.state.modDrugLabInteraction}
                  modDrugDisease={context.state.modDrugDisease}
                  modDrugInteraction={context.state.modDrugInteraction}
                  modRecontinueMed={context.state.modRecontinueMed}
                  modAllVerifyDrug={context.state.modAllVerifyDrug}
                  modDuplicateDrug={context.state.modDuplicateDrug}
                  pediatricData={context.state.pediatricData}
                  currentDoctor={context.state.currentDoctor}
                  drugOrderItemEstimate={context.state.drugOrderItemEstimate}
                  drugPermission={context.state.drugPermission}
                  drugOrderItemMasterData={{
                    masterUnits: context.state.masterOptions.unit,
                    masterRoutes: context.state.masterOptions.route,
                    masterSites: context.state.masterOptions.site,
                    masterFrequencies: context.state.masterOptions.frequency,
                    masterMethods: context.state.masterOptions.method,
                  }}
                  duplicateReasonsOptions={
                    context.state.masterOptions.duplicateReasons
                  }
                  divisionForOrderDivOptions={
                    context.state.masterOptions.divisionForOrderDiv
                  }
                  masterData={context.controller.data.masterData}
                  modSolventSelectionConfig={
                    context.state.modSolventSelectionConfig
                  }
                  selectedVerbalDrugOrderWorking={
                    context.state.selectedVerbalDrugOrderWorking
                  }
                  DrugOrderHistorySequence={
                    context.state.DrugOrderHistorySequence
                  }
                  drugHistoryList={context.state.drugHistoryList}
                  drugHistoryOrderList={context.state.drugHistoryOrderList}
                  drugOrderHistoryController={
                    context.drugOrderHistoryController
                  }
                  drugPackageAlertList={context.state.drugPackageAlertList}
                  isStudentUser={context.state.isStudentUser}
                  drugOrderEdit={context.state.drugOrderEdit}
                  drugMedReconcileEditId={context.state.drugMedReconcileEditId}
                  openModPharmacistNote={context.state.openModPharmacistNote}
                  // CardDrugVerbalOrder
                  drugVerbalFreeTxt={context.state.drugVerbalFreeTxt}
                /> :
                <CardDrugOrder
                  disabledVerbalMedOrder={ false }
                  hideCardDrugVerbalOrder={isDoctor}
                  orderTypes={getOrderTypes(
                    context.state.selectedEncounter?.type,
                    !!context.state.selectedOrOrder
                  )}
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  goToTab={context.state.goToTab}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedEmr={context.state.selectedEmr}
                  selectedProgressCycle={context.state.selectedProgressCycle}
                  // selectedDrugItem to edit
                  selectedDrugItemDetail={context.state.selectedDrugItemDetail}
                  selectedDrugItemDetailOptions={
                    context.state.selectedDrugItemDetailOptions
                  }
                  drugSearchText={context.state.drugSearchText}
                  drugSearchResult={context.state.drugSearchResult}
                  drugSearchLoading={context.state.drugSearchLoading}
                  DrugSelectSequence={context.state.DrugSelectSequence}
                  verbalDrugOrderList={context.state.verbalDrugOrderList}
                  selectedDrug={context.state.selectedDrug}
                  drugOrderNotification={context.state.drugOrderNotification}
                  drugOrderList={context.state.drugOrderList}
                  drugOrderListLoading={context.state.drugOrderListLoading}
                  drugOrder={context.state.drugOrder}
                  renderDrugOrderItems={renderDrugOrderItems}
                  drugDose={context.state.drugDose}
                  drugQty={context.state.drugQty}
                  drugDuration={context.state.drugDuration}
                  drugDescription={context.state.drugDescription}
                  drugPrn={context.state.drugPrn}
                  // modal doctor certificate check
                  modDoctorCertificate={context.state.modDoctorCertificate}
                  DrugOrderTemplateSequence={
                    context.state.DrugOrderTemplateSequence
                  }
                  modConfirmDeleteDrug={context.state.modConfirmDeleteDrug}
                  modReMedication={context.state.modReMedication}
                  modDrugLabInteraction={context.state.modDrugLabInteraction}
                  modDrugDisease={context.state.modDrugDisease}
                  modDrugInteraction={context.state.modDrugInteraction}
                  modRecontinueMed={context.state.modRecontinueMed}
                  modAllVerifyDrug={context.state.modAllVerifyDrug}
                  modDuplicateDrug={context.state.modDuplicateDrug}
                  pediatricData={context.state.pediatricData}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  currentDoctor={context.state.currentDoctor}
                  drugOrderItemEstimate={context.state.drugOrderItemEstimate}
                  drugPermission={context.state.drugPermission}
                  searchedItemList={context.state.searchedItemList}
                  drugOrderItemMasterData={{
                    masterUnits: context.state.masterOptions.unit,
                    masterRoutes: context.state.masterOptions.route,
                    masterSites: context.state.masterOptions.site,
                    masterFrequencies: context.state.masterOptions.frequency,
                    masterMethods: context.state.masterOptions.method,
                  }}
                  duplicateReasonsOptions={
                    context.state.masterOptions.duplicateReasons
                  }
                  divisionForOrderDivOptions={
                    context.state.masterOptions.divisionForOrderDiv
                  }
                  masterData={context.controller.data.masterData}
                  // SolventSelectionConfig
                  modSolventSelectionConfig={
                    context.state.modSolventSelectionConfig
                  }
                  selectedVerbalDrugOrderWorking={
                    context.state.selectedVerbalDrugOrderWorking
                  }
                  // Issue 57255
                  selectedDivision={context.state.selectedDivision}
                  // DrugOrderHistory
                  DrugOrderHistorySequence={
                    context.state.DrugOrderHistorySequence
                  }
                  drugHistoryList={context.state.drugHistoryList}
                  drugHistoryOrderList={context.state.drugHistoryOrderList}
                  drugOrderHistoryController={
                    context.drugOrderHistoryController
                  }
                  loadingStatus={context.state.loadingStatus}
                  //MedReconcile
                  medReconcileCheck={context.state.medReconcileCheck}
                  divisionType={context.state.divisionType}
                  medReconcileIndex={context.state.medReconcileIndex}
                  django={context.state.django}
                  // drug package alert
                  drugPackageAlertList={context.state.drugPackageAlertList}
                  isStudentUser={context.state.isStudentUser}
                  masterOptions={context.state.masterOptions}
                  // เมื่อมีการกด edit มาจากรายการ drug tab order summary
                  drugOrderEdit={context.state.drugOrderEdit}
                  // เมื่อมีการกด Med Icon มาจากรายการ Medreconcile เพื่อเปิด Med Order
                  drugMedReconcileEditId={context.state.drugMedReconcileEditId}
                  languageUX={context.state.userSelectedLanguage}
                  drugVerbalFreeTxt={context.state.drugVerbalFreeTxt}
                  openModPharmacistNote={context.state.openModPharmacistNote}
                />
                }</>
              ),
            },
            {
              key: "Continue Med",
              name: "Continue Med",
              hide: !(isEncounterTypeIPD && isClinicWardDivision),
              render: () => (
                <CardDrugContinuePlan
                  // sequence
                  runSequence={context.runSequence}
                  DrugSelectSequence={context.state.DrugSelectSequence}
                  // function
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // permission
                  drugPermission={context.state.drugPermission}
                  // options
                  duplicateReasonsOptions={
                    context.state.masterOptions.duplicateReasons
                  }
                  masterOptions={context.state.masterOptions}
                  masterData={context.controller.data.masterData}
                  searchedItemListWithKey={context.state.searchedItemListWithKey}
                  // selected data
                  selectedPatient={context.state.selectedPatient}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedDrug={context.state.selectedDrug}
                  // drug list
                  drugContinueFilter={context.state.drugContinueFilter}
                  drugContinuePlanItems={context.state.drugContinuePlanItems}
                  // search drug
                  drugSearchLoading={context.state.drugSearchLoading}
                  drugSearchText={context.state.drugSearchText}
                  drugSearchResult={context.state.drugSearchResult}
                  // planning
                  modPlanningConfig={context.state.modPlanningConfig}
                  planningData={context.state.planningData}
                  // SpecialDirection
                  modSpecialDirectionConfig={
                    context.state.modSpecialDirectionConfig
                  }
                  // SolventSelectionConfig
                  modSolventSelectionConfig={
                    context.state.modSolventSelectionConfig
                  }
                  // mod note
                  modNoteData={context.state.modNoteData}
                  // modal doctor certificate check
                  modDoctorCertificate={context.state.modDoctorCertificate}
                  modDrugLabInteraction={context.state.modDrugLabInteraction}
                  modDrugDisease={context.state.modDrugDisease}
                  modDrugInteraction={context.state.modDrugInteraction}
                  currentDoctor={context.state.currentDoctor}
                  //MedReconcile
                  medReconcileCheck={context.state.medReconcileCheck}
                  divisionType={context.state.divisionType}
                  medReconcileIndex={context.state.medReconcileIndex}
                  django={context.state.django}
                  isDoctor={isDoctor}
                  pediatricData={context.state.pediatricData}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Treatment Order",
              // name: "Treatment Order",
              name: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Treatment Order</div>

                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "1em",
                      height: "1em",
                      margin: "0px 5px",
                      ...(!treatmentOrderInfo && { display: "none" }),
                    }}
                  />
                </div>
              ),
              hide: !isOrClinicWardDivision,
              render: () => (
                <CardTreatmentOrder
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  isVerbalOrder={false}
                  claimOptions={context.state.masterOptions.claimTreatment}
                  OrderTreatmentSequence={context.state.OrderTreatmentSequence}
                  searchedItemList={context.state.searchedItemList}
                  treatmentOrderList={context.state.treatmentOrderList}
                  successMessage={context.state.successMessage}
                  errorMessage={context.state.errorMessage}
                  procedureItems={context.state.procedureItems}
                  secondaryItems={context.state.secondaryItems}
                  treatmentTemplateList={context.state.treatmentTemplateList}
                  editId={context.state?.treatmentOrderList?.item?.id || null}
                  selectedProgressCycle={context.state.selectedProgressCycle}
                  //MedReconcile
                  medReconcileCheck={context.state.medReconcileCheck}
                  divisionType={context.state.divisionType}
                  medReconcileIndex={context.state.medReconcileIndex}
                  django={context.state.django}
                  selectedEncounter={context.state.selectedEncounter}
                  isStudentUser={context.state.isStudentUser}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  masterOptions={context.state.masterOptions}
                  selectedEmr={context.state.selectedEmr}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Treatment Result",
              // name: "Treatment Result",
              name: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Treatment Result</div>

                  <div
                    style={{
                      backgroundColor: "red",
                      borderRadius: "50%",
                      width: "1em",
                      height: "1em",
                      margin: "0px 5px",
                      ...(!treatmentOrderResultInfo && { display: "none" }),
                    }}
                  />
                </div>
              ),
              hide: !(isClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <CardTreatmentResult
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  TreatmentResultSequence={
                    context.state.TreatmentResultSequence
                  }
                  TreatmentResultList={context.state.TreatmentResultList}
                  treatmentToken={context.state.treatmentToken}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  procedureItems={context.state.procedureItems}
                  secondaryItems={context.state.secondaryItems}
                  isStudentUser={context.state.isStudentUser}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  masterOptions={context.state.masterOptions}
                  selectedEmr={context.state.selectedEmr}
                  django={context.state.django}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            // Duplicate
            // {
            //   key: "Supply Order",
            //   name: "Supply Order",
            //   hide:
            //     !context.state.selectedEncounter  ||
            //     !context.state.selectedEmr ||
            //     !["หน่วยตรวจ", "หอผู้ป่วย", "ห้องผ่าตัด","ห้องเวชภัณฑ์"].includes(
            //       context.state.divisionType || ""
            //     ),
            //   render: () => (
            //     <CardSupplyOrder
            //       setProp={context.controller.setProp}
            //       onEvent={context.controller.handleEvent}
            //       runSequence={context.runSequence}
            //       OrderSupplySequence={context.state.OrderSupplySequence}
            //       supplyOrderModeOptions={context.state.supplyOrderModeOptions}
            //       supplyOrderEligibilityOptions={
            //         context.state.supplyOrderEligibilityOptions
            //       }
            //       errorMessage={context.state.errorMessage}
            //       successMessage={context.state.successMessage}
            //       selectedEncounter={context.state.selectedEncounter}
            //       buttonLoadCheck={context.state.buttonLoadCheck}
            //     />
            //   ),
            // },
            {
              key: "รายการอาหาร",
              name: intl.formatMessage({id: "รายการอาหาร"}),
              hide: !(isEncounterTypeIPD && isClinicWardDivision),
              render: () => (
                <CardFood
                  // sequence
                  runSequence={context.runSequence}
                  FoodListSequence={context.state.FoodListSequence}
                  FoodRequestSequence={context.state.FoodRequestSequence}
                  // Allergy
                  AllergySequence={context.state.AllergySequence}
                  // function
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  DJANGO={context.state.django}
                  divisionType={context.state.divisionType}
                  medReconcileCheck={context.state.medReconcileCheck}
                  medReconcileIndex={context.state.medReconcileIndex}
                  selectedEncounter={context.state.selectedEncounter}
                  // options
                  foodRequestOptions={context.state.foodRequestOptions}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Miscellaneous Order",
              name: "Miscellaneous Order",
              hide: !(context.state.selectedEmr && (isOrClinicWardDivision || isSpecimenDivision)),
              render: () => (
                <CardOtherBill
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  toggleable={false}
                  encounterId={context.state.selectedEncounter.id}
                  patientData={context.state.patientData}
                  selectedDivision={context.state.selectedDivision}
                  onClose={() => {}}
                  userTokenize={context.state.userTokenize}
                  errorMessage={context.state.errorMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  getHospitalFee={async (params) => {
                    const [data, error] =
                      await context.proxyController.getConstance(params);
                    return [data, error];
                  }}
                  onGetSearchOptions={async ({
                    groupCode,
                    searchText,
                    limit,
                    offset,
                  }) => {
                    const [miscellaneousData, miscellaneousError] =
                      await context.proxyController.getMiscellaneous({
                        encounterId: context.state.selectedEncounter.id,
                        groupCode,
                        name: searchText,
                        limit,
                        offset,
                      });
                    return [miscellaneousData, miscellaneousError];
                  }}
                  postMiscellaneousOrderPreview={async ({ data }) => {
                    for (let item of data.items) {
                      if (!item.encounter) {
                        item.encounter = parseInt(
                          context.state.selectedEncounter.id
                        );
                      }
                      item.charges_date_iso = beToAd(item.charges_date)?.format(
                        "YYYY-MM-DD"
                      );
                    }

                    console.log("postMiscellaneousOrderPreview", data);
                    const [
                      miscellaneousOrderPreviewData,
                      miscellaneousOrderPreviewError,
                    ] =
                      await context.proxyController.postMiscellaneousOrderPreview(
                        { data }
                      );
                    return [
                      miscellaneousOrderPreviewData,
                      miscellaneousOrderPreviewError,
                    ];
                  }}
                  getEligibilityTypeChoices={async ({
                    model,
                    field,
                    nameAsId,
                    name,
                  }) => {
                    const [data, error] =
                      await context.proxyController.getChoices({
                        model,
                        field,
                        nameAsId,
                      });
                    return [data, error];
                  }}
                  getMiscellaneousOrder={async ({
                    encounterId,
                    active,
                    orderBy,
                    fromDate,
                    toDate,
                    offset,
                    limit,
                  }) => {
                    const [miscellaneousOrderData, miscellaneousOrderError] =
                      await context.proxyController.getMiscellaneousOrder({
                        encounterId,
                        active,
                        orderBy,
                        fromDate,
                        toDate,
                        offset,
                        limit,
                      });
                    return [miscellaneousOrderData, miscellaneousOrderError];
                  }}
                  deleteMiscellaneousOrder={async ({ id, data }) => {
                    const [
                      deleteMiscellaneousOrder,
                      deleteMiscellaneousOrderError,
                    ] = await context.proxyController.deleteMiscellaneousOrder({
                      id,
                      data
                    });
                    return [
                      deleteMiscellaneousOrder,
                      deleteMiscellaneousOrderError,
                    ];
                  }}
                  saveMiscellaneousOrder={async ({ saveData }) => {
                    const [
                      saveMiscellaneousOrder,
                      saveMiscellaneousOrderError,
                    ] =
                      await context.proxyController.postMiscellaneousOrderCreate(
                        {
                          data: saveData,
                        }
                      );
                    return [
                      saveMiscellaneousOrder,
                      saveMiscellaneousOrderError,
                    ];
                  }}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Consult",
              name: "Consult",
              hide: !isClinicWardDivision,
              render: () => (
                <CardAppointmentRequestConsultReact
                  style={{ marginBottom: "10px" }}
                  onClose={() => {}}
                  patientId={context.state.selectedEncounter.patient_id}
                  encounterId={context.state.selectedEncounter.id}
                  controller={context.consultController}
                  modSearchDoctorNoteController={
                    context.modSearchDoctorNoteController
                  }
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "ConsultApp",
              name: (
                <label>
                  Consultation{" "}
                  {consultOrder &&
                    context.state.currentDoctor?.id ===
                      consultOrder?.consulted_doctor && (
                      <Icon name="user doctor" color="blue" />
                    )}
                </label>
              ),
              hide: !(isOrClinicWardDivision && (isNurse || isDoctor)),
              render: () => (
                <CardConsultationList
                  // function
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  django={context.state.django}
                  currentDoctorId={context.state.currentDoctor?.id}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  // sequence
                  runSequence={context.runSequence}
                  ConsultationListSequence={
                    context.state.ConsultationListSequence
                  }
                  // options
                  masterOptions={context.state.masterOptions}
                  searchedItemList={context.state.searchedItemList}
                  consultData={context.state.consultData}
                  divisionList={context.state.divisionList}
                  // data
                  consultDetail={context.state.consultDetail}
                  showList={false}
                  selectedConsultOrder={consultOrder}
                  patientId={context.state.selectedPatient?.id}
                  encounterId={context.state.selectedEncounter?.id}
                  isWard={context.state.selectedEncounter?.type === "IPD"}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Imaging Result",
              name: "Imaging Result",
              hide: !isOrClinicWardDivision,
              render: () => (
                <CardImagingResult
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // seq
                  runSequence={context.runSequence}
                  ImagingResultSequence={context.state.ImagingResultSequence}
                  // data
                  django={context.state.django}
                  doctorId={context.state.currentDoctor?.id}
                  // CommonInterface
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  errorMessage={context.state.errorMessage}
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  modXrayDetail={context.state.modXrayDetail}
                  // options
                  masterOptions={context.state.masterOptions}
                  // config
                  readOnly={context.state.ImagingResultSequence?.readOnly}
                  // ----- Dental Diagram
                  clinicalFindingId={context.state.clinicalFindingId}
                  clinicalFindingIndex={context.state.clinicalFindingIndex}
                  clinicalFindings={context.state.clinicalFindingList || []}
                  organ={context.controller.data.organ}
                  clinicaltags={context.controller.data.clinicaltags}
                  filterClinicalFindingIds={
                    context.state.filterClinicalFindingIds || []
                  }
                  languageUX={context.props.userSelectedLanguage}
                  // -----#
                />
              ),
            },
            {
              key: "Imaging Order",
              name: "Imaging Order",
              hide: !isOrClinicWardDivision,
              render: () => (
                // <CardImagingRequest
                //   setProp={context.controller.setProp}
                //   onEvent={context.controller.handleEvent}
                //   successMessage={context.state.successMessage}
                //   errorMessage={context.state.errorMessage}
                //   imagingList={context.state.imagingList}
                //   orderImagingList={context.state.orderImagingList}
                //   masterOptions={context.state.masterOptions}
                //   // onSuccess={handleCloseModOrder}
                //   editId={context.state.selectedImaging?.id || null}
                // />
                <CardImagingOrder
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // seq
                  runSequence={context.runSequence}
                  ImagingOrderSequence={context.state.ImagingOrderSequence}
                  // data
                  orderId={context.state.selectedImaging?.id || null}
                  // CommonInterface
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  selectedEncounter={context.state.selectedEncounter}
                  userTokenize={context.state.userTokenize}
                  errorMessage={context.state.errorMessage}
                  modXrayDetail={context.state.modXrayDetail}
                  // options
                  masterOptions={context.state.masterOptions}
                  // ----- Dental Diagram
                  clinicalFindingId={context.state.clinicalFindingId}
                  clinicalFindingIndex={context.state.clinicalFindingIndex}
                  clinicalFindings={context.state.clinicalFindingList || []}
                  organ={context.controller.data.organ}
                  clinicaltags={context.controller.data.clinicaltags}
                  filterClinicalFindingIds={
                    context.state.filterClinicalFindingIds || []
                  }
                  languageUX={context.props.userSelectedLanguage}
                  // -----#
                />
              ),
            },
            {
              key: "X-ray Result",
              name: "X-ray Result",
              hide: !isScheduleConfirmed,
              render: () => (
                <CardImagingExamResult
                  closeable={false}
                  toggleable={false}
                  hideHeaderIcon={true}
                  isLoading={context.state.imagingExamResultLoading}
                  imagingExameResult={context.state.imagingExameResult}
                  getImagingExamResult={() => {
                    const enId: number =
                      context.state.selectedEncounter?.id ||
                      context.state.selectedOrOrder?.encounter;
                    if (enId) {
                      context.controller.handleEvent({
                        message: "ListImagingResult",
                        params: { encounter: enId },
                      });
                    }
                  }}
                  onOpenPACS={() => {
                    context.controller.handleEvent({
                      message: "OpenPACS",
                      params: {
                        hn:
                          context.state.selectedEncounter?.hn ||
                          context.state.selectedOrOrder?.hn,
                      },
                    });
                  }}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Admit Order",
              name: "Admit Order",
              hide: !(context.state.selectedEmr && isClinicWardDivision),
              render: () => (
                <CardAdmitOrder
                  card="CardAdmitOrder"
                  controller={context.crdAdmitOrderController}
                  orderId={admitOrder?.id}
                  subICDController={context.subICD10DetailController}
                  DJANGO={context.state.django}
                  PATIENT_DATA={context.state.patientData}
                  showHeader={false}
                  allowClear={true}
                  hideSaveButton={false}
                  hideCancelButton={false}
                  closeable={false}
                  toggleable={false}
                  labelFitContent={true}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Admission Form",
              name: "Admission Form",
              hide: !(context.state.hasAdmitOrder && isOrClinicWardDivision),
              render: () => (
                <CardAdmissionFormReact
                  controller={context.crdAdmissionFormController}
                  subICDController={context.subICD10DetailController}
                  emrId={context.state.selectedEmr.id}
                  patientId={context.state.selectedEncounter.patient_id}
                  PATIENT_DATA={context.state.patientData}
                  isReadOnly={false}
                  closeable={false}
                  toggleable={true}
                  // CardPhysicalExam
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  runSequence={context.runSequence}
                  PhysicalExamSequence={context.state.PhysicalExamSequence}
                  isCheckout={isCheckout}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            // {
            //   key: "Room Reservation",
            //   name: <div style={{ marginLeft: "10px" }}>Room Reservation</div>,
            //   hide:
            //     !context.state.hasAdmitOrder ||
            //     !["หน่วยตรวจ", "หอผู้ป่วย"].includes(
            //       context.state.divisionType || ""
            //     ),
            //   render: () => (
            //     <CardBed
            //       setProp={context.controller.setProp}
            //       onEvent={context.controller.handleEvent}
            //       runSequence={context.runSequence}
            //       masterOptions={context.state.masterOptions}
            //       SetBedSequence={context.state.SetBedSequence}
            //     />
            //   ),
            // },
            {
              key: "OPD Summary",
              name: "OPD Summary",
              hide: !(selectedEncounter && (["หน่วยตรวจ", "คลินิกพรีเมี่ยม"].includes(divisionType) || isScheduleConfirmed)),
              render: () => (
                <CardOPDOrderSummary
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  masterOptions={context.state.masterOptions}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  django={context.state.django}
                  orderSummaryOPDList={context.state.orderSummaryOPDList}
                  orderSummaryOPDOptions={context.state.orderSummaryOPDOptions}
                  orderSummaryOPDFilter={context.state.orderSummaryOPDFilter}
                  nurseOPDOrderPerformed={context.state.nurseOPDOrderPerformed}
                  loadingSummary={context.state.loadingSummary}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Progress Note",
              name: "Progress Note",
              hide: !(isEncounterTypeIPD && (isDrugDivision || isClinicWardDivision)),
              render: () => (
                <CardProgressNoteList
                  onEvent={context.controller.handleEvent}
                  DJANGO={context.state.django}
                  selectedEncounter={context.state.selectedEncounter}
                  selectedPatient={context.state.selectedPatient}
                  selectedEmr={context.state.selectedEmr}
                  apiToken={context.controller.apiToken}
                  progressionNoteList={context.state.progressionNoteList}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "คำสั่งอื่น ๆ",
              name: intl.formatMessage({id: "คำสั่งอื่น ๆ"}),
              hide: !isOrClinicWardDivision,
              render: () => (
                <CardDoctorNoteOrder
                  // function
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // permission
                  drNoteOrderPermission={context.state.drNoteOrderPermission}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // seq
                  runSequence={context.runSequence}
                  DoctorNoteOrderSequence={
                    context.state.DoctorNoteOrderSequence
                  }
                  // options
                  drNoteDivisionOptions={context.state.drNoteDivisionOptions}
                  // data
                  DJANGO={context.state.django}
                  // modPlanning
                  modPlanningConfig={context.state.modPlanningConfig}
                  planningData={context.state.planningData}
                  //MedReconcile
                  medReconcileCheck={context.state.medReconcileCheck}
                  divisionType={context.state.divisionType}
                  medReconcileIndex={context.state.medReconcileIndex}
                  django={context.state.django}
                  selectedEncounter={context.state.selectedEncounter}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Pre-Assessment",
              name: "Pre-Assessment",
              hide: !(isEncounterTypeIPD && isClinicWardDivision),
              render: () => (
                <CardPreAssessment
                  // function
                  controller={context.crdAdmissionFormController}
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  selectedPatient={context.state.selectedPatient}
                  emrId={context.state.selectedEmr.id}
                  // seq
                  runSequence={context.runSequence}
                  PreAssessmentSequence={context.state.PreAssessmentSequence}
                  // options
                  masterOptions={context.state.masterOptions}
                  // data
                  preAssessmentOptions={context.state.preAssessmentOptions}
                  preAssessmentActionLog={context.state.preAssessmentActionLog}
                  subICDController={context.subICD10DetailController}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "สรุปรายการค่าใช้จ่ายผู้ป่วย",
              name: intl.formatMessage({id: "สรุปรายการค่าใช้จ่ายผู้ป่วย"}),
              hide: !(isEncounterTypeIPD && isClinicWardDivision),
              render: () => (
                <CardMedicalFee
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  patientInfo={context.state.selectedPatient}
                  selectedEncounter={context.state.selectedEncounter}
                  invoiceItemByModes={context.state.invoiceItemByModes}
                  invoiceItemByOrders={context.state.invoiceItemByOrders}
                  invoiceItemByItems={context.state.invoiceItemByItems}
                  patientOrders={context.state.patientOrders}
                  BillPaymentSequence={context.state.BillPaymentSequence}
                  errorMessage={context.state.errorMessage}
                  // Suspending
                  taskTrackingList={context.state.taskTrackingList}
                  // BillPending
                  billPendingDetail={context.state.billPendingDetail}
                  // ReceiptCode
                  bilReceiptCodeDetail={context.state.bilReceiptCodeDetail}
                  // Hide แจ้งชำระเงิน และ รับชำระเงิน button
                  hidebp={true}
                  hidebpInvoice={true}
                  refundOrders={context.state.refundOrders}
                  // Bill Report Print
                  billReportPrint={context.state.billReportPrint}
                  // FaxClaimList
                  faxClaimItemList={context.state.faxClaimItemList}
                  // Package
                  invoiceItemPackageList={context.state.invoiceItemPackageList}
                  // Bill Patient Language
                  billingPatientLanguage={context.state.billingPatientLanguage}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Appointment",
              name: "Appointment",
              hide: !isClinicWardDivision,
              render: () => (
                <CardAppointmentManage
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  selectedPatient={context.state.selectedPatient}
                  SetAppointmentSequence={context.state.SetAppointmentSequence}
                  searchedItemList={context.state.searchedItemList}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            /*
            {
              key: "Med Certificate",
              name: "Med Certificate",
              hide:
                !(isClinicWardDivision || isScheduleConfirmed) ||
                isImagingOrder,
              render: () => (
                <div id="CardMedCert">
                  <CardMedCert
                    // finishLoading={context.finishModalMedCertificate}
                    controller={context.cardMedCertController}
                    patientData={context.state.patientData}
                    // onClose={context.closeModalMedCertificate}
                  />
                </div>
              ),
            },*/
            {
              key: "Med Certificate",
              name: "Med Certificate",
              hide: !(isClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <CardMedCertificateReport
                  // Function
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  // Data
                  DoctorCertificateSequence={
                    context.state.DoctorCertificateSequence
                  }
                  masterOptions={context.state.masterOptions}
                  errorMessage={context.state.errorMessage}
                  loadingStatus={context.state.loadingStatus}
                  //Config
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Discharge Summary",
              name: "Discharge Summary",
              hide: !(isEncounterTypeIPD && isDrugClinicWardDivision),
              render: () => (
                // Quite incomplete
                <CardDischargeSummary
                  // Function
                  runSequence={context.runSequence}
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  masterOptions={context.state.masterOptions}
                  DischargeSummarySequence={
                    context.state.DischargeSummarySequence
                  }
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  loadingStatus={context.state.loadingStatus}
                  dischargeSummaryPermission={
                    context.state.dischargeSummaryPermission
                  }
                  searchedItemListWithKey={context.state.searchedItemListWithKey}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            // {
            //   key: "Discharge",
            //   name: "Discharge",
            //   hide:
            //     !context.state.selectedEmr ||
            //     !["หน่วยตรวจ", "หอผู้ป่วย"].includes(
            //       context.state.divisionType || ""
            //     ),
            //   render: () => (
            //     // Quite incomplete
            //     <>
            //       {context.state.openDischargeReassessment && (
            //         <CardDischargeReassessment
            //           onEvent={context.controller.handleEvent}
            //           setProp={context.controller.setProp}
            //           runSequence={context.runSequence}
            //           DJANGO={context.state.django}
            //           selectedEncounter={context.state.selectedEncounter}
            //           masterOptions={context.state.masterOptions}
            //           AssessmentSequence={context.state.AssessmentSequence}
            //           choiceTriage={context.state.ChoiceTriage}
            //           patientInfo={context.state.selectedPatient}
            //         />
            //       )}
            //       <CardDischargeReact
            //         setProp={context.controller.setProp}
            //         // data
            //         titleText="Discharge"
            //         closeable={false}
            //         doctorOptions={{ items: [] }}
            //         userId={context.controller.data.user as any}
            //         dischargeDoctor={{ userId: -1 }}
            //         controller={context.cardDischargeController}
            //         DJANGO={context.state.django}
            //         PATIENT_DATA={context.state.patientData}
            //         onDischarged={async (checkoutCause: any) => {
            //           context.controller.handleEvent({
            //             message: "HandleRefreshEncounter",
            //             params: { isOpen: true },
            //           });
            //         }}
            //       />
            //     </>
            //   ),
            // },
            {
              key: "Discharge summary (Nurse)",
              name: "Discharge summary (Nurse)",
              hide: !(isEncounterTypeIPD && isDrugClinicWardDivision),
              render: () => (
                // Quite incomplete
                <CardDischargeSummaryNurse
                  // Function
                  runSequence={context.runSequence}
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  masterOptions={context.state.masterOptions}
                  selectedEncounter={context.state.selectedEncounter}
                  DischaegeNurseSequence={context.state.DischaegeNurseSequence}
                  FormDataDischargePlanning={
                    context.state.FormDataDischargePlanning
                  }
                  FormDataDischargeTeaching={
                    context.state.FormDataDischargePlanning
                  }
                  FormDataDischargeNursing={
                    context.state.FormDataDischargePlanning
                  }
                  ClinicalTermPlanning={context.state.ClinicalTermPlanning}
                  ClinicalTermTeaching={context.state.ClinicalTermTeaching}
                  ClinicalTermNursing={context.state.ClinicalTermNursing}
                  dischargePlanning={context.state.dischargePlanning}
                  typeOptionsMethod={context.state.typeOptionsMethod}
                  typeOptionsRelative={context.state.typeOptionsRelative}
                  typeOptionKnowledge={context.state.typeOptionKnowledge}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Encounter History",
              name: "Encounter History",
              hide: !isDrugClinicWardDivision,
              render: () => <div>Encounter History</div>,
            },
            ...(CONFIG.ENABLE_TELEPHAR
              ? [
                  {
                    // Todo: get channel id for given division, hn with PRX/resolve-chat-channel/
                    key: "Telehealth_Encounter_drug",
                    name: (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <label>Telemed</label>
                        {!!context.state.chatDetail?.unread_message_count && (
                          <Label
                            data-testid="msg-noti"
                            circular
                            color={"red"}
                            size="mini"
                            languageUX={context.props.userSelectedLanguage}
                          >
                            {context.state.chatDetail.unread_message_count}
                          </Label>
                        )}
                      </div>
                    ),
                    hide: context.state.divisionType !== "ห้องยา",
                    render: () => (
                      <div className="chatBox">
                        <ChatBox
                          ref={(ref: any) => (context.chatBoxRef = ref)}
                          // controller
                          controller={context.chatController}
                          classifyController={context.classifyController}
                          diagFormController={context.diagFormController}
                          // data
                          userId={
                            context.controller.data.user?.toString() || ""
                          }
                          apiToken={context.controller.apiToken}
                          isPatient={false}
                          nullId={false}
                          fullname={"fullname"}
                          username={"username"}
                          encounterId={context.state.selectedEncounter?.id}
                          // patientData={{ patient: context.state.selectedPatient?.id }}
                          patientData={{}}
                          patientId={context.state.selectedPatient?.id}
                          match={{
                            params: {
                              chatChannelId:
                                context.state.chatDetail?.chat_channel,
                            },
                          }}
                          division_id={context.state.selectedDivision?.id}
                          readOnly={false}
                          // callback
                          onSetChannelName={() => {}}
                          enHasNoDiv={() => {}}
                          enHasDiv={() => {}}
                          onDidMount={() =>
                            context.controller.setProp(
                              "chatDetail.unread_message_count",
                              0
                            )
                          }
                          onNavigationMessage={
                            context.state.chatDetail?.onNavigationMessage
                          }
                          onCallGetLastMessage={() => {}}
                          onOpenVideoCall={(url: string) => {
                            context.controller.setProp(
                              "openVideoCallModal",
                              true
                            );
                            context.controller.setProp("videoCallRoom", url);
                            context.controller.setProp(
                              "currChatChannelId",
                              context.state.chatDetail?.chat_channel
                            );
                          }}
                          onCloseVideoCall={() => {}}
                          languageUX={context.props.userSelectedLanguage}
                        />
                      </div>
                    ),
                  },
                ]
              : []),
            {
              key: "Invoice",
              name: "Invoice",
              hide: !isClinicWardDivision,
              render: () => (
                <CardMedicalFee
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  patientInfo={context.state.selectedPatient}
                  selectedEncounter={context.state.selectedEncounter}
                  invoiceItemByModes={context.state.invoiceItemByModes}
                  invoiceItemByOrders={context.state.invoiceItemByOrders}
                  invoiceItemByItems={context.state.invoiceItemByItems}
                  patientOrders={context.state.patientOrders}
                  BillPaymentSequence={context.state.BillPaymentSequence}
                  errorMessage={context.state.errorMessage}
                  // Suspending
                  taskTrackingList={context.state.taskTrackingList}
                  // BillPending
                  billPendingDetail={context.state.billPendingDetail}
                  // ReceiptCode
                  bilReceiptCodeDetail={context.state.bilReceiptCodeDetail}
                  refundOrders={context.state.refundOrders}
                  // Bill Report Print
                  billReportPrint={context.state.billReportPrint}
                  // FaxClaimList
                  faxClaimItemList={context.state.faxClaimItemList}
                  // Package
                  invoiceItemPackageList={context.state.invoiceItemPackageList}
                  // Bill Patient Language
                  billingPatientLanguage={context.state.billingPatientLanguage}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Station Summary",
              name: "Station Summary",
              hide: !isClinicWardDivision,
              render: () => (
                <CardStationSummary
                  onEvent={context.controller.handleEvent}
                  reportStationSummary={context.state.reportStationSummary}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Episode of Care",
              name: "Episode of Care",
              hide: !isClinicWardDivision,
              render: () => (
                <CardEpisodeOfCare
                  modOrderDetailController={context.modOrderDetailController}
                  onSelectedRow={(data) => {
                    console.log(" onSelectedRow data:", data);
                  }}
                  onEpisodeSelected={context.handleEpisodeSelected}
                  onSearchEpisode={async (params: any) => {
                    let nParams = {
                      ...params,
                      patientId: context.state.selectedEncounter.patient_id,
                    };
                    var result = [];
                    const [episodeListData, episodeError] =
                      await context.proxyController.getEpisodeList(nParams);
                    if (!episodeError) {
                      result = episodeListData;
                    }
                    return [result];
                  }}
                  onNewEpisode={async (episodeName: any, episodeType: any) => {
                    // Create episode
                    context.setState({ eocIsLoading: true });
                    const [data, error] =
                      await context.proxyController.createEpisode({
                        patientId: context.state.selectedEncounter.patient_id,
                        name: episodeName,
                        params: { episodeType: episodeType },
                      });
                    if (error) {
                      toast.error("ERROR: Cannot create episode!");
                      console.log("ERROR: Cannot create episode!");
                      context.setState({ eocIsLoading: false });
                      return;
                    }
                    // Move encounter to episode
                    context.handleEpisodeSelected(data);
                  }}
                  onCancelEpisode={context.handleCancelENFromEpisode}
                  getEpisodeDetail={() => {
                    return context.state.eocEpisodeSelected;
                  }}
                  getEncounterList={() => {
                    return context.state.eocEncounterList;
                  }}
                  episodeType={context.state.episodeType}
                  getEOCEnEPData={() => {
                    return context.state.eocEnEpData;
                  }}
                  handleGetDoctorSpecialty={context.handleGetDoctorSpecialty}
                  getDoctorSpecialty={() => {
                    return context.state.searchDoctorSpecialties;
                  }}
                  handleSearchDoctor={context.handleSearchDoctor}
                  getDoctorList={() => {
                    return context.state.searchDoctorList;
                  }}
                  getSearchDoctorNumberOfPage={() => {
                    return context.state.searchDoctorNumberOfPage;
                  }}
                  getSearchDoctorCurrentPage={() => {
                    return context.state.searchDoctorCurrentPage;
                  }}
                  onSearchDoctorListPageChange={async (
                    doctorName,
                    specialty,
                    page
                  ) => {
                    context.setState({ searchDoctorCurrentPage: page });
                    await context.handleSearchDoctor(
                      doctorName,
                      specialty,
                      page
                    );
                  }}
                  eocIsLoading={context.state.eocIsLoading}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Supply Workflow",
              name: "Supply Workflow",
              hide: !isSupplyDivision,
              render: () => (
                <CardSupplyOrderWorkflow
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // seq
                  runSequence={context.runSequence}
                  DispensingOrderSupplySequence={
                    context.state.DispensingOrderSupplySequence
                  }
                  ReturnSupplySequence={context.state.ReturnSupplySequence}
                  OrderSupplySequence={context.state.OrderSupplySequence}
                  // CommonInterface
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedEncounter={context.state.selectedEncounter}
                  loadingStatus={context.state.loadingStatus}
                  // options
                  masterOptions={context.state.masterOptions}
                  supplyOrderEligibilityOptions={
                    context.state.supplyOrderEligibilityOptions
                  }
                  supplyOrderModeOptions={context.state.supplyOrderModeOptions}
                  parentCard={"ViewEncounter"}
                  // style
                  // cardStyle={{ width: "calc(100vw - 28rem)" }}
                  django={context.state.django}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Supply Order",
              name: "Supply Order",
              hide: !(isClinicWardDivision || isSupplyDivision || isScheduleConfirmed),
              render: () => (
                <CardSupplyOrder
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  // seq
                  runSequence={context.runSequence}
                  OrderSupplySequence={context.state.OrderSupplySequence}
                  // options
                  supplyOrderModeOptions={context.state.supplyOrderModeOptions}
                  supplyOrderEligibilityOptions={
                    context.state.supplyOrderEligibilityOptions
                  }
                  masterOptions={context.state.masterOptions}
                  // CommonInterface
                  django={context.state.django}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedEncounter={context.state.selectedEncounter}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  //MedReconcile
                  medReconcileCheck={context.state.medReconcileCheck}
                  divisionType={context.state.divisionType}
                  medReconcileIndex={context.state.medReconcileIndex}
                  // style
                  // tableStyle={{ width: "calc(100vw - 29.5rem)" }}
                  // config
                  isSupplyOrderWorkflow={false}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "รายการรอรับชำระและคืนเงิน",
              name: intl.formatMessage({id: "รายการรอรับชำระและคืนเงิน"}),
              hide: !(isBilDivision && context.state.selectedPatient),
              render: () => (
                <CardMedicalFee
                  showAuthorize={CONFIG.FAX_CLAIM_AUTHORIZE}
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  patientInfo={context.state.selectedPatient}
                  selectedEncounter={context.state.selectedEncounter}
                  invoiceItemByModes={context.state.invoiceItemByModes}
                  invoiceItemByOrders={context.state.invoiceItemByOrders}
                  invoiceItemByItems={context.state.invoiceItemByItems}
                  patientOrders={context.state.patientOrders}
                  refundOrders={context.state.refundOrders}
                  medicalFeeFilter={context.state.medicalFeeFilter}
                  patientEncounterLockerStatus={context.state.patientEncounterLockerStatus}
                  showFilter={!CONFIG.FEATURE_DISABLED_UP_TO_VERS["202401"]}
                  BillPaymentSequence={context.state.BillPaymentSequence}
                  errorMessage={context.state.errorMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  masterOptions={context.state.masterOptions}
                  // Suspending
                  taskTrackingList={context.state.taskTrackingList}
                  // BillPending
                  billPendingDetail={context.state.billPendingDetail}
                  // ReceiptCode
                  bilReceiptCodeDetail={context.state.bilReceiptCodeDetail}
                  // Bill Report Print
                  billReportPrint={context.state.billReportPrint}
                  // FaxClaimList
                  faxClaimItemList={context.state.faxClaimItemList}
                  // Package
                  invoiceItemPackageList={context.state.invoiceItemPackageList}
                  // Bill Patient Language
                  billingPatientLanguage={context.state.billingPatientLanguage}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "รายการสถานะผู้ป่วย",
              name: intl.formatMessage({id: "รายการสถานะผู้ป่วย"}),
              hide: !isBilDivision,
              render: () => (
                <CardBillingEncounters
                  setProp={context.controller.setProp}
                  divisionList={context.state.divisionList}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  BillingHistorySequence={context.state.BillingHistorySequence}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedEncounter={context.state.selectedEncounter}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Medical_Bill_Summary",
              name: intl.formatMessage({id: "สรุปค่ารักษาพยาบาล"}),
              hide: !(selectedEncounter && ["การเงิน", "หน่วยตรวจ", "หอผู้ป่วย", "ห้องผ่าตัด"].includes(divisionType)),
              render: () => (
                <CardMedicalFeeHistory
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  masterOptions={context.state.masterOptions}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedEncounter={context.state.selectedEncounter}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  MedicalFeeHistorySequence={
                    context.state.MedicalFeeHistorySequence
                  }
                  loadingStatus={context.state.loadingStatus}
                  divisionType={context.state.divisionType}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "เอกสารทั้งหมด",
              name: intl.formatMessage({id: "เอกสารทั้งหมด"}),
              hide: !isBilDivision,
              render: () => (
                <CardBillingHistory
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // seq
                  runSequence={context.runSequence}
                  BillingHistorySequence={context.state.BillingHistorySequence}
                  BillPaymentSequence={context.state.BillPaymentSequence}
                  // data
                  divisionList={context.state.divisionList}
                  invoiceHistory={context.state.invoiceHistory}
                  // options
                  masterOptions={context.state.masterOptions}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedEncounter={context.state.selectedEncounter}
                  patientInfo={context.state.selectedPatient}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // Medical Fee
                  invoiceItemByModes={context.state.invoiceItemByModes}
                  invoiceItemByOrders={context.state.invoiceItemByOrders}
                  invoiceItemByItems={context.state.invoiceItemByItems}
                  refundOrders={context.state.refundOrders}
                  patientOrders={context.state.patientOrders}
                  // Bill Report Print
                  billReportPrint={context.state.billReportPrint}
                  // Bill Patient Language
                  billingPatientLanguage={context.state.billingPatientLanguage}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "ประวัติการรับชำระเงินค่ารักษาพยาบาล",
              name: intl.formatMessage({id: "ประวัติการรับชำระเงินค่ารักษาพยาบาล"}),
              hide: !(isDrugClinicWardDivision || isScheduleConfirmed),
              render: () => (
                <CardBillingHistory
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // seq
                  runSequence={context.runSequence}
                  BillingHistorySequence={context.state.BillingHistorySequence}
                  BillPaymentSequence={context.state.BillPaymentSequence}
                  // data
                  divisionList={context.state.divisionList}
                  invoiceHistory={context.state.invoiceHistory}
                  // options
                  masterOptions={context.state.masterOptions}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedEncounter={context.state.selectedEncounter}
                  patientInfo={context.state.selectedPatient}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // Medical Fee
                  invoiceItemByModes={context.state.invoiceItemByModes}
                  invoiceItemByOrders={context.state.invoiceItemByOrders}
                  invoiceItemByItems={context.state.invoiceItemByItems}
                  refundOrders={context.state.refundOrders}
                  patientOrders={context.state.patientOrders}
                  // Bill Report Print
                  billReportPrint={context.state.billReportPrint}
                  roleDoctor={true}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "กำหนดวงเงินรายครั้ง",
              name: intl.formatMessage({id: "กำหนดวงเงินรายครั้ง"}),
              hide: !isBilDivision,
              render: () => (
                <CardFinancialAmountSet
                  // origin function
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  // origin data
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // data
                  FinancialAmountSetSequence={
                    context.state.FinancialAmountSetSequence
                  }
                  financialAmountList={context.state.financialAmountList}
                  modCoverageApprove={context.state.modCoverageApprove}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "รับชำระเงินมัดจำ",
              name: intl.formatMessage({id: "บันทึกรับเงินมัดจำ"}),
              hide: !isBilDivision,
              render: () => (
                <CardBillingDeposit
                  setProp={context.controller.setProp}
                  divisionList={context.state.divisionList}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  BillingHistorySequence={context.state.BillingHistorySequence}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  selectedEncounter={context.state.selectedEncounter}
                  patientInfo={context.state.selectedPatient}
                  BillPaymentSequence={context.state.BillPaymentSequence}
                  invoiceItemByItems={context.state.invoiceItemByItems}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // ReceiptCode
                  bilReceiptCodeDetail={context.state.bilReceiptCodeDetail}
                  // Deposit
                  BillingDepositSequence={context.state.BillingDepositSequence}
                  // Bill Patient Language
                  billingPatientLanguage={context.state.billingPatientLanguage}
                  // options
                  masterOptions={context.state.masterOptions}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "ประวัติการใช้เงินมัดจำ",
              name: intl.formatMessage({id: "ประวัติการใช้เงินมัดจำ"}),
              hide: !isBilDivision,
              render: () => (
                <CardBillingDepositHistory
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  // Deposit
                  BillingDepositHistorySequence={
                    context.state.BillingDepositHistorySequence
                  }
                  billReportPrint={context.state.billReportPrint}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Invoices",
              name: "Invoices",
              hide: !selectedEncounter,
              render: () => (
                <CardInvoicesSummary
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  InvoicesSummarySequence={
                    context.state.InvoicesSummarySequence
                  }
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "แจ้งคืนค่าบริการ",
              name: intl.formatMessage({id: "แจ้งคืนค่าบริการ"}),
              hide: !(isClinicWardDivision || isBilDivision) || isScheduleConfirmed,
              render: () => (
                <CardServiceRefund
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  selectedPatient={context.state.selectedPatient}
                  ORServiceRefundSequence={
                    context.state.ORServiceRefundSequence
                  }
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "วงเงินคงเหลือ",
              name: intl.formatMessage({id: "วงเงินคงเหลือ"}),
              hide: !(selectedEncounter && isClinicWardDivision ) || isScheduleConfirmed,
              render: () => (
                <CardFinancialAmountSet
                  // origin function
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  runSequence={context.runSequence}
                  // origin data
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // data
                  FinancialAmountSetSequence={
                    context.state.FinancialAmountSetSequence
                  }
                  financialAmountList={context.state.financialAmountList}
                  modCoverageApprove={context.state.modCoverageApprove}
                  // config
                  readOnly={true}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Doctor Fee",
              name: "Doctor Fee",
              hide: !(!!context.state.selectedEmr && (isClinicWardDivision || isScheduleConfirmed || isBilDivision)),
              render: () => (
                <CardDoctorFeeOrderAndCompensation
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  onEvent={context.controller.handleEvent}
                  SetDoctorFeeSequence={context.state.SetDoctorFeeSequence}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  selectedEncounter={context.state.selectedEncounter}
                  providerEmployeeInfo={context.state.providerEmployeeInfo}
                  masterOptions={context.state.masterOptions}
                  userProfile={context.controller.data.userProfile}
                  languageUX={context.props.userSelectedLanguage}
                  divisionType={divisionType}
                />
              ),
            },
            {
              key: "สิทธิการรักษา",
              name: intl.formatMessage({id: "สิทธิการรักษา"}),
              hide: !(isClinicWardDivision || isBilDivision) || isScheduleConfirmed,
              render: () => (
                <CardPatientCoverage
                  onEvent={context.controller.handleEvent}
                  // data
                  patientCoverageList={context.state.patientCoverageList}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Package",
              name: "Package",
              hide: !(["การเงิน", "หน่วยตรวจ", "คลินิกพรีเมี่ยม"].includes(divisionType) && selectedEncounter),
              render: () => (
                <CardSearchPackage
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  // seq
                  runSequence={context.runSequence}
                  PackagePurchaseSequence={context.state.PackagePurchaseSequence}
                  // CommonInterface
                  searchedItemListWithKey={context.state.searchedItemListWithKey}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  // options
                  masterOptions={context.state.masterOptions}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Package Purchase History",
              name: "Package Purchase History",
              hide: !(selectedEncounter && ["การเงิน", "หน่วยตรวจ", "คลินิกพรีเมี่ยม"].includes(divisionType)),
              render: () => (
                <CardPurchaseHistory
                  setProp={context.controller.setProp}
                  onEvent={context.controller.handleEvent}
                  // seq
                  runSequence={context.runSequence}
                  PackagePurchaseSequence={
                    context.state.PackagePurchaseSequence
                  }
                  // data
                  masterData={context.controller.data.masterData}
                  // Filter
                  statusesFilter={["BILLED", "PAID"]}
                  // CommonInterface
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  errorMessage={context.state.errorMessage}
                  // options
                  masterOptions={context.state.masterOptions}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Apply Policy Package",
              name: intl.formatMessage({id: "บันทึกใช้แพ็กเกจ"}),
              hide: !isBilDivision,
              render: () => (
                <CardApplyPolicyPackage
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // seq
                  runSequence={context.runSequence}
                  ApplyPolicyPackageSequence={
                    context.state.ApplyPolicyPackageSequence
                  }
                  // CommonInterface
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  errorMessage={context.state.errorMessage}
                  // options
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Print_List_Encounter",
              name: "Print List",
              hide: !(selectedEncounter && ["การเงิน", "หน่วยตรวจ", "หอผู้ป่วย", "พื้นที่ดูแล", "ห้องผ่าตัด"].includes( divisionType )),
              render: () => (
                <CardPrintList
                  printer={context.state.django?.user?.full_name}
                  patientInfo={context.state.selectedPatient}
                  controller={context.printListController}
                  patientData={context.state.patientData}
                  patientId={context.state.selectedEncounter.patient_id}
                  encounterId={context.state.selectedEncounter.id}
                  encounterNumber={context.state.selectedEncounter.number}
                  masterOptions={context.state.masterOptions}
                  onClose={context.togglePrintList}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Re-Assessment",
              name: "Re-Assessment",
              hide: !(isEncounterTypeIPD && isClinicWardDivision),
              render: () => (
                <CardReAssessment
                  // function
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // CommonInterface
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  // seq
                  runSequence={context.runSequence}
                  ReAssessmentSequence={context.state.ReAssessmentSequence}
                  // options
                  masterOptions={context.state.masterOptions}
                  // data
                  reAssessmentOptions={context.state.reAssessmentOptions}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Counselling",
              name: "Counselling",
              hide: !isDrugDivision,
              render: () => (
                <CardDrugCounselling
                  // main event
                  runSequence={context.runSequence}
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // data
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  successMessage={context.state.successMessage}
                  errorMessage={context.state.errorMessage}
                  userTokenize={context.state.userTokenize}
                  DrugCounsellingSequence={
                    context.state.DrugCounsellingSequence
                  }
                  searchedItemListWithKey={
                    context.state.searchedItemListWithKey
                  }
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Scanned_Document_Encounter",
              name: "Scanned Document",
              hide: !(isDrugClinicWardDivision || isBilDivision || isOrDivision),
              render: () => (
                <CardScannedDocument
                  key="Common/CardScannedDocument"
                  controller={context.scannedDocumentController}
                  patientData={context.state.patientData}
                  patientInfo={{
                    ...context.state.selectedPatient,
                    encounter_id: context.state.selectedEncounter?.id,
                  }}
                  django={context.state.django}
                  isLoading={context.state.eocIsLoading}
                  onClose={context.removeCardScannedDocument}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Scanning Document",
              name: "Scanning Document",
              hide: !(isDrugClinicWardDivision || isBilDivision || isOrDivision),
              render: () => (
                <CardScanner
                  onClose={() => {
                    context.setState({
                      openModScanner: !context.state.openModScanner,
                    });
                    context.setState({ openMorePopup: false });
                  }}
                  patientInfo={context.state.selectedPatient}
                  controller={context.scannedDocumentController}
                  patientId={context.state.selectedEncounter?.patient_id}
                  encounterId={context.state.selectedEncounter?.id}
                  PATIENT_DATA={context.state.patientData}
                  onNoPatient={() =>
                    context.setState({ openModScannerError: true })
                  }
                  django={context.state.django}
                  setProp={context.controller.setProp}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
            key: "รับรองสิทธิ",
            name: intl.formatMessage({id: "รับรองสิทธิ"}),
            hide: !isBilDivision,
            render: () => (
              <CardConfirmCoverage
                onEvent={context.controller.handleEvent}
                setProp={context.controller.setProp}
                runSequence={context.runSequence}
                SetCoverageSequence={context.state.SetCoverageSequence}
                masterOptions={context.state.masterOptions}
                searchedItemList={context.state.searchedItemList}
                errorMessage={context.state.errorMessage}
                successMessage={context.state.successMessage}
                errorCoverage={context.state.errorCoverage}
                onRefreshPatient={() => {
                  context.controller.handleEvent({
                    message: "HandleSelect",
                    params: {
                      action: "select",
                      patient: context.state.selectedPatient,
                      noClear: true,
                    },
                  });
                }}
                languageUX={context.props.userSelectedLanguage}
              />
            ),
          },
            {
              key: "แก้ไข Encounter",
              name: intl.formatMessage({id: "แก้ไข Encounter"}),
              hide: !((!!selectedEncounter && !["การเงิน"].includes(divisionType)) || isScheduleConfirmed),
              render: () => (
                <CardOpenEncounter
                  isEditEncounter={true}
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  runSequence={context.runSequence}
                  patient={context.state.SetPatientSequence?.patient || {}}
                  selectedPatient={context.state.selectedPatient}
                  CreateUpdateEncounterSequence={
                    context.state.CreateUpdateEncounterSequence
                  }
                  appointmentEncounterList={
                    context.state.appointmentEncounterList
                  }
                  selectedAppointmentItems={
                    context.state.selectedAppointmentItems
                  }
                  masterOptions={context.state.masterOptions}
                  errorMessage={context.state.errorMessage}
                  successMessage={context.state.successMessage}
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            {
              key: "Picking Order",
              name: "Picking Order",
              hide: !((isEncounterTypeIPD && isDrugClinicWardDivision) || isScheduleConfirmed),
              render: () => (
                <CardDrugPickingOrder
                  onEvent={context.controller.handleEvent}
                  setProp={context.controller.setProp}
                  // seq
                  runSequence={context.runSequence}
                  DrugPickingOrderSequence={
                    context.state.DrugPickingOrderSequence
                  }
                  // CommonInterface
                  buttonLoadCheck={context.state.buttonLoadCheck}
                  errorMessage={context.state.errorMessage}
                  languageUX={context.props.userSelectedLanguage}
                />
              ),
            },
            ...(CONFIG.ENABLE_TELEPHAR
              ? [
                  {
                    key: "ใบสั่งยา",
                    name: intl.formatMessage({id: "ใบสั่งยา"}),
                    hide: !isDrugDivision,
                    render: () => (
                      <CardDrugOrderList
                        runSequence={context.runSequence}
                        onEvent={context.controller.handleEvent}
                        setProp={context.controller.setProp}
                        selectedEncounter={context.state.selectedEncounter}
                        DrugOrderListSequence={
                          context.state.DrugOrderListSequence
                        }
                        DashboardTelepharSequence={
                          context.state.DashboardTelepharSequence
                        }
                        languageUX={context.props.userSelectedLanguage}
                      />
                    ),
                  },
                ]
              : []),
            ...(CONFIG.ENABLE_TELEPHAR
              ? [
                  {
                    key: "Transportation",
                    name: "Transportation",
                    hide: !isDrugDivision,
                    render: () => (
                      <CardDrugTransportation
                        runSequence={context.runSequence}
                        setProp={context.controller.setProp}
                        TransportationSequence={
                          context.state.TransportationSequence
                        }
                        masterOptions={context.state.masterOptions}
                        masterData={context.controller.data.masterData}
                        languageUX={context.props.userSelectedLanguage}
                      />
                    ),
                  },
                ]
              : []),
          ]
            ?.filter((item: any): boolean => {
                const isVisible =
                  CONFIG.SCREEN_ITEM?.find((conf: any) => conf.name === item.key) && !item.hide;

                const tabList =
                  isImagingOrder
                    ? MainHISI.IMAGING_ENCOUNTER_TAB_LIST
                    : undefined;

                return isVisible && (!tabList || tabList.includes(item.key));
            })?.concat(
              (context.state.formScreen || []).map((item: any) => {
                // เพิ่ม Tab โดยไม่ได้เพิ่มผ่าน Screen
                return {
                  key: `${item?.name}`,
                  name: `${item?.name}`,
                  hide: false,
                  render: () => {
                    return (
                      <CardFormScreen
                        runSequence={context.runSequence}
                        setProp={context.controller.setProp}
                        masterOptions={context.state.masterOptions}
                        formScreen={item}
                        onEvent={context.controller.handleEvent}
                        successMessage={context.state.successMessage}
                        errorMessage={context.state.errorMessage}
                        buttonLoadCheck={context.state.buttonLoadCheck}
                        //MedReconcile
                        ReAssessmentSequence={
                          context.state.ReAssessmentSequence
                        }
                        reAssessmentOptions={context.state.reAssessmentOptions}
                        // Assessment
                        DJANGO={context.state.django}
                        selectedEncounter={context.state.selectedEncounter}
                        masterData={context.controller.data.masterData}
                        AssessmentSequence={context.state.AssessmentSequence}
                        choiceTriage={context.state.ChoiceTriage}
                        selectedPatient={context.state.selectedPatient}
                        // Triage
                        TriageSequence={context.state.TriageSequence}
                        // PreAssessment
                        crdAdmissionFormController={
                          context.crdAdmissionFormController
                        }
                        emrId={context.state.selectedEmr.id}
                        PreAssessmentSequence={
                          context.state.PreAssessmentSequence
                        }
                        preAssessmentOptions={
                          context.state.preAssessmentOptions
                        }
                        preAssessmentActionLog={
                          context.state.preAssessmentActionLog
                        }
                        subICDController={context.subICD10DetailController}
                        // ByEquipment
                        selectedOrOrder={context.state.selectedOrOrder}
                        PerioperativeNursingSequence={
                          context.state.PerioperativeNursingSequence
                        }
                        languageUX={context.props.userSelectedLanguage}
                      />
                    );
                  },
                };
              })
            )}
        />
      </div>
    );
  };

// For reference -- CardProgressCycle / Appointment Old
// {
//   key: "Progress Cycle",
//   name: "Progress Cycle",
//   hide:
//     !context.state.selectedEmr ||
//     !["หน่วยตรวจ", "หอผู้ป่วย"].includes(context.state.divisionType || ""),
//   render: () => (
//     <CardProgressCycle
//       setProp={context.controller.setProp}
//       onEvent={context.controller.handleEvent}
//       runSequence={context.runSequence}
//       selectedProgressCycle={context.state.selectedProgressCycle}
//       SetProgressCycleSequence={context.state.SetProgressCycleSequence}
//     />
//   ),
// },
// {
//   key: "Appointment Old",
//   name: "Appointment Old",
//   hide:
//     !context.state.selectedEncounter ||
//     !["หน่วยตรวจ", "หอผู้ป่วย"].includes(context.state.divisionType || ""),
//   render: () => (
//     <CardAppointmentRequestNew
//       titleCard="Appointment Request"
//       controller={context.crdAppointmentRequestNewController}
//       modSearchDoctorNoteController={
//         context.modSearchDoctorNoteController
//       }
//       subDoctorScheduleController={context.subDoctorScheduleController}
//       DJANGO={context.state.django}
//       PATIENT_DATA={context.state.patientData}
//       closeable={false}
//     />
//   ),
// },
