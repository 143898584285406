import React, { useMemo } from "react";
import ModDrugInteractionUX from "./ModDrugInteractionUX";
import { useIntl } from "react-intl";

export const SEVERITY_IMAGE_MAP = {
  1: "/static/images/di_minor.png",
  2: "/static/images/di_moderate.png",
  3: "/static/images/di_severe.png",
  4: "/static/images/di_contraindication.png",
};

export const SEVERITY_TEXT_MAP = {
  1: "Minor",
  2: "Moderate",
  3: "Major",
  4: "Contraindication",
  5: "Unknow",
};

const ModDrugInteraction = (props: any) => {
  const intl = useIntl();
  const drugInteractionList = useMemo(() => {
    let firstItem = props.modDrugInteraction?.drugItems?.[0] || [];
    return (
      <div
        style={{ padding: "15px 20px", display: "flex", flexDirection: "column", fontSize: "16px" }}
      >
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffea9e",
          }}
        >
          {firstItem.members_name.map((item: any, index: number) => {
            return (
              <div style={{ display: "flex", lineHeight: "2.2rem" }}>
                <span style={{ fontWeight: "bold" }}>{index + 1 + ". " + item}</span>
              </div>
            );
          })}
        </div>
        <div
          style={{ padding: "10px 0px", display: "flex", fontWeight: "bold", alignItems: "center" }}
        >
          <span style={{ marginRight: "10px" }}>{intl.formatMessage({ id: "ความรุนแรง:" })}</span>
          {firstItem.severity !== 5 && firstItem.severity !== undefined && (
            <img
              src={SEVERITY_IMAGE_MAP[firstItem.severity as 1 | 2 | 3 | 4]}
              height={30}
              width={30}
            />
          )}
          <span
            style={{
              marginLeft:
                firstItem.severity !== 5 && firstItem.severity !== undefined ? "10px" : "0px",
            }}
          >
            {SEVERITY_TEXT_MAP[firstItem.severity as 1 | 2 | 3 | 4 | 5]}
          </span>
        </div>
        <div style={{ padding: "10px 0px" }}>
          <b>{intl.formatMessage({ id: "ผลของการเกิดอันตรกิริยา:" })}</b>
          {firstItem.affect_summary}
        </div>
        <div style={{ padding: "10px 0px" }}>
          <b>{intl.formatMessage({ id: "การจัดการ:" })}</b>
          {firstItem.management}
        </div>
      </div>
    );
  }, [props.modDrugInteraction?.drugItems]);

  console.log("modDrugInteraction: ", props, drugInteractionList);
  return (
    <ModDrugInteractionUX
      // data
      drugInteractionList={drugInteractionList}
      orderItemId={
        props.modDrugInteraction?.orderItem?.id ||
        props.modDrugInteraction?.orderItem?.note_drug_interaction
      }
      isEditable={!!props.currentDoctor?.id}
      note={props.modDrugInteraction?.note}
      // function
      onNoteChange={(e: any, data: any) => {
        props.setProp("modDrugInteraction.note", data.value);
      }}
      onApprove={() => {
        if (
          props.modDrugInteraction.orderItem?.id ||
          props.modDrugInteraction?.orderItem?.note_drug_interaction
        ) {
          props.onEvent({
            message: "HandleModDrugInteraction",
            params: {
              editItem: {
                note_drug_interaction: props.modDrugInteraction?.note,
              },
              drugIndex: props.modDrugInteraction?.orderItem.drugIndex,
              orderType: props.orderType,
              card: "CardDrugOrder",
              action: "edit_item",
            },
          });
          props.onEvent({
            message: "HandleModDrugInteraction",
            params: { action: "close" },
          });
        } else {
          props.onEvent({
            message: "RunSequence",
            params: {
              sequence: "DrugSelect",
              feedback: "confirm",
              note: props.modDrugInteraction?.note,
            },
          });
        }
      }}
      onDeny={() => {
        if (
          props.modDrugInteraction.orderItem?.id ||
          props.modDrugInteraction?.orderItem?.note_drug_interaction
        ) {
          props.onEvent({
            message: "HandleModDrugInteraction",
            params: { action: "close" },
          });
        } else {
          props.onEvent({
            message: "RunSequence",
            params: { sequence: "DrugSelect", feedback: "cancel" },
          });
        }
      }}
      languageUX={props.languageUX}
    />
  );
};

export default ModDrugInteraction;
