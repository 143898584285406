import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Checkbox,
  Input,
  TextArea,
  Radio
} from 'semantic-ui-react'

const CardAirwayTeethHeadNeckUX = (props: any) => {
    return(
      <div
        style={{ width: "100%" }}>
        <Form>
          <FormGroup
            inline={true}
            style={{ display: "flex", alignItems: "center" }}>
            <FormField
              inline={true}>
              <div
                style={{ fontWeight: "bold" }}>
                Airway/ Teeth/ Head & Neck
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ fontWeight: "bold" }}>
                Dental
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  fluid={true}
                  name="dental"
                  onChange={props.handleChangeData}
                  options={props.dentalOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.dental}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.denturesCheck}
                  disabled={props.systemicEvaluation?.dental !== "Yes"}
                  label="Dentures"
                  name="denturesCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.capsCrownsCheck}
                  disabled={props.systemicEvaluation?.dental !== "Yes"}
                  label="Caps/Crowns"
                  name="capsCrownsCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.overBiteCheck}
                  disabled={props.systemicEvaluation?.dental !== "Yes"}
                  label="Overbite"
                  name="overBiteCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.looseTeethCheck}
                  disabled={props.systemicEvaluation?.dental !== "Yes"}
                  label="Loose teeth"
                  name="looseTeethCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <div
                style={{ width: "100%" }}>
                
                <Checkbox
                  checked={props.systemicEvaluation?.dentalOtherCheck}
                  disabled={props.systemicEvaluation?.dental !== "Yes"}
                  label="Other"
                  name="dentalOtherCheck"
                  onChange={props.handleChangeData}
                  style={{ width: "100%" }}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{ width: "100%" }}>
                
                <Input
                  disabled={props.systemicEvaluation?.dental !== "Yes" || !props.systemicEvaluation?.dentalOtherCheck}
                  fluid={true}
                  name="dentalOtherDetail"
                  onChange={props.handleChangeData}
                  value={props.systemicEvaluation?.dentalOtherDetail}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ fontWeight: "bold" }}>
                Mallampati grade
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ width: "100%" }}
              width={6}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  fluid={true}
                  name="mallampatiGradeType"
                  onChange={props.handleChangeData}
                  options={props.mallampatiGradeOptions}
                  selection={true}
                  value={props.systemicEvaluation?.mallampatiGradeType}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              style={{ height: "200px" }}
              width={3}>
              <div
                style={{ height: "100%" }}>
                {props.mallampatiImg}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={13}>
              <Form>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      Thyromental distance
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={3}>
                    <div
                      style={{ width: "100%" }}>
                      
                      <Dropdown
                        fluid={true}
                        name="thyromentalDistance"
                        onChange={props.handleChangeData}
                        options={props.masterOptions?.preanes_thyromental || []}
                        search={true}
                        selection={true}
                        value={props.systemicEvaluation?.thyromentalDistance}>
                      </Dropdown>
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{ display: "flex", justifyContent: "center" }}
                    width={2}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      Mouth opening
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={3}>
                    <div
                      style={{ width: "100%" }}>
                      
                      <Dropdown
                        fluid={true}
                        name="mouthOpening"
                        onChange={props.handleChangeData}
                        options={props.masterOptions?.preanes_mouth_opening || []}
                        search={true}
                        selection={true}
                        value={props.systemicEvaluation?.mouthOpening}>
                      </Dropdown>
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{ display: "flex", justifyContent: "center" }}
                    width={2}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      Patency of nares
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={3}>
                    <div
                      style={{ width: "100%" }}>
                      
                      <Dropdown
                        fluid={true}
                        name="patencyOfNares"
                        onChange={props.handleChangeData}
                        options={props.masterOptions?.preanes_patency_nares || []}
                        search={true}
                        selection={true}
                        value={props.systemicEvaluation?.patencyOfNares}>
                      </Dropdown>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      Prominent incisor
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={3}>
                    <div
                      style={{ width: "100%" }}>
                      
                      <Dropdown
                        fluid={true}
                        name="prominentIncisor"
                        onChange={props.handleChangeData}
                        options={props.masterOptions?.preanes_prominent || []}
                        search={true}
                        selection={true}
                        value={props.systemicEvaluation?.prominentIncisor}>
                      </Dropdown>
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{ display: "flex", justifyContent: "center" }}
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      Limitation of head /neck motility
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={7}>
                    <div
                      style={{ width: "100%" }}>
                      
                      <Dropdown
                        fluid={true}
                        name="limitation"
                        onChange={props.handleChangeData}
                        options={props.masterOptions?.preanes_limitation || []}
                        search={true}
                        selection={true}
                        value={props.systemicEvaluation?.limitation}>
                      </Dropdown>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      Dificult intubation expected
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={3}>
                    <div
                      style={{ width: "100%" }}>
                      
                      <Dropdown
                        fluid={true}
                        name="dificultIntubation"
                        onChange={props.handleChangeData}
                        options={props.masterOptions?.preanes_difficult || []}
                        search={true}
                        selection={true}
                        value={props.systemicEvaluation?.dificultIntubation}>
                      </Dropdown>
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{ display: "flex", justifyContent: "center" }}
                    width={1}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      Comment
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={9}>
                    <div
                      style={{ width: "100%" }}>
                      
                      <Input
                        fluid={true}
                        name="dificultIntubationComment"
                        onChange={props.handleChangeData}
                        value={props.systemicEvaluation?.dificultIntubationComment}>
                      </Input>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}
                    width={2}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      Artificial airway
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={5}>
                    <Radio
                      checked={props.systemicEvaluation?.airway === "None"}
                      label="None"
                      name="airway"
                      onChange={props.handleChangeData}
                      style={{  marginLeft: "10px" }}
                      value="None">
                    </Radio>
                    <Radio
                      checked={props.systemicEvaluation?.airway === "EET"}
                      label="EET"
                      name="airway"
                      onChange={props.handleChangeData}
                      style={{  marginLeft: "10px" }}
                      value="EET">
                    </Radio>
                    <Radio
                      checked={props.systemicEvaluation?.airway === "Tracheostomy tube"}
                      label="Tracheostomy tube"
                      name="airway"
                      onChange={props.handleChangeData}
                      style={{  marginLeft: "25px" }}
                      value="Tracheostomy tube">
                    </Radio>
                  </FormField>
                  <FormField
                    inline={true}
                    width={1}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      Comment
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={8}>
                    <div
                      style={{ width: "100%" }}>
                      
                      <Input
                        fluid={true}
                        name="airwayComment"
                        onChange={props.handleChangeData}
                        value={props.systemicEvaluation?.airwayComment}>
                      </Input>
                    </div>
                  </FormField>
                </FormGroup>
              </Form>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ margin: "30px 0px" }}>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ fontWeight: "bold" }}>
                Planned anesthesia
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.gaCheck}
                  label="GA"
                  name="gaCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ justifyContent: "center", display: "flex" }}
              width={1}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.tivaCheck}
                  label="TIVA"
                  name="tivaCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ justifyContent: "center", display: "flex" }}
              width={2}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.ivSedateCheck}
                  label="IV Sedate"
                  name="ivSedateCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.macCheck}
                  label="MAC"
                  name="macCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.regionalCheck}
                  label="Regional"
                  name="regionalCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ justifyContent: "center", display: "flex" }}
              width={1}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.plannedOtherCheck}
                  label="Other"
                  name="plannedOtherCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <div
                style={{ width: "100%" }}>
                
                <Input
                  disabled={!props.systemicEvaluation?.plannedOtherCheck}
                  fluid={true}
                  name="plannedOtherDetail"
                  onChange={props.handleChangeData}
                  value={props.systemicEvaluation?.plannedOtherDetail}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ fontWeight: "bold" }}>
                Airway Management
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.raeOralCheck}
                  label="RAE Oral"
                  name="raeOralCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.raeNasalCheck}
                  label="RAE Nasal"
                  name="raeNasalCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ justifyContent: "center", display: "flex" }}
              width="1">
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.reinforceCheck}
                  label="Reinforce"
                  name="reinforceCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ justifyContent: "center", display: "flex" }}
              width="2">
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.maskCheck}
                  label="Mask"
                  name="maskCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width="2">
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.tracheostomyCheck}
                  label="Tracheostomy"
                  name="tracheostomyCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width="2">
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.lmaCheck}
                  label="LMA"
                  name="lmaCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ marginBottom: "30px" }}>
            <FormField
              inline={true}
              width={3}>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.airwayOtherCheck}
                  label="Other"
                  name="airwayOtherCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width="12">
              <div
                style={{ width: "100%" }}>
                
                <Input
                  disabled={!props.systemicEvaluation?.airwayOtherCheck}
                  name="airwayOtherDetail"
                  onChange={props.handleChangeData}
                  value={props.systemicEvaluation?.airwayOtherDetail}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ fontWeight: "bold" }}>
                Intubation Assessment
              </div>
            </FormField>
            <FormField
              inline={true}
              width="1">
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.standardCheck}
                  label="Standard"
                  name="standardCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ justifyContent: "center", display: "flex" }}
              width="2">
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.blindNasalCheck}
                  label="Blind Nasal"
                  name="blindNasalCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ justifyContent: "center", display: "flex" }}
              width="1">
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.styletCheck}
                  label="Stylet"
                  name="styletCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ justifyContent: "center", display: "flex" }}
              width={2}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.fiberopticCheck}
                  label="Fiberoptic"
                  name="fiberopticCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{ justifyContent: "center", display: "flex" }}
              width="2">
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.videolaryngoscopeCheck}
                  label="Videolaryngoscope"
                  name="videolaryngoscopeCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ marginBottom: "30px" }}>
            <FormField
              inline={true}
              width={3}>
            </FormField>
            <FormField
              inline={true}
              width="1">
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.intubationOtherCheck}
                  label="Other"
                  name="intubationOtherCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width="12">
              <div
                style={{ width: "100%" }}>
                
                <Input
                  disabled={!props.systemicEvaluation?.intubationOtherCheck}
                  fluid={true}
                  name="intubationOtherDetail"
                  onChange={props.handleChangeData}
                  value={props.systemicEvaluation?.intubationOtherDetail}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ fontWeight: "bold" }}>
                ASA PS Classification
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  fluid={true}
                  name="classification"
                  onChange={props.handleChangeData}
                  options={props.asaClassOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.classification}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              style={{ justifyContent: "center", display: "flex" }}>
              <Checkbox
                checked={props.systemicEvaluation?.emergencyCheck}
                label="Emergency"
                name="emergencyCheck"
                onChange={props.handleChangeData}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ fontWeight: "bold" }}>
                Anesthetic problems / considerations
              </div>
            </FormField>
            <FormField
              inline={true}
              width={13}>
              <div
                style={{ width: "100%" }}>
                
                <TextArea
                  name="considerations"
                  onChange={props.handleChangeData}
                  style={{ resize: "none" }}
                  value={props.systemicEvaluation?.considerations}>
                </TextArea>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ fontWeight: "bold" }}>
                Plan of postoperative pain management
              </div>
            </FormField>
            <FormField
              inline={true}
              width={13}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  clearable={true}
                  fluid={true}
                  multiple={true}
                  name="management"
                  onChange={props.handleChangeData}
                  options={props.masterOptions?.preanes_plan_of_post || []}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.management}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ fontWeight: "bold",   }}>
                Pre-Anesthesia Medication Ordered
              </div>
              <div>
                {props.medication}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={13}>
              <div
                style={{ width: "100%" }}>
                
                <TextArea
                  disabled={true}
                  style={{ resize: "none" }}>
                </TextArea>
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardAirwayTeethHeadNeckUX

export const screenPropsDefault = {}

/* Date Time : Tue Sep 24 2024 00:06:16 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Airway/ Teeth/ Head & Neck"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "dental"
        },
        "onAddItem": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.dentalOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.dental"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "Mallampati grade"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 98,
      "props": {
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Dental"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Dropdown",
      "parent": 95,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mallampatiGradeType"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.mallampatiGradeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.mallampatiGradeType"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 180,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 179,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "Checkbox",
      "parent": 183,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.dentalOtherCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.dental !== \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "dentalOtherCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "Checkbox",
      "parent": 184,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.looseTeethCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.dental !== \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Loose teeth"
        },
        "name": {
          "type": "value",
          "value": "looseTeethCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "Checkbox",
      "parent": 185,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.overBiteCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.dental !== \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Overbite"
        },
        "name": {
          "type": "value",
          "value": "overBiteCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "Checkbox",
      "parent": 186,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.capsCrownsCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.dental !== \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Caps/Crowns"
        },
        "name": {
          "type": "value",
          "value": "capsCrownsCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "Checkbox",
      "parent": 187,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.denturesCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.dental !== \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Dentures"
        },
        "name": {
          "type": "value",
          "value": "denturesCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Input",
      "parent": 182,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.dental !== \"Yes\" || !props.systemicEvaluation?.dentalOtherCheck"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "dentalOtherDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.dentalOtherDetail"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "FormField",
      "parent": 194,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"200px\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "FormField",
      "parent": 194,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Form",
      "parent": 196,
      "props": {
      },
      "seq": 197,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "div",
      "parent": 195,
      "props": {
        "children": {
          "type": "code",
          "value": "props.mallampatiImg"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\" }"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "FormGroup",
      "parent": 197,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "FormGroup",
      "parent": 197,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "FormGroup",
      "parent": 197,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "FormField",
      "parent": 199,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "FormField",
      "parent": 199,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "FormField",
      "parent": 199,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "FormField",
      "parent": 199,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "FormField",
      "parent": 199,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "FormField",
      "parent": 199,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "FormField",
      "parent": 201,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "FormField",
      "parent": 201,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "FormField",
      "parent": 201,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "FormField",
      "parent": 201,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "FormField",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "FormField",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "FormField",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 215,
      "name": "FormField",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": null,
      "id": 216,
      "name": "div",
      "parent": 202,
      "props": {
        "children": {
          "type": "value",
          "value": "Thyromental distance"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": null,
      "id": 217,
      "name": "div",
      "parent": 203,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": null,
      "id": 218,
      "name": "div",
      "parent": 204,
      "props": {
        "children": {
          "type": "value",
          "value": "Mouth opening"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 219,
      "name": "div",
      "parent": 205,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "div",
      "parent": 206,
      "props": {
        "children": {
          "type": "value",
          "value": "Patency of nares"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "div",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 222,
      "name": "div",
      "parent": 211,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "div",
      "parent": 210,
      "props": {
        "children": {
          "type": "value",
          "value": "Comment"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "div",
      "parent": 209,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "div",
      "parent": 208,
      "props": {
        "children": {
          "type": "value",
          "value": "Dificult intubation expected"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 215,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "div",
      "parent": 214,
      "props": {
        "children": {
          "type": "value",
          "value": "Limitation of head /neck motility"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": null,
      "id": 228,
      "name": "div",
      "parent": 213,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 229,
      "name": "div",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": "Prominent incisor"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "Dropdown",
      "parent": 217,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "thyromentalDistance"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_thyromental || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.thyromentalDistance"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "Dropdown",
      "parent": 219,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mouthOpening"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_mouth_opening || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.mouthOpening"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "Dropdown",
      "parent": 221,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "patencyOfNares"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_patency_nares || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.patencyOfNares"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "Dropdown",
      "parent": 228,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "prominentIncisor"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_prominent || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.prominentIncisor"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "Dropdown",
      "parent": 226,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "limitation"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_limitation || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.limitation"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "Dropdown",
      "parent": 224,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "dificultIntubation"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_difficult || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.dificultIntubation"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "Input",
      "parent": 222,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "dificultIntubationComment"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.dificultIntubationComment"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"30px 0px\" }"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: \"30px\" }"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: \"30px\" }"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 243,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "FormField",
      "parent": 237,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "FormField",
      "parent": 237,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "FormField",
      "parent": 237,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"center\", display: \"flex\" }"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "FormField",
      "parent": 237,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"center\", display: \"flex\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 248,
      "name": "FormField",
      "parent": 237,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "FormField",
      "parent": 237,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "FormField",
      "parent": 237,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"center\", display: \"flex\" }"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 251,
      "name": "FormField",
      "parent": 237,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 252,
      "name": "div",
      "parent": 251,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "div",
      "parent": 250,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 254,
      "name": "div",
      "parent": 249,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": null,
      "id": 255,
      "name": "div",
      "parent": 248,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "div",
      "parent": 247,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": null,
      "id": 257,
      "name": "div",
      "parent": 245,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": null,
      "id": 258,
      "name": "div",
      "parent": 246,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": null,
      "id": 259,
      "name": "div",
      "parent": 244,
      "props": {
        "children": {
          "type": "value",
          "value": "Planned anesthesia"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 260,
      "name": "Checkbox",
      "parent": 257,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.gaCheck"
        },
        "label": {
          "type": "value",
          "value": "GA"
        },
        "name": {
          "type": "value",
          "value": "gaCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 261,
      "name": "Checkbox",
      "parent": 258,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.tivaCheck"
        },
        "label": {
          "type": "value",
          "value": "TIVA"
        },
        "name": {
          "type": "value",
          "value": "tivaCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 262,
      "name": "Checkbox",
      "parent": 256,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.ivSedateCheck"
        },
        "label": {
          "type": "value",
          "value": "IV Sedate"
        },
        "name": {
          "type": "value",
          "value": "ivSedateCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 263,
      "name": "Checkbox",
      "parent": 255,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.macCheck"
        },
        "label": {
          "type": "value",
          "value": "MAC"
        },
        "name": {
          "type": "value",
          "value": "macCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "Checkbox",
      "parent": 254,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.regionalCheck"
        },
        "label": {
          "type": "value",
          "value": "Regional"
        },
        "name": {
          "type": "value",
          "value": "regionalCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 265,
      "name": "Checkbox",
      "parent": 253,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.plannedOtherCheck"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "plannedOtherCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 266,
      "name": "Input",
      "parent": 252,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.plannedOtherCheck"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "plannedOtherDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.plannedOtherDetail"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 267,
      "name": "FormField",
      "parent": 238,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "FormField",
      "parent": 238,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "FormField",
      "parent": 238,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "FormField",
      "parent": 238,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"center\", display: \"flex\" }"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 271,
      "name": "FormField",
      "parent": 238,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"center\", display: \"flex\" }"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "FormField",
      "parent": 238,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 273,
      "name": "FormField",
      "parent": 239,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 274,
      "name": "FormField",
      "parent": 239,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 275,
      "name": "FormField",
      "parent": 239,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "12"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": null,
      "id": 276,
      "name": "div",
      "parent": 275,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "div",
      "parent": 274,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": null,
      "id": 279,
      "name": "div",
      "parent": 272,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "div",
      "parent": 271,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "div",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 282,
      "name": "div",
      "parent": 269,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": null,
      "id": 283,
      "name": "div",
      "parent": 268,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": null,
      "id": 284,
      "name": "div",
      "parent": 267,
      "props": {
        "children": {
          "type": "value",
          "value": "Airway Management"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 286,
      "name": "Checkbox",
      "parent": 283,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.raeOralCheck"
        },
        "label": {
          "type": "value",
          "value": "RAE Oral"
        },
        "name": {
          "type": "value",
          "value": "raeOralCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 287,
      "name": "Checkbox",
      "parent": 282,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.raeNasalCheck"
        },
        "label": {
          "type": "value",
          "value": "RAE Nasal"
        },
        "name": {
          "type": "value",
          "value": "raeNasalCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "Checkbox",
      "parent": 281,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.reinforceCheck"
        },
        "label": {
          "type": "value",
          "value": "Reinforce"
        },
        "name": {
          "type": "value",
          "value": "reinforceCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "Checkbox",
      "parent": 280,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.maskCheck"
        },
        "label": {
          "type": "value",
          "value": "Mask"
        },
        "name": {
          "type": "value",
          "value": "maskCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "Checkbox",
      "parent": 279,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.tracheostomyCheck"
        },
        "label": {
          "type": "value",
          "value": "Tracheostomy"
        },
        "name": {
          "type": "value",
          "value": "tracheostomyCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 291,
      "name": "Checkbox",
      "parent": 277,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.airwayOtherCheck"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "airwayOtherCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "Input",
      "parent": 276,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.airwayOtherCheck"
        },
        "name": {
          "type": "value",
          "value": "airwayOtherDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.airwayOtherDetail"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "FormField",
      "parent": 241,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "FormField",
      "parent": 241,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "FormField",
      "parent": 241,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "12"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "FormField",
      "parent": 240,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 297,
      "name": "FormField",
      "parent": 240,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 298,
      "name": "FormField",
      "parent": 240,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"center\", display: \"flex\" }"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 299,
      "name": "FormField",
      "parent": 240,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"center\", display: \"flex\" }"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 300,
      "name": "FormField",
      "parent": 240,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"center\", display: \"flex\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 301,
      "name": "FormField",
      "parent": 240,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"center\", display: \"flex\" }"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": null,
      "id": 302,
      "name": "div",
      "parent": 294,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": null,
      "id": 303,
      "name": "div",
      "parent": 295,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": null,
      "id": 304,
      "name": "div",
      "parent": 301,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": null,
      "id": 305,
      "name": "div",
      "parent": 300,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": null,
      "id": 307,
      "name": "div",
      "parent": 299,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 307,
      "void": false
    },
    {
      "from": null,
      "id": 308,
      "name": "div",
      "parent": 298,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 308,
      "void": false
    },
    {
      "from": null,
      "id": 309,
      "name": "div",
      "parent": 297,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 309,
      "void": false
    },
    {
      "from": null,
      "id": 310,
      "name": "div",
      "parent": 296,
      "props": {
        "children": {
          "type": "value",
          "value": "Intubation Assessment"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 310,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 311,
      "name": "Checkbox",
      "parent": 309,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.standardCheck"
        },
        "label": {
          "type": "value",
          "value": "Standard"
        },
        "name": {
          "type": "value",
          "value": "standardCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 311,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 312,
      "name": "Checkbox",
      "parent": 308,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.blindNasalCheck"
        },
        "label": {
          "type": "value",
          "value": "Blind Nasal"
        },
        "name": {
          "type": "value",
          "value": "blindNasalCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "Checkbox",
      "parent": 307,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.styletCheck"
        },
        "label": {
          "type": "value",
          "value": "Stylet"
        },
        "name": {
          "type": "value",
          "value": "styletCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 314,
      "name": "Checkbox",
      "parent": 305,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.fiberopticCheck"
        },
        "label": {
          "type": "value",
          "value": "Fiberoptic"
        },
        "name": {
          "type": "value",
          "value": "fiberopticCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 314,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 315,
      "name": "Checkbox",
      "parent": 304,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.videolaryngoscopeCheck"
        },
        "label": {
          "type": "value",
          "value": "Videolaryngoscope"
        },
        "name": {
          "type": "value",
          "value": "videolaryngoscopeCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 315,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 316,
      "name": "Checkbox",
      "parent": 302,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.intubationOtherCheck"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "intubationOtherCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 316,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 317,
      "name": "Input",
      "parent": 303,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.intubationOtherCheck"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "intubationOtherDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.intubationOtherDetail"
        }
      },
      "seq": 317,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 318,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 318,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 319,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 319,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 320,
      "name": "FormField",
      "parent": 242,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 320,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 321,
      "name": "FormField",
      "parent": 242,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 321,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 322,
      "name": "FormField",
      "parent": 243,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 322,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 323,
      "name": "FormField",
      "parent": 243,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 323,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 324,
      "name": "FormField",
      "parent": 318,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 324,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 325,
      "name": "FormField",
      "parent": 318,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 325,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 326,
      "name": "FormField",
      "parent": 319,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 326,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 327,
      "name": "FormField",
      "parent": 319,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 327,
      "void": false
    },
    {
      "from": null,
      "id": 328,
      "name": "div",
      "parent": 327,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 328,
      "void": false
    },
    {
      "from": null,
      "id": 329,
      "name": "div",
      "parent": 326,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre-Anesthesia Medication Ordered"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",   }"
        }
      },
      "seq": 329,
      "void": false
    },
    {
      "from": null,
      "id": 330,
      "name": "div",
      "parent": 325,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 330,
      "void": false
    },
    {
      "from": null,
      "id": 331,
      "name": "div",
      "parent": 324,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan of postoperative pain management"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 331,
      "void": false
    },
    {
      "from": null,
      "id": 332,
      "name": "div",
      "parent": 323,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 332,
      "void": false
    },
    {
      "from": null,
      "id": 333,
      "name": "div",
      "parent": 322,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthetic problems / considerations"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 333,
      "void": false
    },
    {
      "from": null,
      "id": 334,
      "name": "div",
      "parent": 321,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 334,
      "void": false
    },
    {
      "from": null,
      "id": 335,
      "name": "div",
      "parent": 320,
      "props": {
        "children": {
          "type": "value",
          "value": "ASA PS Classification"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 335,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 336,
      "name": "Dropdown",
      "parent": 334,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "classification"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.asaClassOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.classification"
        }
      },
      "seq": 336,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 337,
      "name": "Dropdown",
      "parent": 330,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "management"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_plan_of_post || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.management"
        }
      },
      "seq": 337,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 338,
      "name": "TextArea",
      "parent": 332,
      "props": {
        "name": {
          "type": "value",
          "value": "considerations"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.considerations"
        }
      },
      "seq": 338,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 339,
      "name": "TextArea",
      "parent": 328,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        }
      },
      "seq": 339,
      "void": true
    },
    {
      "from": null,
      "id": 340,
      "name": "div",
      "parent": 326,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medication"
        }
      },
      "seq": 340,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 341,
      "name": "FormField",
      "parent": 242,
      "props": {
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"center\", display: \"flex\" }"
        }
      },
      "seq": 341,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 342,
      "name": "Checkbox",
      "parent": 341,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.emergencyCheck"
        },
        "label": {
          "type": "value",
          "value": "Emergency"
        },
        "name": {
          "type": "value",
          "value": "emergencyCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 342,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 343,
      "name": "FormField",
      "parent": 238,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 343,
      "void": false
    },
    {
      "from": null,
      "id": 344,
      "name": "div",
      "parent": 343,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 344,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 345,
      "name": "Checkbox",
      "parent": 344,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.lmaCheck"
        },
        "label": {
          "type": "value",
          "value": "LMA"
        },
        "name": {
          "type": "value",
          "value": "lmaCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 345,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 346,
      "name": "FormGroup",
      "parent": 197,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 346,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 347,
      "name": "FormField",
      "parent": 346,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 347,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 348,
      "name": "FormField",
      "parent": 346,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 348,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 349,
      "name": "FormField",
      "parent": 346,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 349,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 350,
      "name": "FormField",
      "parent": 346,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 350,
      "void": false
    },
    {
      "from": null,
      "id": 351,
      "name": "div",
      "parent": 347,
      "props": {
        "children": {
          "type": "value",
          "value": "Artificial airway"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 351,
      "void": false
    },
    {
      "from": null,
      "id": 352,
      "name": "div",
      "parent": 349,
      "props": {
        "children": {
          "type": "value",
          "value": "Comment"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 352,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 354,
      "name": "Radio",
      "parent": 348,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.airway === \"None\""
        },
        "label": {
          "type": "value",
          "value": "None"
        },
        "name": {
          "type": "value",
          "value": "airway"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginLeft: \"10px\" }"
        },
        "value": {
          "type": "value",
          "value": "None"
        }
      },
      "seq": 354,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 355,
      "name": "Radio",
      "parent": 348,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.airway === \"EET\""
        },
        "label": {
          "type": "value",
          "value": "EET"
        },
        "name": {
          "type": "value",
          "value": "airway"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginLeft: \"10px\" }"
        },
        "value": {
          "type": "value",
          "value": "EET"
        }
      },
      "seq": 355,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 356,
      "name": "Radio",
      "parent": 348,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.airway === \"Tracheostomy tube\""
        },
        "label": {
          "type": "value",
          "value": "Tracheostomy tube"
        },
        "name": {
          "type": "value",
          "value": "airway"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  marginLeft: \"25px\" }"
        },
        "value": {
          "type": "value",
          "value": "Tracheostomy tube"
        }
      },
      "seq": 356,
      "void": false
    },
    {
      "from": null,
      "id": 357,
      "name": "div",
      "parent": 350,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 357,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 358,
      "name": "Input",
      "parent": 357,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "airwayComment"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.airwayComment"
        }
      },
      "seq": 358,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardAirwayTeethHeadNeckUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
