import WasmController from "react-lib/frameworks/WasmController";

// APIs
// LAB
import LabDivisionList from "issara-sdk/apis/LabDivisionList_apps_LAB";
import CentralLabTestList from "issara-sdk/apis/CentralLabTestList_apps_LAB";
import CentralLabOrderEstimate from "issara-sdk/apis/CentralLabOrderEstimate_apps_LAB";
import CentralLabOrderList from "issara-sdk/apis/CentralLabOrderList_apps_LAB";
import CentralLabOrderDetail from "issara-sdk/apis/CentralLabOrderDetail_apps_LAB";
import CentralLabTemplateList from "issara-sdk/apis/CentralLabTemplateList_apps_LAB";
import CentralLabOrderEducationApproveView from "issara-sdk/apis/CentralLabOrderEducationApproveView_apps_LAB";
// DPO
import DoctorOrderCancel from "issara-sdk/apis/DoctorOrderCancel_apps_DPO";
// USERS
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";

import moment from "moment";
import { beStringDateToDateObject } from "react-lib/utils/dateUtils";

export type State = {
  // CommonInterface
  preOrderList?: any[];
  loadingStatus?: any;
  buttonLoadCheck?: any;
  selectedEncounter?: any;
  selectedEmr?: any;
  selectedProgressCycle?: any;
  userLabPermission?: {
    config_LAB_BLOOD_BANK_LAB_DIVISION_CODES?: any[];
    config_QUE_ADVANCE_APPOINTMENT_DATE_BEFORE?: number | null;
  };
  successMessage?: any;
  errorMessage?: any;
  centralLabOrderEditId?: number | null;
  doctorLabOrderList?: any;
  // seq
  OrderCentralLabSequence?: {
    sequenceIndex: string | null;
    labDivisions?: any[];
    labDivision?: string | null;
    labCode?: string;
    labName?: string;
    labTests?: any[];
    orderId?: number | null;
    labOrder?: {
      order_items?: any[];
      note?: string;
      order_time?: string;
      allow_duplicate_flag?: boolean;
      out_perform_div?: any;
      isOrderTime?: boolean;
      id?: any;
    };
    message?: string;
    price_claimable?: number;
    price_non_claimable?: number;
    price_total?: number;
    labGroupSearch?: string;
    labGroupItems?: any[];
    promptMessage?: any;
    duplicateLabDetected?: boolean;
    data_changed?: boolean;
  } | null;
  isStudentUser?: boolean;
  django?: any;
};

const userLabPermission = {
  config_LAB_BLOOD_BANK_LAB_DIVISION_CODES: [],
  config_QUE_ADVANCE_APPOINTMENT_DATE_BEFORE: 1,
};

export const StateInitial: State = {
  userLabPermission: userLabPermission,
  OrderCentralLabSequence: null,
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} }
  | { message: "CreatePatientAppointmentForBloodBank"; params: {} };

export type Data = {
  division?: number;
  masterLabDivisions?: any[];
};

export const DataInitial = {
  masterLabDivisions: [],
};

const ORDER_STATUS = [
  "",
  "APPOINTMENT",
  "PENDING",
  "PERFORMED",
  "CANCEL",
  "DRAFT",
  "PLANNING",
  "OFF",
] as const;

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const GetMaster: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["doctor", {}],
        ...(params.isOutPerformDiv ? [["divisionOpd", {}]] : []),
      ],
    },
  });

  if (!state.OrderCentralLabSequence || (!state.selectedEmr && !params.isNullEmr)) return;

  const [labDivision, labPermission] = await Promise.all([
    LabDivisionList.list({
      apiToken: controller.apiToken,
    }),
    UserPermissionView.post({
      apiToken: controller.apiToken,
      data: userLabPermission,
    }),
  ]);

  controller.data = {
    ...controller.data,
    masterLabDivisions: labDivision[0]?.items || [],
  };

  controller.setState(
    {
      userLabPermission: labPermission?.[0]
        ? {
            ...labPermission?.[0],
            config_LAB_BLOOD_BANK_LAB_DIVISION_CODES: JSON.parse(
              labPermission?.[0]?.config_LAB_BLOOD_BANK_LAB_DIVISION_CODES || "[]"
            ),
          }
        : userLabPermission,
      OrderCentralLabSequence: {
        ...state.OrderCentralLabSequence,
        sequenceIndex: "SelectLabTest",
        labDivisions: (controller.data.masterLabDivisions || []).map((item: any) => ({
          ...item,
          key: item.id,
          value: item.id,
          text: item.name,
        })),
        labCode: "",
        labName: "",
      },
      ...(!state.isStudentUser && {
        isStudentUser: state.selectedEncounter?.student_user === state.django?.user?.id,
      }),
    },
    () => {
      // เมื่อมีการกด edit มาจากรายการ lab tab order summary
      if (state.centralLabOrderEditId) {
        HandleGetDoctorLabOrder(controller, {
          ...params,
          orderId: state.centralLabOrderEditId,
        });
      } else {
        HandleEditId(controller, params);
      }
    }
  );
};

export const SelectLabTest: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.OrderCentralLabSequence || (!state.selectedEmr && !params.isNullEmr)) return;

  if (params.action === "search") {
    const centralLabTest = await CentralLabTestList.list({
      params: {
        lab_division:
          state.OrderCentralLabSequence.labDivision === "All Division"
            ? null
            : state.OrderCentralLabSequence.labDivision,
        lab_code: state.OrderCentralLabSequence.labCode,
        name: state.OrderCentralLabSequence.labName,
        only_blood_bank: params.isBloodBank || false,
        ...(Number.isInteger(state.selectedEncounter?.id)
          ? { encounter: state.selectedEncounter.id }
          : Number.isInteger(state.selectedEmr?.id)
          ? { emr: state.selectedEmr.id }
          : Number.isInteger(state.selectedProgressCycle?.id)
          ? { progression_cycle: state.selectedProgressCycle.id }
          : {}),
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      OrderCentralLabSequence: {
        ...state.OrderCentralLabSequence,
        labTests: centralLabTest[0]?.items || [],
      },
    });
  } else if (params.action === "add") {
    if (!params?.addedItem) return;

    const addedItem = params.addedItem;

    if (addedItem.lab_code.trim() == "") return;

    const labOrderItems = state.OrderCentralLabSequence?.labOrder?.order_items || [];

    const orderItem = {
      _id: null,
      lab_code: addedItem.lab_code,
      lab_speciality: addedItem.lab_speciality,
      name: addedItem.name,
      product: addedItem.product_id,
      specimen: addedItem.specimen,
      specimen_name: addedItem.specimen_name,
      specimen_time: moment().format("HH:mm"),
      lab_type_label: addedItem.lab_type_label,
      note: "",
      urgency: "ROUTINE", // To implement selecting urgency
      children: addedItem.children.map((item: any) => ({
        ...item,
        id: null,
        note: "",
      })),
    };

    labOrderItems.push(orderItem);

    estimate(controller, labOrderItems);
  } else if (params.action === "group_add") {
    const newItems: any[] = params.items;
    const labOrderItems = state.OrderCentralLabSequence?.labOrder?.order_items || [];

    newItems.forEach((addedItem: any) => {
      const orderItem = {
        _id: null,
        lab_code: addedItem.lab_code,
        name: addedItem.name,
        product: addedItem.product_id,
        specimen: addedItem.specimen,
        specimen_name: addedItem.specimen_name,
        specimen_time: moment().format("HH:mm"),
        lab_type_label: addedItem.lab_type_label,
        note: "",
        urgency: "ROUTINE", // To implement selecting urgency
        children: addedItem.children.map((item: any) => ({
          ...item,
          id: null,
          note: "",
        })),
      };

      labOrderItems.push(orderItem);
    });

    estimate(controller, labOrderItems);
  } else if (params.action === "delete") {
    const labOrderItems = state.OrderCentralLabSequence?.labOrder?.order_items || [];

    estimate(controller, labOrderItems);
  } else if (params.action === "edit") {
    const state = controller.getState();

    if (!params.selected) return console.log("No selected lab order");

    const [response, error, network] = await CentralLabOrderDetail.retrieve({
      pk: params.selected,
      apiToken: controller.apiToken,
    });

    const notAllowEdit = !checkAllowEditOrder({
      ...(response || {}),
      order_status: ORDER_STATUS[response.order_status],
      order_payment_status: response.payment_status_name,
    });

    if (notAllowEdit) {
      return;
    }

    const isOrderTime = response.is_out_perform_div || response.is_advance;

    controller.setState({
      OrderCentralLabSequence: {
        ...state.OrderCentralLabSequence,
        sequenceIndex: "SelectLabTest",
        labOrder: {
          ...response,
          isOrderTime,
          order_time: !response.is_advance ? null : response.order_time?.split(" ")?.[0],
        },
      },
    });

    estimate(controller, response.order_items);
  } else if (params?.action === "save" || params?.action === "confirm") {
    // controller.setState({
    //   loadingStatus: { ...state.loadingStatus, [params?.sequence]: true }
    // });

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const is_advance = !!state.OrderCentralLabSequence?.labOrder?.order_time;

    let orderItems = state.OrderCentralLabSequence?.labOrder?.order_items || [];

    if (params.isAdvanceAppointment && params.isBloodBank) {
      const otherLab = orderItems?.filter((item: any) => item.lab_speciality !== 104);
      const targetlab = orderItems?.filter((item: any) => item.lab_speciality === 104);

      if (targetlab?.length > 0) {
        // console.log("CreatePatientAppointmentForBloodBank: props", targetlab)
        controller.handleEvent({
          message: "CreatePatientAppointmentForBloodBank",
          params: {
            ...params,
            divisionId: params.performDivBloodBank,
            note: state.OrderCentralLabSequence?.labOrder?.note,
            order_items: targetlab || [],
            refreshPage: otherLab?.length > 0 ? false : true,
          },
        });
      }

      if (otherLab?.length === 0) {
        return;
      }

      orderItems = orderItems?.filter((item: any) => item.lab_speciality !== 104);
    }

    const [response, error] = await CentralLabOrderList.create({
      data: {
        action: "ORDER",
        allow_duplicate_flag:
          state.OrderCentralLabSequence?.labOrder?.allow_duplicate_flag || false,
        emr: state.selectedEmr?.id,
        encounter: state.selectedEncounter?.id,
        progression_cycle: state.selectedProgressCycle?.id,
        note: state.OrderCentralLabSequence?.labOrder?.note,
        order_items: orderItems,
        order_time: state.OrderCentralLabSequence?.labOrder?.order_time
          ? state.OrderCentralLabSequence?.labOrder?.order_time + "-00:00"
          : null,
        order_status: params.isAppointment ? 1 : 2,
        order_by: params.isAppointment ? params?.order_by : null,
        is_advance: is_advance,
        ...(params.isOutPerformDiv
          ? {
              is_out_perform_div: params.isOrderTime,
              out_perform_div: state.OrderCentralLabSequence?.labOrder?.out_perform_div || null,
              order_time: null,
              // ack_unavailable_lab: false,
              // nurse_status: "รอรับคำสั่ง",
              // order_payment_status: 1,
              // order_status: 1,
            }
          : {}),
        system: params.isBloodBank ? "bloodbank" : "",
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    // console.log(labOrder[1] ? labOrder[1] : labOrder[0]);

    // isStudentUser and Confirm Order
    if (params.approved_by_username && response) {
      (response?.order_list || []).forEach((item: any) => {
        SelectLabTest(controller, { ...params, action: "education_approve", orderId: item.id });
      });
    }

    let fullNameSplit = state.django?.user?.full_name.split(" ");
    if (response) {
      // approve all drug order
      state.doctorLabOrderList?.items
        ?.filter((item: any) => item?.need_approve === true)
        ?.forEach(async (item: any) => {
          if (
            state.selectedEmr?.doctor_name ===
              `${fullNameSplit?.[0]}${fullNameSplit?.[1]} ${fullNameSplit?.[2]}` &&
            item?.student_name
          ) {
            await SelectLabTest(controller, {
              ...params,
              action: "education_approve",
              approved_by_username: "",
              approved_by_password: "",
              orderId: item.id,
            });
          }
        });
    }

    HandleSaveSuccess(controller, { ...params, response, error });
  } else if (params.action === "education_approve") {
    // addprove order
    const [response, error] = await CentralLabOrderEducationApproveView.put({
      pk:
        params.orderId ||
        state.OrderCentralLabSequence?.orderId ||
        state.OrderCentralLabSequence?.labOrder?.id,
      data: {
        approved_by_username: params.approved_by_username,
        approved_by_password: params.approved_by_password,
        data_changed: params.data_changed,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (response) {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [`${params.cardKey}_${params.action}`]: response,
        },
      });

      HandleSaveSuccess(controller, { ...params, response, error });
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [`${params.cardKey}_${params.action}`]: { error: error, showMessage: false },
        },
      });
    }
  }
  if (params.action === "group_search") {
    const centralLabGroup = await CentralLabTemplateList.list({
      params: {
        name: state.OrderCentralLabSequence.labGroupSearch,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    controller.setState({
      OrderCentralLabSequence: {
        ...state.OrderCentralLabSequence,
        labGroupItems: centralLabGroup[0].items || [],
      },
    });
  } else if (params?.action === "edit_save") {
    // controller.setState({
    //   loadingStatus: { ...state.loadingStatus, [params?.sequence]: true }
    // });

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let tmpLabOrder: any = { ...state.OrderCentralLabSequence.labOrder };

    // console.log('OrderCentalLab edit_save tmpLabOrder.order_time: ', tmpLabOrder.order_time);
    const isAdvance = !!tmpLabOrder.order_time;

    delete tmpLabOrder["date"];

    // * order time ยังไม่ได้มีการเปลี่ยนแปลง
    if (!params.data_changed) {
      delete tmpLabOrder.order_time;
    } else if (tmpLabOrder.order_time) {
      tmpLabOrder.order_time = `${tmpLabOrder.order_time}-00:00`;
      tmpLabOrder.date = tmpLabOrder.order_time;
      const iso = beStringDateToDateObject(tmpLabOrder.order_time).toISOString();
      tmpLabOrder.date_iso = iso;
      tmpLabOrder.order_time_iso = iso;
    }

    const [response, error, network] = await CentralLabOrderDetail.update({
      data: {
        ...tmpLabOrder,
        is_out_perform_div: params.isOrderTime || false,
        action: "EDIT",
        order_status: params.isAppointment ? 1 : 2,
        // data_changed: params.dataChanged,
        is_advance: isAdvance,
        order_by: params.isAppointment ? params?.order_by : null,
      },
      pk: tmpLabOrder.id,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    // isStudentUser and Confirm Order
    if (params.approved_by_username && response) {
      SelectLabTest(controller, { ...params, action: "education_approve", orderId: response.id });
    }

    HandleSaveSuccess(controller, { ...params, response, error });
  } else if (params?.action === "cancel" && params.item) {
    let tmpLabOrder: any = { ...state.OrderCentralLabSequence.labOrder };

    delete tmpLabOrder["date"];
    delete tmpLabOrder["order_time"];

    const [response, error, network] = await DoctorOrderCancel.put({
      data: {
        ...tmpLabOrder,
        action: "REQUEST",
        order_status: "DRAFT",
        items: tmpLabOrder.order_items,
      },
      pk: params.item.id,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (response) {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.sequence]: response },
      });

      controller.handleEvent({
        message: "HandleRefreshEncounter" as any,
        params: {},
      });

      if (state.selectedEmr !== null || state.selectedProgressCycle !== null) {
        if (tmpLabOrder.id === params.item.id) {
          await controller.setState({
            OrderCentralLabSequence: {
              ...state.OrderCentralLabSequence,
              labOrder: {},
            },
          });
        }

        controller.handleEvent({
          message: "GetDoctorLabOrderList",
          params: {
            progression_cycle: state.selectedProgressCycle?.id || null,
            emr: state.selectedEmr?.id || null,
          },
        } as any);
      }
    }

    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: error },
      });
    }
  } else if (params?.action === "clear") {
    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: null },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  }
};

/* ------------------------------------------------------ */

/*                         Handle                         */

/* ------------------------------------------------------ */

const estimate = async (controller: any, labOrderItems: any) => {
  let state = controller.getState();

  if (labOrderItems.length <= 0) {
    controller.setState({
      OrderCentralLabSequence: {
        ...state.OrderCentralLabSequence,
        labOrder: { ...state.OrderCentralLabSequence.labOrder, order_items: labOrderItems },
        message: "",
        price_claimable: 0,
        price_non_claimable: 0,
        price_total: 0,
        duplicateLabDetected: false,
        promptMessage: "",
      },
    });
    return;
  }

  labOrderItems.forEach((object: any) => {
    delete object["cache"];
    delete object["code"];
  });

  // console.log(labOrderItems);
  const [response, error] = await CentralLabOrderEstimate.post({
    data: {
      order_id: state.OrderCentralLabSequence?.labOrder?.id || null,
      encounter: state.selectedEncounter?.id,
      order_items: labOrderItems,
      is_appointment: false,
    },
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  state = controller.getState();

  if (response) {
    controller.setState({
      OrderCentralLabSequence: {
        ...state.OrderCentralLabSequence,
        labOrder: { ...state.OrderCentralLabSequence.labOrder, order_items: labOrderItems },
        message: response?.claim_payload?.message || "",
        price_claimable: response?.price_claimable,
        price_non_claimable: response?.price_non_claimable,
        price_total: response?.price_total,
      },
    });
  }
};

const HandleEditId: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.OrderCentralLabSequence) {
    return;
  }

  let updatedOrder: any = {};

  if(params.editId){
    const result = await CentralLabOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.editId,
    });

    const detail = result[0] || {};

    const isOrderTime = detail.is_out_perform_div || detail.is_advance;

    updatedOrder = {
      labOrder: {
        ...state.OrderCentralLabSequence.labOrder,
        ...detail,
        id: params.editId,
        isOrderTime,
        order_time: detail.is_advance ? detail.order_time : null,
        out_perform_div: detail.out_perform_div,
      },
    };
  }

  const orderDoctor = await controller.handleEvent({
    message: "GetAppointmentOrderDoctor",
    params: { orderBy: updatedOrder.labOrder?.order_by },
  });

  controller.setState(
    {
      OrderCentralLabSequence: {
        ...state.OrderCentralLabSequence,
        ...updatedOrder,
        ...orderDoctor,
      },
    },
    () => {
      if (params.editId) {
        estimate(controller, updatedOrder?.labOrder?.order_items || []);
      }
    }
  );
};

const HandleGetDoctorLabOrder: Handler = async (controller, params) => {
  controller.setState({ centralLabOrderEditId: null });

  SelectLabTest(controller, {
    action: "edit",
    selected: params.orderId,
    isNullEmr: params.isNullEmr,
  });
};

const HandleSaveSuccess: Handler = (controller, params) => {
  const state = controller.getState();

  if (!state.OrderCentralLabSequence) {
    return;
  }

  const { response, error } = params;

  if (response) {
    const preLabList = [...(state.preOrderList || [])];

    if (response.order_list) {
      response.order_list.forEach((item: any) => {
        preLabList.push({ ...item, summary_detail: item.order_summary, type: "centrallaborder" });
      });
    }

    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: response },
      // loadingStatus: { ...state.loadingStatus, [params?.sequence]: false },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "SUCCESS",
      },
      OrderCentralLabSequence: {
        ...state.OrderCentralLabSequence,
        labOrder: {},
        labTests: [],
        labCode: "",
        labDivision: "All Division",
        labName: "",
        message: "",
        price_claimable: 0,
        price_non_claimable: 0,
        price_total: 0,
        duplicateLabDetected: false,
        promptMessage: "",
      },
      ...(params.isOutPerformDiv
        ? response.order_list && response.order_list.length > 1
          ? {
              preOrderList: [...preLabList],
            }
          : {
              preOrderList: [
                ...(state.preOrderList || []).filter((item: any) => item.id !== response.id),
                { ...response, type: "centrallaborder" },
              ],
            }
        : {}),
    });

    controller.handleEvent({
      message: "HandleRefreshEncounter" as any,
      params: {},
    });

    if (state.selectedEmr !== null || state.selectedProgressCycle !== null) {
      controller.handleEvent({
        message: "GetDoctorLabOrderList",
        params: {
          progression_cycle: state.selectedProgressCycle?.id || null,
          emr: state.selectedEmr?.id || null,
        },
      } as any);
    }

    params.onSuccess?.();
  }

  if (error) {
    if (
      error.code &&
      ["DUPLICATE_CENTRAL_LAB_ITEM_NAME", "DUPLICATE_SAME_ORDER"].includes(error.code[0])
    ) {
      controller.setState({
        // loadingStatus: { ...state.loadingStatus, [params?.sequence]: false },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
        OrderCentralLabSequence: {
          ...state.OrderCentralLabSequence,
          duplicateLabDetected: true,
          promptMessage: error.message,
        },
      });
    } else {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.sequence]: error,
          [params.cardKey]: error,
        },
        // loadingStatus: { ...state.loadingStatus, [params?.sequence]: false },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  }
};

export const checkAllowEditOrder = (data: {
  system: string;
  order_payment_status: string;
  order_status: string;
}) => {
  const notAllowEdit =
    data.system === "bloodbank"
      ? false
      : data.order_payment_status === "PAID" || ["PERFORMED", "CANCEL"].includes(data.order_status)
      ? true
      : false;

  return !notAllowEdit;
};
