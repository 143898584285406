import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { IntlProvider } from "react-intl";
import { localizationEN, localizationTH } from "react-lib/localization/localization";
import { DropdownProps } from "semantic-ui-react";

type LocalizationWrapperState = {
  selectedLanguage: "en" | "th";
};

function getDisplayName(WrappedComponent: any) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const LocalizationWrapper = (WrappedComponent: React.ComponentType<any>) => {
  return class extends React.Component<{}, LocalizationWrapperState> {

    static displayName = `localizationWrapper(${getDisplayName(WrappedComponent)})`;
    constructor(props: any) {
      super(props);
      this.state = { selectedLanguage: "en" };
      this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    }
    componentDidMount(): void {
      console.log("continue SetState user_lang", Cookies.get("user_lang"));
      let sysLang = window.navigator.language || "en-US";
      if (sysLang?.includes("th")) {
        sysLang = "th";
      } else {
        sysLang = "en";
      }
      Cookies.set("user_lang", sysLang);
      this.setState({ selectedLanguage: sysLang });
    }

    handleChangeLanguage = (e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
      this.setState({ selectedLanguage: data.value }); //(data?.value as "en" | "th")
      Cookies.set("user_lang", data?.value as string);
    };

    render() {
      return (
        <React.Fragment>
          <IntlProvider
            locale={this.state.selectedLanguage}
            messages={this.state.selectedLanguage?.includes("th") ? localizationTH : localizationEN}
          >
            <WrappedComponent
              userSelectedLanguage={this.state.selectedLanguage}
              onChangeLanguage={this.handleChangeLanguage}
            />
          </IntlProvider>
        </React.Fragment>
      );
    }
  };
};

export default LocalizationWrapper;
