import WasmController from "react-lib/frameworks/WasmController";
import PatientData from "react-lib/apps/common/PatientData";

// APIs
// Bill
import InvoiceItemByModeList from "issara-sdk/apis/InvoiceItemByModeList_apps_BIL";
import InvoiceItemByOrderList from "issara-sdk/apis/InvoiceItemByOrderList_apps_BIL";
import InvoiceItemList from "issara-sdk/apis/InvoiceItemList_apps_BIL";
import MasterAccountList from "issara-sdk/apis/MasterAccountList_apps_BILM";
import MasterEDCList from "issara-sdk/apis/MasterEDCList_apps_BILM";
import ReceiptTargetList from "issara-sdk/apis/ReceiptTargetList_apps_BILM";
import RequireApprovalCodeCheck from "issara-sdk/apis/RequireApprovalCodeCheck_apps_BIL";
import EDCTransactionAmountAPIView from "issara-sdk/apis/EDCTransactionAmountAPIView_apps_BIL";
import RequireInterfaceClaimCodeCheck from "issara-sdk/apis/RequireInterfaceClaimCodeCheck_apps_BIL";
import ReceiptCodeView from "issara-sdk/apis/ReceiptCodeView_apps_BIL";
import CouponCodeView from "issara-sdk/apis/CouponCodeView_apps_BIL";
import ReportStationSummaryView from "issara-sdk/apis/ReportStationSummaryView_apps_BIL";
import EncounterBillingList from "issara-sdk/apis/EncounterBillingList_apps_BIL";
import EncounterSuspensionViewM from "issara-sdk/apis/EncounterSuspensionView_apps_BILM";
import DiscountList from "issara-sdk/apis/DiscountList_apps_BIL";
import ReceiptCodeWithoutSessionView from "issara-sdk/apis/ReceiptCodeWithoutSessionView_apps_BIL";
import CreditNoteItemList from "issara-sdk/apis/CreditNoteItemList_apps_BIL";
import CreditNoteList from "issara-sdk/apis/CreditNoteList_apps_BIL";
import TransferDetail from "issara-sdk/apis/TransferDetail_apps_BIL";
import PrintReceipt from "issara-sdk/apis/PrintReceipt_apps_BIL";
import BillPendingDetail from "issara-sdk/apis/BillPendingDetail_apps_BIL";
import InvoiceExists from "issara-sdk/apis/InvoiceExists_apps_BIL";
import NewReportMedicalFeeDetailView from "issara-sdk/apis/NewReportMedicalFeeDetailView_apps_BIL";
import SendClaimItemListView from "issara-sdk/apis/SendClaimItemListView_apps_CLM";
import InvoiceItemPackageApplyView from "issara-sdk/apis/InvoiceItemPackageApplyView_apps_BIL";
import ARReceiptCodeWithoutSessionView from "issara-sdk/apis/ARReceiptCodeWithoutSessionView_apps_INF";
import ReceiptPoolCreate from "issara-sdk/apis/ReceiptPoolCreate_apps_BIL";
import ReceiptPoolDetail from "issara-sdk/apis/ReceiptPoolDetail_apps_BIL";
import PatientHasDebtView from "issara-sdk/apis/PatientHasDebtView_apps_BIL";

// CORE
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import ReportPrintTrackingResult from "issara-sdk/apis/ReportPrintTrackingResult_core";
import TaskTrackingList from "issara-sdk/apis/TaskTrackingList_core";
import ConstanceView from "issara-sdk/apis/ConstanceView_core";
import PatientCoverageList from "issara-sdk/apis/PatientCoverageList_core";
import EncounterLockerView from "issara-sdk/apis/EncounterLockerView_core";
import EncounterLockerCheckView from "issara-sdk/apis/EncounterLockerCheckView_core";
import PatientEncounterLockerStatusList from "issara-sdk/apis/PatientEncounterLockerStatusList_core";

// INF
import CommonBillTransactionView from "issara-sdk/apis/CommonBillTransactionView_apps_INF";
// #import BillingQueueList from "issara-sdk/apis/BillingQueueList_apps_BIL";
// NTF
import UserNotificationListView from "issara-sdk/apis/UserNotificationListView_apps_NTF";

// Serializer
import ReportStationSummarySerializer from "issara-sdk/types/ReportStationSummarySerializer_apps_BIL";

// Interface
import * as BillPayment from "./sequence/BillPayment";
import * as BillHistory from "./sequence/BillingHistory";
import * as MedicalFeeHistoryI from "./sequence/MedicalFeeHistory";
import * as InvoicesSummaryI from "./sequence/InvoicesSummary";
import * as FinancialAmountSetI from "./sequence/FinancialAmountSet";
import * as BillingDepositI from "./sequence/BillingDeposit";
import * as BillingDepositHistoryI from "./sequence/BillingDepositHistory";
import * as FinancialReport from "./sequence/FinancialReport";
import * as ClinicAnnualReport from "./sequence/ClinicAnnualReport";
import * as BillPaymentAr from "./sequence/BillPaymentAr";

import { CreateApiRequests } from "../common/CardPatientListInterface";

import { beToAd, formatDate } from "react-lib/utils/dateUtils";
import moment from "moment";
import { adToBe } from "react-lib/utils/dateUtils";
import DoctorOrderPendingList from "issara-sdk/apis/DoctorOrderPendingList_core";
import { bahttext } from "bahttext";
import { createPDFReceipt } from "react-lib/apps/HISV3/BIL/sequence/BillPayment";

import { mapOptions, SetErrorMessage } from "react-lib/apps/HISV3/common/CommonInterface";

import CONFIG from "config/config";

let toDate = moment();
let toYear = parseInt(toDate.format("YYYY")) + 543;

export type State = {
  django?: any;
  selectedEncounter?: any | null;
  patientData?: PatientData | null;
  invoiceItemByModes?: any;
  invoiceItemByOrders?: any;
  invoiceItemByItems?: any;
  invoiceHistory?: any;
  patientOrders?: any;
  refundOrders?: {
    data?: any;
    tracking?: any;
  };
  reportStationSummary?: ReportStationSummarySerializer;
  encounterSuspension?: {
    discount: boolean;
    coverage: boolean;
    discountList: any[];
  };
  taskTrackingList?: any[];
  bilReceiptCodeDetail?: {
    code: string;
    BIL_RECEIPT_SYSTEM_PREFIX: string;
    BIL_RECEIPT_PREFIX_SIZE: number;
    BIL_RECEIPT_RUNNING_SIZE: number;
  };
  patientEncounterLockerStatus?: any[];
  billPendingDetail?: any;
  faxClaimItemList?: any;
  commonBillTransaction?: Record<string, any>;
  medicalFeeFilter?: Partial<{
    division: "" | "ALL" | number;
    encounter: "" | "ALL" | number;
    endDate: string;
    isDate: boolean;
    isDivision: boolean;
    isEncounter: boolean;
    startDate: string;
    encounterOptions: any[];
  }>;
  billingFilterQueue?: {
    checkedHN?: boolean;
    checkedDivision?: boolean;
    checkedReceiptDate?: boolean;
    checkedStatus?: boolean;
    checkedCoverage?: boolean;
    checkedOPD?: boolean;
    checkedIPD?: boolean;
    checkedTelemed?: boolean;
    checkedOverdue?: boolean;
    division?: any;
    status?: any;
    coverage?: any;
    receiptStartDate?: any;
    receiptEndDate?: any;
    selectedPatient?: {
      id?: any;
      hn?: any;
      full_name?: any;
    };
  };
  billingQueuePagination?: {
    page?: any;
    offset?: any;
    limit?: any;
    totalPage?: any;
  };
  billingQueueList?: any[];
  billReportPrint?: Record<string, any>;
  billingPatientLanguage?: any;
  // InvoiceItemPackage
  invoiceItemPackageList?: any[];
  patientCoverageList?: Record<string, any>[];
  // ReceiptGroupSetting
  receiptGroupSetting?: {
    budgetYear?: any;
    status?: string;
    date?: any;
    receiptPoolData?: any[];
    selectData?: any;
  };

  billingLockNotificationList?: Record<string, any>[]
} & BillPayment.State &
  BillHistory.State &
  MedicalFeeHistoryI.State &
  InvoicesSummaryI.State &
  FinancialAmountSetI.State &
  BillingDepositI.State &
  BillingDepositHistoryI.State &
  FinancialReport.State &
  ClinicAnnualReport.State &
  BillPaymentAr.State;

export const StateInitial: State = {
  // invoiceItemByModes: [],
  // invoiceItemByOrders: [],
  // invoiceItemByItems: [],
  invoiceHistory: false,
  patientOrders: [],
  refundOrders: {
    data: [],
    tracking: {},
  },
  reportStationSummary: {},
  billingFilterQueue: {
    checkedHN: false,
    checkedDivision: false,
    checkedReceiptDate: true,
    checkedStatus: true,
    checkedCoverage: false,
    checkedOPD: false,
    checkedIPD: false,
    checkedTelemed: false,
    checkedOverdue: false,
    division: null,
    status: "WAIT_PAY",
    coverage: null,
    receiptStartDate: formatDate(moment()),
    receiptEndDate: formatDate(moment()),
    selectedPatient: {
      id: null,
      hn: "",
      full_name: "",
    },
  },
  billingQueuePagination: {
    page: 1,
    offset: 0,
    limit: 10,
    totalPage: 1,
  },
  billingQueueList: [],
  receiptGroupSetting: {
    budgetYear: toYear,
    status: "ทั้งหมด",
    date: formatDate(moment()),
  },
  // ...BillPayment.StateInitial,
  // ...BillHistory.StateInitial,
  // ...MedicalFeeHistoryI.StateInitial,
  // ...InvoicesSummaryI.StateInitial,
  // ...FinancialAmountSetI.StateInitial,
  // ...BillingDepositI.StateInitial,
  // ...BillingDepositHistoryI.StateInitial,
  // ...FinancialReport.StateInitial,
  // ...ClinicAnnualReport.StateInitial,
};

export type Event =
  | { message: "GetInvoiceItemByMode"; params: {} }
  | { message: "GetInvoiceItemByOrder"; params: {} }
  | { message: "GetInvoiceItemByItem"; params: {} }
  | { message: "GetPatientDoctorOrder"; params: {} }
  | { message: "GetCreditNoteItem"; params: {} }
  | { message: "HandleTransfer"; params: {} }
  | { message: "GetMasterDataPayMethod"; params: { idList: number[] } }
  | { message: "HandleSetPaymentMethodList"; params: { list: any[] } }
  | { message: "HandleChangePaymentMethodList"; params: { e: any; v: any } }
  | {
      message: "HandleKeyDownCouponNumber";
      params: { e: any; v: any; idList: number[] };
    }
  | { message: "GetReportStationSummary"; params: {} }
  | { message: "UpdateReportStationSummary"; params: {} }
  | { message: "BillingQueueAction"; params: {} }
  | { message: "GetMasterData"; params: {} }
  | { message: "BillingQueuePagination"; params: {} }
  | { message: "HandleGetEncounterSuspension"; params: { patientId: number } }
  | {
      message: "HandleUpdateEncounterSuspension";
      params: { type: "discount" | "coverage" };
    }
  | { message: "HandleRefreshTaskTracking"; params: {} }
  | {
      message: "HandlePostReceiptCode";
      params: { prefix: string; code: string; card: string };
    }
  | {
      message: "HandleGetReceiptCode";
      params: { prefix: string; code: string; card: string };
    }
  | { message: "GetBillPendingDetail"; params: {} }
  | { message: "GetPatientEncounterLockerStatus"; params: {} }
  | { message: "GetCommonBillTransaction"; params: {} }
  | { message: "HandlePrintMedicalFeeDetail"; params: {} }
  | { message: "HandleReportPrintTrackingResult"; params: { taskId: number } }
  | { message: "GetFexClaimDetail"; params: {} }
  | { message: "HandleUpdateFexClaim"; params: {} }
  | { message: "GetInvoiceItemPackageApply"; params: {} }
  | { message: "CreateInvoiceItemPackageApply"; params: {} }
  | { message: "GetPatientLanguage"; params: {} }
  | { message: "GetPatientCoverageList"; params: {} }
  | { message: "HandleSearchInvoiceItems"; params: {} }
  | { message: "GetReceiptPoolData"; params: {} }
  | { message: "EditReceiptPool"; params: {} }
  | {
      message: "UpdateEncounterLocker";
      params: { action: string; id?: number; onSuccess?: () => void };
    }
  | { message: "CheckEncounterLocker"; params: { ids?: number[] } }
  | { message: "HandleInitMedicalFeeFilter"; params: {} }
  | { message: "GetBillingLockNotifications"; params: {} }
  | BillPayment.Event
  | BillHistory.Event
  | MedicalFeeHistoryI.Event
  | InvoicesSummaryI.Event
  | FinancialAmountSetI.Event
  | BillingDepositI.Event
  | BillingDepositHistoryI.Event
  | FinancialReport.Event
  | ClinicAnnualReport.Event
  | BillPaymentAr.Event;

export type Data = {
  division?: number;
  device?: number;
} & BillPayment.Data &
  BillHistory.Data &
  BillingDepositI.Data &
  BillingDepositHistoryI.Data &
  FinancialReport.Data &
  ClinicAnnualReport.Data &
  BillPaymentAr.Data;

export const DataInitial = {
  ...BillPayment.DataInitial,
  ...BillHistory.DataInitial,
  ...BillingDepositI.DataInitial,
  ...BillingDepositHistoryI.DataInitial,
};

export const statusIPD = [
  "Admit",
  "Ward Admitted",
  "หมอมีคำสั่งให้ Discharge",
  "Cashier Request",
  "Cashier Discharged",
  "กลับบ้าน",
  "ส่งตัวไป OR",
];

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const GetInvoiceItemByMode: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.card]: "LOADING",
    },
  });

  const invByModeApis = {
    invByMode: {
      api: InvoiceItemByModeList.get,
      payload: {
        apiToken: controller.apiToken,
        params: {
          ...(params?.receiptId
            ? { receipt: params.receiptId }
            : {
                patient: state.selectedPatient?.id || params?.patientId,
                pending: true,
                strict_encounter_type: true,
              }),
        },
        extra: { device: controller.data.device },
      },
    },
  };

  const promises = CreateApiRequests(controller as any, {
    apis: invByModeApis,
    key: "invByModeApis",
  });

  const responses = await Promise.all(promises);

  const [invoiceItems] = responses;

  // * มีการ Abort request
  if (invoiceItems[1] === "canceled") {
    return;
  }

  if (invoiceItems[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.card]: "ERROR",
      },
    });

    if (params?.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.card]:
            invoiceItems[1]?.length > 2000 ? "Backend error with code 500" : invoiceItems[1],
        },
        invoiceItemByModes: [],
      });

      return;
    }

    controller.setState({ invoiceItemByModes: [] });

    return;
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.card]: "SUCCESS",
    },
  });

  controller.setState({ invoiceItemByModes: invoiceItems[0]?.items });
};

export const GetInvoiceItemByOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.card]: "LOADING",
    },
  });

  const filter = state.medicalFeeFilter || {};
  const division = formatParams(filter, "isDivision", filter.division);
  const startDate = formatParams(filter, "isDate", filter.startDate);
  const endDate = formatParams(filter, "isDate", filter.endDate);
  const encounter = formatParams(filter, "isEncounter", filter.encounter);

  const invByOrderApis = {
    invByOrder: {
      api: InvoiceItemByOrderList.get,
      payload: {
        apiToken: controller.apiToken,
        params: {
          ...(params?.receiptId
            ? { receipt: params.receiptId }
            : {
                patient: state.selectedPatient?.id || params?.patientId,
                pending: true,
                strict_encounter_type: true,
              }),
          division,
          encounter,
          end_date: endDate,
          start_date: startDate,
        },
        extra: {
          device: controller.data.device,
        },
      },
    },
  };

  const promises = CreateApiRequests(controller as any, {
    apis: invByOrderApis,
    key: "invByOrderApis",
  });

  const responses = await Promise.all(promises);

  const [invoiceItems] = responses;

  // * มีการ Abort request
  if (invoiceItems[1] === "canceled") {
    return;
  }

  if (invoiceItems[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.card]: "ERROR",
      },
    });

    if (params?.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.card]:
            invoiceItems[1]?.length > 2000 ? "Backend error with code 500" : invoiceItems[1],
        },
        invoiceItemByOrders: [],
      });

      return;
    }

    controller.setState({ invoiceItemByOrders: [] });

    return;
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.card]: "SUCCESS",
    },
  });

  controller.setState({ invoiceItemByOrders: invoiceItems[0]?.items });
};

export const GetInvoiceItemByItem: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.card]: "LOADING",
    },
  });

  const filter = state.medicalFeeFilter || {};

  const invoiceItems = await GetInvoiceItemList(controller, { ...params, filter });

  // * มีการ Abort request
  if (invoiceItems[1] === "canceled") {
    return;
  }

  if (invoiceItems[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.card]: "ERROR",
      },
    });

    if (params?.card) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params?.card]:
            invoiceItems[1]?.length > 2000 ? "Backend error with code 500" : invoiceItems[1],
        },
        invoiceItemByItems: [],
      });

      return;
    }

    controller.setState({ invoiceItemByItems: [] });

    return;
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.card]: "SUCCESS",
    },
  });

  if (!invoiceItems[1]) {
    controller.setState({ invoiceItemByItems: invoiceItems[0]?.items });
  }
};

export const HandleSearchInvoiceItems: Handler = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}`;

  await new Promise((resolve) => {
    controller.setState(
      { buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } },
      async () => {
        await controller.handleEvent({
          message: `GetInvoiceItemBy${params.orderBy}` as any,
          params,
        });

        resolve("DONE!!!");
      }
    );
  });

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" } });
};

export const GetPatientDoctorOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  const orders = await DoctorOrderPendingList.list({
    apiToken: controller.apiToken,
    params: {
      patient: state.selectedPatient?.id || params?.patientId,
    },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (orders[1]) {
    if (params?.card) {
      return controller.setState({
        patientOrders: [],
        errorMessage: {
          ...state.errorMessage,
          [params?.card]: orders[1].length > 2000 ? "Backend error with code 500" : orders[1],
        },
      });
    }
    return controller.setState({ patientOrders: [] });
  }

  GetPatientLanguage(controller, {});

  controller.setState({ patientOrders: orders[0]?.items });
};

export const GetCreditNoteItem: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "getCreditNoteItem") {
    const creditNote = await CreditNoteItemList.list({
      apiToken: controller.apiToken,
      params: {
        patient: state.selectedPatient?.id || params.patientId,
        pending: true,
      },
      extra: {
        division: controller.data.division,
        device: controller.data.device,
      },
    });

    if (creditNote[0]) {
      controller.setState({
        refundOrders: { ...state.refundOrders, data: creditNote[0].items },
      });
    }
  } else if (params.action === "getCreditNoteList") {
    let refundData = state.refundOrders;
    let itemsId: any[] = [];

    refundData?.data.forEach((item: any) => {
      itemsId.push(item.id);
    });

    const creditNote = await CreditNoteList.create({
      data: {
        patient: state.selectedPatient?.id,
        items: itemsId,
      },
      apiToken: controller.apiToken,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    if (creditNote[0]) {
      const reportView = await ReportPrintTrackingResult.retrieve({
        apiToken: controller.apiToken,
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
        params: {
          task_id: creditNote[0].task_id,
        },
      });

      controller.setState({
        refundOrders: {
          ...state.refundOrders,
          tracking: reportView[0],
        },
      });
    }
  } else if (params.action === "cancelCreditNote") {
    const reportView = await ReportPrintTrackingResult.retrieve({
      apiToken: controller.apiToken,
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
      params: {
        task_id: state.refundOrders?.tracking?.task_id,
        action: "CANCEL",
      },
    });

    if (reportView[0]) {
      controller.setState({
        refundOrders: {
          ...state.refundOrders,
          tracking: reportView[0],
        },
      });
    }

    GetCreditNoteItem(controller, { action: "getCreditNoteItem" });
  } else if (params.action === "printReceipt") {
    if (params.data["log"].print) {
      const printReceipt = await PrintReceipt.retrieve({
        pk: params.data["log"].receipt,
        params: {
          ignore_print_copy_flag: true,
          ...(CONFIG?.BIL_RECEIPT === "sapiens" && {
            only_data: true,
          }),
        },
        apiToken: controller.apiToken,
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      });

      if (CONFIG?.BIL_RECEIPT === "sapiens" && printReceipt[0]?.receipt) {
        let receipt = await createPDFReceipt(
          controller,
          {
            ...printReceipt[0]?.receipt,
            remark: `ออกแทนใบเสร็จเดิม ลบ${bahttext(printReceipt[0]?.receipt?.price)} (-${
              printReceipt[0]?.receipt?.price
            })`,
          },
          params
        );

        receipt?.open();
      }
    }

    controller.setState({
      refundOrders: {
        ...state.refundOrders,
        tracking: params.data,
      },
    });

    GetCreditNoteItem(controller, { action: "getCreditNoteItem" });
  }
};

export const GetMasterDataPayMethod: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.BillPaymentSequence) {
    return;
  }

  const getMasterAccount = MasterAccountList.list({
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });
  const getMasterEDC = MasterEDCList.list({
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });
  const getReceiptTarget = ReceiptTargetList.list({
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });
  const getRequireApproval = RequireApprovalCodeCheck.put({
    apiToken: controller.apiToken,
    patient_id: state.selectedPatient?.id,
    data: {
      items: params.idList,
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });
  const getEDCTransactionAmount = EDCTransactionAmountAPIView.post({
    apiToken: controller.apiToken,
    data: {
      items: params.idList,
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });
  const getRequireInterfaceClaim = RequireInterfaceClaimCodeCheck.put({
    apiToken: controller.apiToken,
    data: {
      items: params.idList,
      require_interface_claim_code: false,
    },
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  console.log("----------GetMasterDataPayMethod");

  const [
    [masterAccount],
    [masterEDC],
    [receiptTarget],
    [requiredApproval],
    [edcTransactionAmount],
    [requireInterfaceClaim],
  ] = await Promise.all([
    getMasterAccount,
    getMasterEDC,
    getReceiptTarget,
    getRequireApproval,
    getEDCTransactionAmount,
    getRequireInterfaceClaim,
  ]);

  controller.setState({
    BillPaymentSequence: {
      ...state.BillPaymentSequence,
      ...params.paymentData,
      masterAccountList: masterAccount?.items || [],
      masterEDCList: masterEDC?.items || [],
      receiptTargetList: receiptTarget?.items || [],
      requireApprovalCodeCheck: requiredApproval,
      edcTransactionAmount: edcTransactionAmount,
      requireInterfaceClaimCodeCheck: requireInterfaceClaim,
    },
  });
};
export const HandleSetPaymentMethodList: Handler = (controller, params) => {
  const state = controller.getState();

  if (!state.BillPaymentSequence) {
    return;
  }

  controller.setState(
    {
      BillPaymentSequence: {
        ...state.BillPaymentSequence,
        paymentMethodList: params.list,
      },
    },
    () =>
      controller.handleEvent({
        message: "RunSequence",
        params: { sequence: "BillPayment" },
      })
  );
};

export const HandleChangePaymentMethodList: Handler = (controller, params) => {
  const state = controller.getState();

  if (!state.BillPaymentSequence) {
    return;
  }

  const { e, v } = params;

  let [key, index] = v.name.split(".");
  const cloneArray: any[] = [...(state.BillPaymentSequence?.paymentMethodList || [])];
  index = Number(index);

  if (Number.isInteger(index)) {
    if (!Array.isArray(cloneArray[v.index][key])) {
      cloneArray[v.index][key] = [];
    }
    if (v.value.length >= 4) {
      v.value = v.value.slice(0, 4);
      const parent = (e.target.parentElement as HTMLElement).nextElementSibling;

      if (parent?.firstElementChild) {
        (parent.firstElementChild as HTMLInputElement).focus();
      }
    }

    cloneArray[v.index][key][index] = v.value;
  } else {
    cloneArray[v.index][key] = v.value;
  }

  if (key === "value" && Number(v.value) > Number(state.BillPaymentSequence.rawOweText)) {
    cloneArray[v.index][key] = "0";
    alert("ยอดเกินจำนวนค้างชำระ");
  }

  HandleSetPaymentMethodList(controller, { list: cloneArray });
};

export const HandleKeyDownCouponNumber: Handler = async (controller, params) => {
  const state = controller.getState();

  const { e, v, idList } = params;

  if (e.key === "Enter") {
    const couponCode = await CouponCodeView.get({
      apiToken: controller.apiToken,
      params: {
        code: e.target.value,
        items: idList.join(","),
        patient: state.selectedPatient?.id,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    const cloneArray: any[] = [...(state.BillPaymentSequence?.paymentMethodList || [])];
    const value = typeof couponCode[0]?.value !== "undefined" ? couponCode[0].value : "0.00";
    cloneArray[v.index].value = value;
    cloneArray[v.index].paymentNo = couponCode[1] ? "" : e.target.value;
    cloneArray[v.index].couponMessage = couponCode[1] ? "error" : "success";

    HandleSetPaymentMethodList(controller, { list: cloneArray });
  }
};

export const GetReportStationSummary: Handler = async (controller, params) => {
  const result = await ReportStationSummaryView.retrieve({
    apiToken: controller.apiToken,
    pk: controller.data.device,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (params.getReceiptPool) {
    GetReceiptPoolData(controller, params);
  }
  controller.setState({ reportStationSummary: result[0] || {} });
};

export const UpdateReportStationSummary: Handler = async (controller, params) => {
  const result = await ReportStationSummaryView.update({
    apiToken: controller.apiToken,
    pk: controller.data.device,
    data: params.data,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  params.callback?.({
    status: result[1] ? "error" : "success",
    message: result[1]
      ? result[1]
      : params.data.will_print
      ? "พิมพ์ใบสรุปยอดแล้ว"
      : "สรุปยอดและปิดกะสำเร็จ",
  });

  if (params.data.will_close && !result[1]) {
    GetReportStationSummary(controller, params);
  }
};

export const BillingQueueAction: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "initial") {
    controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["billingStatus", {}],
          ["division", {}],
          ["coverage", {}],
          ["EncounterStatus", {}],
        ],
      },
    });

    BillingQueueAction(controller, { ...params, action: "search" });
  } else if (params.action === "change_value") {
    let filterQueue = { ...state.billingFilterQueue };
    filterQueue = { ...filterQueue, [params.name]: params.value };
    // if (params.name === "checkedHN") {
    //   filterQueue = { ...filterQueue, selectedPatient: { id: null, hn: null, full_name: null } }
    // } else if (params.name === "checkedDivision") {
    //   filterQueue = { ...filterQueue, division: null }
    // } else if (params.name === "checkedReceiptDate") {
    //   filterQueue = { ...filterQueue, receiptStartDate: "", receiptEndDate: "" }
    // } else if (params.name === "checkedStatus") {
    //   filterQueue = { ...filterQueue, status: null }
    // } else if (params.name === "selectedPatient" && params.value?.id) {
    //   filterQueue = { ...filterQueue, checkedHN: true }
    // } else if (params.name === "receiptStartDate" && params.value) {
    //   filterQueue = { ...filterQueue, checkedReceiptDate: true }
    // } else if (params.name === "receiptEndDate" && params.value) {
    //   filterQueue = { ...filterQueue, checkedReceiptDate: true }
    // } else if (params.name === "status" && params.value) {
    //   filterQueue = { ...filterQueue, checkedStatus: true }
    // } else if (params.name === "division" && params.value) {
    //   filterQueue = { ...filterQueue, checkedDivision: true }
    // }
    if (params.name === "selectedPatient" && params.value?.id) {
      filterQueue = { ...filterQueue, checkedHN: true };
    } else if (params.name === "receiptStartDate" && params.value) {
      filterQueue = { ...filterQueue, checkedReceiptDate: true };
    } else if (params.name === "receiptEndDate" && params.value) {
      filterQueue = { ...filterQueue, checkedReceiptDate: true };
    } else if (params.name === "status" && params.value) {
      filterQueue = { ...filterQueue, checkedStatus: true };
    } else if (params.name === "division" && params.value) {
      filterQueue = { ...filterQueue, checkedDivision: true };
    }

    controller.setState({
      billingFilterQueue: filterQueue,
    });

    if (params.name === "selectedPatient") {
      BillingQueueAction(controller, { ...params, action: "search" });
    }
  } else if (params.action === "search") {
    const state = controller.getState();

    let encounterStatus = (state.masterOptions.EncounterStatus || []).map((items: any) => {
      return items.value;
    });
    let encounterStatusIPD = (state.masterOptions.EncounterStatusIPD || [])
      .filter((items: any) => statusIPD.includes(items.text))
      .map((items: any) => items.value);

    let filterParams = {
      hn: state.billingFilterQueue?.checkedHN
        ? state.billingFilterQueue?.selectedPatient?.hn || ""
        : "",
      // division: state.billingFilterQueue?.division,
      // status: state.billingFilterQueue?.status,
      // start_date: state.billingFilterQueue?.receiptStartDate,
      // end_date: state.billingFilterQueue?.receiptEndDate,
      division: state.billingFilterQueue?.checkedDivision
        ? state.billingFilterQueue?.division || ""
        : "",
      coverage: state.billingFilterQueue?.checkedCoverage
        ? state.billingFilterQueue?.coverage || ""
        : "",
      start_date: state.billingFilterQueue?.checkedReceiptDate
        ? state.billingFilterQueue?.receiptStartDate || ""
        : "",
      end_date: state.billingFilterQueue?.checkedReceiptDate
        ? state.billingFilterQueue?.receiptEndDate || ""
        : "",
      is_opd: state.billingFilterQueue?.checkedOPD,
      is_ipd: state.billingFilterQueue?.checkedIPD,
      is_telemed: state.billingFilterQueue?.checkedTelemed,
      only_outstanding: state.billingFilterQueue?.checkedOverdue,
      offset: params.offset || 0,
      limit: state.billingQueuePagination?.limit,
      ...(state.billingFilterQueue?.checkedStatus && {
        status: state.billingFilterQueue?.status.includes("ทั้งหมด")
          ? [...encounterStatus, ...encounterStatusIPD]
          : state.billingFilterQueue?.status,
      }),
    };

    const billing = await EncounterBillingList.list({
      apiToken: controller.apiToken,
      params: { ...filterParams },
      extra: {
        division: controller.data?.division || "",
        device: controller.data?.device || "",
      },
    });

    if (billing[1]) {
      if (billing[1].length > 2000) {
        return controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params?.card]: "Backend error with code 500",
          },
        });
      }
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: billing[1] },
      });
    }

    controller.setState({
      billingQueuePagination: {
        ...state.billingQueuePagination,
        page: params.offset ? state.billingQueuePagination?.page : 1,
        totalPage: Math.ceil(billing[0]?.total / state.billingQueuePagination?.limit),
      },
      billingQueueList:
        billing[0]?.items?.map((item: any) => ({
          ...item,
          visit_date: `${adToBe(moment(item.created).format("DD/MM/YYYY"))}  [${moment(
            item.created
          ).format("HH:mm")}]`,
        })) || [],
    });
  } else if (params.action === "select_encounter") {
    const encounter = await EncounterDetail.retrieve({
      pk: params.encounterId,
      apiToken: controller.apiToken,
    });

    if (encounter[1]) {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: encounter[1] },
      });
    }

    await controller.handleEvent({
      message: "SelectEncounter" as any,
      params: { encounter: encounter[0] },
    });
  }
};

export const BillingQueuePagination: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.billingQueueList || state.billingQueueList.length === 0) {
    return;
  }

  if (params?.action === "previous" && state.billingQueuePagination?.page > 1) {
    let offset = (state.billingQueuePagination?.page - 2) * state.billingQueuePagination?.limit;

    await BillingQueueAction(controller, { action: "search", offset });

    controller.setState({
      billingQueuePagination: {
        ...state.billingQueuePagination,
        page: state.billingQueuePagination?.page - 1,
        offset: offset,
      },
    });
  } else if (params?.action === "page_change" && params.value) {
    let page = state.billingQueuePagination?.page || 1;
    let offset = 0;

    if (params.value < 1) {
      page = 1;
      offset = 0;
    } else if (params.value > state.billingQueuePagination?.totalPage) {
      page = state.billingQueuePagination?.totalPage;
      offset = (state.billingQueuePagination?.totalPage - 1) * state.billingQueuePagination?.limit;
    } else {
      page = params.value;
      offset = (params.value - 1) * state.billingQueuePagination?.limit;
    }

    await BillingQueueAction(controller, { action: "search", offset });

    controller.setState({
      billingQueuePagination: {
        ...state.billingQueuePagination,
        page: page,
        offset: offset,
      },
    });
  } else if (
    params?.action === "next" &&
    state.billingQueuePagination?.page < state.billingQueuePagination?.totalPage
  ) {
    let offset = state.billingQueuePagination?.page * state.billingQueuePagination?.limit;

    await BillingQueueAction(controller, { action: "search", offset });

    controller.setState({
      billingQueuePagination: {
        ...state.billingQueuePagination,
        page: state.billingQueuePagination?.page + 1,
        offset: offset,
      },
    });
  }
};

export const HandleGetEncounterSuspension: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["activatePrivilege", {}],
        ["suspendPrivilege", {}],
      ],
    },
  });

  const promiseArr = ["coverage", "discount"].map((type) =>
    EncounterSuspensionViewM.retrieve({
      apiToken: controller.apiToken,
      pk: params.patientId || state.selectedPatient?.id,
      type,
    })
  );

  promiseArr.push(
    DiscountList.list({
      params: {
        patient: params.patientId || state.selectedPatient?.id,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    })
  );

  const [coverage, discount, oneTime] = await Promise.all(promiseArr);

  controller.setState({
    encounterSuspension: {
      discount: discount?.[0]?.suspended,
      coverage: coverage?.[0]?.suspended,
      discountList: oneTime[0]?.items || [],
    },
  });
};

export const HandleTransfer: Handler = async (controller, params) => {
  const state = controller.getState();

  const transferData = await TransferDetail.put({
    apiToken: controller.apiToken,
    data: {
      patientId: state.selectedPatient?.id,
      items: params.invoiceData.items,
      encounters: "",
    },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (transferData[0]) {
    GetInvoiceItemByMode(controller, params);
    GetInvoiceItemByOrder(controller, params);
    GetInvoiceItemByItem(controller, params);
  } else {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params?.card]: transferData[1],
      },
    });
  }
};

export const HandleUpdateEncounterSuspension: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params?.card}_SUSPENDED`]: "LOADING",
    },
  });

  const result = await EncounterSuspensionViewM.update({
    apiToken: controller.apiToken,
    pk: params.patientId,
    type: params.type,
    data: {
      remark: params.remark,
      suspend: params.suspend,
    },
  });

  if (result[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params?.card}_SUSPENDED`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params?.card]: result[1],
      },
    });
  } else {
    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params?.card}_SUSPENDED`]: "SUCCESS",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.card]: null,
        },
      },
      () =>
        HandleGetEncounterSuspension(controller, {
          patientId: params.patientId,
        })
    );

    params.onSuccess?.();
  }
};

export const HandleRefreshTaskTracking: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.selectedPatient?.id) {
    return;
  }

  const task = await TaskTrackingList.list({
    apiToken: controller.apiToken,
    params: {
      patient: state.selectedPatient?.id,
      task_type: "BIL_RECALCULATE|BIL_RECALCULATE_ENCOUNTER",
      status: "WAITING|IN_PROGRESS",
      active: true,
    },
  });

  controller.setState({
    taskTrackingList: task[0]?.items || [],
  });
};

export const HandlePostReceiptCode: Handler = async (controller, params) => {
  const state = controller.getState();

  const api = params.isAr ? ARReceiptCodeWithoutSessionView.post : ReceiptCodeView.post;
  const receiptCode = `${params.prefix || ""}-${params.code || ""}`;

  const result = await api({
    apiToken: controller.apiToken,
    data: { receipt_code: receiptCode },
    extra: {
      device: controller.data.device,
    },
  });

  if (result[1]) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.card]: result[1] },
    });
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.card]: null },
      successMessage: {
        ...state.successMessage,
        [params?.card]: "บันทึกสำเร็จ",
      },
    });

    HandleGetReceiptCode(controller, params);
  }
};

export const HandleGetReceiptCode: Handler = async (controller, params) => {
  const state = controller.getState();
  const api = params.isAr ? ARReceiptCodeWithoutSessionView.get : ReceiptCodeWithoutSessionView.get;

  const receiptCodeApis = {
    constance: {
      api: ConstanceView.get,
      payload: {
        apiToken: controller.apiToken,
        params: {
          list: [
            "BIL_RECEIPT_SYSTEM_PREFIX",
            "BIL_RECEIPT_PREFIX_SIZE",
            "BIL_RECEIPT_RUNNING_SIZE",
          ].join(","),
        },
        extra: {
          division: controller.data.division,
        },
      },
    },
    result: {
      api,
      payload: {
        apiToken: controller.apiToken,
        extra: {
          device: controller.data.device,
        },
      },
    },
  };

  const promises = CreateApiRequests(controller as any, {
    apis: receiptCodeApis,
    key: "receiptCodeApis",
  });

  const responses = await Promise.all(promises);

  const [constance, result] = responses;

  // * มีการ Abort request
  const isAbort = responses.some((res) => res[1] === "canceled");

  if (isAbort) {
    return;
  }
  if (result[0] && constance) {
    if (params.isAr) {
      controller.setState({
        bilReceiptCodeDetail: {
          ...state.bilReceiptCodeDetail,
          arReceipt: {
            ...(constance?.[0]?.result || {}),
            ...result?.[0],
            ...(params.isShift && {
              isShift: true,
            }),
          },
        },
      });
    } else {
      controller.setState({
        bilReceiptCodeDetail: {
          ...state.bilReceiptCodeDetail,
          code: result[0]?.receipt_code || "",
          station_name: result[0]?.station_name || "",
          ...(constance[0]?.result || {}),
        },
      });
    }
  } else if (CONFIG.BIL_AUTO_RUN_RECEIPT) {
    controller.setState({
      bilReceiptCodeDetail: {
        ...state.bilReceiptCodeDetail,
        code: "AUTO",
        ...(constance?.[0]?.result || {}),
      },
    });
  } else {
    controller.setState({
      bilReceiptCodeDetail: {
        ...state.bilReceiptCodeDetail,
      },
      errorMessage: {
        ...state.errorMessage,
        [params?.card]: (result[1]?.receipt_pool || result[1]) || null,
      },
    });
  }
};

export const GetPatientEncounterLockerStatus: Handler = async (controller, params) => {
  const state = controller.getState();

  const patientEnLockerApis = {
    enLockerStatus: {
      api: PatientEncounterLockerStatusList.get,
      payload: {
        apiToken: controller.apiToken,
        params: { only_locked: true },
        pk: state.selectedPatient?.id,
      },
    },
  };

  const promises = CreateApiRequests(controller as any, {
    apis: patientEnLockerApis,
    key: "patientEnLockerApis",
  });

  const responses = await Promise.all(promises);

  const [enLockerStatus] = responses;

  // * มีการ Abort request
  const isAbort = responses.some((res) => res[1] === "canceled");

  if (isAbort) {
    return;
  }

  controller.setState({
    patientEncounterLockerStatus: enLockerStatus[0]?.items || [],
  });
};

export const GetBillPendingDetail: Handler = async (controller, params) => {
  const state = controller.getState();

  const billingPendingApis = {
    billPending: {
      api: BillPendingDetail.retrieve,
      payload: {
        apiToken: controller.apiToken,
        pk: state.selectedPatient?.id,
      },
    },
    doctorOrder: {
      api: DoctorOrderPendingList.list,
      payload: {
        apiToken: controller.apiToken,
        params: {
          patient: state.selectedPatient?.id,
        },
      },
    },
    invoiceExist: {
      api: InvoiceExists.get,
      payload: {
        apiToken: controller.apiToken,
        params: {
          patient: state.selectedPatient?.id,
          status: "BILLED",
          type: "FULL_PAYMENT",
        },
      },
    },
  };

  const promises = CreateApiRequests(controller as any, {
    apis: billingPendingApis,
    key: "billingPendingApis",
  });

  const responses = await Promise.all(promises);

  const [[billPending], [doctorOrder], [invoiceExist]] = responses;

  // * มีการ Abort request
  const isAbort = responses.some((res) => res[1] === "canceled");

  if (isAbort) {
    return;
  }

  const hasDoctorOrder = doctorOrder?.items.find(
    (item: any) => item.message === "กำลังจัดเวชภัณฑ์" || item.message === "กำลังจัดยา"
  );

  controller.setState({
    billPendingDetail: {
      ...billPending,
      ...invoiceExist,
      hasDoctorOrder,
    },
  });
};

export const GetCommonBillTransaction: Handler = async (controller, params) => {
  const bill = await CommonBillTransactionView.get({
    apiToken: controller.apiToken,
    invoice_no: params.invoiceNo,
  });

  controller.setState({
    commonBillTransaction: bill[0] || {},
  });
};

export const HandlePrintMedicalFeeDetail: Handler = async (controller, params) => {
  const state = controller.getState();

  const report = await NewReportMedicalFeeDetailView.get({
    apiToken: controller.apiToken,
    params: {
      task_id: null,
      multiple: true,
      ...(params.receipt ? { receipt: params.receipt } : { patient: state.selectedPatient?.id }),
      pending: true,
      strict_encounter_type: true,
    },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  controller.setState({
    billReportPrint: { open: true, task_id: report[0]?.task_id || "" },
  });
};

export const HandleReportPrintTrackingResult: Handler = async (controller, params) => {
  const report = await ReportPrintTrackingResult.retrieve({
    apiToken: controller.apiToken,
    params: {
      task_id: params.taskId,
      ...(params.action ? { action: params.action } : { multiple: true }),
    },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (params.action === "CANCEL") {
    controller.setState({
      billReportPrint: { error: report[0]?.current_progress_description || "" },
    });
  } else {
    controller.setState({
      billReportPrint: {},
    });
  }
};

export const GetFexClaimDetail: Handler = async (controller, params) => {
  const state = controller.getState();

  const result = await SendClaimItemListView.get({
    apiToken: controller.apiToken,
    params: {
      data_type: params?.dataType,
      invoice_item: params?.invoiceItem,
      hn: params?.hn,
      bill_mode: params?.billMode,
    },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (result[0] && result[0].cov_setting_hos_send_claim === true) {
    // ตั้งค่า priority
    let faxClaimItem = result[0]?.items
      ?.sort((a: any, b: any) => (a.priority > b.priority ? 1 : -1))
      ?.filter((item: any) => item.hospital_send_claim !== false)
      ?.map((item: any, index: any) => {
        return {
          ...item,
          priority: index + 1,
          billMode: params?.billMode,
          hn: params?.hn,
          total_send_claim_price: item?.total_send_claim_price.replace(",", ""),
        };
      });

    controller.setState({
      faxClaimItemList: {
        ...result[0],
        items: faxClaimItem,
      },
    });

    if (faxClaimItem.length !== 0) {
      if (params?.dataType === "by_item") {
        GetInvoiceItemByItem(controller, params);
      } else if (params?.dataType === "by_mode") {
        GetInvoiceItemByMode(controller, params);
      }
    }
  }
};

export const HandleUpdateFexClaim: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.card]: "LOADING",
    },
  });

  let data: any = [];

  state.faxClaimItemList?.items
    ?.sort((a: any, b: any) => b.send_claim - a.send_claim)
    .forEach((item: any, index: number) => {
      if (params?.dataType === "by_item") {
        data.push({
          send_claim_item: item?.send_claim_item_id,
          encounter_coverage: item?.encounter_coverage,
          send_claim_value: item?.total_send_claim_price,
          priority: index + 1,
        });
      } else {
        data.push({
          bill_mode: item?.bill_mode_id,
          encounter_coverage: item?.encounter_coverage_id,
          send_claim_value: item?.total_send_claim_price,
          priority: index + 1,
        });
      }
    });

  const result = await SendClaimItemListView.post({
    apiToken: controller.apiToken,
    data: {
      data_type: params?.dataType,
      items: data,
    },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (result[0]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.card]: "SUCCESS",
      },
    });

    if (params?.dataType === "by_item") {
      GetFexClaimDetail(controller, params);
    } else if (params?.dataType === "by_mode") {
      GetFexClaimDetail(controller, {
        ...params,
        billMode: state.faxClaimItemList?.items?.[0]?.billMode,
        hn: state.faxClaimItemList?.items?.[0]?.hn,
      });
    }
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.card]: "ERROR",
      },
    });
  }
};

export const GetInvoiceItemPackageApply: Handler = async (controller, params) => {
  const result = await InvoiceItemPackageApplyView.get({
    apiToken: controller.apiToken,
    pk: params.invoiceItemId,
  });

  const state = controller.getState();

  controller.setState({
    invoiceItemPackageList: result?.[0]?.items || [],
    errorMessage: {
      ...state.errorMessage,
      [params?.card]: result[1] || null,
    },
  });
};

export const CreateInvoiceItemPackageApply: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params?.card}_SAVE`]: "LOADING",
    },
  });

  const result = await InvoiceItemPackageApplyView.post({
    apiToken: controller.apiToken,
    pk: params.invoiceItemId,
    data: params.data,
  });

  if (result[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params?.card}_SAVE`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params?.card]: result[1],
      },
    });
  } else {
    GetInvoiceItemByItem(controller, {});

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params?.card}_SAVE`]: "SUCCESS",
      },
    });
  }
};

export const GetPatientLanguage: Handler = async (controller, params) => {
  const state = controller.getState();

  const patient = state.selectedPatient;
  const _items = [];
  let value = "";

  if (patient?.real_name_th !== "") {
    _items.push({
      key: "TH",
      value: "TH",
      text: patient?.real_name_th,
    });
  }

  if (patient?.real_name_en !== "") {
    _items.push({
      key: "EN",
      value: "EN",
      text: patient?.real_name_en,
    });
  }
  if (patient?.real_name === patient?.real_name_en) {
    value = "EN";
  } else {
    value = "TH";
  }

  controller.setState({
    billingPatientLanguage: {
      options: _items,
      value,
    },
  });
};

export const GetPatientCoverageList: Handler = async (controller, params) => {
  const state = controller.getState();

  const [result] = await PatientCoverageList.list({
    apiToken: controller.apiToken,
    params: {
      patient: state.selectedPatient?.id,
      order_by_priority_and_id: true,
      limit: 200,
    },
  });

  controller.setState({
    patientCoverageList: result?.items || [],
  });
};

export const GetInvoiceItemList: Handler = async (controller, params) => {
  const state = controller.getState();

  const filter = params?.filter || {};
  const startDate = formatParams(filter, "isDate", filter.startDate);
  const endDate = formatParams(filter, "isDate", filter.endDate);
  const encounter = formatParams(filter, "isEncounter", filter.encounter);

  const invByItemApis = {
    invByItem: {
      api: InvoiceItemList.list,
      payload: {
        apiToken: controller.apiToken,
        params: {
          ...(params?.receiptId
            ? { receipt: params.receiptId }
            : {
                patient: state.selectedPatient?.id || params?.patientId,
                pending: true,
                strict_encounter_type: true,
              }),
          end_date: endDate,
          start_date: startDate,
          encounter,
        },
        extra: { device: controller.data.device },
      },
    },
  };

  const promises = CreateApiRequests(controller as any, {
    apis: invByItemApis,
    key: "invByItemApis",
  });

  const responses = await Promise.all(promises);

  const [invoiceItems] = responses;

  return invoiceItems as [unknown, unknown];
};

export const GetReceiptPoolData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.buttonKey]: "LOADING",
    },
  });

  const [receiptPoolRes, receiptPoolErr, receiptPoolNet] = await ReceiptPoolCreate.list({
    params: {
      receipt_requested_at: state.receiptGroupSetting?.date,
      status: state.receiptGroupSetting?.status,
    },
    apiToken: controller.apiToken,
    extra: { device: controller.data.device },
  });

  if (receiptPoolRes) {
    controller.setState({
      receiptGroupSetting: {
        ...state.receiptGroupSetting,
        receiptPoolData: receiptPoolRes?.items,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.buttonKey]: "SUCCESS",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.buttonKey]: "ERROR",
      },
    });
  }
};

export const EditReceiptPool: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    errorMessage: { ...state.errorMessage, [params.buttonKey]: null },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.buttonKey]: "LOADING",
    },
  });

  let receiptRequestedAt = !state.receiptGroupSetting?.selectData?.receipt_requested_at
    ? `${beToAd(state.receiptGroupSetting?.selectData?.date)?.format("YYYY-MM-DD")}T${
        state.receiptGroupSetting?.selectData?.time
      }`
    : state.receiptGroupSetting?.selectData?.receipt_requested_at || null;

  let data = {
    ...state.receiptGroupSetting?.selectData,
    receipt_requested_at: receiptRequestedAt,
    ...(!state.receiptGroupSetting?.selectData?.station && {
      station: params.station,
    }),
  };

  let api = state.receiptGroupSetting?.selectData?.id
    ? ReceiptPoolDetail.update
    : ReceiptPoolCreate.create;

  const [receiptPoolRes, receiptPoolErr, receiptPoolNet] = await api({
    data: data,
    apiToken: controller.apiToken,
    extra: { device: controller.data.device },
    ...(state.receiptGroupSetting?.selectData?.id && {
      pk: state.receiptGroupSetting?.selectData?.id,
    }),
  });

  if (receiptPoolRes) {
    controller.setState({
      receiptGroupSetting: {
        ...state.receiptGroupSetting,
        selectData: {},
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.buttonKey]: "SUCCESS",
      },
    });

    GetReceiptPoolData(controller, params);

    params.callback();
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.buttonKey]: receiptPoolErr || null },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.buttonKey]: "ERROR",
      },
    });
  }
};

export const UpdateEncounterLocker: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "LOADING" },
  });

  const ids: number[] = Array.isArray(params.id)
    ? params.id
    : [params.id || state.selectedEncounter?.id];

  const promiseArr = ids.map((id) =>
    EncounterLockerView.put({
      apiToken: controller.apiToken,
      data: { action: params.action },
      pk: id,
      extra: { division: controller.data.division },
    })
  );

  const response = await Promise.all(promiseArr);

  const isError = response.some((res) => res[1]);

  if (isError) {
    SetErrorMessage(controller, { ...params, error: response[0][1], errorKey: params.btnAction });

    return;
  }

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: null },
  });

  // Refresh เมื่ออยู่ในหน้าชำระเงิน
  if (state.selectedPatient?.id) {
    controller.handleEvent({ message: "GetPatientEncounterLockerStatus", params: {} });
  }

  if (params.action === "UNLOCK") {
    controller.handleEvent({ message: "GetBillingLockNotifications", params: {} });
  }

  params.onSuccess?.();
};

export const CheckEncounterLocker: Handler = async (controller, params) => {
  if (!CONFIG.ENABLE_ENCOUNTER_LOCKER) {
    // return false เมื่อไม่ใช้ feature ล็อคค่าใช้จ่าย
    return false;
  }

  const state = controller.getState();

  const ids: number[] = params?.ids ? params.ids : [state.selectedEncounter?.id];

  const promiseArr = ids.map((id) =>
    EncounterLockerCheckView.get({
      apiToken: controller.apiToken,
      pk: id,
      extra: { division: controller.data.division },
    })
  );

  const response = await Promise.all(promiseArr);

  const isError = response.some((res) => res[1]);

  if (isError) {
    controller.setState({
      errorMessage: { ...state.errorMessage, ModLockExpense: response.map((item) => item[1]) },
    });

    return true;
  }

  return false;
};

export const HandleInitMedicalFeeFilter: Handler = (controller, params) => {
  const currentDate = formatDate(moment());
  const filter = params.showFilter
    ? {
        division: "ALL" as const,
        encounter: "ALL" as const,
        endDate: currentDate,
        startDate: currentDate,
      }
    : {};

  controller.setState(
    {
      medicalFeeFilter: filter,
    },
    async () => {
      const invoiceItems = await GetInvoiceItemList(controller, { ...params });
      const items: Record<string, any>[] = invoiceItems[0]?.items || [];

      const encounterSet = new Set();
      const uniqueEncounterItems = items.filter((item) =>
        encounterSet.has(item.encounter) ? false : encounterSet.add(item.encounter)
      );

      const state = controller.getState();

      controller.setState({
        medicalFeeFilter: {
          ...state.medicalFeeFilter,
          encounterOptions: mapOptions(uniqueEncounterItems, "encounter", "encounter_number"),
        },
      });
    }
  );
};

export const GetBillEncounterStatus: Handler = async (controller) => {
  const state = controller.getState();

  const billingApis = {
    encounterLocker: {
      api: EncounterLockerCheckView.get,
      payload: {
        apiToken: controller.apiToken,
        pk: state.selectedEncounter?.id,
        extra: { division: controller.data.division },
      },
    },
    patientHasDebt: {
      api: PatientHasDebtView.get,
      payload: {
        apiToken: controller.apiToken,
        pk: state.selectedPatient?.id,
        extra: { division: controller.data.division },
      },
    },
  };

  return Promise.all(
    CreateApiRequests(controller as any, {
      apis: billingApis,
      key: "billingApis",
    })
  );
};

export const GetBillingLockNotifications: Handler = async (controller, params) => {
  const [constance] = await ConstanceView.get({
    apiToken: controller.apiToken,
    params: {
      list: ["NTF_ENCOUNTER_BILLING_LOCK_TOPIC_CODE"].join(","),
    },
    extra: {
      division: controller.data.division,
    },
  });

  const code = constance?.result?.NTF_ENCOUNTER_BILLING_LOCK_TOPIC_CODE;

  if(code){
    const [result] = await UserNotificationListView.list({
      apiToken: controller.apiToken,
      params: { topic_codes: code },
    });

    controller.setState({
      billingLockNotificationList: result?.items || [],
    });
  }
};

// Utils
const formatParams = (
  filter: Record<string, any>,
  isKey: string,
  input: any
): number | string | undefined => {
  if (!filter[isKey]) {
    return undefined;
  }

  return input === "ALL" ? undefined : input;
};