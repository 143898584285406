import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { FormGroup, FormField, Input, Dropdown, Checkbox, Button, Label } from "semantic-ui-react";

const CardProductInfoUX = (props: any) => {
  return (
    <div style={{ width: "100%", padding: "10px", height: "100%" }}>
      <div>
        <div style={{ fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0" }}>
          Add New Product
        </div>
      </div>
      <div className="ui divider"></div>
      <div className="ui form" style={{ padding: "0 10px" }}>
        <FormGroup inline={true}>
          <FormField
            className={props.showRequiredField?.code ? "error" : ""}
            inline={true}
            width={3}
          >
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <div style={{ disply: "flex" }}>
                <label style={{ fontWeight: "bold" }}>Code</label>
                <label style={{ color: "red", marginRight: "5px" }}>*</label>
              </div>
              <Input
                fluid={true}
                name="code"
                onChange={props.onChangeValue}
                style={{ width: "100%" }}
                value={props.productDetail?.code || ""}
              ></Input>
            </div>
          </FormField>
          <FormField
            className={props.showRequiredField?.name ? "error" : ""}
            inline={true}
            width={3}
          >
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <div style={{ display: "flex" }}>
                <label style={{ fontWeight: "bold", minWidth: "max-content" }}>Trade Name</label>
                <label style={{ color: "red", marginRight: "5px" }}>*</label>
              </div>
              <Input
                fluid={true}
                name="name"
                onChange={props.onChangeValue}
                style={{ width: "100%" }}
                value={props.productDetail?.name || ""}
              ></Input>
            </div>
          </FormField>
          <FormField
            className={
              props.showRequiredField?.base_unit && props.productDetail?.product_type === "DRUG"
                ? "error"
                : ""
            }
            inline={true}
            width={3}
          >
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <label style={{ fontWeight: "bold" }}>Unit</label>
                <label style={{ color: "red", marginRight: "5px" }}>
                  {props.productDetail?.product_type === "DRUG" ? "*" : ""}
                </label>
              </div>
              <Dropdown
                fluid={true}
                name="unit"
                onChange={props.onChangeValue}
                options={props.unitOptions || []}
                search={true}
                selection={true}
                style={{ width: "100%" }}
                value={props.productDetail?.unit || ""}
              ></Dropdown>
            </div>
          </FormField>
          <FormField
            className={props.showRequiredField?.dosage_form ? "error" : ""}
            inline={true}
            width={3}
          >
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <div style={{ display: "flex" }}>
                <label style={{ fontWeight: "bold" }}>Dosage</label>
                <label style={{ color: "red", marginRight: "5px" }}>
                  {props.productDetail?.product_type === "DRUG" ? "*" : ""}
                </label>
              </div>
              <Dropdown
                fluid={true}
                name="dosage_form"
                onChange={props.onChangeValue}
                options={props.dosageOptions || []}
                search={true}
                selection={true}
                style={{ width: "100%" }}
                value={props.productDetail?.dosage_form || ""}
              ></Dropdown>
            </div>
          </FormField>
          <FormField inline={true} width={4}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <label style={{ fontWeight: "bold", minWidth: "max-content" }}>Product Type</label>
              <label style={{ color: "red", marginRight: "5px" }}>*</label>
              <Dropdown
                fluid={true}
                name="product_type"
                onChange={props.onChangeValue}
                options={props.productTypeDrugSupplyOptions || []}
                selection={true}
                style={{ width: "100%" }}
                value={props.productDetail?.product_type || ""}
              ></Dropdown>
            </div>
          </FormField>
        </FormGroup>
      </div>
      <div
        className="ui form"
        style={{
          padding: "1em 10px 1em",
          backgroundColor: "#bbe7f1",
          display: props.type === "DRUG" ? "none" : "none",
        }}
      >
        <FormGroup className="noMargin" inline={true}>
          <FormField inline={true}>
            <label style={{ width: "105px" }}>Generic name</label>
          </FormField>
          <FormField inline={true} width={11}>
            <Input
              name="drug.generic_name"
              onChange={props.onChangeValue}
              value={props.productDetail?.drug?.generic_name || ""}
            ></Input>
          </FormField>
        </FormGroup>
      </div>
      <div className="ui form" style={{ width: "100%", marginBottom: "1em" }}>
        {props.subProductIngredients}
      </div>
      <div
        className="ui form"
        style={{
          margin: "-1.25rem 0 1rem",
          padding: "1rem",
          backgroundColor: "rgb(187, 231, 241)",
          display: props.type === "DRUG" ? "" : "none",
        }}
      >
        <FormGroup
          inline={true}
          style={{
            border: "1px solid rgba(34, 36, 38, 0.3)",
            padding: "1.5rem 1rem",
            margin: 0,
            position: "relative",
          }}
        >
          <FormField
            className={`${props.showRequiredField?.strength ? "error" : ""}`}
            inline={true}
            width={4}
          >
            <div style={{ display: "flex" }}>
              <label style={{ fontWeight: "bold", fontSize: ".92857143em" }}>Strength</label>
              <label style={{ color: "red" }}>*</label>
            </div>
            <Input
              fluid={true}
              name="strength"
              onChange={props.onChangeValue}
              value={props.productDetail?.strength || ""}
            ></Input>
          </FormField>
          <FormField inline={true} width={4}>
            <label>Container</label>
            <Input
              fluid={true}
              name="container"
              onChange={props.onChangeValue}
              value={props.productDetail?.container || ""}
            ></Input>
          </FormField>
          <FormField inline={true} width={4}>
            <label style={{ width: "min-content" }}>{"Pronunciation\n(Trade name)"}</label>
            <Input
              fluid={true}
              name="pronunciation_trade"
              onChange={props.onChangeValue}
              value={props.productDetail?.pronunciation_trade || ""}
            ></Input>
          </FormField>
          <FormField inline={true} style={{ padding: 0 }} width={4}>
            <label style={{ width: "9.5rem" }}>{"Pronunciation\n(Generic name)"}</label>
            <Input
              fluid={true}
              name="pronunciation"
              onChange={props.onChangeValue}
              value={props.productDetail?.pronunciation || ""}
            ></Input>
          </FormField>
          <label
            style={{
              position: "absolute",
              top: "-0.55em",
              backgroundColor: "rgb(187, 231, 241)",
              padding: "0 0.5rem",
              left: "0.5rem",
            }}
          >
            Display on label
          </label>
        </FormGroup>
        <FormGroup inline={true} style={{ margin: "1rem 0 1rem" }}>
          <FormField inline={true}>
            <Checkbox
              checked={props.productDetail?.is_outside_drug || false}
              label="เป็นยาที่ไม่มีใช้ในโรงพยาบาล (Outside drug)"
              name="is_outside_drug"
              onChange={props.onChangeValue}
            ></Checkbox>
          </FormField>
        </FormGroup>
        <FormGroup inline={true}>
          <FormField inline={true} width={5}>
            <label style={{ minWidth: "max-content" }}>Drug name format:</label>
            <Dropdown
              className="inline-label"
              fluid={true}
              name="drug_name_format"
              onChange={props.onChangeValue}
              options={props.drugNameFormatOptions}
              selection={true}
              style={{ width: "100%" }}
              value={props.productDetail?.drug_name_format || false}
            ></Dropdown>
          </FormField>
          <FormField inline={true} width={5}>
            <label style={{ minWidth: "max-content" }}>Custom generic as</label>
            <Input
              fluid={true}
              name="custom_generic"
              onChange={props.onChangeValue}
              value={props.productDetail?.custom_generic || ""}
            ></Input>
          </FormField>
        </FormGroup>
        <FormGroup inline={true} style={{ margin: "1rem 0 0" }}>
          <FormField inline={true} style={{ padding: "0 1rem 0 0" }} width={16}>
            <label style={{ minWidth: "5rem" }}>Drug name:</label>
            <Input
              className="left labeled"
              disabled={true}
              readOnly={true}
              style={{
                opacity: 1,
                border: "1px solid rgb(146, 226, 247)",
                borderRadius: ".28571429rem",
                backgroundColor: "white",
              }}
            >
              <Label
                basic={true}
                style={{
                  border: "none",
                  margin: "auto",
                  padding: "0 0.25rem 0 1rem",
                  fontWeight: "normal",
                }}
              >
                {props.drugLabelName}
              </Label>
              <input
                style={{ border: "none", opacity: 1, paddingLeft: "0.5rem" }}
                value={""}
              ></input>
            </Input>
          </FormField>
        </FormGroup>
        <FormGroup inline={true} style={{ margin: "1rem 0 0" }}>
          <FormField inline={true} width={4}>
            <label style={{ minWidth: "5rem" }}>Drug list</label>
            <Dropdown
              clearable={true}
              fluid={true}
              name="drug_list"
              onChange={props.onChangeValue}
              options={props.drugListingOptions}
              search={true}
              selection={true}
              style={{ width: "100%" }}
              value={props.productDetail?.drug_list ?? ""}
            ></Dropdown>
          </FormField>
        </FormGroup>
      </div>
      <div
        className="ui form"
        style={{
          padding: "0 10px",
          backgroundColor: "#bbe7f1",
          paddingTop: "1em",
          display: ["SUPPLY", "EQUIP"].includes(props.type || "") ? "" : "none",
        }}
      >
        <FormGroup inline={true}>
          <FormField inline={true}>
            <label style={{ width: "105px" }}>Mode</label>
          </FormField>
          <FormField
            className={props.showRequiredField?.mode ? "error" : ""}
            inline={true}
            width="11"
          >
            <Dropdown
              fluid={true}
              name="supply.mode"
              onChange={props.onChangeValue}
              options={props.modeOptions || []}
              selection={true}
              style={{ width: "100%" }}
              value={props.productDetail?.supply?.mode || ""}
            ></Dropdown>
          </FormField>
        </FormGroup>
        <FormGroup className="noMargin" inline={true}>
          <FormField inline={true}>
            <div style={{ display: "flex", width: "105px" }}>
              <label style={{ fontWeight: "bold" }}>Base unit</label>
              <label style={{ color: "red" }}>*</label>
            </div>
          </FormField>
          <FormField
            className={props.showRequiredField?.base_unit ? "error" : ""}
            inline={true}
            width={3}
          >
            <div style={{ width: "100%" }}>{props.baseUnitSearch}</div>
          </FormField>
          <FormField inline={true}>
            <div style={{ display: "flex", width: "75px", margin: "0 2.5rem" }}>
              <label style={{ fontWeight: "bold" }}>Stock unit</label>
              <label style={{ color: "red" }}>*</label>
            </div>
          </FormField>
          <FormField
            className={props.showRequiredField?.stock_unit ? "error" : ""}
            inline={true}
            width={3}
          >
            <div style={{ width: "100%" }}>{props.stockUnitSearch}</div>
          </FormField>
          <FormField inline={true}>
            <div style={{ display: "flex", width: "85px", margin: "0 2.5rem" }}>
              <label style={{ fontWeight: "bold" }}>Stock size</label>
              <label style={{ color: "red" }}>*</label>
            </div>
          </FormField>
          <FormField
            className={props.showRequiredField?.stock_size ? "error" : ""}
            inline={true}
            width={3}
          >
            <Input
              name="supply.stock_size"
              onChange={props.onChangeValue}
              onKeyDown={(e: any) => ["-", "e"].includes(e.key) && e.preventDefault()}
              type="number"
              value={props.productDetail?.supply?.stock_size || ""}
            ></Input>
          </FormField>
        </FormGroup>
      </div>
      <div
        className="ui form"
        style={{
          padding: "1em 10px 1em",
          backgroundColor: "#bbe7f1",
          display: ["SUPPLY", "EQUIP"].includes(props.type || "") ? "" : "none",
          marginBottom: "1em",
        }}
      >
        <FormGroup className="noMargin" inline={true}>
          <FormField inline={true}>
            <label style={{ width: "105px" }}>Manufacturer</label>
          </FormField>
          <FormField inline={true} width={12}>
            <div style={{ width: "100%" }}>{props.manufacturerSearch}</div>
          </FormField>
        </FormGroup>
      </div>
      <div className="ui form" style={{ padding: "0 10px" }}>
        <FormGroup inline={true}>
          <FormField inline={true}>
            <div style={{ display: "flex", width: "105px" }}>
              <label style={{ fontWeight: "bold" }}>Bill Mode</label>
              <label style={{ color: "red" }}>*</label>
            </div>
          </FormField>
          <FormField
            className={props.showRequiredField?.full_pricings?.[0]?.bill_mode ? "error" : ""}
            inline={true}
            width={6}
          >
            <Dropdown
              fluid={true}
              name="bill_mode"
              onChange={props.onChangeValue}
              options={props.billModeOptions || []}
              search={true}
              selection={true}
              style={{ width: "100%" }}
              value={props.productDetail?.bill_mode || ""}
            ></Dropdown>
          </FormField>
        </FormGroup>
        <FormGroup inline={true}>
          <FormField inline={true}>
            <div style={{ display: "flex", width: "105px" }}>
              <label style={{ fontWeight: "bold" }}>ราคาขายปกติ</label>
              <label style={{ color: "red" }}>*</label>
            </div>
          </FormField>
          <FormField
            className={props.showRequiredField?.full_pricings?.[0]?.price_normal ? "error" : ""}
            inline={true}
            width={3}
          >
            <Input
              fluid={true}
              name="price_normal"
              onChange={props.onChangeValue}
              onKeyDown={(e: any) => ["-", "e"].includes(e.key) && e.preventDefault()}
              style={{ width: "100%" }}
              type="number"
              value={props.productDetail?.price_normal || ""}
            ></Input>
          </FormField>
          <FormField className="hidden --hard" inline={true} width={1}></FormField>
          <FormField inline={true}>
            <label style={{ fontWeight: "bold", width: "75px", margin: "0 2.5rem" }}>
              ราคาพิเศษ
            </label>
          </FormField>
          <FormField className="hidden --hard" inline={true} width={1}></FormField>
          <FormField inline={true} width={3}>
            <Input
              fluid={true}
              name="price_special"
              onChange={props.onChangeValue}
              onKeyDown={(e: any) => ["-", "e"].includes(e.key) && e.preventDefault()}
              style={{ width: "100%" }}
              type="number"
              value={props.productDetail?.price_special || ""}
            ></Input>
          </FormField>
          <FormField className="hidden --hard" inline={true} width={1}></FormField>
          <FormField inline={true}>
            <label style={{ fontWeight: "bold", width: "85px", margin: "0 2.5rem" }}>
              ราคาพรีเมี่ยม
            </label>
          </FormField>
          <FormField className="hidden --hard" inline={true} width={1}></FormField>
          <FormField inline={true} width={3}>
            <Input
              fluid={true}
              name="price_premium"
              onChange={props.onChangeValue}
              onKeyDown={(e: any) => ["-", "e"].includes(e.key) && e.preventDefault()}
              style={{ width: "100%" }}
              type="number"
              value={props.productDetail?.price_premium || ""}
            ></Input>
          </FormField>
        </FormGroup>
        <FormGroup inline={true}>
          <FormField inline={true}>
            <label style={{ width: "105px" }}>ราคาชาวต่างชาติ</label>
          </FormField>
          <FormField inline={true} width={3}>
            <Input
              fluid={true}
              name="price_foreign"
              onChange={props.onChangeValue}
              onKeyDown={(e: any) => ["-", "e"].includes(e.key) && e.preventDefault()}
              style={{ width: "100%" }}
              type="number"
              value={props.productDetail?.price_foreign || ""}
            ></Input>
          </FormField>
          <FormField className="hidden --hard" inline={true} width={1}></FormField>
          <FormField inline={true}>
            <label style={{ width: "75px", margin: "0 2.5rem" }}>ราคาประกัน</label>
          </FormField>
          <FormField className="hidden --hard" inline={true} width={1}></FormField>
          <FormField inline={true} width={3}>
            <Input
              fluid={true}
              name="price_pledge"
              onChange={props.onChangeValue}
              onKeyDown={(e: any) => ["-", "e"].includes(e.key) && e.preventDefault()}
              style={{ width: "100%" }}
              type="number"
              value={props.productDetail?.price_pledge || ""}
            ></Input>
          </FormField>
        </FormGroup>
        <FormGroup inline={true}>
          <FormField inline={true}>
            <label style={{ width: "105px" }}>Max Discount</label>
          </FormField>
          <FormField inline={true} width={3}>
            <Input
              fluid={true}
              name="max_discount"
              onChange={props.onChangeValue}
              onKeyDown={(e: any) => ["-", "e"].includes(e.key) && e.preventDefault()}
              style={{ width: "100%" }}
              type="number"
              value={props.productDetail?.max_discount || ""}
            ></Input>
          </FormField>
          <FormField className="hidden --hard" inline={true} width={1}></FormField>
          <FormField inline={true}>
            <label style={{ width: "75px", margin: "0 2.5rem" }}>ราคาต้นทุน</label>
          </FormField>
          <FormField className="hidden --hard" inline={true} width={1}></FormField>
          <FormField inline={true} width={3}>
            <Input
              fluid={true}
              name="remark"
              onChange={props.onChangeValue}
              style={{ width: "100%" }}
              value={props.productDetail?.remark || ""}
            ></Input>
          </FormField>
        </FormGroup>
        <FormGroup inline={true}>
          <FormField inline={true}>
            <div style={{ display: "flex", width: "105px" }}>
              <label style={{ fontWeight: "bold" }}>วันที่เริ่มต้น</label>
              <label style={{ color: "red" }}></label>
            </div>
          </FormField>
          <FormField inline={true} width={3}>
            <div style={{ width: "100%" }}>{props.startDate}</div>
          </FormField>
          <FormField className="hidden --hard" inline={true} width={1}></FormField>
          <FormField inline={true}>
            <div style={{ display: "flex", width: "75px", margin: "0 2.5rem" }}>
              <label style={{ fontWeight: "bold" }}>วันที่สิ้นสุด</label>
              <label style={{ color: "red" }}></label>
            </div>
          </FormField>
          <FormField className="hidden --hard" inline={true} width={1}></FormField>
          <FormField inline={true} width={3}>
            <div style={{ width: "100%" }}>{props.endDate}</div>
          </FormField>
        </FormGroup>
      </div>
      <div className="ui form" style={{ marginTop: "2rem", padding: "0 10px" }}>
        <FormGroup inline={true}>
          <FormField>
            <Checkbox
              checked={props.productDetail?.active_flag || ""}
              label="Active"
              name="active_flag"
              onChange={props.onChangeValue}
            ></Checkbox>
          </FormField>
          <div className="field inline" style={{ flex: 1 }}></div>
          <FormField>
            <div>{props.buttonSave}</div>
          </FormField>
          <FormField>
            <Button color="red" onClick={props.onCancel}>
              ยกเลิก
            </Button>
          </FormField>
        </FormGroup>
      </div>
    </div>
  );
};

CardProductInfoUX.displayName = "CardProductInfoUX";
export default React.memo(CardProductInfoUX);

export const screenPropsDefault = {};

/* Date Time : Mon Oct 28 2024 15:38:34 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Add New Product"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 8,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.code ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 8,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.name ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 179,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{disply: \"flex\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 180,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",justifyContent: \"center\",alignItems: \"center\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\",width: \"100%\",alignItems: \"center\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 179,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "code"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.code || \"\""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Input",
      "parent": 180,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.name || \"\""
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Dropdown",
      "parent": 181,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.unitOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.unit || \"\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Dropdown",
      "parent": 20,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "product_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.productTypeDrugSupplyOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.product_type || \"\""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Code"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\",marginRight:\"5px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Trade Name"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\",marginRight:\"5px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.productDetail?.product_type===\"DRUG\"?\"*\":\"\""
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\",marginRight:\"5px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "Product Type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\",}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\",marginRight:\"5px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.subProductIngredients"
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginBottom: \"1em\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 48,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.full_pricings?.[0]?.price_normal? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",width: \"105px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคาขายปกติ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคาพิเศษ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",width: \"75px\", margin: \"0 2.5rem\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคาพรีเมี่ยม"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"85px\", margin: \"0 2.5rem\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Input",
      "parent": 50,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "price_normal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.price_normal || \"\""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Input",
      "parent": 52,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "price_special"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.price_special || \"\""
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Input",
      "parent": 54,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "price_premium"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.price_premium || \"\""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคาชาวต่างชาติ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"105px\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคาประกัน"
        },
        "style": {
          "type": "code",
          "value": "{width: \"75px\", margin: \"0 2.5rem\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Max Discount"
        },
        "style": {
          "type": "code",
          "value": "{width: \"105px\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคาต้นทุน "
        },
        "style": {
          "type": "code",
          "value": "{width: \"75px\", margin: \"0 2.5rem\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 66,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "price_foreign"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.price_foreign || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Input",
      "parent": 68,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "price_pledge"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.price_pledge || \"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Input",
      "parent": 70,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "max_discount"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.max_discount || \"\""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Input",
      "parent": 72,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.remark || \"\""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 81,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 81,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 81,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 81,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",width: \"105px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",width: \"75px\", margin: \"0 2.5rem\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เริ่มต้น"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สิ้นสุด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "label",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\", padding: \"0 10px\"}"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormGroup",
      "parent": 94,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormField",
      "parent": 95,
      "props": {
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 95,
      "props": {
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 95,
      "props": {
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field inline"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Checkbox",
      "parent": 96,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.productDetail?.active_flag || \"\""
        },
        "label": {
          "type": "value",
          "value": "Active"
        },
        "name": {
          "type": "value",
          "value": "active_flag"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Button",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 48,
      "props": {
        "className": {
          "type": "value",
          "value": "hidden --hard"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 48,
      "props": {
        "className": {
          "type": "value",
          "value": "hidden --hard"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 48,
      "props": {
        "className": {
          "type": "value",
          "value": "hidden --hard"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormField",
      "parent": 48,
      "props": {
        "className": {
          "type": "value",
          "value": "hidden --hard"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "hidden --hard"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "hidden --hard"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormField",
      "parent": 64,
      "props": {
        "className": {
          "type": "value",
          "value": "hidden --hard"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 64,
      "props": {
        "className": {
          "type": "value",
          "value": "hidden --hard"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 81,
      "props": {
        "className": {
          "type": "value",
          "value": "hidden --hard"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 81,
      "props": {
        "className": {
          "type": "value",
          "value": "hidden --hard"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 10px 1em\", backgroundColor: \"#bbe7f1\", display: props.type === \"DRUG\" ? \"none\": \"none\" }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormGroup",
      "parent": 114,
      "props": {
        "className": {
          "type": "value",
          "value": "noMargin"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "Generic name "
        },
        "style": {
          "type": "code",
          "value": "{width: \"105px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Input",
      "parent": 121,
      "props": {
        "name": {
          "type": "value",
          "value": "drug.generic_name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.drug?.generic_name || \"\""
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "FormGroup",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "FormField",
      "parent": 128,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "FormField",
      "parent": 128,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.full_pricings?.[0]?.bill_mode ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 129,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",width: \"105px\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "label",
      "parent": 131,
      "props": {
        "children": {
          "type": "value",
          "value": "Bill Mode"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:  \"bold\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "label",
      "parent": 131,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "Dropdown",
      "parent": 130,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "bill_mode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.billModeOptions|| []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.bill_mode || \"\""
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\",backgroundColor: \"#bbe7f1\", paddingTop: \"1em\", display: [\"SUPPLY\",\"EQUIP\"].includes(props.type || \"\") ? \"\": \"none\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormGroup",
      "parent": 135,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormGroup",
      "parent": 135,
      "props": {
        "className": {
          "type": "value",
          "value": "noMargin"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 136,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.mode? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "11"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormField",
      "parent": 137,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 137,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.base_unit? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 137,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 137,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.stock_unit? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 137,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormField",
      "parent": 137,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.stock_size? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": "Mode"
        },
        "style": {
          "type": "code",
          "value": "{width: \"105px\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Dropdown",
      "parent": 140,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "supply.mode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.modeOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.supply?.mode || \"\""
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",width: \"105px\"}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",width: \"75px\", margin: \"0 2.5rem\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width: \"85px\", margin: \"0 2.5rem\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "Base unit"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:  \"bold\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "label",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "Stock unit"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "label",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "label",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "Stock size"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "code",
          "value": "props.baseUnitSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 144,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stockUnitSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Input",
      "parent": 146,
      "props": {
        "name": {
          "type": "value",
          "value": "supply.stock_size"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.supply?.stock_size|| \"\""
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 10px 1em\",backgroundColor: \"#bbe7f1\", display: [\"SUPPLY\",\"EQUIP\"].includes(props.type || \"\") ? \"\": \"none\", marginBottom: \"1em\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormGroup",
      "parent": 165,
      "props": {
        "className": {
          "type": "value",
          "value": "noMargin"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 166,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormField",
      "parent": 166,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "Manufacturer"
        },
        "style": {
          "type": "code",
          "value": "{width: \"105px\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 168,
      "props": {
        "children": {
          "type": "code",
          "value": "props.manufacturerSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "label",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": "Dosage"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "label",
      "parent": 173,
      "props": {
        "children": {
          "type": "code",
          "value": "props.productDetail?.product_type===\"DRUG\"?\"*\":\"\""
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\",marginRight:\"5px\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "FormField",
      "parent": 8,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.dosage_form? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Dropdown",
      "parent": 182,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "dosage_form"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.dosageOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.dosage_form || \"\""
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormField",
      "parent": 8,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.base_unit && props.productDetail?.product_type===\"DRUG\" ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",width: \"100%\"}"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",width: \"100%\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 178,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",width: \"100%\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",width: \"100%\"}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"-1.25rem 0 1rem\", padding: \"1rem\", backgroundColor: \"rgb(187, 231, 241)\", display: props.type === \"DRUG\"? \"\": \"none\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormGroup",
      "parent": 183,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{border: \"1px solid rgba(34, 36, 38, 0.3)\", padding: \"1.5rem 1rem\", margin: 0, position: \"relative\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 184,
      "props": {
        "className": {
          "type": "code",
          "value": "`${props.showRequiredField?.strength ? \"error\" : \"\"}`"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "Input",
      "parent": 187,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "pronunciation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.pronunciation || \"\""
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "Input",
      "parent": 186,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "pronunciation_trade"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.pronunciation_trade || \"\""
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "Input",
      "parent": 185,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "strength"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.strength || \"\""
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "label",
      "parent": 187,
      "props": {
        "children": {
          "type": "code",
          "value": "\"Pronunciation\\n(Generic name)\""
        },
        "style": {
          "type": "code",
          "value": "{width: \"9.5rem\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "code",
          "value": "\"Pronunciation\\n(Trade name)\""
        },
        "style": {
          "type": "code",
          "value": "{width: \"min-content\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": "Display on label"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", top: \"-0.55em\", backgroundColor: \"rgb(187, 231, 241)\", padding: \"0 0.5rem\", left: \"0.5rem\"}"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "label",
      "parent": 195,
      "props": {
        "children": {
          "type": "value",
          "value": "Strength"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \".92857143em\"}"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "label",
      "parent": 195,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "FormGroup",
      "parent": 183,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"1rem 0 1rem\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "FormField",
      "parent": 198,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "Checkbox",
      "parent": 199,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.productDetail?.is_outside_drug || false"
        },
        "label": {
          "type": "value",
          "value": "เป็นยาที่ไม่มีใช้ในโรงพยาบาล (Outside drug)"
        },
        "name": {
          "type": "value",
          "value": "is_outside_drug"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "label",
      "parent": 201,
      "props": {
        "children": {
          "type": "value",
          "value": "Container"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "Input",
      "parent": 201,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "container"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.container || \"\""
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "FormGroup",
      "parent": 183,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"1rem 0 0\"}"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "FormField",
      "parent": 204,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 1rem 0 0\"}"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "Input",
      "parent": 205,
      "props": {
        "className": {
          "type": "value",
          "value": "left labeled"
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1, border: \"1px solid rgb(146, 226, 247)\", borderRadius: \".28571429rem\", backgroundColor: \"white\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "Label",
      "parent": 206,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "code",
          "value": "props.drugLabelName"
        },
        "style": {
          "type": "code",
          "value": "{border: \"none\", margin: \"auto\", padding: \"0 0.25rem 0 1rem\", fontWeight: \"normal\"}"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "input",
      "parent": 206,
      "props": {
        "style": {
          "type": "code",
          "value": "{border: \"none\", opacity: 1, paddingLeft: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "\"\""
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "FormGroup",
      "parent": 183,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"1rem 0 0\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "FormField",
      "parent": 209,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "Dropdown",
      "parent": 210,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_list"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.drugListingOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.drug_list?? \"\""
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "label",
      "parent": 210,
      "props": {
        "children": {
          "type": "value",
          "value": "Drug list"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"5rem\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "FormGroup",
      "parent": 183,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "FormField",
      "parent": 213,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "label",
      "parent": 214,
      "props": {
        "children": {
          "type": "value",
          "value": "Drug name format:"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Dropdown",
      "parent": 214,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_name_format"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.drugNameFormatOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.drug_name_format|| false"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "FormField",
      "parent": 213,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": null,
      "id": 218,
      "name": "label",
      "parent": 217,
      "props": {
        "children": {
          "type": "value",
          "value": "Custom generic as"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "Input",
      "parent": 217,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "custom_generic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.productDetail?.custom_generic||\"\""
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "label",
      "parent": 205,
      "props": {
        "children": {
          "type": "value",
          "value": "Drug name: "
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"5rem\"}"
        }
      },
      "seq": 206,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardProductInfoUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
