import React, {
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  useRef,
} from "react";
import { Button, InputOnChangeData } from "semantic-ui-react";

// UX
import CardAppointmentListUX from "./CardAppointmentListUX";
import CardPatientAppointment from "react-lib/apps/Scheduling/CardPatientAppointment";
import CardAppointmentSummary from "react-lib/apps/Scheduling/CardAppointmentSummary";
import AppointmentDashboard from "react-lib/apps/IsHealth/APP/AppointmentDashboard";

// Utils
import {
  formatDate,
  beStringToDateObject,
  formatADtoBEString,
} from "react-lib/utils/dateUtils";

// CONFIG
import CONFIG from "../../../../config/config";
import { useIntl } from "react-intl";

type CardAppointmentListHandle = {
  setTabName: (tabName: string) => void;
};




const CardAppointmentList = React.forwardRef<CardAppointmentListHandle, any>(
  (props, forwardedRef) => {
    // const CardAppointmentList = (props: any) => {
    const intl = useIntl();
    const MENU_ITEMS = [
      ["appointment-list", intl.formatMessage({id: "นัดหมายวันนี้"})],
      ["patient-appointment", intl.formatMessage({id: "ทำนัดหมาย"})],
      ["appointment-summary", intl.formatMessage({id: "สรุปนัดหมาย"})],
      ["listmybplus", intl.formatMessage({id: "นัดหมาย Telemed"})],
      ["dash-appointment", intl.formatMessage({id: "Dashboard นัดหมาย"})],
    ];

    const TabActive = MENU_ITEMS.filter(
      ([name]) => !CONFIG.V3_HIDDEN_TAB_LIST.includes(name)
    );
    
    const TabPatientApp = TabActive.find(
      ([name]) => name === "patient-appointment"
    )?.[0];
 
    const TabInitial = TabPatientApp || TabActive?.[0] || "";

    const [tab, setTab] = useState(TabInitial);
    const [hn, setHn] = useState("");
    const [name, setName] = useState("");
    const [dob, setDob] = useState("");
    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const [division, setDivision] = useState(props.selectedDivision?.id);
    const [doctor, setDoctor] = useState("");
    const [divisionOptions, setDivisionOptions] = useState([]);
    const [filterPatientListMyBplus, setFilterPatientListMyBplus] = useState();
    const goToCreateAppointmentRef = useRef<any>(null);



    React.useImperativeHandle(forwardedRef, () => ({
      // start() has type inferrence here
      setTabName(tabName: string) {
        setTab(tabName);
      },
    }));

    useEffect(() => {
      //item.patient_dob (YYYY-MM-DD) AD
      //dob ( DD/MM/YYYY) BE
      let dateSearch = beStringToDateObject(dob);
      console.log("dob: ", dob);
      console.log("dateSearch: ", dateSearch);

      let filter = (props.patientListMyBplus || [])
        ?.map((item: any) => {
          let app = item.appointment_datetime?.split(" ");

          return {
            ...item,
            appointment_date: app?.[0] ? formatADtoBEString(app?.[0]) : "",
            appointment_time: app?.[1],
            patient_dob_be: formatADtoBEString(item.patient_dob),
          };
        })
        ?.filter((item: any) => {
          if (item.patient_dob) {
            let patient_dob = new Date(item.patient_dob);
            if (dob && dateSearch) {
              if (
                patient_dob?.getDate() === dateSearch?.getDate() &&
                patient_dob?.getMonth() === dateSearch?.getMonth() &&
                patient_dob?.getFullYear() === dateSearch?.getFullYear()
              ) {
                // ตรวจแล้วตรงกัน
                return true;
              } else {
                // ตรวจแล้วไม่ตรง
                return false;
              }
            } else {
              // ไม่ filter dob ดังนั้นไม่กรองออก
              return true;
            }
          } else {
            // ไม่มีข้อมูลวันเกิด ไม่กรองออก
            return true;
          }

          return false;
        }); // dob
      console.log("filter: ", filter);
      setFilterPatientListMyBplus(filter);
    }, [props.patientListMyBplus]);

    useEffect(() => {
      setDivisionOptions(props.divisionOptions);
    }, [props.divisionList]);

    useEffect(() => {
      console.log(" props.goToCreateAppointment", props.goToCreateAppointment);
      if (props.goToCreateAppointment) {
        setTab("patient-appointment");
      }
    }, []);

    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const hn = params.get("HN");
      const id = params.get("ID");
      if (hn && id) {
        setTab("patient-appointment");
      }
    }, []);

    // * ต้องการ ค่าที่ update แล้วตอน DidMount
    useEffect(() => {
      goToCreateAppointmentRef.current = props.goToCreateAppointment;
    }, [props.goToCreateAppointment]);

    useEffect(() => {
      return () => {
        const locationSearch = goToCreateAppointmentRef.current?.locationSearch;

        props.onEvent({
          message: "HandleUnmountEncounter",
          params: {
            notResetKeys: ["initialLayout"],
            onUnmounted: () => {
              const isEncounterTabActive =
                document.querySelector("#tab-Encounter")?.className === "active";

              if (isEncounterTabActive) {
                props.onEvent({
                  message: "HandleGoBackEncounter",
                  params: { locationSearch },
                });
              } else {
                props.setProp("goBackEncounterState", null);
                props.setProp("goToCreateAppointment", null);
              }
            },
          },
        });
      };
    }, []);

    useEffect(() => {
      props.onEvent({ message: "HandleDidMountEncounter", params: {} });
    }, []);

    const handleChangeHN = useCallback(
      (e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setHn(data.value);
      },
      []
    );

    const handleChangeName = useCallback(
      (e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setName(data.value);
      },
      []
    );

    const handleChangeDOB = useCallback((data: any) => {
      setDob(data);
    }, []);

    const handleChangeStartDate = useCallback((data: any) => {
      setStartDate(data);
    }, []);

    const handleChangeEndDate = useCallback((data: any) => {
      setEndDate(data);
    }, []);

    const handleChangeDivision = useCallback(
      (e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setDivision(data.value);
      },
      []
    );

    const handleChangeDoctor = useCallback(
      (e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setDoctor(data.value);
      },
      []
    );

    return (
      <div
        id="CardAppointmentList"
        style={{
          height: "100%",
          overflow: "auto",
        }}
      >
        <div style={{ display: "flex", margin: "5px" }}>
          {MENU_ITEMS.map(([name, title]) => (
            <div key={name}>
              {!CONFIG.V3_HIDDEN_TAB_LIST.includes(name) && (
                <MenuItem tab={tab} name={name} title={title} setTab={setTab} />
              )}
            </div>
          ))}
        </div>
        {tab === "appointment-list" ? (
          <CardAppointmentListUX
            setProp={props.setProp}
            onEvent={props.onEvent}
            patientSearchText={props.patientSearchText}
            onSearchButton={(e: any) => {
              props.onEvent({ message: "SearchPatient", params: {} });
            }}
            onSearchKey={(e: any) => {
              if (e.key === "Enter") props.onEvent({ message: "SearchPatient", params: {} });
            }}
            patientList={props.patientList}
            appointmentList={props.appointmentList}
            patientRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
              return {
                style: {
                  backgroundColor:
                    rowInfo?.original?.id === props.selectedPatientId ? "#cccccc" : "white",
                },
                onClick: () => {
                  props.onEvent({
                    message: "SelectPatientForAppointment",
                    params: { id: rowInfo?.original?.patient },
                  });
                  props.layoutProps.layout === 0 && props.layoutProps?.forward();
                },
              };
            }}
            hideAppointmentTable={props.selectedPatientId === null}
            appointmentRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
              return {
                style: {
                  backgroundColor:
                    rowInfo?.original?.id === props.selectedAppointmentId ? "#cccccc" : "white",
                },
                onClick: () => {
                  console.log("  rowInfo?.original", rowInfo?.original);
                  props.onEvent({
                    message: "SelectAppointment",
                    params: { id: rowInfo?.original?.id },
                  });
                },
              };
            }}
            languageUX={props.languageUX}
          />
        ) : tab === "patient-appointment" ? (
          <CardPatientAppointment
            setProp={props.setProp}
            onEvent={props.onEvent}
            runSequence={props.runSequence}
            patientList={props.patientList}
            appointmentList={props.appointmentList}
            rescheduleList={props.rescheduleList}
            reconfirmList={props.reconfirmList}
            waitingList={props.waitingList}
            selectedPatient={props.selectedPatient}
            selectedAppointment={props.selectedAppointment}
            selectedBlock={props.selectedBlock}
            blockList={props.blockList}
            divisionList={props.divisionList}
            selectedDivision={props.selectedDivision}
            searchedItemList={props.searchedItemList}
            availableSlots={props.availableSlots}
            masterOptions={props.masterOptions}
            OrderTreatmentSequence={props.OrderTreatmentSequence}
            treatmentOrderList={props.treatmentOrderList}
            successMessage={props.successMessage}
            errorMessage={props.errorMessage}
            procedureItems={props.procedureItems}
            secondaryItems={props.secondaryItems}
            treatmentTemplateList={props.treatmentTemplateList}
            preOrderList={props.preOrderList}
            // central lab
            OrderCentralLabSequence={props.OrderCentralLabSequence}
            userLabPermission={props.userLabPermission}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            selectedProgressCycle={props.selectedProgressCycle}
            doctorLabOrderList={props.doctorLabOrderList}
            // imaging
            imagingList={props.imagingList}
            orderImagingList={props.orderImagingList}
            loadingStatus={props.loadingStatus}
            buttonLoadCheck={props.buttonLoadCheck}
            selectedOperatingOption={props.selectedOperatingOption}
            showOrderAppointment={props.showOrderAppointment}
            hideDrugAllergy={props.hideDrugAllergy}
            // Ishealth-v3 port into CUDent (vice versa)
            controller={props.controller}
            proxyController={props.proxyController}
            patientAppointmentList={props.patientAppointmentList || []}
            searchedItemListWithKey={props.searchedItemListWithKey}
            userTokenize={props.userTokenize}
            patientAppType={props.masterOptions?.patientAppType}
            waitingListItemStatus={props.masterOptions?.waitingListItemStatus}
            allWaitingList={props.allWaitingList}
            waitingQueueList={props.waitingQueueList}
            staticCanCelQueueList={props.staticCanCelQueueList}
            duplicateAppointment={props.duplicateAppointment}
            reoccureDuplicateAppointment={props.reoccureDuplicateAppointment}
            chairList={props.chairList}
            allDivisionDict={props.allDivisionDict}
            searchBlockList={props.searchBlockList}
            // OR Request
            ORRequestSequence={props.ORRequestSequence}
            masterData={props.masterData}
            encounterPatientList={props.encounterPatientList}
            divisionId={props.divisionId}
            django={props.django}
            userProfile={props.userProfile}
            selectedOrOrder={props.selectedOrOrder}
            // Operating select date time
            OperatingDateTimeSequence={props.OperatingDateTimeSequence}
            selectedOperatingDetail={props.selectedOperatingDetail}
            operatingBlock={props.operatingBlock}
            selectOperatingDSBChange={props.selectOperatingDSBChange}
            selectedDoctor={props.selectedDoctor}
            selectedAnesthesiologist={props.selectedAnesthesiologist}
            selectedOperatingRoom={props.selectedOperatingRoom}
            estimateTimeText={props.estimateTimeText}
            isSelectTabCalendar={props.isSelectTabCalendar}
            operatingLocationRoom={props.operatingLocationRoom}
            //
            config={props.cardPatientAppointmentConfig}
            goToCreateAppointment={props.goToCreateAppointment}
            // CardDrugOrder
            selectedDrugItemDetail={props.selectedDrugItemDetail}
            selectedDrugItemDetailOptions={props.selectedDrugItemDetailOptions}
            drugSearchText={props.drugSearchText}
            drugSearchResult={props.drugSearchResult}
            DrugSelectSequence={props.DrugSelectSequence}
            selectedDrug={props.selectedDrug}
            drugOrderList={props.drugOrderList}
            drugOrderListLoading={props.drugOrderListLoading}
            drugOrder={props.drugOrder}
            renderDrugOrderItems={props.renderDrugOrderItems}
            drugDose={props.drugDose}
            drugQty={props.drugQty}
            drugDuration={props.drugDuration}
            drugDescription={props.drugDescription}
            drugPrn={props.drugPrn}
            drugSearchLoading={props.drugSearchLoading}
            // modal doctor certificate check
            modDoctorCertificate={props.modDoctorCertificate}
            // modal drug order template
            DrugOrderTemplateSequence={props.DrugOrderTemplateSequence}
            modReMedication={props.modReMedication}
            modConfirmDeleteDrug={props.modConfirmDeleteDrug}
            modDrugLabInteraction={props.modDrugLabInteraction}
            modDrugDisease={props.modDrugDisease}
            modDrugInteraction={props.modDrugInteraction}
            currentDoctor={props.currentDoctor}
            drugOrderItemEstimate={props.drugOrderItemEstimate}
            drugPermission={props.drugPermission}
            modSolventSelectionConfig={props.modSolventSelectionConfig}
            selectedVerbalDrugOrderWorking={props.selectedVerbalDrugOrderWorking}
            medReconcileCheck={props.medReconcileCheck}
            medReconcileIndex={props.medReconcileIndex}
            // package
            PackagePurchaseSequence={props.PackagePurchaseSequence}
            PackageDateTimeSequence={props.PackageDateTimeSequence}
            // consult
            consultDetail={props.consultDetail}
            consultData={props.consultData}
            // Imaging
            ImagingOrderSequence={props.ImagingOrderSequence}
            modXrayDetail={props.modXrayDetail}
            // ----- Dental Diagram
            clinicalFindingId={props.clinicalFindingId}
            clinicalFindingIndex={props.clinicalFindingIndex}
            clinicalFindings={props.clinicalFindingList || []}
            organ={props.organ}
            clinicaltags={props.clinicaltags}
            filterClinicalFindingIds={props.filterClinicalFindingIds || []}
            // Issue 64134
            userPosition={props.userPosition}
            // currentDoctor={props.currentDoctor}
            // Issue 64135
            myPermission={props.myPermission}
            providerInfo={props.providerInfo}
            // Feature 67072
            waitingListLoading={props.waitingListLoading}
            // Feature 68560 Appointment
            allDivisionList={props.allDivisionList}
            selectedAppointmentDivision={props.selectedAppointmentDivision}
            languageUX={props.languageUX}
          />
        ) : tab === "appointment-summary" ? (
          <CardAppointmentSummary
            // main control
            onEvent={props.onEvent}
            setProp={props.setProp}
            proxyController={props.proxyController}
            runSequence={props.runSequence}
            searchedItemList={props.searchedItemList}
            masterOptions={props.masterOptions}
            loadingStatus={props.loadingStatus}
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // config
            showOrderAppointment={props.showOrderAppointment}
            hideDrugAllergy={props.hideDrugAllergy}
            // handle check
            waitingList={props.waitingList}
            blockList={props.blockList}
            availableSlots={props.availableSlots}
            selectedPatient={props.selectedPatient}
            selectedAppointment={props.selectedAppointment}
            selectedDivision={props.selectedDivision}
            selectedBlock={props.selectedBlock}
            selectedEncounter={props.selectedEncounter}
            selectedEmr={props.selectedEmr}
            selectedProgressCycle={props.selectedProgressCycle}
            searchedItemListWithKey={props.searchedItemListWithKey}
            patientAppType={props.masterOptions?.patientAppType}
            appointmentSummaryList={props.appointmentSummaryList}
            appointmentSummaryPage={props.appointmentSummaryPage}
            loadingFilterAppList={props.loadingFilterAppList}
            loadingPrintAppList={props.loadingPrintAppList}
            chairList={props.chairList}
            divisionList={props.divisionList}
            allDivisionDict={props.controller.data.allDivisionDict}
            userTokenize={props.userTokenize}
            preOrderList={props.preOrderList}
            duplicateAppointment={props.duplicateAppointment}
            reoccureDuplicateAppointment={props.reoccureDuplicateAppointment}
            django={props.django}
            // consult
            consultDetail={props.consultDetail}
            consultData={props.consultData}
            // treatment order
            OrderTreatmentSequence={props.OrderTreatmentSequence}
            procedureItems={props.procedureItems}
            secondaryItems={props.secondaryItems}
            treatmentTemplateList={props.treatmentTemplateList}
            treatmentOrderList={props.treatmentOrderList}
            // imaging order
            imagingList={props.imagingList}
            orderImagingList={props.orderImagingList}
            // lab order
            OrderCentralLabSequence={props.OrderCentralLabSequence}
            userLabPermission={props.userLabPermission}
            doctorLabOrderList={props.doctorLabOrderList}
            // Imaging
            ImagingOrderSequence={props.ImagingOrderSequence}
            modXrayDetail={props.modXrayDetail}
            // ----- Dental Diagram
            clinicalFindingId={props.clinicalFindingId}
            clinicalFindingIndex={props.clinicalFindingIndex}
            clinicalFindings={props.clinicalFindingList || []}
            organ={props.organ}
            clinicaltags={props.clinicaltags}
            filterClinicalFindingIds={props.filterClinicalFindingIds || []}
            languageUX={props.languageUX}
          />
        ) : tab === "listmybplus" ? (
          <CardAppointmentListUX
            // feature 56561
            advanceSearch={true}
            onChangeHN={handleChangeHN}
            hn={hn}
            onChangeName={handleChangeName}
            name={name}
            onChangeDOB={handleChangeDOB}
            dob={dob}
            onChangeStartDate={handleChangeStartDate}
            startDate={startDate}
            onChangeEndDate={handleChangeEndDate}
            endDate={endDate}
            onChangeDivision={handleChangeDivision}
            division={division}
            onChangeDoctor={handleChangeDoctor}
            doctor={doctor}
            maxDateDOB={formatDate(new Date())}
            divisionList={props.divisionOptions}
            setProp={props.setProp}
            onEvent={props.onEvent}
            patientSearchText={props.patientSearchText}
            onSearchButton={(e: any) => {
              console.log(" onSearchButton ", hn, name, doctor, division, startDate, endDate);
              props.onEvent({
                message: "SearchPatient",
                params: {
                  type: "mybplus",
                  ...(hn && { hn }),
                  ...(name && { name }),
                  ...(doctor && { doctor }),
                  ...(division && { division }),
                  ...(startDate && { startDate }),
                  ...(endDate && { endDate }),
                  ...(props.selectedDivision?.organization?.code && {
                    hospital: props.selectedDivision?.organization?.code,
                  }),
                },
              });
            }}
            // onSearchKey={(e: any) => {
            //   if (e.key === "Enter")
            //     props.onEvent({
            //       message: "SearchPatient",
            //       params: { type: "mybplus" },
            //     });
            // }}
            patientList={filterPatientListMyBplus}
            appointmentList={props.appointmentList}
            patientRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
              return {
                style: {
                  backgroundColor:
                    rowInfo?.original?.id === props.selectedPatientId ? "#cccccc" : "white",
                },
                onClick: () => {
                  props.onEvent({
                    message: "SelectPatientForAppointment",
                    params: { id: rowInfo?.original?.patient },
                  });
                  props.layoutProps.layout === 0 && props.layoutProps?.forward();
                },
              };
            }}
            hideAppointmentTable={props.selectedPatientId === null}
            appointmentRowProps={(state: any, rowInfo: any, column: any, instance: any) => {
              return {
                style: {
                  backgroundColor:
                    rowInfo?.original?.id === props.selectedAppointmentId ? "#cccccc" : "white",
                },
                onClick: () => {
                  props.onEvent({
                    message: "SelectAppointment",
                    params: { id: rowInfo?.original?.id },
                  });
                },
              };
            }}
            languageUX={props.languageUX}
          />
        ) : tab === "dash-appointment" ? (
          <AppointmentDashboard
            controller={props.appointmentDashboardController}
            languageUX={props.languageUX}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
);

const MenuItem = (props: any) => (
  <Button
    size="tiny"
    color={props.name === props.tab ? "blue" : null}
    onClick={(e: any) => props.setTab(props.name)}
  >
    {props.title}
  </Button>
);

CardAppointmentList.displayName = "CardAppointmentList"
export default CardAppointmentList;
