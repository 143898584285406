import React, {
  useMemo,
  forwardRef,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import { Form, Input, TextArea, Button } from "semantic-ui-react";

import ComboBox from "react-lib/apps/common/ComboBox"
import DateTextBox from "react-lib/apps/common/DateTextBox"
import SearchBox from "react-lib/apps/common/SearchBox"
import ModAuthen from "react-lib/apps/common/ModAuthen"
import ErrorMessage from "react-lib/apps/common/ErrorMessage"

import * as Util from "../../utils";
import moment from "moment";

// Config
import CONFIG from "config/config";
import { useIntl } from "react-intl";

const SubScannerForm = forwardRef((props: any, ref) => {
  const intl = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const [scanDocId, setScanDocId] = useState(null);
  const [documentType, setDocumentType] = useState<any>(null);
  const [documentTypeName, setDocumentTypeName] = useState("");
  const [documentNo, setDocumentNo] = useState("");
  const [version, setVersion] = useState("");
  const [ownerDivision, setOwnerDivision] = useState(null);
  const [documentCategory, setDocumentCategory] = useState(null);
  const [documentTypeOptions, setDocumentTypeOptions] = useState(null);
  const [documentDate, setDocumentDate] = useState(Util.formatDate(moment()));
  const [expiryDate, setExpiryDate] = useState("");
  const [remark, setRemark] = useState("");
  const [openModEditAuthen, setOpenModEditAuthen] = useState(false);
  const [openModCancelAuthen, setOpenModCancelAuthen] = useState(false);
  const [cancelNote, setCancelNote] = useState("");
  const [noDocTypeError, setNoDocTypeError] = useState<any>(null);

  const docTypeRef = useRef<any>();
  const doctorRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    clearForm: () => {
      clearForm();
    },
    fillRow: () => {
      // @ts-ignore
      return fillRow();
    },
  }));

  useEffect(() => {
    setIsEditing(props.isEditing);
  }, [props.isEditing]);

  useEffect(() => {
    setScanDocId(props.scanDocId);
  }, [props.scanDocId]);

  useEffect(() => {
    if (props.data && Object.keys(props.data).length > 0) {
      fillFormEdit(props.data);
    }
  }, [props.data]);

  const clearForm = () => {
    docTypeRef.current?.clear()

    setDocumentType(null);
    setDocumentTypeName("");
    setDocumentNo("");
    setVersion("");
    setOwnerDivision(null);
    setDocumentDate(Util.formatDate(moment()));
    setExpiryDate("");
    setRemark("");
    setIsEditing(false);
  };

  const fillFormEdit = (row: any) => {
    console.log(" fillFormEdit row: ", row)
    console.log(" fillFormEdit doctorRef: ", doctorRef)
    console.log(" fillFormEdit props.showDocumentCategory", props.showDocumentCategory)
    if (row.id && !row.id.toString().includes("fakeId")) {
      setScanDocId(row.id);
    }
    if (row.doctor_fullname) {
      doctorRef?.current?.setTextByElement(row.doctor_fullname);
    }
    setDocumentType(row.document_type);
    setDocumentTypeName(row.document_type_name || row.document_type_name_en);
    setDocumentNo(row.document_no);
    setVersion(row.version);
    setOwnerDivision(row.owner_division);
    setDocumentDate(row.document_date);
    setExpiryDate(row.expiry_date);
    setRemark(row.remark);
    setIsEditing(true);
    if (props.showDocumentCategory) {
      handleChangeCategory(row.document_category_id);
    }
  };

  const fillRow = (row: any) => {
    return {
      document_type_name: docTypeRef.current.getText(),
      document_type: docTypeRef.current.getId(),
      document_no: documentNo,
      version: version,
      owner_division: ownerDivision,
      document_date: documentDate,
      expiry_date: expiryDate,
      remark: remark,
    };
  };

  useEffect(() => {
    if (props.defaultDocName) {
      setDocumentType(props.defaultDocName.id ? props.defaultDocName.id : "");
      setDocumentTypeName(
        props.defaultDocName.name ? props.defaultDocName.name : ""
      );
    }
  }, [props.defaultDocName]);

  const buttonScanContent = useMemo(() => {
    let content = "";

    if (isEditing) {
      content = !props.buttonEditTextTh ? "EDIT" : "แก้ไข";
    } else if (CONFIG.SCAN_VIA_FILE_BROWSE) {
      content = "Browse File";
    } else {
      content = !props.buttonEditTextTh ? "SCAN" : "สแกน";
    }

    return content;
  }, [props.buttonEditTextTh, isEditing]);

  const handleChangeCategory = async (id: any) => {
    setDocumentCategory(id);

    const [optionsData, optionsError] = await props.onGetDocumentTypeOptions({
      searchText: "",
      documentCategory: id,
    });
    const options = optionsData.map((item: any, index: any) => ({
      key: index,
      text: item.name || item.name_en,
      value: item.id,
    }));
    setDocumentTypeOptions(options);
  };

  return (
    <Form>
      <ModAuthen
        testid="modCancelAuthen"
        open={openModCancelAuthen}
        approveButtonText="ตกลง"
        denyButtonText="ปิด"
        titleColor="red"
        titleName="ยกเลิกเอกสาร"
        onDeny={() => setOpenModCancelAuthen(false)}
        error={props.cancelDocError}
        loading={props.loading}
        onCloseWithDimmerClick={() => setOpenModCancelAuthen(false)}
        onApprove={({ username, password }: any = {}) =>
          props.onCancelApprove({ username, password, cancelNote, scanDocId })
        }
      >
        <Form.Field
          label="เหตุผลในการยกเลิก"
          error={props.cancelDocError ? true : false}
        />
        <Form.Field error={props.cancelDocError ? true : false}>
          <TextArea
            value={cancelNote}
            onChange={(e) => setCancelNote(e.target.value)}
          />
        </Form.Field>
      </ModAuthen>
      <ModAuthen
        testid="modEditAuthen"
        open={openModEditAuthen}
        approveButtonText="ตกลง"
        denyButtonText="ปิด"
        titleName="แก้ไขเอกสาร"
        onDeny={() => setOpenModEditAuthen(false)}
        error={props.editDocError}
        onCloseWithDimmerClick={() => setOpenModEditAuthen(false)}
        loading={props.loading}
        onApprove={({ username, password }: any = {}) =>
          props.onEditApprove({
            username,
            password,
            scanDocId,
            documentType: props.showDocumentCategory
              ? documentType
              : docTypeRef.current.getId(),
            documentNo,
            version,
            ownerDivision,
            documentDate,
            expiryDate,
            remark,
            documentCategory: documentCategory,
            encounterDoctor: doctorRef.current?.getId(),
          })
        }
      />
      <ErrorMessage error={noDocTypeError} />
      <Form.Group>
        {!props.showDocumentCategory ? (
          <Form.Field width={9} error={noDocTypeError ? true : false}>
            <label>{intl.formatMessage({ id: "ชื่อเอกสาร" })}</label>
            <SearchBox
              ref={docTypeRef}
              defaultId={documentType}
              defaultText={documentTypeName}
              textField="name"
              onGetSearchOptions={props.onGetDocumentTypeOptions}
              fetchOnFocus={true}
              onSelectOption={() => setNoDocTypeError(null)}
            />
          </Form.Field>
        ) : (
          <Form.Field width={9}>
            <label>{intl.formatMessage({ id: "แผนกเจ้าของเอกสาร" })}</label>
            <ComboBox
              value={ownerDivision}
              options={props.divisionOptions}
              onChange={(e: any, { value }: any) => setOwnerDivision(value)}
              fluid
              search
              placeholder="ไม่ระบุ"
            />
          </Form.Field>
        )}
        <Form.Field width={4}>
          <label>{intl.formatMessage({ id: "เลขที่เอกสาร" })}</label>
          <Input
            value={documentNo}
            onChange={(e) => setDocumentNo(e.target.value)}
          />
        </Form.Field>
        <Form.Field width={3}>
          <label>Version</label>
          <Input
            type="number"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        {!props.showDocumentCategory ? (
          <Form.Field width={16}>
            <label>{intl.formatMessage({ id: "แผนกเจ้าของเอกสาร" })}</label>
            <ComboBox
              value={ownerDivision}
              options={props.divisionOptions}
              onChange={(e: any, { value }: any) => setOwnerDivision(value)}
              clearable
              fluid
              placeholder="ไม่ระบุ"
            />
          </Form.Field>
        ) : (
          <>
            <Form.Field width={8}>
              <label>{intl.formatMessage({ id: "ประเภทเอกสาร" })}</label>
              <ComboBox
                value={documentCategory}
                options={props.categoryOptions}
                search
                onChange={(e: any, { value }: any) => {
                  setDocumentType("");
                  handleChangeCategory(value);
                }}
                fluid
                placeholder="ไม่ระบุ"
              />
            </Form.Field>
            <Form.Field width={8} error={noDocTypeError ? true : false}>
              <label>{intl.formatMessage({ id: "ชื่อเอกสาร" })}</label>
              <ComboBox
                value={documentType}
                search
                options={documentTypeOptions}
                onChange={(e: any, { value }: any) => setDocumentType(value)}
                fluid
                placeholder="ไม่ระบุ"
              />
            </Form.Field>
          </>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Field width={8}>
          <label>{intl.formatMessage({ id: "วันที่ของเอกสาร" })}</label>
          <DateTextBox
            value={documentDate}
            onChange={(date: any) => setDocumentDate(date)}
          />
        </Form.Field>
        <Form.Field width={8}>
          <label>{intl.formatMessage({ id: "วันที่หมดอายุของเอกสาร" })}</label>
          <DateTextBox
            value={expiryDate}
            onChange={(date: any) => setExpiryDate(date)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group inline>
        {props.showDocumentCategory && (
          <Form.Field width={8}>
            <label>{intl.formatMessage({ id: "ทันตแพทย์" })}</label>
            <SearchBox
              ref={doctorRef}
              defaultId={doctorRef.current?.getId()}
              defaultText={doctorRef.current?.getText()}
              textField="name_code"
              onGetSearchOptions={props.onGetSearchOptions}
              fetchOnFocus={true}
            />
          </Form.Field>
        )}
        <Form.Field width={10}>
          <label>{intl.formatMessage({ id: "หมายเหตุ" })}</label>
          <TextArea
            rows={1}
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </Form.Field>
        <Form.Field style={{ flex: 1 }} />
        {!props.hideButtonCancel && (
          <Form.Field>
            <Button
              fluid
              color="red"
              content={scanDocId ? "ยกเลิก" : "ล้างข้อมูล"}
              style={{
                display: !isEditing ? "none" : null,
                minWidth: "max-content",
              }}
              onClick={() => {
                if (scanDocId) {
                  setOpenModCancelAuthen(true);
                } else {
                  props.cancelEditClicked();
                }
              }}
            />
          </Form.Field>
        )}

        <Form.Field style={{ padding: 0 }}>
          <Button
            testid="btnScan"
            fluid
            color={isEditing ? "orange" : "green"}
            content={buttonScanContent}
            style={{ minWidth: "max-content" }}
            onClick={() => {
              if (docTypeRef.current && !docTypeRef.current.getId()) {
                return setNoDocTypeError("กรุณาเลือกค่าในช่อง ประเภทเอกสาร");
              }
              if (props.showDocumentCategory && !documentType) {
                return setNoDocTypeError("กรุณาเลือกค่าในช่อง ชื่อเอกสาร");
              }
              if (isEditing) {
                if (scanDocId) {
                  setOpenModEditAuthen(true);
                } else {
                  props.onEditClicked();
                }
              } else {
                props.onScanClicked();
              }
            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
});

SubScannerForm.propTypes = {
  scanDocId: PropTypes.number,
  isEditing: PropTypes.bool,
  data: PropTypes.object,
  onGetDocumentTypeOptions: PropTypes.func,
  divisionOptions: PropTypes.array,
  onCancelApprove: PropTypes.func,
  cancelDocError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  editDocError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loading: PropTypes.bool,
  onEditApprove: PropTypes.func,
  onEditClicked: PropTypes.func,
  onScanClicked: PropTypes.func,
  cancelEditClicked: PropTypes.func,
  hideButtonCancel: PropTypes.bool,
  buttonEditTextTh: PropTypes.bool,
  showDocumentCategory: PropTypes.bool,
  categoryOptions: PropTypes.array,
  onGetSearchOptions: PropTypes.func,
};

SubScannerForm.defaultProps = {
  scanDocId: null,
  isEditing: false,
  data: {},
  onGetDocumentTypeOptions: () => [null, null],
  divisionOptions: [],
  onCancelApprove: () => {},
  cancelDocError: null,
  editDocError: null,
  loading: false,
  onEditApprove: () => {},
  onEditClicked: () => {},
  onScanClicked: () => {},
  cancelEditClicked: () => {},
  hideButtonCancel: false,
  buttonEditTextTh: false,
  showDocumentCategory: false,
  categoryOptions: [],
  onGetSearchOptions: () => {},
};

export default React.memo(SubScannerForm);
