import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Checkbox,
  Segment,
  Label,
  Dropdown,
  FormGroup,
  FormField,
  Form,
  Input,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const ModWaitingListManageUX = (props: any) => {
    return(
      <div>
        
        <Segment>
          <Label
            color="blue"
            ribbon={true}
            style={{fontSize: "1.2rem", fontWeight: "bold"}}>
            จัดการรายชื่อผู้ป่วย Waiting List
          </Label>
          <Label
            size="big">
            {props.waitingListName}
          </Label>
          <Label
            size="big"
            style={{float: "right", backgroundColor: "#5dbcd2", color: "white"}}>
            {props.divisionName || ""}
          </Label>
          <div
            style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "20px 0px 10px 0px"}}>
            
            <div
              style={{display: props.isHiddenGroup ? "none": "flex", flexDirection: "column", width: "100%", padding: "0px 5px"}}>
              
              <div>
                ชื่อ-สกุล / HN
              </div>
              <div
                style={{display: "flex", alignItems: "center",}}>
                
                <div
                  style={{margin: "10px 0px"}}>
                  {props.patientSelector}
                </div>
                <div
                  style={{display: !props.manageWaitingListPatient?"none":"grid", backgroundColor: "#dff6fb", padding: "0.75rem 1rem", margin: "0 0.5rem 0 1rem", borderRadius: "3px", width: "100%", gridTemplateColumns: "repeat(3,1fr)"}}>
                  
                  <div>
                    
                    <label
                      style={{marginLeft: "0.5rem"}}>
                      {props.manageWaitingListPatient?.full_name||"-"}
                    </label>
                  </div>
                  <div>
                    
                    <label
                      style={{fontWeight: "bold", marginRight: "1rem"}}>
                      ID/Passport
                    </label>
                    <label>
                      {props.manageWaitingListPatient?.citizen_passport||"-"}
                    </label>
                  </div>
                  <div>
                    
                    <label
                      style={{fontWeight: "bold", marginRight: "1rem"}}>
                      วันเดือนปีเกิด
                    </label>
                    <label>
                      {props.manageWaitingListPatient?.birthdate||"-"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{display: props.isHiddenGroup ? "none": "none", flexDirection: "column", width: "30%",padding: "0px 5px"}}>
              ทันตแพทย์ / นิสิต
              <div
                style={{width: "100%", margin: "10px 0px"}}>
                {props.doctorSelector}
              </div>
            </div>
            <div
              style={{flex:1}}>
              
            </div>
            <div
              style={{display: "flex", flexDirection: "column", alignSelf: "flex-end",paddingBottom: "12px"}}>
              {}
              <Button
                color="green"
                disabled={props.disableAddPatient}
                onClick={props.addPatientToWaitingList}
                size="small"
                style={{...(props.isHiddenGroup && {display: "none"}), minWidth: "max-content"}}>
                เพิ่มผู้ป่วยเข้า List
              </Button>
            </div>
            <div
              style={{display: "flex", flexDirection: "column",alignSelf: "flex-end",paddingBottom: "12px"}}>
              
              <Button
                color="blue"
                disabled={props.disabledPrint}
                onClick={props.printWaitingList}
                size="small"
                style={{minWidth: "max-content"}}>
                พิมพ์รายการทั้งหมด
              </Button>
            </div>
          </div>
          <div>
            {props.divider}
          </div>
          <div
            style={{display: "none", justifyContent: "flex-start", alignItems: "center", padding: "10px 10px"}}>
            
            <Checkbox
              checked={props.isAppointed}
              label="แสดงรายชื่อผู้ป่วยที่มีนัดหมายแล้ว"
              onClick={props.toggleIsAppointed}
              style={{marginRight: "30px"}}>
            </Checkbox>
            <Checkbox
              checked={props.isOnHoldQ}
              label="แสดงรายชื่อผู้ป่วยที่พักคิว"
              onClick={props.toggleIsOnHoldQ}
              style={{marginRight: "30px",}}>
            </Checkbox>
          </div>
          <div
            style={{display:"flex",justifyContent:"space-between",alignItems:"flex-start",marginTop: "2px"}}>
            
            <Form>
              <FormGroup
                inline={true}>
                <FormField
                  style={{display: "flex", alignItems: "center"}}>
                  <label>
                    HN
                  </label>
                  <Input
                    onChange={props.onChangeHN}
                    value={props.hn}>
                  </Input>
                </FormField>
                <FormField
                  style={{display: "flex", alignItems: "center"}}>
                  <label
                    style={{minWidth: "max-content"}}>
                    เลขที่คิว
                  </label>
                  <Input
                    onChange={props.onChangeQueueNumber}
                    value={props.queueNumber}>
                  </Input>
                </FormField>
                <FormField
                  style={{display: "flex", alignItems: "center"}}>
                  <label
                    style={{minWidth: "max-content"}}>
                    วันที่เข้าคิว
                  </label>
                  <DateTextBox
                    onChange={props.onChangeStartDate}
                    value={props.startDate}>
                  </DateTextBox>
                </FormField>
                <FormField
                  style={{display: "flex", alignItems: "center"}}>
                  <label>
                    ถึง
                  </label>
                  <DateTextBox
                    onChange={props.onChangeEndDate}
                    value={props.endDate}>
                  </DateTextBox>
                </FormField>
                <FormField
                  style={{display:"flex",alignItems:"center"}}>
                  <label>
                    สถานะ
                  </label>
                  <Dropdown
                    clearable={true}
                    multiple={true}
                    onChange={props.onChangeFilterStatus}
                    options={props.statusOptions}
                    selection={true}
                    style={{minWidth: "250px"}}
                    value={props.filterStatus}>
                  </Dropdown>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}>
                <FormField>
                  <label>
                    เลือกแพทย์
                  </label>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex",alignItems:"center",width:"250px"}}>
                    {props.doctorFilters}
                  </div>
                </FormField>
                <FormField>
                  <Checkbox
                    checked={props.noDoctorFilters}
                    label="แสดงเฉพาะไม่กำหนดแพทย์"
                    onChange={props.onChangeNoDoctorFilters}>
                  </Checkbox>
                </FormField>
                <FormField
                  style={{marginLeft:"20px"}}>
                  <label>
                    วันที่ Complete case 
                  </label>
                  <DateTextBox
                    onChange={props.onChangeCompleteCaseStart}
                    value={props.completeCaseStart}>
                  </DateTextBox>
                </FormField>
                <FormField>
                  <label>
                    ถึง
                  </label>
                  <DateTextBox
                    onChange={props.onChangeCompleteCaseEnd}
                    value={props.completeCaseEnd}>
                  </DateTextBox>
                </FormField>
              </FormGroup>
            </Form>
            <Button
              color="blue"
              loading={props.waitingListLoading}
              onClick={props.onClickSearch}>
              ค้นหา
            </Button>
          </div>
          <Table
            data={props.waitingListItems}
            getTrProps={props.getTrPropsWaitingList}
            headers={props.headers}
            keys={props.keys}
            minRows="4"
            showPagination={false}
            style={{maxHeight: "50vh"}}
            widths={props.widths}>
          </Table>
          <div>
            {props.pagination}
          </div>
          <div
            className="--override-disabled"
            style={{ marginTop:"15px", display: props.disableAssign ? "none" : "block" }}>
            
            <div
              style={{display: "flex", flexDirection:"row"}}>
              
              <div
                style={{width: "50%"}}>
                {props.providerSelector}
              </div>
              <div
                style={{display:"none", justifyContent: "center", alignItems:"center"}}>
                ประเภทการนัดหมาย
                <Dropdown
                  onChange={props.onChangeTreatmentType}
                  options={props.treatmentTypeOptions}
                  selection={true}
                  style={{marginLeft:"10px"}}
                  value={props.treatmentType}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{margin:"20px 0px 10px 0px",fontWeight: "bold", display:"flex",justifyContent:"flex-start",gap:"100px",alignItems:"center"}}>
              รายละเอียดงาน
              <div
                style={{display:props.isDoctor?"none":"flex",justifyContent:"center",alignItems:"center",gap:"20px"}}>
                
                <label>
                  วันที่ Complete Case
                </label>
                <DateTextBox
                  disabled={props.isWaitingListReadonly || props.disabledMultiDetail}
                  onChange={props.onChangeCompleteCaseDate}
                  value={props.completeCaseDate}>
                </DateTextBox>
              </div>
            </div>
            <div
              className="ui form"
              style={{position: "relative"}}>
              
              <TextArea
                onChange={props.changeNote}
                readOnly={props.isWaitingListReadonly || props.disabledMultiDetail}
                style={{width: "100%", margin :"0px 0px 20px 0px"}}
                value={props.note}>
              </TextArea>
              <div
                style={{display: props.disabledMultiDetail?"flex":"none",opacity: 0.75, position: "absolute",alignItems: "center", height: "100%", top: "-0.75rem", left: "1rem", color: "red"}}>
                {"ไม่สามารถแสดงรายละเอียดงาน กรณีเลือก เลขที่คิวมากกว่า 1 รายการ" }
              </div>
            </div>
            <div
              style={{display:props.isWaitingListReadonly?"none":"flex", flexDirection: "row"}}>
              
              <Button
                color="green"
                disabled={props.disableAssign || props.disabledMultiDetail || !props.buttonActions?.save}
                onClick={props.assignWaitingListItems}
                size="tiny"
                style={{marginRight: "10px"}}>
                Save
              </Button>
              <Button
                color="orange"
                disabled={props.disabledPauseQ || !props.buttonActions?.stall}
                onClick={props.onClickPauseQ}
                size="tiny"
                style={{marginRight: "10px", display: props.isDoctor?"none":""}}>
                พักคิว
              </Button>
              <Button
                color="blue"
                disabled={props.disabledResumeQ || !props.buttonActions?.recall}
                onClick={props.onClickResumeQ}
                size="tiny"
                style={{marginRight: "10px",display: props.isDoctor?"none":""}}>
                คืนคิว
              </Button>
              <Button
                color="red"
                disabled={!props.buttonActions?.cancel}
                onClick={props.onCancelTreatmentPlan}
                size="tiny"
                style={{marginRight: "10px",display: props.isDoctor?"none":""}}>
                ยกเลิกวางแผนการรักษา
              </Button>
              <Button
                disabled={!props.buttonActions?.sent}
                onClick={props.onSendToClinic}
                size="tiny"
                style={{backgroundColor: "#5bbdd2", color: "white",display: props.isDoctor?"none":""}}>
                ส่งต่อคลินิก
              </Button>
            </div>
          </div>
          <div
            style={{padding: "10px",display:props.openModClinic ? "":"none"}}>
            {props.OpenClinic}
          </div>
        </Segment>
      </div>
    )
}


export default ModWaitingListManageUX

export const screenPropsDefault = {"patientSelector":"[Patient Selector]","providerSelector":"[Provider Selector]"}

/* Date Time : Thu Nov 14 2024 12:51:07 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 20,
      "props": {
        "data": {
          "type": "code",
          "value": "props.waitingListItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrPropsWaitingList"
        },
        "headers": {
          "type": "code",
          "value": "props.headers"
        },
        "keys": {
          "type": "code",
          "value": "props.keys"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"50vh\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.widths"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\", padding: \"20px 0px 10px 0px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มผู้ป่วยเข้า List"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableAddPatient"
        },
        "onClick": {
          "type": "code",
          "value": "props.addPatientToWaitingList"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(props.isHiddenGroup && {display: \"none\"}), minWidth: \"max-content\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\", justifyContent: \"flex-start\", alignItems: \"center\", padding: \"10px 10px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isAppointed"
        },
        "label": {
          "type": "value",
          "value": "แสดงรายชื่อผู้ป่วยที่มีนัดหมายแล้ว"
        },
        "onClick": {
          "type": "code",
          "value": "props.toggleIsAppointed"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"30px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.providerSelector"
        },
        "style": {
          "type": "code",
          "value": "{width: \"50%\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "--override-disabled"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop:\"15px\", display: props.disableAssign ? \"none\" : \"block\" }"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "Save"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableAssign || props.disabledMultiDetail || !props.buttonActions?.save"
        },
        "onClick": {
          "type": "code",
          "value": "props.assignWaitingListItems"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายการทั้งหมด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrint"
        },
        "onClick": {
          "type": "code",
          "value": "props.printWaitingList"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ทันตแพทย์ / นิสิต"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isHiddenGroup ? \"none\": \"none\", flexDirection: \"column\", width: \"30%\",padding: \"0px 5px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.isWaitingListReadonly?\"none\":\"flex\", flexDirection: \"row\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดงาน"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"20px 0px 10px 0px\",fontWeight: \"bold\", display:\"flex\",justifyContent:\"flex-start\",gap:\"100px\",alignItems:\"center\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.OpenClinic"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\",display:props.openModClinic ? \"\":\"none\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Segment",
      "parent": 0,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "จัดการรายชื่อผู้ป่วย Waiting List"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "ribbon": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.isHiddenGroup ? \"none\": \"flex\", flexDirection: \"column\", width: \"100%\", padding: \"0px 5px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSelector"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin: \"10px 0px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\", alignSelf: \"flex-end\",paddingBottom: \"12px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\",alignSelf: \"flex-end\",paddingBottom: \"12px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOnHoldQ"
        },
        "label": {
          "type": "value",
          "value": "แสดงรายชื่อผู้ป่วยที่พักคิว"
        },
        "onClick": {
          "type": "code",
          "value": "props.toggleIsOnHoldQ"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"30px\",}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 31,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeTreatmentType"
        },
        "options": {
          "type": "code",
          "value": "props.treatmentTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.treatmentType"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection:\"row\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{display:\"none\", justifyContent: \"center\", alignItems:\"center\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "พักคิว"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPauseQ || !props.buttonActions?.stall"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickPauseQ"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", display: props.isDoctor?\"none\":\"\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "คืนคิว"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledResumeQ || !props.buttonActions?.recall"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickResumeQ"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\",display: props.isDoctor?\"none\":\"\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกวางแผนการรักษา"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "!props.buttonActions?.cancel"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelTreatmentPlan"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\",display: props.isDoctor?\"none\":\"\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Label",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.waitingListName"
        },
        "size": {
          "type": "value",
          "value": "big"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งต่อคลินิก"
        },
        "color": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "!props.buttonActions?.sent"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSendToClinic"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#5bbdd2\", color: \"white\",display: props.isDoctor?\"none\":\"\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorFilters"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",alignItems:\"center\",width:\"250px\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"space-between\",alignItems:\"flex-start\",marginTop: \"2px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormGroup",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 47,
      "name": "DateTextBox",
      "parent": 45,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Form",
      "parent": 43,
      "props": {
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 52,
      "name": "DateTextBox",
      "parent": 50,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เข้าคิว"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Dropdown",
      "parent": 55,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterStatus"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"250px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterStatus"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divider"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Button",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.waitingListLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pagination"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 69,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Checkbox",
      "parent": 63,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.noDoctorFilters"
        },
        "label": {
          "type": "value",
          "value": "แสดงเฉพาะไม่กำหนดแพทย์"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNoDoctorFilters"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 66,
      "name": "DateTextBox",
      "parent": 68,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.isWaitingListReadonly || props.disabledMultiDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCompleteCaseDate"
        },
        "value": {
          "type": "code",
          "value": "props.completeCaseDate"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ Complete Case"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.isDoctor?\"none\":\"flex\",justifyContent:\"center\",alignItems:\"center\",gap:\"20px\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormGroup",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Input",
      "parent": 70,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeHN"
        },
        "value": {
          "type": "code",
          "value": "props.hn"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่คิว"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Input",
      "parent": 73,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeQueueNumber"
        },
        "value": {
          "type": "code",
          "value": "props.queueNumber"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 69,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 69,
      "props": {
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ Complete case "
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 81,
      "name": "DateTextBox",
      "parent": 77,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeCompleteCaseStart"
        },
        "value": {
          "type": "code",
          "value": "props.completeCaseStart"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 82,
      "name": "DateTextBox",
      "parent": 78,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeCompleteCaseEnd"
        },
        "value": {
          "type": "code",
          "value": "props.completeCaseEnd"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 69,
      "props": {
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกแพทย์"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Label",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionName || \"\""
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "big"
        },
        "style": {
          "type": "code",
          "value": "{float: \"right\", backgroundColor: \"#5dbcd2\", color: \"white\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSelector"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 0px\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: !props.manageWaitingListPatient?\"none\":\"grid\", backgroundColor: \"#dff6fb\", padding: \"0.75rem 1rem\", margin: \"0 0.5rem 0 1rem\", borderRadius: \"3px\", width: \"100%\", gridTemplateColumns: \"repeat(3,1fr)\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล / HN"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "code",
          "value": "props.manageWaitingListPatient?.full_name||\"-\""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "ID/Passport"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"1rem\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.manageWaitingListPatient?.citizen_passport||\"-\""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเดือนปีเกิด"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginRight: \"1rem\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.manageWaitingListPatient?.birthdate||\"-\""
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "TextArea",
      "parent": 110,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeNote"
        },
        "readOnly": {
          "type": "code",
          "value": "props.isWaitingListReadonly || props.disabledMultiDetail"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", margin :\"0px 0px 20px 0px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 111,
      "void": true
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "code",
          "value": "\"ไม่สามารถแสดงรายละเอียดงาน กรณีเลือก เลขที่คิวมากกว่า 1 รายการ\" "
        },
        "style": {
          "type": "code",
          "value": "{display: props.disabledMultiDetail?\"flex\":\"none\",opacity: 0.75, position: \"absolute\",alignItems: \"center\", height: \"100%\", top: \"-0.75rem\", left: \"1rem\", color: \"red\"}"
        }
      },
      "seq": 112,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "ModWaitingListManageUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "patientSelector": "[Patient Selector]",
    "providerSelector": "[Provider Selector]"
  },
  "width": 40
}

*********************************************************************************** */
