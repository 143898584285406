import WasmController from "react-lib/frameworks/WasmController";
import DoctorFeeRuleList from "issara-sdk/apis/DoctorFeeRuleList_apps_DFC";
import DoctorFeeOrderList from "issara-sdk/apis/DoctorFeeOrderList_apps_DFC";
import DoctorFeeOrderCreateUpdateList from "issara-sdk/apis/DoctorFeeOrderCreateUpdateList_apps_DFC";
import DoctorFeeOrderPreviewList from "issara-sdk/apis/DoctorFeeOrderPreviewList_apps_DFC";
import EmployeeDetailByUsername from "issara-sdk/apis/EmployeeDetailByUsername_users";
import DoctorFeeOrderDetail from "issara-sdk/apis/DoctorFeeOrderDetail_apps_DFC";
import ServiceCompensationItemList from "issara-sdk/apis/ServiceCompensationItemList_apps_DFC";
import ServiceCompensationItemDetail from "issara-sdk/apis/ServiceCompensationItemDetail_apps_DFC";
import ServiceCompensationItemUpdateListView from "issara-sdk/apis/ServiceCompensationItemUpdateListView_apps_DFC";
import UserTokenizeView from "issara-sdk/apis/UserTokenizeView_users";
import CancelServiceCompensationItemView from "issara-sdk/apis/CancelServiceCompensationItemView_apps_DFC";

import CONFIG from "config/config";

export type State = {
  selectedEncounter?: any;
  providerEmployeeInfo?: any;
  currentDoctor?: any;
  SetDoctorFeeSequence?: {
    sequenceIndex: string | null;
    searchResult?: any[];
    searchProductResult?: any[];
    dfItems?: any[];
    scItems?: any[];
    dfPreviewItems?: any[];
    scPreviewItems?: any[];
    tokenize? : any;
    editSCIdxType? : any[];
  } | null;
  tokenize: any;
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any;
};

export const StateInitial: State = {
  SetDoctorFeeSequence: null,
  successMessage: null,
  errorMessage: null,
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
  userProfile?: any;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetDoctorFeeSequence) return;
  if (!state.selectedEncounter) return;

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["officerList", {}],
        ["doctorFeeCancelNote", {}],
        ["dfRuleCategory", {}],
      ],
    },
  });

  const existingDF = await DoctorFeeOrderList.list({
    params: { encounter: state.selectedEncounter.id, limit: 99999 },
    apiToken: controller.apiToken,
  });
  console.log(existingDF[1] ? existingDF[1] : existingDF[0]);

  const serviceItemList = await ServiceCompensationItemList.get({
    params: {
      encounter: state.selectedEncounter.id,
      limit: 99999,
    },
    apiToken: controller.apiToken,
  });
  console.log(serviceItemList[1] ? serviceItemList[1] : serviceItemList[0]);

  let dfPreviewItems: Record<string, any>[] = existingDF[0] ? existingDF[0].items : [];

  const employeeId = controller.data.userProfile?.employee;
  const userId = controller.data.userProfile?.id;

  dfPreviewItems = dfPreviewItems.filter((item) => {
    const isEditable = employeeId === item.received_employee || userId === item.edited_by_user_id;

    return CONFIG.DOCTOR_FEE_USER_FILTER ? isEditable : true;
  });

  controller.setState({
    SetDoctorFeeSequence: {
      ...state.SetDoctorFeeSequence,
      sequenceIndex: "EditDF",
      dfPreviewItems,
      scPreviewItems: serviceItemList[0] ? serviceItemList[0].items : [],
      dfItems: []
    },
  });
};

export const EditDF: Handler = async (controller, params) => {
  let state = controller.getState();
  if (!state.SetDoctorFeeSequence) return;
  if (!state.selectedEncounter) return;
  if (params?.action === "search" && params?.searchText?.length >= 3) {
    console.log("search");
    const df = await DoctorFeeRuleList.list({
      encounter: state.selectedEncounter.id,
      params: { keyword: params?.searchText },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    console.log(df[1] ? df[1] : df[0]);
    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        searchResult: df[0] ? df[0].items || [] : [],
      },
    });
  } else if (params?.action === "add" && params?.item) {
    const item = {
      encounter: state.selectedEncounter.id,
      doctor_fee_rule: params?.item?.id,
      doctor_fee_rule_display: params?.item?.name,
      service_code: params?.item?.service_code,
      quantity: 1,
      price_unit: params?.item?.price_unit,
      received_employee_display: state.providerEmployeeInfo?.employee_info?.full_name,
      compensation_price: params?.item?.price_unit,
      received_employee: state.providerEmployeeInfo?.employee_info?.id,
    };

    const dfItems = [...(state.SetDoctorFeeSequence.dfItems || []), item];
    const dfPreviewList = (state.SetDoctorFeeSequence.dfPreviewItems || []).filter(
      (item: any) => item.id
    );

    const dfPreview = await DoctorFeeOrderPreviewList.post({
      data: { items: dfItems },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    console.log(dfPreview[1] ? dfPreview[1] : dfPreview[0]);
    const dfPreviewItems = (dfPreview[0]?.items || []).map((item: any) => ({
      ...item,
      encounter: state.selectedEncounter.id,
    }));
    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        searchResult: [],
        dfItems: dfItems,
        dfPreviewItems: [...dfPreviewList, ...dfPreviewItems],
      },
    });
  } else if (params?.action === "save") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [response, error] = await DoctorFeeOrderCreateUpdateList.create({
      data: {
        items: state.SetDoctorFeeSequence?.dfPreviewItems || [],
      } as any,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    // console.log(dfSave[1] ? dfSave[1] : dfSave[0]);
    if (response) {
      controller.setState(
        {
          successMessage: { ...state.successMessage, [params?.sequence]: "บันทึกสำเร็จ" },
          SetDoctorFeeSequence: {
            ...state.SetDoctorFeeSequence,
            sequenceIndex: "START",
          },
          errorMessage: {
            ...state.errorMessage,
            [params.sequence]: null,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
        },
        () => controller.handleEvent({ message: "RunSequence", params })
      );
    }
    if (error) {
      // params.onFailed?.(error);
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: error },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    }
  } else if (params?.action === "SaveSC") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [response, error] = await ServiceCompensationItemUpdateListView.put({
      data: {
        items: state.SetDoctorFeeSequence?.scPreviewItems || [],
      } as any,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (error) {
      // params.onFailed?.( error );
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.sequence]: error,
        },
      });
    } else {
      state = controller.getState();

      controller.setState({
        successMessage: { ...state.successMessage, [params?.sequence]: "บันทึกสำเร็จ" },
        errorMessage: {
          ...state.errorMessage,
          [params.sequence]: null,
        },
        SetDoctorFeeSequence: {
          ...state.SetDoctorFeeSequence,
          scPreviewItems: response ? response.items : [],
          editSCIdxType: [],
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
      });
    }
  } else if (params?.action === "AddSC") {
    const [response, error] = await ServiceCompensationItemList.post({
      data: {
        invoice_item: params.invoiceId,
        officer: params.officer,
        received_employee: params.employee,
        compensation_price: params.amount,
      },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    state = controller.getState();

    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        tokenize: {}
      },
    })

    if (!error) {
      Start(controller, {});
    } else {
      console.log("error: ", error);
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.sequence]: error },
        SetDoctorFeeSequence: {
          ...state.SetDoctorFeeSequence,
        },
      });
    }

  } else if (params?.action === "DeleteSC") {
    const [response, error] = await CancelServiceCompensationItemView.delete({
      pk: params.id,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (!error) {
      Start(controller, {});
    } else {
      alert(" ไม่สามารถลบได้ ");
    }
  } else if (params?.action === "clear") {
    controller.setState({
      successMessage: { ...state.successMessage, [params?.sequence]: null },
      errorMessage: { ...state.errorMessage, [params?.sequence]: null },
    });
  } else if (params?.action === "update") {
    // console.log("update params: ", params)
    const data = params.data;
    let items = state.SetDoctorFeeSequence?.dfPreviewItems || [];
    let dfItems = state.SetDoctorFeeSequence?.dfItems || [];

    const mapItems = (item: any) => {
      items = items.map((acc: any, index: number) =>
        index === data.index ? { ...acc, ...item } : acc
      );
    };

    if (params.data.type === "compensation_price") {
      mapItems({ compensation_price: data.value });
    } else if (params.data.type === "received_employee") {
      if (data.value) {
        const employee = await EmployeeDetailByUsername.get({
          apiToken: controller.apiToken,
          username: data.value,
        });

        if (!employee[0]) {
          return params.onFailed?.(employee[1]);
        }

        mapItems({
          received_employee_display: employee[0].name_code,
          received_employee: employee[0].id,
        });
      } else {
        mapItems({ received_employee_display: "" });
      }
    } else if (params.data.type === "perform_datetime") {
      mapItems({ perform_datetime: data.value });
    } else if (params.data.type === "delete") {
      const sumIdNotNull = items.reduce((sum, item) => (item.id ? (sum += 1) : sum), 0);
      dfItems = dfItems.filter((_: any, index: any) => index !== data.index - sumIdNotNull);

      if (data.value) {
        if ("username" in params.data) {
          const { userId, error } = await controller.handleEvent({
            message: "CheckUserCredentials",
            params: { ...params.data, username: params.data.username },
          });

          if (!userId) {
            params.onFailed(error);

            return null;
          }
        }

        await DoctorFeeOrderDetail.delete({
          apiToken: controller.apiToken,
          pk: data.value,
          extra: { data: { remark: params.data.remark } },
        });

        items = items.filter((_: any, index: any) => index !== data.index);
        // controller.setState({
        //   SetDoctorFeeSequence: {
        //     ...state.SetDoctorFeeSequence,
        //     sequenceIndex: 'START'
        //   }
        // }, () => controller.handleEvent({ message: "RunSequence", params }));
      } else {
        items = items.filter((_: any, index: any) => index !== data.index);
      }
    }

    let dfUpdate: any = null;

    if (["compensation_price", "received_employee"].includes(params.data.type)) {
      dfUpdate = await DoctorFeeOrderCreateUpdateList.create({
        data: {
          items,
        } as any,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      items = dfUpdate[0]?.items;
    }

    if (dfUpdate === null || dfUpdate?.[0]) {
      controller.setState({
        SetDoctorFeeSequence: {
          ...state.SetDoctorFeeSequence,
          dfPreviewItems: items,
          dfItems,
        },
      });
    } else {
      params.onFailed?.(dfUpdate[1]);
    }
  } else if (params?.action === "open_search") {
    const df = await DoctorFeeRuleList.list({
      encounter: state.selectedEncounter.id,
      params: { keyword: params?.keyword, category: params?.category },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    console.log(df[1] ? df[1] : df[0]);
    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        searchProductResult: df[0] ? df[0].items || [] : [],
      },
    });
  } else if (params?.action === "add_select" && params?.item) {
    const dfItems = [...(state.SetDoctorFeeSequence.dfItems || []), params.item];
    const dfPreviewList = (state.SetDoctorFeeSequence.dfPreviewItems || [])

    const dfPreview = await DoctorFeeOrderPreviewList.post({
      data: { items: params.item },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    console.log(dfPreview[1] ? dfPreview[1] : dfPreview[0]);
    const dfPreviewItems = (dfPreview[0]?.items || []).map((item: any) => ({
      ...item,
      encounter: state.selectedEncounter.id,
      edited_by_user_id: state.django?.user?.id,
    }));
    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        searchResult: [],
        dfItems: dfItems,
        dfPreviewItems: [...dfPreviewList, ...dfPreviewItems],
      },
    });
  } else if (params?.action === "UpdateSC") {
    const data = params.data;
    console.log('data: ', data);
    let items = state.SetDoctorFeeSequence?.scPreviewItems || [];
    let scItems = state.SetDoctorFeeSequence?.scItems || [];

    const mapItems = (item: any) => {
      items = items.map((acc: any, index: number) =>
        index === data.index ? { ...acc, ...item } : acc
      );
    };

    if (params.data.type === "compensation_price") {
      mapItems({ compensation_price: data.value });
    } else if (params.data.type === "received_employee") {

      mapItems({
        received_employee_display: data.receivedEmployeeName,
        received_employee: data.receivedEmployeeId
      });
      // if (data.value) {
      //   const employee = await EmployeeDetailByUsername.get({
      //     apiToken: controller.apiToken,
      //     username: data.value,
      //   });

      //   if (!employee[0]) {
      //     return params.onFailed?.(employee[1]);
      //   }

      //   mapItems({
      //     received_employee_display: employee[0].name_code,
      //     received_employee: employee[0].id,
      //   });
      // } else {
      //   mapItems({ received_employee_display: "" });
      // }
    }

    console.log("UpdateSC items: ", items)

    // Not Save yet

    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        scPreviewItems: items,
        editSCIdxType: [...(state.SetDoctorFeeSequence?.editSCIdxType || []), { index: data.index, type: data.type }]
      },
    });

    return


    let scUpdate: any = null;

    scUpdate = await ServiceCompensationItemUpdateListView.put({
      data: {
        items,
      } as any,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    // if (["compensation_price", "received_employee"].includes(params.data.type)) {
    //   scUpdate = await ServiceCompensationItemList.post({
    //     data: {
    //       id: params.data?.id,
    //       officer: params.data?.officer,
    //       received_employee: params.data?.employee,
    //       compensation_price: params.data?.amount,
    //     },
    //     extra: { division: controller.data.division },
    //     apiToken: controller.apiToken,
    //   });

    //   // items = scUpdate[0]?.items;
    // }

    if (scUpdate === null || scUpdate?.[0]) {
      Start(controller, {})
      // controller.setState({
      //   SetDoctorFeeSequence: {
      //     ...state.SetDoctorFeeSequence,
      //     scPreviewItems: items,
      //     scItems,
      //   },
      // });
    } else {
      params.onFailed?.(scUpdate[1]);
    }
  } else if (params?.action === "setTokenization") {
    let code: any = params?.params?.code;
    let idx: any = params?.params?.idx;
    let name: "dfPreviewItems" | "scPreviewItems" = params?.params?.name;

    // console.log(" setTokenization params: ", params)
    let prepare = state.SetDoctorFeeSequence?.[name] || [];
    prepare[idx].loading = true;
    prepare[idx].error = null;
    prepare[idx].token = "";
    prepare[idx].employeeName = "";
    // console.log('prepare: ', prepare);

    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        [name]: prepare,
      },
    });

    const [response, error, network] = await HandleGetTokenization(controller, {
      code,
      id: idx,
    });

    prepare = [...state.SetDoctorFeeSequence?.[name]] || [];

    if (error) {
      prepare[idx].loading = false;
      prepare[idx].error = error;
      controller.setState({
        SetDoctorFeeSequence: {
          ...state.SetDoctorFeeSequence,
          [name]: prepare,
        },
      });
      return;
    }

    let employeeName = decodeURIComponent(atob(response?.token.split(".")[1])) || "";
    prepare[idx].loading = false;
    prepare[idx].error = null;
    prepare[idx].token = response?.token || "";
    prepare[idx].employeeName = employeeName;

    prepare[idx].received_employee_display = employeeName;
    prepare[idx].received_employee = response?.employee ?? 0;

    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        [name]: prepare,
      },
    }, () => {
      if (params?.params?.name === "scPreviewItems") {
        // แก้ไข แพทย์ในตาราง
        EditDF(controller, {
          action: "UpdateSC",
          data: {
            receivedEmployeeName: employeeName,
            receivedEmployeeId: response?.employee,
            value: response?.employee,
            index: params.params.idx,
            type: "received_employee",
          },
          onFailed: (error: any) => {
            console.warn(" error", error)
          }
        })
      }
    });
  } else if (params?.action === "clearTokenization") {
    let idx: any = params?.params?.idx;
    let name: "dfPreviewItems" | "scPreviewItems" = params?.params?.name;

    let prepare = state.SetDoctorFeeSequence?.[name] || [];
    prepare[idx].employeeName = "";
    prepare[idx].token = "";
    prepare[idx].employeeName = "";

    prepare[idx].received_employee_display = "";
    prepare[idx].received_employee = 0;

    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        [name]: prepare,
      },
    });
  } else if (params?.action === "setTokenizationNoneIndex") {
    let code: any = params?.code;

    let tokenize: any = {};
    tokenize.tokenizeLoading = true;
    tokenize.tokennizeError = null;
    tokenize.tokenizeToken = "";
    tokenize.tokenizeEmployeeName = "";
    // console.log('prepare: ', prepare);

    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        tokenize,
      },
    });

    const [response, error, network] = await HandleGetTokenization(controller, { code });

    if (error) {
      tokenize.tokenizeLoading = false;
      tokenize.tokennizeError = error;
      controller.setState({
        SetDoctorFeeSequence: {
          ...state.SetDoctorFeeSequence,
          tokenize,
        },
      });
      return;
    }

    let employeeName = decodeURIComponent(atob(response?.token.split(".")[1])) || "";
    tokenize.tokenizeLoading = false;
    tokenize.tokennizeError = null;
    tokenize.tokenizeToken = response?.token || "";
    tokenize.tokenizeEmployeeName = employeeName;
    tokenize.tokenizeEmployeeId = response?.employee ?? 0;

    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        tokenize,
      },
    });
  } else if (params?.action === "clearTokenizationNoneIndex") {
    let tokenize: any = {};

    tokenize.tokenizeToken = "";
    tokenize.tokenizeEmployeeName = "";
    tokenize.tokenizeEmployeeId = 0;

    controller.setState({
      SetDoctorFeeSequence: {
        ...state.SetDoctorFeeSequence,
        tokenize,
      },
    });
  }
};
export const HandleGetTokenization: Handler = async (controller, params) => {
  // params should only have code
  const [response, error, network] = await UserTokenizeView.post({
    apiToken: controller.apiToken,
    data: { ...params },
  });

  return [response, error, network];
};
