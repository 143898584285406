import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Modal } from "semantic-ui-react";
import CardProgressNoteListUX from "./CardProgressNoteListUX";
import CardProgressNote from "react-lib/apps/DPI/CardProgressNote";
// import { ActionMenuColumn } from "./CardOrderSummary";
import ActionMenuColumn from "react-lib/apps/HISV3/DPI/ActionMenuColumn";
import { useIntl } from "react-intl";

// api
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";

// common
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { GENDER_LIST } from "react-lib/apps/common/CardPrintList";
import { MONTH_OPTION } from "react-lib/utils/dateUtils";

// pdf
import FormProgressNote from "./pdfForm/FormProgressNote";

const CardProgressNoteList = (props: any) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [progressNoteId, setProgressNoteId] = useState<number | null>(null);

  const getListProgressionNote = useCallback(() => {
    props.onEvent({
      message: "GetListProgressionNote",
      params: { emrId: props.selectedEmr?.id },
    });
  }, [props.selectedEmr?.id]);

  useEffect(() => {
    getListProgressionNote();
  }, [props.selectedEmr?.id]);

  const handleClickAction = useCallback((data: any) => {
    setIsOpen(true);
    setProgressNoteId(data.item.id);
  }, []);

  const progressionNoteItems = useMemo(() => {
    return (props.progressionNoteList || []).map((item: any, index: number) => ({
      ...item,
      menu: (
        // <ActionMenuColumn
        //   key={"menu" + index}
        //   item={item}
        //   allowedActions={["EDIT"]}
        //   isDoctor={props.DJANGO.user?.id === item.editer_id}
        //   onClickAction={handleClickAction}
        // />
        <ActionMenuColumn
          actions={["EDIT"]}
          isButtonDisabled={props.DJANGO.user?.id !== item.editer_id}
          item={item}
          onCallback={() => {
            handleClickAction?.({
              item,
            });
          }}
        />
      ),
    }));
  }, [props.progressionNoteList, props.DJANGO]);

  const handleSuccessCallback = () => {
    getListProgressionNote();
    handleClose();
  };

  const handleCloseModProgressNote = () => {
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
    setProgressNoteId(null);
  };

  const handleCreate = () => {
    setIsOpen(true);
  };

  const handlePrint = async () => {
    // console.log("Yeti Data for Print: ", {items: progressionNoteItems, patient: props.selectedPatient, encounter: props.selectedEncounter});

    const getItems = progressionNoteItems?.map(({ menu, ...resp }: any) => resp);
    const getDoctorCertNumber = await getDoctorNumber(props, props.selectedEncounter?.doctor);

    const docDef = await FormProgressNote({
      printer: props.DJANGO?.user?.full_name || "",
      items: getItems || [],
      detail: {
        hn: props.selectedPatient?.hn,
        number: props.selectedEncounter?.number || props.selectedPatient?.encounter_no || "-",
        patientName: props.selectedPatient?.full_name /*props.selectedPatient?.full_name_en ||*/,
        patient_gender_name:
          GENDER_LIST[props.selectedPatient?.gender].name_th || props.selectedPatient?.gender_name,
        patient_birthdate: props.selectedPatient?.birthdate,
        patient_age: props.selectedPatient?.full_age,
        patient_citizen_no:
          props.selectedPatient?.citizen_passport || props.selectedPatient?.citizen_no || "-",
        telMobile: props.selectedPatient?.present_address?.tel_mobile || "",
        visit_date: formatVisitDate(props.selectedEncounter?.created) || "-",
        doctorName: props.selectedEncounter?.doctor_name || "-",
        doctor_number: getDoctorCertNumber,
      },
    });

    const pdfMake = await getPdfMake(true);
    const pdf = pdfMake.createPdf(docDef);

    pdf.open();
  };

  // console.log("CardProgressNoteList", props);

  return (
    <div>
      <CardProgressNoteListUX
        onCreate={handleCreate}
        onPrint={handlePrint}
        hideButtonCreate={!props.DJANGO.user?.role_types?.includes("DOCTOR")}
        progressionNoteItems={progressionNoteItems}
        languageUX={props.languageUX}
      />
      <Modal open={isOpen} size="large">
        {/* @ts-ignore */}
        <CardProgressNote
          apiToken={props.apiToken}
          django={props.DJANGO}
          createModule="DPI"
          doctorId={props.selectedEncounter.doctor}
          emrId={props.selectedEmr?.id}
          progressionNoteId={progressNoteId as number}
          isTitleReporter={!!progressNoteId}
          rows={5}
          // callback
          successCallback={handleSuccessCallback}
          hideCallback={handleCloseModProgressNote}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

/**============================================
 **                   UTILS
 *=============================================**/
export const formatVisitDate = (date: string) => {
  if (!date) {
    return "-";
  }

  const [datePart, timePart] = date.split(" ");
  const [day, month, year] = datePart.split("/");

  const getDay = parseInt(day);
  const getThaiMonth = MONTH_OPTION.find((opt: any) => opt.number === month)?.text || month;

  return `${getDay} ${getThaiMonth} ${year}`;
};

/**============================================
 **                   APIS
 *=============================================**/
export const getDoctorNumber = async (props: any, doctorId: number) => {
  if (!doctorId) {
    return "-";
  }

  const [doctorDetailResp, doctorDetailErr] = await DoctorDetail.retrieve({
    pk: doctorId,
    apiToken: props.apiToken,
  });

  if (doctorDetailErr) {
    return "-";
  } else {
    return doctorDetailResp?.certificate_no || "-";
  }
};

export default CardProgressNoteList;
