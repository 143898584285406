import React, { CSSProperties, MutableRefObject, useCallback, useMemo, useRef } from "react";

import { CheckboxProps, DropdownProps, InputProps } from "semantic-ui-react";

// Common
import { useIntl } from "react-intl";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import { mapOptions } from "react-lib/apps/HISV3/common/CommonInterface";
import SearchBoxDropdown, { OnEventHandler } from "react-lib/appcon/common/SearchBoxDropdown";

// UX
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";
import CardProductInfoUX from "./CardProductInfoUX";
import SubProductIngredients from "./SubProductIngredients";

// Interface
import {
  BUTTON_ACTIONS,
  OptionType,
  ProductDetailType,
  RequiredFieldType,
  RunSequence,
} from "./sequence/StockManagement";

import { beToAd } from "react-lib/utils/dateUtils";

// Types
type CardProductInfoProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => void) => any;
  // seq
  runSequence: RunSequence;
  // data
  drugListingData?: Record<string, any>[];
  drugNameFormatData?: Record<string, any>[];
  languageUX?: string;
  productDetail?: Partial<ProductDetailType>;
  showRequiredField?: RequiredFieldType | null;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  // options
  billModeOptions?: any[];
  dosageOptions?: OptionType[];
  modeOptions?: any[];
  productTypeDrugSupplyOptions: any[];
  unitOptions?: OptionType[];
  // callback
  onClose?: () => void;
};

// Const
type Styles = Record<"input" | "searchBox", CSSProperties>;

const styles: Styles = {
  input: { width: "100%" },
  searchBox: { width: "100%" },
};

const PRODUCT_NAME = "name";
const PRODUCT_ID = "id";

const OUTSIDE_LABEL = "[OUTSIDE]";

const CARD_PRODUCT_INFO = "CardProductInfo";

const CardProductInfo = (props: CardProductInfoProps) => {
  const intl = useIntl();
  const boxRef = useRef() as MutableRefObject<HTMLDivElement>;

  // Callback
  const handleChangeValue = useCallback(
    (e: any, data: CheckboxProps | DropdownProps | InputProps) => {
      let value = typeof data.checked === "boolean" ? data.checked : data.value;

      const name: string = data.name;
      const [first, second] = name.split(".") as [keyof ProductDetailType, string];

      const detail = { ...props.productDetail };

      const priceKey: (keyof ProductDetailType)[] = [
        "price_normal",
        "price_special",
        "price_premium",
        "price_foreign",
        "price_pledge",
        "max_discount",
        "overall_cose",
      ];

      // type number value ไม่น้อยกว่า 0
      // type number value ไม่น้อยกว่า 0, supply
      if (priceKey.includes(first) || second === "stock_size") {
        if (!value) {
          value = "";
        } else if (Number(value) < 0) {
          value = 0;
        }
      }
      // set default value เมื่อเปลี่ยน P-type
      else if (first === "product_type") {
        if (value === "DRUG") {
          delete detail.supply;
          detail.drug = { drug_ingredients: [{}] };
        } else if (value === "SUPPLY") {
          delete detail.drug;
          detail.supply = {};
        }
      }

      detail[first] = second
        ? {
            ...(detail as any)[first],
            [second]: value,
          }
        : value;

      props.setProp("StockManagementSequence.productDetail", { ...detail });
    },
    [props.productDetail]
  );

  const handleChangeDate = useCallback(
    (name: keyof ProductDetailType) => async (date: string) => {
      if (!!date && date.length !== 10) {
        return;
      }

      if (name === "start_date") {
        const start = beToAd(date);
        const end = beToAd(props.productDetail?.end_date || "");
        const diff = start?.diff(end, "days") || 0;

        // clear end date หาก start date มากกว่า end date
        if (start && end && diff >= 1) {
          await props.setProp("StockManagementSequence.productDetail.end_date", "");
        }
      }

      handleChangeValue(null, { name, value: date });
    },
    [handleChangeValue, props.productDetail]
  );

  const handleSave = useCallback(() => {
    props.runSequence({
      sequence: "StockManagement",
      action: "ADD_PRODUCT",
      btnAction: BUTTON_ACTIONS.SAVE,
      card: CARD_PRODUCT_INFO,
      errorKey: CARD_STOCK_MANAGEMENT,
      onSuccess: props.onClose,
    });
  }, []);

  const handleCancel = useCallback(() => {
    props.onClose?.();
  }, []);

  const handleEventSearch: OnEventHandler = useCallback(
    (data) => {
      const { params } = data;
      const searchText = params.searchText || "";
      const key = `searchedItemListWithKey.${params.type}_${params.id}`;
      const filter = (props.unitOptions || []).flatMap((option) =>
        option.text.toLowerCase().includes(searchText.toLowerCase())
          ? [{ id: option.value, name: option.text }]
          : []
      );

      props.setProp(key, params.action === "clear" ? [] : filter);
    },
    [props.unitOptions]
  );

  const handleClickDate = useCallback(() => {
    const modal = boxRef.current.closest(".ui.modal.active");

    if (modal) {
      modal.scrollTop = modal.scrollHeight;
    }
  }, []);

  const mapProductOptions = useCallback(
    (items: any[]) =>
      items.map((item) => ({
        key: item[PRODUCT_ID],
        text: item[PRODUCT_NAME],
        value: item[PRODUCT_ID],
      })),
    []
  );

  const handleSelectedItem = useCallback(
    (data: { id: string; name: string; type: string }) => async (value: any) => {
      handleChangeValue(null, { name: data.name, value: value || null });
    },
    [handleChangeValue, props.productDetail, props.searchedItemListWithKey]
  );

  const getGenericName = useCallback(
    (drug?: ProductDetailType["drug"], isIncludeStrength = false) => {
      const drugIngredients = drug?.drug_ingredients;

      if (drugIngredients) {
        const ingredientNames = drugIngredients.map((item, index) => {
          const items: Record<string, any>[] =
            props.searchedItemListWithKey?.[`ADRingredient_${index + 1}`] || [];

          const foundItem = items.find((acc) => acc.id === item.ingredient);
          const name: string = foundItem?.name;

          if (!name) {
            return "";
          }

          return isIncludeStrength && item.strength ? `${name} ${item.strength}` : name;
        });

        return ingredientNames.filter(Boolean).join(" + ");
      }

      return null;
    },
    [props.searchedItemListWithKey]
  );

  const getDrugName = useCallback((name = "", isOutsideDrug = false) => {
    if (!name) {
      return "";
    }

    return isOutsideDrug ? `${name} ${OUTSIDE_LABEL}` : name;
  }, []);

  const getExtraInfo = (
    data: Partial<ProductDetailType>,
    dosageOptions: typeof props.dosageOptions
  ) => {
    const strength = data.strength ? ` ${data.strength}` : "";
    const foundDosageForm = dosageOptions?.find(
      (option) => Number(option.value) === data.dosage_form
    );
    const dosageForm = foundDosageForm ? ` ${foundDosageForm.text}` : "";
    const container = data.container ? ` ${data.container}` : "";

    return { container, dosageForm, strength };
  };

  const getCustomFormat = (trade: string, genericText: string, extraInfo: any) => {
    const { container, dosageForm, strength } = extraInfo;

    const formatted = (
      <>
        {trade} (<span style={{ opacity: genericText ? 1 : 0.45 }}>{genericText || "Custom"}</span>)
        {strength}
        {dosageForm}
        {container}
      </>
    );

    const plain = `${trade} (${genericText})${strength}${dosageForm}${container}`.trim();

    return { formatted, plain };
  };

  const getNormalFormat = (trade: string, genericText: string, format: string, extraInfo: any) => {
    const { container, dosageForm, strength } = extraInfo;
    const name = genericText && format !== "WITHOUT" ? `${trade} (${genericText})` : trade;
    const text = `${name}${strength}${dosageForm}${container}`.trim();

    return {
      formatted: text,
      plain: text,
    };
  };

  const getLabelName = useCallback(
    (data: Partial<ProductDetailType> = {}) => {
      const format: string =
        props.drugNameFormatData?.find((item) => item.id === data.drug_name_format)?.name || "";

      if (!format) {
        const empty = "";

        return { formatted: empty, plain: empty };
      }

      const trade = getDrugName(data.name, data.is_outside_drug);
      const genericText = {
        CUSTOM: data.custom_generic || "",
        FULL: getGenericName(data.drug, false),
        FULL_STRENGTH: getGenericName(data.drug, true),
        WITHOUT: "",
      }[format];

      const extraInfo = getExtraInfo(data, props.dosageOptions);

      return format === "CUSTOM"
        ? getCustomFormat(trade, genericText || "", extraInfo)
        : getNormalFormat(trade, genericText || "", format, extraInfo);
    },
    [getDrugName, getGenericName, props.dosageOptions, props.drugNameFormatData]
  );

  const formattedDrugName = useMemo(
    () => getLabelName(props.productDetail).formatted,
    [getLabelName, props.productDetail]
  );

  const drugListingOptions = useMemo(
    () =>
      (props.drugListingData || []).map((item) => ({
        key: item.id,
        text: `${item.name} - ${item.label}`,
        value: item.id,
      })),
    [props.drugListingData]
  );

  const drugNameFormatOptions = useMemo(
    () => mapOptions(props.drugNameFormatData || [], "id", "label"),
    [props.drugNameFormatData]
  );

  const baseUnitSearch = useMemo(
    () => (
      <SearchBoxDropdown
        key="BaseUnitSearch"
        onEvent={handleEventSearch}
        id="1"
        icon="search"
        selectedItem={props.productDetail?.supply?.base_unit || null}
        style={styles.searchBox}
        fluid
        useWithKey
        searchedItemListWithKey={props.searchedItemListWithKey}
        setSelectedItem={handleSelectedItem({
          id: "1",
          name: "supply.base_unit",
          type: "Unit",
        })}
        mapOptions={mapProductOptions}
      />
    ),
    [
      handleEventSearch,
      handleSelectedItem,
      mapProductOptions,
      props.productDetail?.supply?.base_unit,
      props.searchedItemListWithKey,
    ]
  );

  const buttonSave = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"green"}
        name={BUTTON_ACTIONS.SAVE}
        paramKey={`${CARD_PRODUCT_INFO}_${BUTTON_ACTIONS.SAVE}`}
        size="medium"
        title={intl.formatMessage({ id: "บันทึก" })}
        buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_PRODUCT_INFO}_${BUTTON_ACTIONS.SAVE}`]}
        onClick={handleSave}
      />
    ),
    [handleSave, props.buttonLoadCheck]
  );

  const endDate = useMemo(
    () => (
      <DateTextBox
        inputStyle={styles.input}
        minDate={props.productDetail?.start_date || ""}
        style={styles.input}
        value={props.productDetail?.end_date || ""}
        onChange={handleChangeDate("end_date")}
        onClick={handleClickDate}
      />
    ),
    [
      handleChangeDate,
      handleClickDate,
      props.productDetail?.end_date,
      props.productDetail?.start_date,
    ]
  );

  const manufacturerSearch = useMemo(
    () => (
      <SearchBoxDropdown
        key="ManufacturerSearch"
        onEvent={props.onEvent}
        id="1"
        icon="search"
        limit={20}
        selectedItem={props.productDetail?.supply?.manufacturer || null}
        style={styles.searchBox}
        type="Manufacturer"
        fluid
        useWithKey
        searchedItemListWithKey={props.searchedItemListWithKey}
        setSelectedItem={handleSelectedItem({
          id: "1",
          name: "supply.manufacturer",
          type: "Manufacturer",
        })}
        mapOptions={mapProductOptions}
      />
    ),
    [
      handleSelectedItem,
      mapProductOptions,
      props.productDetail?.supply?.manufacturer,
      props.searchedItemListWithKey,
    ]
  );

  const stockUnitSearch = useMemo(
    () => (
      <SearchBoxDropdown
        key="StockUnitSearch"
        onEvent={handleEventSearch}
        id="2"
        icon="search"
        selectedItem={props.productDetail?.supply?.stock_unit || null}
        style={styles.input}
        fluid
        useWithKey
        searchedItemListWithKey={props.searchedItemListWithKey}
        setSelectedItem={handleSelectedItem({
          id: "2",
          name: "supply.stock_unit",
          type: "Unit",
        })}
        mapOptions={mapProductOptions}
      />
    ),
    [
      handleEventSearch,
      handleSelectedItem,
      mapProductOptions,
      props.productDetail?.supply?.stock_unit,
      props.searchedItemListWithKey,
    ]
  );

  const startDate = useMemo(
    () => (
      <DateTextBox
        inputStyle={styles.input}
        style={styles.input}
        value={props.productDetail?.start_date || ""}
        onChange={handleChangeDate("start_date")}
        onClick={handleClickDate}
      />
    ),
    [handleChangeDate, handleClickDate, props.productDetail?.start_date]
  );

  const subProductIngredients = useMemo(() => {
    if (props.productDetail?.product_type === "DRUG") {
      return (props.productDetail.drug?.drug_ingredients || []).map((item, index) => (
        <SubProductIngredients
          key={`ingredients-${item.ingredient}`}
          onEvent={props.onEvent}
          setProp={props.setProp}
          data={item}
          // data
          index={index}
          // config
          // hideRemove={index === 0}
          languageUX={props.languageUX}
          productDetail={props.productDetail}
          // CommonInterface
          searchedItemListWithKey={props.searchedItemListWithKey}
        />
      ));
    }

    return null;
  }, [props.productDetail, props.searchedItemListWithKey]);

  return (
    <div ref={boxRef}>
      <CardProductInfoUX
        // data
        baseUnitSearch={baseUnitSearch}
        buttonSave={buttonSave}
        drugLabelName={formattedDrugName}
        endDate={endDate}
        // SubProductIngredients={
        //   props.productDetail?.product_type === "DRUG" &&
        //   (props.productDetail.drug?.drug_ingredients || []).map((item, index) => (
        //     <SubProductIngredients
        //       key={`ingredients-${item.ingredient}`}
        //       onEvent={props.onEvent}
        //       setProp={props.setProp}
        //       data={item}
        //       // data
        //       index={index}
        //       productDetail={props.productDetail}
        //       // CommonInterface
        //       searchedItemListWithKey={props.searchedItemListWithKey}
        //       // config
        //       // hideRemove={index === 0}
        //     />
        //   ))
        languageUX={props.languageUX}
        manufacturerSearch={manufacturerSearch}
        productDetail={props.productDetail}
        showRequiredField={props.showRequiredField}
        startDate={startDate}
        stockUnitSearch={stockUnitSearch}
        subProductIngredients={subProductIngredients}
        type={props.productDetail?.product_type}
        // options
        billModeOptions={props.billModeOptions}
        dosageOptions={props.dosageOptions}
        drugListingOptions={drugListingOptions}
        drugNameFormatOptions={drugNameFormatOptions}
        modeOptions={props.modeOptions}
        productTypeDrugSupplyOptions={props.productTypeDrugSupplyOptions}
        unitOptions={props.unitOptions}
        // callback
        onCancel={handleCancel}
        onChangeValue={handleChangeValue}
      />
    </div>
  );
};

CardProductInfo.displayName = "CardProductInfo";

export default React.memo(CardProductInfo);
