import { getLogoReportNResize } from "../common/CommonInterface";
import CompanyLogoForm from "../common/CompanyLogoForm";

const color = {
  greyHeader: "#D9D9D9",
  greySummary: "#E8E8E8",
  greyBorder: "#9F9F9F",
};

type FormPostOperationProps = {
  hn: string;
  patientName: string;
  birthdate: string;
  citizen: string;
  gender: string;
  age: string;
  tel: string;
  incisionDate: string;
  incisionDatetime: string;
  closureDatetime: string;
  primarySurgeon: string;
  assistantSurgeons: string;
  scrubNurse: string;
  circulatingNurse: string;
  anesthesiologist: string;
  anesthetist: string;
  anesthesiaType: string;
  principalDiagnosis: string;
  postOperativePrincipal: string;
  postOperativeSecondary: string;
  operation: string;
  operationPrincipal: string;
  operationSecondary: string;
  implant: string;
  specificConcern: string;
  typeOfWound: string;
  specimen: string;
  bloodLoss: string;
  tourniquet: string;
  operativeFindings: string;
  procedure: string;
  complication: string;
  uploadImg: any;
  userName: string;
  printDate: string;
};

const FORM_NAME = `FormPostOperation`;
const LOGO_HEIGHT = 30;

const FormPostOperation = async (props: FormPostOperationProps) => {
  var origin = window?.location?.origin;

  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    height: 30,
    form: FORM_NAME,
  });

  const { font, images } = companyLogoForm;

  const logoResize = await getLogoReportNResize(LOGO_HEIGHT, 1, FORM_NAME);

  const getBase64ImageFromURL = async (url: any) => {
    return await new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  };

  const uploadImageData = async () => {
    let imageNumber = Math.ceil(props.uploadImg?.length / 3);
    let imageData = [];
    for (let i = 0; i < imageNumber; i = i + 3) {
      let imageForm = await Promise.all(
        Array.from({ length: 3 }).map(async (item: any, index: number) => {
          let image = props.uploadImg?.[i + index]?.image;
          if (image) {
            let getBase64 = await getBase64ImageFromURL(image);
            return {
              border: [false, false, false, false],
              margin: [0, 0, 5, 0],
              table: {
                widths: ["100%"],
                body: [
                  [
                    {
                      width: 150,
                      image: getBase64,
                      alignment: "center",
                      border: [true, true, true, true],
                    },
                  ],
                ],
              },
              layout: {
                hLineStyle: function (i: any, node: any) {
                  return { dash: { length: 2, space: 2 } };
                },
                vLineStyle: function (i: any, node: any) {
                  return { dash: { length: 2, space: 2 } };
                },
                hLineColor: function (i: any) {
                  return "gray";
                },
                vLineColor: function (i: any) {
                  return "gray";
                },
              },
            };
          } else {
            return {
              text: " ",
              width: 150,
              border: [false, false, false, false],
            };
          }
        })
      );

      imageData.push(imageForm);
    }

    return imageData;
  };

  return {
    pageSize: "A4",
    pageMargins: [0, 119, 0, 25],
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 14,
    },
    styles: {
      facultyHeader: {
        fontSize: "16",
        bold: true,
      },
    },
    header: () => ({
      margin: [0, 10, 0, 0],
      stack: [
        {
          columns: [
            {
              width: "50%",
              columns: [
                {
                  margin: [0, 0, 0, 0],
                  width: logoResize.width,
                  height: LOGO_HEIGHT,
                  image: "logo",
                  alignment: "left",
                },
                {
                  margin: [0, 0, 0, 0],
                  width: "*",
                  stack: [
                    {
                      text: "Dental Hospital of the Faculty of Dentistry",
                      bold: true,
                      alignment: "center",
                    },
                    {
                      text: "Chulalongkorn University",
                      bold: true,
                      alignment: "center",
                    },
                    {
                      text: " ",
                      bold: true,
                      alignment: "center",
                    },
                    {
                      text: "OPERATIVE NOTE",
                      style: "facultyHeader",
                      margin: [25, -10, 0, 0],
                    },
                  ],
                },
              ],
            },
            {
              margin: [0, 0, 15, 0],
              width: "50%",
              columns: [
                {
                  width: "60%",
                  stack: [
                    {
                      text: [
                        {
                          text: "HN: ",
                          bold: true,
                        },
                        {
                          text: props.hn,
                        },
                      ],
                    },
                    {
                      text: [
                        {
                          text: "Name: ",
                          bold: true,
                        },
                        {
                          text: props.patientName,
                        },
                      ],
                    },
                    {
                      text: [
                        {
                          text: "Birthdate: ",
                          bold: true,
                        },
                        {
                          text: props.birthdate,
                        },
                      ],
                    },
                    {
                      text: [
                        {
                          text: "Citizen ID/ Passport: ",
                          bold: true,
                        },
                        {
                          text: props.citizen,
                        },
                      ],
                    },
                  ],
                },
                {
                  width: "40%",
                  stack: [
                    {
                      text: " ",
                    },
                    {
                      columns: [
                        {
                          text: "Sex: ",
                          alignment: "left",
                          bold: true,
                        },
                        {
                          width: "80%",
                          text: props.gender,
                          alignment: "right",
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          text: "Age: ",
                          alignment: "left",
                          bold: true,
                        },
                        {
                          width: "80%",
                          text: props.age,
                          alignment: "right",
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          text: "Tel: ",
                          alignment: "left",
                          bold: true,
                        },
                        {
                          width: "80%",
                          text: props.tel || "-",
                          alignment: "right",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          margin: [0, 10, 0, 0],
          table: {
            widths: ["100%"],
            body: [
              [
                {
                  text: " ",
                  border: [false, true, false, false],
                },
              ],
            ],
          },
          layout: {
            hLineStyle: function (i: any, node: any) {
              return { dash: { length: 1, space: 1 } };
            },
            vLineStyle: function (i: any, node: any) {
              return { dash: { length: 1, space: 1 } };
            },
          },
        },
      ],
    }),
    content: [
      {
        margin: [15, -20, 15, 0],
        stack: [
          {
            columns: [
              {
                width: "33.33%",
                columns: [
                  {
                    text: "Date of Operation ",
                    bold: true,
                  },
                  {
                    text: props.incisionDate,
                    alignment: "center",
                  },
                ],
              },
              {
                width: "33.33%",
                columns: [
                  {
                    text: "Start ",
                    alignment: "center",
                    bold: true,
                  },
                  {
                    text: props.incisionDatetime,
                    alignment: "center",
                  },
                ],
              },
              {
                width: "33.33%",
                columns: [
                  {
                    text: "Stop ",
                    alignment: "center",
                    bold: true,
                  },
                  {
                    text: props.closureDatetime,
                    alignment: "center",
                  },
                ],
              },
            ],
          },
          {
            stack: [
              {
                field_1: "Primary Surgeon",
                value_1: props.primarySurgeon,
                field_2: "Assistant Surgeon",
                value_2: props.assistantSurgeons,
              },
              {
                field_1: "Scrub nurse",
                value_1: props.scrubNurse,
                field_2: "Circulating nurse",
                value_2: props.circulatingNurse,
              },
              {
                field_1: "Anesthesiologist",
                value_1: props.anesthesiologist,
                field_2: "Nurse anesthetist",
                value_2: props.anesthetist,
              },
              {
                field_1: "Anesthesia type",
                value_1: props.anesthesiaType,
                field_2: " ",
                value_2: " ",
              },
            ].map((item) => ({
              columns: [
                {
                  width: "50%",
                  columns: [
                    {
                      width: "30%",
                      text: item.field_1,
                      bold: true,
                    },
                    {
                      text: item.value_1,
                    },
                  ],
                },
                {
                  width: "50%",
                  columns: [
                    {
                      width: "30%",
                      text: item.field_2,
                      bold: true,
                    },
                    {
                      text: item.value_2,
                    },
                  ],
                },
              ],
            })),
          },
          {
            stack: [
              {
                header: "Pre-Operative Diagnosis",
                detail: " ",
                data: [
                  {
                    field: "Principal Diagnosis",
                    value: props.principalDiagnosis,
                  },
                ],
              },
              {
                header: "Post-Operative Diagnosis",
                detail: " ",
                data: [
                  {
                    field: "Principal Diagnosis",
                    value: props.postOperativePrincipal || "-",
                  },
                  {
                    field: "Secondary Diagnosis",
                    value: props.postOperativeSecondary || "-",
                  },
                ],
              },
              {
                header: "Operation",
                detail: props.operation,
                data: [
                  {
                    field: "Principal Diagnosis",
                    value: props.operationPrincipal || "-",
                  },
                  {
                    field: "Secondary Diagnosis",
                    value: props.operationSecondary || "-",
                  },
                ],
              },
              {
                header: "Implant",
                detail: props.implant,
                data: [],
              },
              {
                header: "Specific Concern",
                detail: props.specificConcern,
                data: [],
              },
              {
                header: "Type of wound",
                detail: props.typeOfWound,
                data: [],
              },
              {
                header: "Specimen",
                detail: props.specimen,
                data: [],
              },
              {
                header: "Blood loss (mL)",
                detail: props.bloodLoss,
                data: [],
              },
              {
                header: "TourniTourniquet",
                detail: props.tourniquet,
                data: [],
              },
              {
                header: "Operative Finding",
                detail: props.operativeFindings,
                data: [],
              },
              {
                header: "Procedure",
                detail: props.procedure,
                data: [],
              },
              {
                header: "Complication",
                detail: props.complication,
                data: [],
              },
            ].map((item) => ({
              stack: [
                {
                  columns: [
                    {
                      text: item.header,
                      bold: true,
                      ...(["Operation", "Implant"].includes(item.header)
                        ? {
                            width: "13%",
                          }
                        : !["Pre-Operative Diagnosis", "Post-Operative Diagnosis"].includes(
                            item.header
                          ) && {
                            width: "18%",
                          }),
                    },
                    {
                      text: item.detail,
                      ...(["Operation", "Implant"].includes(item.header)
                        ? {
                            width: "82%",
                          }
                        : !["Pre-Operative Diagnosis", "Post-Operative Diagnosis"].includes(
                            item.header
                          ) && {
                            width: "77%",
                          }),
                    },
                  ],
                },
              ].concat(
                (item.data || []).map((data) => ({
                  columns: [
                    {
                      width: "18%",
                      text: data.field,
                    },
                    {
                      width: "82%",
                      text: data.value,
                    },
                  ],
                }))
              ),
            })),
          },
          {
            text: "Surgery illustration",
            bold: true,
            margin: [0, 0, 0, 10],
          },
          {
            table: {
              widths: ["33%", "33%", "33%"],
              body: await uploadImageData(),
            },
          },
          {
            margin: [0, 50, 0, 0],
            columns: [
              { width: "33%", text: " " },
              { width: "33%", text: " " },
              {
                width: "33%",
                alignment: "right",
                stack: [
                  {
                    text: ".........................................................................",
                    alignment: "center",
                  },
                  {
                    text: "(.........................................................................)",
                    alignment: "center",
                  },
                  {
                    text: "Primary/ Assistant Surgeon",
                    alignment: "center",
                    bold: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [0, 0, 0, 0],
        stack: [
          {
            table: {
              widths: ["100%"],
              body: [
                [
                  {
                    columns: [
                      {
                        width: "80%",
                        margin: [10, 0, 0, 0],
                        alignment: "left",
                        text: [
                          {
                            text: "วันเวลาที่พิมพ์เอกสาร ",
                          },
                          {
                            text: props.printDate,
                            italics: true,
                          },
                          {
                            text: " โดย ",
                          },
                          {
                            text: props.userName,
                          },
                        ],
                      },
                      {
                        width: "20%",
                        margin: [0, 0, 10, 0],
                        alignment: "right",
                        text: `หน้า ${currentPage.toString()}/${pageCount.toString()}`,
                      },
                    ],
                    border: [false, true, false, false],
                  },
                ],
              ],
            },
            layout: {
              hLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
              vLineStyle: function (i: any, node: any) {
                return { dash: { length: 1, space: 1 } };
              },
            },
          },
        ],
      };
    },
    images: {
      ...images,
    },
  };
};

export default FormPostOperation;
